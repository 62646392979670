import { Box, Link, Typography } from "@mui/material"
import {
    FIREFOX_RECALL_EXTENSION_STORE_URL,
    OnboardingStepsEnum,
    RECALL_EXTENSION_STORE_URL,
    isFirefox,
    posthogService,
    tutorials,
    useIsMobile,
    useOpenInNewTab,
} from "@recall/common"
import { STARTED_ONBOARDING_STEP } from "constants/events"
import { HOME_PATH, INSTALL_EXTENSION_PATH, SIGNUP_PATH } from "constants/routes"
import { useIsNative } from "hooks/useIsNative"
import { ReactNode, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"
import { SET_IS_ADD_MODAL_OPEN } from "storage/redux/app/actionTypes"
import { RootState } from "storage/redux/rootReducer"
import { SET_ACTIVE_TUTORIAL, TUTORIAL_RESTART_SINGLE } from "storage/redux/user/actionTypes"
import { useOpenOnboarding } from "./useOpenOnboarding"

export interface OnboardingSection {
    title: string
    description?: string
    steps: OnboardingStep[]
}

interface OnboardingStep {
    title: string
    completeLabel?: string
    isCompleted: boolean
    isEnabled: boolean
    isSkipped?: boolean
    skippedLabel?: string
    description: ReactNode
    handleComplete?: () => void
    handleSkip?: () => void
}

const ExtensionDescription = () => (
    <Typography variant="body2">
        <Box component="span" display="block" pb={2}>
            The Recall Browser Extension lets you save, summarize, and chat with content from
            webpages—including articles, blogs, YouTube videos, and PDFs. It's fully editable, so
            you can highlight text and take notes as you browse. Everything automatically syncs with
            your Recall knowledge base.
        </Box>
        <Box component="span" display="block" pb={2}>
            The extension also unlocks Augmented Browsing, which resurfaces relevant content from
            your knowledge base as you explore the web.
        </Box>
        <Box component="span" display="block">
            <Link
                target="_blank"
                href="https://docs.getrecall.ai/deep-dives/recall-augmented-browsing"
            >
                Learn more here.
            </Link>
        </Box>
    </Typography>
)
const AddContentToThroughExtensionDescription = () => (
    <Typography variant="body2">
        <Box component="span" display="block" pb={2}>
            Click the Recall extension or use the on-page widget to summarize and chat with the
            content you're viewing. When you're ready, hit Save to add it to your knowledge base.
        </Box>
        <Box component="span" display="block" pb={2}>
            Want everything auto-saved? You can turn this on in your settings. The extension is
            designed to save you time by helping you get to the key points—fast.
        </Box>
    </Typography>
)

const AddContentToRecallInAppDescription = () => (
    <Box fontSize="14px">
        <Typography variant="body2">
            Click{" "}
            <Box component="span" fontWeight={600}>
                “+ Create Card”
            </Box>{" "}
            in the top right corner of the app to start adding content. You'll see multiple options
            to bring different types of information into Recall:
        </Typography>
        <ul>
            <li>Paste a URL to summarize any online content</li>
            <li>Search Wikipedia – great for adding books, movies, people, and more</li>
            <li>Upload a PDF</li>
            <li>Import your bookmarks</li>
            <li>Create an empty note to write your own notes from scratch</li>
        </ul>
    </Box>
)

export const useSteps = () => {
    const onboarding = useSelector((state: RootState) => state.user.onboarding)
    const { closeOnboarding } = useOpenOnboarding()
    const isMobile = useIsMobile()
    const history = useHistory()
    const isNative = useIsNative()

    const dispatch = useDispatch()
    const { openInNewTab } = useOpenInNewTab()

    const isCompleted = useCallback(
        (step: OnboardingStepsEnum): boolean => {
            return onboarding.finished.includes(step)
        },
        [onboarding]
    )

    const makeHandleStart = (step: OnboardingStepsEnum) => () => {
        posthogService.captureEvent(STARTED_ONBOARDING_STEP, { step })
        switch (step) {
            case OnboardingStepsEnum.CREATE_CARD: {
                dispatch({ type: SET_ACTIVE_TUTORIAL, payload: tutorials.ITEMS_INTRO })
                dispatch({ type: TUTORIAL_RESTART_SINGLE, payload: tutorials.ITEMS_INTRO })
                closeOnboarding()
                break
            }
            case OnboardingStepsEnum.CREATE_LINK: {
                dispatch({ type: SET_ACTIVE_TUTORIAL, payload: tutorials.CREATE_LINK })
                dispatch({ type: TUTORIAL_RESTART_SINGLE, payload: tutorials.CREATE_LINK })
                closeOnboarding()
                break
            }
            case OnboardingStepsEnum.INSTALL_EXTENSION: {
                openInNewTab(
                    !isFirefox() ? RECALL_EXTENSION_STORE_URL : FIREFOX_RECALL_EXTENSION_STORE_URL
                )
                break
            }
            case OnboardingStepsEnum.CREATE_CARD_WITH_EXTENSION: {
                history.push(INSTALL_EXTENSION_PATH)
                break
            }
            case OnboardingStepsEnum.CREATE_ACCOUNT: {
                history.push(SIGNUP_PATH)
                break
            }
            case OnboardingStepsEnum.CREATE_CARD_IN_APP_SEARCH: {
                closeOnboarding()
                history.push(HOME_PATH)
                dispatch({ type: SET_IS_ADD_MODAL_OPEN, payload: true })
                break
            }
            case OnboardingStepsEnum.CREATE_CARD_WITH_SHARE_TARGET: {
                history.push(INSTALL_EXTENSION_PATH)
                break
            }
        }
    }

    const onboardingSections: OnboardingSection[] = [
        !isMobile && {
            title: "Experience the Recall Extension",
            steps: [
                {
                    title: "Install & Pin the Browser Extension",
                    isCompleted: isCompleted(OnboardingStepsEnum.INSTALL_EXTENSION),
                    description: <ExtensionDescription />,
                    isEnabled: true,
                    handleComplete: makeHandleStart(OnboardingStepsEnum.INSTALL_EXTENSION),
                },
                {
                    title: "Add Content to Recall Through the Extension",
                    description: <AddContentToThroughExtensionDescription />,
                    isCompleted: isCompleted(OnboardingStepsEnum.CREATE_CARD_WITH_EXTENSION),
                    isEnabled: isCompleted(OnboardingStepsEnum.INSTALL_EXTENSION),
                    handleComplete: makeHandleStart(OnboardingStepsEnum.CREATE_CARD_WITH_EXTENSION),
                },
            ],
        },
        {
            title: "Explore the Recall Knowledge base",
            steps: [
                isNative && {
                    title: "Add Content to Recall in the App",
                    isCompleted: isCompleted(OnboardingStepsEnum.CREATE_CARD_WITH_SHARE_TARGET),
                    description: "Use share intent to create knowledge cards.",
                    isEnabled: true,
                    handleComplete: makeHandleStart(
                        OnboardingStepsEnum.CREATE_CARD_WITH_SHARE_TARGET
                    ),
                },
                {
                    title: "Add Content to Recall in the App",
                    description: <AddContentToRecallInAppDescription />,
                    isCompleted: isCompleted(OnboardingStepsEnum.CREATE_CARD_IN_APP_SEARCH),
                    isEnabled: true,
                    handleComplete: makeHandleStart(OnboardingStepsEnum.CREATE_CARD_IN_APP_SEARCH),
                },
                {
                    title: "Link Content Together",
                    description: (
                        <Typography variant="body2">
                            Turn your saved cards into a connected network of knowledge. By linking
                            related content, you build a dynamic{" "}
                            <Box component="span" fontWeight={600}>
                                knowledge graph
                            </Box>{" "}
                            that intelligently surfaces relevant insights when you need
                            them—enhancing the utility of your knowledge base
                        </Typography>
                    ),
                    isCompleted: isCompleted(OnboardingStepsEnum.CREATE_LINK),
                    isEnabled: true,
                    handleComplete: makeHandleStart(OnboardingStepsEnum.CREATE_LINK),
                },
            ],
        },
    ]

    return onboardingSections.filter(Boolean).map((section) => ({
        ...section,
        steps: section.steps.filter(Boolean),
    })) as OnboardingSection[]
}
