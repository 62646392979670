import OpenInFullIcon from "@mui/icons-material/OpenInFull"
import { Box, SxProps, Theme, Tooltip } from "@mui/material"
import { Link2Off } from "lucide-react"
import React, { ReactNode } from "react"
import { ReferenceItem } from "../../editor"
import { ActionButton } from "../buttons/ActionButton"
import { IconContainer } from "../icons/IconContainer"
import { ItemPreview } from "./ItemPreview/ItemPreview"

export interface Options {
    state?: { elementId: string; isReaderMention?: boolean }
    reset?: boolean
    target?: string
}

interface Props {
    item: ReferenceItem | null
    children: React.ReactNode
    open: boolean
    actionButtonsStart?: ReactNode
    actionButtonsEnd?: ReactNode
    readOnly?: boolean
    disablePortal?: boolean
    openItemById: (id: string, options?: Options) => void
    deleteConnection: (_: string) => void
}

export const ItemPreviewPopper: React.FC<React.PropsWithChildren<Props>> = ({
    item,
    open,
    actionButtonsEnd,
    actionButtonsStart,
    children,
    readOnly,
    openItemById,
    deleteConnection,
    disablePortal = false,
}: Props) => {
    const handleOpenItem = (): void => {
        if (item) openItemById(item.id)
    }

    const handleDelete = () => {
        if (item) {
            deleteConnection(item.id)
        }
    }

    return (
        <Tooltip
            open={open}
            PopperProps={{
                sx: styles.popper,
                disablePortal,
                modifiers: [
                    {
                        name: "offset",
                        options: {
                            offset: [0, -2],
                        },
                    },
                ],
            }}
            title={
                <Box sx={styles.container}>
                    {item && !readOnly && (
                        <Box sx={styles.header}>
                            <Box>
                                <ActionButton
                                    onClick={handleDelete}
                                    tooltip={
                                        <>
                                            Remove the connection to <b>{item.name}</b>
                                        </>
                                    }
                                >
                                    <IconContainer icon={Link2Off} />
                                </ActionButton>
                                {actionButtonsStart && actionButtonsStart}
                            </Box>
                            <Box>{actionButtonsEnd && actionButtonsEnd}</Box>
                        </Box>
                    )}
                    {item && (
                        <ItemPreview
                            openItemById={openItemById}
                            item={item}
                            onClick={handleOpenItem}
                        />
                    )}
                </Box>
            }
            arrow
        >
            <span>{children}</span>
        </Tooltip>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    popper: {
        "& .MuiTooltip-arrow": {
            color: (theme: Theme) => theme.palette.background.layout,
        },
        "& .MuiTooltip-arrow::before": {
            border: (theme: Theme) => theme.borders.paper,
        },
        "& .MuiTooltip-tooltip": {
            minWidth: 250,
            maxWidth: 350,
            overflow: "hidden",
            padding: 0,
            borderRadius: 1,
            border: (theme: Theme) => theme.borders.paper,
            backgroundColor: (theme: Theme) => theme.palette.background.layout,
        },
    },
    container: {
        maxHeight: 600,
        boxShadow: 3,
        overflow: "hidden",
        overflowY: "auto",
        borderRadius: 1,
        borderTopLeftRadius: 0,
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: "background.paper",
    },
}
