import { Button, ButtonProps, SxProps, Theme, alpha } from "@mui/material"
import { FC } from "react"

export const LinkButton: FC<ButtonProps> = ({ sx = {}, children, ...props }) => {
    const style = {
        ...styles.button,
        ...sx,
    } as SxProps<Theme>
    return (
        <Button variant="text" sx={style} size="small" {...props}>
            {children}
        </Button>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    button: {
        color: "primary.light",
        px: 2,
        "&:hover": {
            backgroundColor: (theme) => alpha(theme.palette.primary.light, 0.05),
        },
    },
}
