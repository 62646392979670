import { DialogContent, Typography } from "@mui/material"
import { IS_EXTENSION_TUTORIAL_CARD, Step, commonTutorialStyles, tutorials } from "@recall/common"
import { SHARE_BUTTON_DIV_ID } from "components/ItemPage/components/item/components/ItemHeader"
import {
    CHAT_TAB_ID,
    NOTEBOOK_TAB_ID,
    READER_TAB_ID,
} from "components/ItemPage/components/item/components/ItemTabs"
import { READER_ID } from "components/ItemPage/components/item/components/Reader/Reader"
import { useQueryParameter } from "hooks/useQueryParameter"
import { FC, memo } from "react"
import { useSelector } from "react-redux"
import { RootState } from "storage/redux/rootReducer"
import { AppTutorialBase } from "./AppTutorialBase"
export interface Props {
    goNext: Function
}

const TutorialItemIntroComponent: FC = () => {
    const isReaderVisible = useSelector((state: RootState) => state.user.settings.isReaderVisible)

    const { isPresent: isExtensionTutorialCard } = useQueryParameter(IS_EXTENSION_TUTORIAL_CARD)

    const steps: Step[] = [
        {
            anchorId: isReaderVisible ? READER_ID : READER_TAB_ID,
            placement: "top",
            showArrow: true,
            component: () => (
                <DialogContent sx={commonTutorialStyles.popoverContent}>
                    <Typography sx={commonTutorialStyles.heading} variant={"h4"}>
                        Access the Original Content
                    </Typography>
                    <Typography sx={commonTutorialStyles.body}>
                        This is a read-only view of the full content.
                    </Typography>
                </DialogContent>
            ),
        },
        {
            anchorId: NOTEBOOK_TAB_ID,
            placement: "top",
            showArrow: true,
            component: () => (
                <DialogContent sx={commonTutorialStyles.popoverContent}>
                    <Typography sx={commonTutorialStyles.heading} variant={"h4"}>
                        Customize your Notebook
                    </Typography>
                    <Typography sx={commonTutorialStyles.body}>
                        Save summaries and chat outputs here. They're fully editable—you can even
                        link notes together using “/” or ⚡.
                    </Typography>
                </DialogContent>
            ),
        },
        {
            anchorId: CHAT_TAB_ID,
            placement: "top",
            showArrow: true,
            component: () => (
                <DialogContent sx={commonTutorialStyles.popoverContent}>
                    <Typography sx={commonTutorialStyles.heading} variant={"h4"}>
                        Chat with your Content
                    </Typography>
                    <Typography sx={commonTutorialStyles.body}>
                        Ask questions and get insights—responses are based only on the source
                        content.
                    </Typography>
                </DialogContent>
            ),
        },
        {
            anchorId: SHARE_BUTTON_DIV_ID,
            placement: "bottom",
            showArrow: true,
            component: () => (
                <DialogContent sx={commonTutorialStyles.popoverContent}>
                    <Typography sx={commonTutorialStyles.heading} variant={"h4"}>
                        Share your Knowledge
                    </Typography>
                    <Typography sx={commonTutorialStyles.body}>
                        Share your content with others, signup-free
                    </Typography>
                </DialogContent>
            ),
        },
    ]

    if (isExtensionTutorialCard) {
        steps.unshift({
            anchorId: null,
            placement: null,
            showArrow: false,
            component: () => (
                <DialogContent sx={commonTutorialStyles.dialogContent}>
                    <Typography sx={commonTutorialStyles.heading} variant={"h4"} textAlign="center">
                        Congratulations
                    </Typography>
                    <Typography sx={commonTutorialStyles.body} textAlign="center" mt={2}>
                        You have added your first knowledge card with the Recall Browser Extension.
                    </Typography>
                </DialogContent>
            ),
        })
    }

    return <AppTutorialBase tutorialName={tutorials.ITEM_INTRO} steps={steps} />
}

export const TutorialItemIntro = memo(TutorialItemIntroComponent)
