import ExtensionIcon from "@mui/icons-material/Extension"
import {
    Avatar,
    Box,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    SxProps,
    Theme,
    Typography,
    alpha,
} from "@mui/material"
import { useDatabase } from "@nozbe/watermelondb/react"
import {
    FIREFOX_RECALL_EXTENSION_STORE_URL,
    PremiumIcon,
    RECALL_EXTENSION_STORE_URL,
    RecallPlus,
    dbUtils,
    firebase,
    isFirefox,
    useOpenInNewTab,
} from "@recall/common"
import { ConfirmModal } from "components/shared/modals/ConfirmModal"
import { ReferralsModal } from "components/shared/modals/referrals/ReferralsModal"
import MyPopover from "components/shared/popovers/MyPopover"
import { useAuth } from "hooks/auth/useAuth"
import { useOpenPricingPage } from "hooks/useOpenPricingPage"
import { useShouldInstallExtension } from "hooks/useShouldInstallExtension"
import { Lightbulb, LogOut, TicketPercent } from "lucide-react"
import { FC, MouseEvent, memo, useState } from "react"
import { useSelector } from "react-redux"
import { RootState } from "storage/redux/rootReducer"
import { useOpenOnboarding } from "../Onboarding/hooks/useOpenOnboarding"

interface MenuAction {
    icon: JSX.Element
    name: string | JSX.Element
    url?: string
    cb?: Function
    disabled?: boolean
    pop?: boolean
    selected?: boolean
}

const AppMenu: FC = () => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
    const [isConfirmLogoutOpen, setIsConfirmLogoutOpen] = useState(false)
    const [isReferralsModalOpen, setIsReferralsModalOpen] = useState(false)

    const db = useDatabase()
    const auth = useAuth()
    const { openOnboarding } = useOpenOnboarding()
    const { shouldInstallExtension } = useShouldInstallExtension()

    const { email, isPremiumUser } = useSelector((state: RootState) => state.user)
    const { openInNewTab } = useOpenInNewTab()
    const { openPricingPage } = useOpenPricingPage()

    const open = Boolean(anchorEl)

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleLogout = async () => {
        await auth.signOut()
    }

    const handleClickLogout = async () => {
        handleClose()
        const hasUnsyncedChanges = await dbUtils.checkUnsyncedChanges(db)

        if (hasUnsyncedChanges) {
            setIsConfirmLogoutOpen(true)
            return
        }

        handleLogout()
    }

    const handleOnClick = (action: MenuAction) => {
        handleClose()
        if (action.url) openInNewTab(action.url)
        if (action.cb) action.cb()
    }

    const handleOpenOnboarding = () => {
        openOnboarding()
    }

    const handleOpenPricingPage = () => {
        openPricingPage()
    }

    const handleCloseReferralModal = () => {
        setIsReferralsModalOpen(false)
    }

    const handleOpenReferralModal = () => {
        setIsReferralsModalOpen(true)
    }

    let actions: MenuAction[] = [
        shouldInstallExtension && {
            icon: <ExtensionIcon fontSize="small" />,
            name: "Install Browser Extension",
            url: !isFirefox() ? RECALL_EXTENSION_STORE_URL : FIREFOX_RECALL_EXTENSION_STORE_URL,
            pop: true,
        },
        !isPremiumUser && {
            icon: <PremiumIcon />,
            name: "Upgrade to Recall Plus",
            cb: handleOpenPricingPage,
        },
        {
            icon: <Lightbulb size={20} />,
            name: "Continue onboarding",
            cb: handleOpenOnboarding,
        },
        {
            icon: <TicketPercent size={20} />,
            name: "Get 50% off",
            cb: handleOpenReferralModal,
        },
        { icon: <LogOut size={20} />, name: "Logout", cb: handleClickLogout },
    ]

    actions = actions.filter(Boolean) as MenuAction[]

    return (
        <Box>
            <ConfirmModal
                isOpen={isConfirmLogoutOpen}
                onClose={() => setIsConfirmLogoutOpen(false)}
                closeText="Don't Logout"
                onConfirm={handleLogout}
                confirmText="Logout anyway"
                title="You have unsynced data that could be lost."
                description="Please wait for your data to finish syncing before logging out."
            />
            {isReferralsModalOpen && <ReferralsModal closeModal={handleCloseReferralModal} />}

            <Avatar
                onClick={handleClick}
                sx={{
                    ...styles.avatar,
                    height: 26,
                    width: 26,
                    fontSize: 14,
                }}
                src={firebase.auth?.currentUser?.photoURL}
            >
                {firebase.auth?.currentUser?.email?.[0]?.toUpperCase() || ""}
            </Avatar>
            {open && (
                <MyPopover
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                >
                    <List
                        sx={styles.header}
                        subheader={
                            <Box
                                position="relative"
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Box sx={styles.headerContent}>
                                    <Box display="flex" alignItems="center" gap={1}>
                                        {firebase.auth?.currentUser?.photoURL && (
                                            <Avatar
                                                sx={{ width: 34, height: 34 }}
                                                src={firebase.auth?.currentUser?.photoURL}
                                            />
                                        )}
                                        <Typography mb={0.5} variant="body2" fontWeight={500}>
                                            {firebase.auth?.currentUser?.displayName || email}
                                        </Typography>
                                    </Box>
                                    {isPremiumUser && (
                                        <Box>
                                            <RecallPlus size={40} />
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                        }
                        component="nav"
                    >
                        {actions.map((action, index) => (
                            <ListItemButton
                                onClick={() => handleOnClick(action)}
                                key={index}
                                disabled={action.disabled}
                                sx={action.pop ? styles.poppingItem : { py: 0.6 }}
                                selected={action.selected}
                            >
                                <ListItemIcon
                                    sx={action.pop ? styles.poppingItemIcon : { minWidth: 30 }}
                                >
                                    {action.icon}
                                </ListItemIcon>
                                <ListItemText primary={action.name} />
                            </ListItemButton>
                        ))}
                    </List>
                </MyPopover>
            )}
        </Box>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    poppingItem: {
        py: 0.6,
        color: (theme) => theme.palette.primary.contrastText,
        background: (theme) => theme.palette.primary.main,
        "&:hover": {
            background: (theme) => alpha(theme.palette.primary.main, 0.9),
        },
    },
    poppingItemIcon: {
        color: (theme) => theme.palette.primary.contrastText,
        minWidth: 30,
    },
    header: {
        pb: 0,
        backgroundColor: (theme) => theme.palette.background.default,
    },
    headerContent: {
        p: 1.5,
        width: "100%",
        color: (theme) => theme.palette.text.secondary,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    avatar: {
        bgcolor: (theme) => theme.palette.primary.main,
        color: "white",
        fontWeight: 500,
        cursor: "pointer",
    },
}

export default memo(AppMenu)
