import ExtensionIcon from "@mui/icons-material/Extension"
import { ButtonProps } from "@mui/material"
import { PrimaryButton } from "@recall/common"
import { FC } from "react"

interface Props extends ButtonProps {
    target?: string
}

export const InstallExtensionButton: FC<Props> = (props) => {
    return (
        // @ts-ignore
        <PrimaryButton startIcon={<ExtensionIcon />} {...props}>
            Install Browser Extension
        </PrimaryButton>
    )
}
