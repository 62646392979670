import { Box, SxProps, Theme, Typography } from "@mui/material"
import { Spinner } from "@recall/common"
import { AuthLayout } from "components/layouts/AuthLayout"
import { useSelector } from "react-redux"
import { RootState } from "storage/redux/rootReducer"

export const AppLoader = () => {
    const isDbMigrating = useSelector((state: RootState) => state.app.isDbMigrating)

    return (
        <AuthLayout isContained={false}>
            <Loader
                loadingText={
                    isDbMigrating
                        ? "Your account is being upgraded. This may take a few minutes."
                        : "Syncing data"
                }
            />
        </AuthLayout>
    )
}

export const Loader = ({ loadingText = "Loading..." }) => {
    return (
        <Box sx={styles.loader}>
            <Spinner />
            <Typography variant="h6" fontWeight={600} textAlign="center" maxWidth={400}>
                {loadingText}
            </Typography>
        </Box>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    loader: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: 4,
        pt: 6,
        pb: 4,
        px: 8,
    },
}
