import { useCallback, useEffect, useMemo, useState } from "react"
import { v4 as uuid } from "uuid"
import { ChatMessage } from "../services/chat/chatService"
import { replaceTimestamps } from "../utils/chat"
import { UsageLimitReachedError } from "../utils/httpClient/errors"

interface ChatHandlers<T> {
    updateMessages: (messages: ChatMessage[]) => Promise<void>
    getMessages: () => Promise<ChatMessage[]>
    questionHandler: (
        messages: ChatMessage[],
        question: string,
        item: T,
        url: string
    ) => Promise<ReadableStream<Uint8Array>>
    aiActionHandler: (message: ChatMessage, item: T, markdown: string) => Promise<string>
    getSource: (item: T) => Promise<string>
    handleSave: () => Promise<void>
    onUsageLimitReached: () => void
}

export const useChatMessages = <T extends { isSaved: boolean; id: string }>(
    item: T,
    markdown: string,
    handlers: ChatHandlers<T>
) => {
    const [messages, setMessages] = useState<ChatMessage[]>([])
    const [isAnswering, setIsAnswering] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        if (!item) return

        loadMessages()
    }, [item?.id])

    const loadMessages = async () => {
        const messages = await handlers.getMessages()
        setMessages(messages)
        setIsLoading(false)
    }

    const processResponse = async (responseBody: ReadableStream<Uint8Array>, url: string) => {
        const reader = responseBody.getReader()
        const decoder = new TextDecoder()
        let fullAnswer = ""
        let animationInterval: NodeJS.Timeout | null = null

        const updateMessageWithAnimation = (text: string, isLoaded = false) => {
            if (animationInterval) clearInterval(animationInterval)

            const step = 5
            animationInterval = setInterval(() => {
                setMessages((prev) => {
                    const lastMessage = prev[prev.length - 1]
                    if (lastMessage?.role !== "assistant")
                        return [...prev, { role: "assistant", content: text, id: uuid() }]

                    const newLength = lastMessage.content.length + step
                    if (newLength >= text.length) {
                        setIsAnswering(!isLoaded)
                        clearInterval(animationInterval!)
                        return [...prev.slice(0, -1), { ...lastMessage, content: text }]
                    }

                    const animatedText = text.slice(0, newLength)
                    return [...prev.slice(0, -1), { ...lastMessage, content: animatedText }]
                })
            }, 5)
        }

        while (true) {
            const { value, done } = await reader.read()
            const chunk = decoder.decode(value)
            fullAnswer += chunk
            updateMessageWithAnimation(replaceTimestamps(fullAnswer, url), done)
            if (done) return fullAnswer
        }
    }

    const updateMessages = useCallback(
        async (messages: ChatMessage[]) => {
            setMessages(messages)
            await handlers.updateMessages(messages)
        },
        [item]
    )

    const processQuestion = useCallback(
        async (messages: ChatMessage[], answerProps?: Partial<ChatMessage>) => {
            const question = messages[messages.length - 1]

            try {
                const answerMessage: ChatMessage = {
                    role: "assistant",
                    content: "",
                    id: uuid(),
                    ...answerProps,
                }

                setMessages([...messages, answerMessage])
                setIsAnswering(true)

                if (!item.isSaved) await handlers.handleSave()

                let answer = ""
                if (question.action) {
                    answer = await handlers.aiActionHandler(question, item, markdown)
                    setMessages((prev) => [
                        ...prev.slice(0, -1),
                        { ...answerMessage, content: answer },
                    ])
                    setIsAnswering(false)
                } else {
                    const url = await handlers.getSource(item)
                    const stream = await handlers.questionHandler(
                        messages,
                        question.content,
                        item,
                        url
                    )
                    answer = await processResponse(stream, url)
                }

                await handlers.updateMessages([...messages, { ...answerMessage, content: answer }])
                return { ...answerMessage, content: answer }
            } catch (error) {
                setIsAnswering(false)
                if (error instanceof UsageLimitReachedError) {
                    handlers.onUsageLimitReached()
                    setMessages((prev) => [...prev.slice(0, -2)])
                    return null
                }
                setMessages((prev) => [
                    ...prev.slice(0, -1),
                    {
                        id: uuid(),
                        role: "assistant",
                        content: "Sorry, there was an error processing your request.",
                        error: true,
                    },
                ])
                return null
            }
        },
        [messages, item, processResponse]
    )

    const actions = useMemo(
        () => ({
            setMessages,
            processQuestion,
            updateMessages,
        }),
        [processQuestion, updateMessages]
    )

    const state = useMemo(
        () => ({
            isLoadingMessages: isLoading,
            messages,
            isAnswering,
        }),
        [messages, isAnswering]
    )

    return { state, actions }
}
