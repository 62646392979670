import { Database } from "@nozbe/watermelondb"
import { map } from "lodash"
import { Descendant } from "slate"
import { EditorElements } from "../../editor/types"
import { ItemModel } from "../../storage/watermelon/models/ItemModel"
import { connectionRepository } from "../../storage/watermelon/repository/connectionRepository"
import { editorBlockRepository } from "../../storage/watermelon/repository/editorBlockRepository"
import { editorOrderRepository } from "../../storage/watermelon/repository/editorOrderRepository"
import { EditorBlockData } from "./EditorBlockData"

export const initEditorData = async (db: Database, itemId: string): Promise<Descendant[]> => {
    const editorBlockModels = await editorBlockRepository.getEditorBlocksByItemId(db, itemId)
    const editorOrderModel = await editorOrderRepository.get(db, itemId)
    let value: any = EditorBlockData.getOrdered(editorBlockModels, editorOrderModel)

    const connections = await connectionRepository.getItemConnections(db, itemId)
    const connectionIds = map(connections, "id")

    EditorBlockData.removeStaleReferences(value, connectionIds)

    if (value.length === 0) {
        value = [EditorBlockData.create()]
    }

    return value
}

export const initItemEditorData = async (item: ItemModel): Promise<EditorElements> => {
    const [editorBlockModels, editorOrderModels, connectionIds] = await Promise.all([
        item.editorBlocks.fetch(),
        item.editorOrders.fetch(),
        item.links.fetchIds(),
    ])
    const editorOrderModel = editorOrderModels?.[0] || null

    let orderedEditorBlocks: any[] = EditorBlockData.getOrdered(editorBlockModels, editorOrderModel)

    EditorBlockData.removeStaleReferences(orderedEditorBlocks, connectionIds)

    if (orderedEditorBlocks.length === 0) {
        orderedEditorBlocks = [EditorBlockData.create()]
    }

    return orderedEditorBlocks
}
