import { Box, SxProps, Theme } from "@mui/material"

interface Props {
    width?: number
    color?: "white" | "light"
    sx?: SxProps<Theme>
    icon: React.ElementType
}
export const IconContainer = ({ width = 20, color = "white", icon, sx }: Props) => {
    const iconColor = color === "white" ? "text.secondary" : "primary.light"

    return <Box component={icon} sx={{ width, color: iconColor, ...sx }} />
}
