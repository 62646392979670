import { sentry } from "../utils/sentry"

interface WikidataAliasResponse {
    entities: {
        [key: string]: {
            aliases: {
                [languageCode: string]: Array<{ value: string; language: string }>
            }
            labels: {
                [languageCode: string]: { value: string; language: string }
            }
        }
    }
}

async function getAliasesBatch(entityIds: string[]): Promise<Record<string, string[]>> {
    const BATCH_SIZE = 50

    const chunks = Array.from({ length: Math.ceil(entityIds.length / BATCH_SIZE) }, (_, i) =>
        entityIds.slice(i * BATCH_SIZE, (i + 1) * BATCH_SIZE)
    )

    const chunkResults = await Promise.all(
        chunks.map(async (chunk, i) => {
            try {
                const response = await fetch(
                    `https://www.wikidata.org/w/api.php?` +
                        new URLSearchParams({
                            action: "wbgetentities",
                            ids: chunk.join("|"),
                            props: "labels|aliases",
                            languages: "en",
                            format: "json",
                            origin: "*",
                        })
                )

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`)
                }

                const data = (await response.json()) as WikidataAliasResponse
                return chunk.reduce<Record<string, string[]>>((acc, entityId) => {
                    const entity = data.entities[entityId]
                    const aliases = entity?.aliases?.en?.map((alias) => alias.value) || []
                    const label = entity?.labels?.en?.value
                    acc[entityId] = Array.from(new Set(label ? [label, ...aliases] : aliases))
                    return acc
                }, {})
            } catch (error) {
                const err = error as Error
                sentry.captureException(err)
                return {}
            }
        })
    )

    return chunkResults.reduce(
        (acc, chunkResult) => ({
            ...acc,
            ...chunkResult,
        }),
        {}
    )
}

export const wikidataService = { getAliasesBatch }
