import { Box, Divider, SxProps, Theme, alpha } from "@mui/material"

import { Stack } from "@mui/material"
import { MarkButtons } from "./MarkButtons"
import { TextTypeMenu } from "./TextTypeMenu"

import { IconList, IconListNumbers } from "@tabler/icons-react"
import { MARK_BG_COLOR, MARK_COLOR } from "@udecode/plate-font"
import { ListStyleType } from "@udecode/plate-indent-list"
import React, { FC, useState } from "react"
import { ColorDropDownMenu } from "../ColorPicker/ColorDropDownMenu"
import { EmojiDropDownMenu } from "../EmojiPicker/EmojiDropDownMenu"
import { AlignToolbarButton } from "./AlignToolbarButton"
import { ImageToolbarButton } from "./ImageToolbarButton"
import { IndentListToolbarButton } from "./IndentListToolbarButton"
import { LinkToolbarButton } from "./LinkToolbarButton"

interface Props {
    uploadImage: (arrayBuffer: ArrayBuffer, contentType: string) => Promise<string>
}

const FixedToolbarComponent: FC<Props> = ({ uploadImage }) => {
    const [isTextMenuOpen, setIsTextMenuOpen] = useState(false)

    return (
        <Box sx={styles.toolbar}>
            <Stack
                spacing={1}
                rowGap={0.6}
                direction="row"
                flexWrap="wrap"
                justifyContent="center"
                alignItems="center"
                onMouseDown={(e) => {
                    e.preventDefault()
                }}
            >
                <TextTypeMenu isOpen={isTextMenuOpen} setIsOpen={setIsTextMenuOpen} />
                <MarkButtons />
                <LinkToolbarButton />
                <ColorDropDownMenu tooltip="Text Color" nodeType={MARK_COLOR} />
                <ColorDropDownMenu tooltip="Highlight Color" nodeType={MARK_BG_COLOR} />
                <Box px={0.5} height={20}>
                    <Divider orientation="vertical" />
                </Box>
                <AlignToolbarButton />
                <IndentListToolbarButton nodeType={ListStyleType.Disc}>
                    <IconList />
                </IndentListToolbarButton>
                <IndentListToolbarButton nodeType={ListStyleType.Decimal}>
                    <IconListNumbers />
                </IndentListToolbarButton>
                <Box px={0.5} height={20}>
                    <Divider orientation="vertical" />
                </Box>
                <ImageToolbarButton uploadImage={uploadImage} />
                <EmojiDropDownMenu />
            </Stack>
        </Box>
    )
}

export const FixedToolbar = React.memo(FixedToolbarComponent, () => true)

const styles: Record<string, SxProps<Theme>> = {
    toolbar: {
        position: "sticky",
        bottom: 0,
        left: 0,
        width: "100%",
        zIndex: 1001,
        p: 1,
        borderRadius: 1,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        background: (theme) => alpha(theme.palette.background.layout, 0.8),
        backdropFilter: "blur(2px)",
    },
}
