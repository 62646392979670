import { Model, Relation } from "@nozbe/watermelondb"
import { BelongsToAssociation, HasManyAssociation } from "@nozbe/watermelondb/Model"
import { date, field, json, readonly, relation, writer } from "@nozbe/watermelondb/decorators"
import { sentry } from "../../../utils/sentry"
import { ROOT_TAG_ID } from "../repository/tagRepository"
import { TAGS } from "../schema"

const sanitizeTagChildren = (data: any) => data

export class TagModel extends Model {
    static table = TAGS

    static associations = {
        item_tag: { type: "has_many", foreignKey: "tag_id" } as HasManyAssociation,
        tags: { type: "belongs_to", key: "parent_id" } as BelongsToAssociation,
    }

    @field("name") name: string
    @field("is_saved") isSaved: boolean
    @field("is_reference") isReference: boolean
    /**
     * @deprecated Use `parent id` instead.
     */
    @json("children", sanitizeTagChildren) children: string[]
    @relation("tags", "parent_id") parent: Relation<TagModel>

    @readonly @date("created_at") createdAt: Date
    @readonly @date("updated_at") updatedAt: Date

    prepareSave = async () => {
        if (this.isSaved) return null

        let tasks: any = []

        const parent = await this.parent.fetch()

        if (parent?.isSaved === false) {
            const parentTasks = await parent.prepareSave()
            tasks = [...tasks, ...parentTasks]
        }

        const task = this.prepareUpdate((record) => {
            record.isSaved = true
        })

        tasks.push(task)

        return tasks
    }

    @writer async setIsReference(isReference: boolean) {
        await this.update((record) => {
            record.isReference = isReference
        })
    }

    @writer async delete() {
        const task = this.prepareDelete()
        await this.batch(task)
    }

    prepareDelete = () => {
        if (this._preparedState === null) {
            if (this.isSaved) {
                return this.prepareMarkAsDeleted()
            } else {
                return this.prepareDestroyPermanently()
            }
        }
    }

    getParent = async () => {
        try {
            return await this.parent.fetch()
        } catch {
            sentry.captureMessage("Failed to fetch parent tag")
            return null
        }
    }

    getTagAncestors = async () => {
        if (this.parent.id === ROOT_TAG_ID) return []

        const parent = await this.getParent()

        if (!parent) return []

        const ancestors = [parent]
        let i = 0
        while (i < ancestors.length && i < 1000) {
            const tag = ancestors[i]

            const parent = await tag.getParent()
            if (parent && parent.id !== ROOT_TAG_ID) ancestors.push(parent)
            else break

            i++
        }

        return ancestors.reverse()
    }
}
