import { ItemModel, SourceModel } from "@recall/common"
import { useEffect, useState } from "react"

export const useSources = (item: ItemModel) => {
    const [sources, setSources] = useState<SourceModel[]>([])
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        if (!item) return
        setIsLoading(true)
        getSources()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item])

    const getSources = async () => {
        const sources = await item.sources
        setSources(sources)
        setIsLoading(false)
    }

    return { sources, isLoading }
}
