import { useEffect } from "react"

const useScrollbarVisibility = () => {
    useEffect(() => {
        let timerId: ReturnType<typeof setTimeout> | null = null
        const target = document.body

        const scrollListener = () => {
            if (timerId) clearTimeout(timerId)
            target.classList.add("scrollbar-visible")

            timerId = setTimeout(() => {
                target.classList.remove("scrollbar-visible")
            }, 1500)
        }

        window.addEventListener("scroll", scrollListener, { passive: true })

        return () => {
            if (timerId) clearTimeout(timerId)
            target.removeEventListener("scroll", scrollListener)
            target.classList.remove("scrollbar-visible")
        }
    }, [])
}

export default useScrollbarVisibility
