import { Box, SxProps, Theme } from "@mui/material"
import { PlateElementProps } from "@udecode/plate-common"
import React, { useEffect, useState } from "react"
import { useFocused } from "slate-react"
import { SearchPreview } from "../../api"
import { SearchResults } from "../../components"
import { useIsMobile } from "../../hooks/useIsMobile"
import { Item, ItemPartial } from "../../types"
import useSearchItems, { Result } from "../hooks/useSearchItems"
import { useMyEditorState } from "../types"
import { handleScroll } from "../utils"
import CustomEditor from "../utils/CustomEditor"

export const LINKS_SEARCH_INPUT_ID = "id-link-search-input"
export const LINK_SELECTED_EVENT = "link-selected-event"

export interface SearchReferenceElementProps extends PlateElementProps {
    searchWikipedia: (query: string) => Promise<ItemPartial[]>
    getSummaryPreview: (url: string) => Promise<SearchPreview>
    createConnection: (
        itemApi: Item | ItemPartial
    ) => Promise<{ name: string; connectionId: string; itemId: string }>
}

export const SearchReferenceElement: React.FC<
    React.PropsWithChildren<SearchReferenceElementProps>
> = ({ attributes, children, nodeProps, searchWikipedia, createConnection }) => {
    const editor = useMyEditorState()
    const [ref, setRef] = useState<HTMLSpanElement | null>(null)
    const isMobile = useIsMobile()
    const focused = useFocused()
    const [selectedIndex, setSelectedIndex] = useState(0)

    useEffect(() => {
        if (!ref) return

        handleScroll()
    }, [ref, isMobile])

    useEffect(() => {
        if (!focused) handleClose()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [focused])

    const handleClose = () => {
        CustomEditor.removeSearchReference(editor)
    }

    const handleSelectResult = async (result: Result) => {
        result = {
            ...result,
            isSaved: true,
        }

        const { connectionId, itemId } = await createConnection(result)

        handleClose()
        CustomEditor.insertReference(editor, connectionId, children[0].props.text.text)
        CustomEditor.moveCursorAfterReference(editor)

        const event = new CustomEvent(LINK_SELECTED_EVENT, { detail: { itemId } })
        document.dispatchEvent(event)
    }

    const textNode = CustomEditor.getTextNode(editor)

    const searchTerm = textNode?.text || ""
    const [loading, results] = useSearchItems(searchTerm, {}, searchWikipedia)

    return (
        <span {...nodeProps} ref={(r) => setRef(r)} id={LINKS_SEARCH_INPUT_ID}>
            <Box component="span" sx={styles.chip} {...attributes}>
                {children}
            </Box>
            {ref && (
                <SearchResults
                    value={searchTerm}
                    results={results}
                    loading={loading}
                    selectedIndex={selectedIndex}
                    setSelectedIndex={setSelectedIndex}
                    open={true}
                    anchorEl={ref}
                    onClose={handleClose}
                    onSelectResult={handleSelectResult}
                    closeOnEmptyBackspace={true}
                    subheaderText="Keep typing to search..."
                />
            )}
        </span>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    chip: {
        whiteSpace: "nowrap",
        backgroundColor: (theme) => theme.palette.secondary.main,
        color: (theme) => theme.palette.secondary.contrastText,
        paddingTop: (theme) => theme.spacing(0.4),
        paddingBottom: (theme) => theme.spacing(0.4),
        paddingRight: (theme) => theme.spacing(1),
        paddingLeft: (theme) => theme.spacing(1),
        borderRadius: (theme) => theme.spacing(1.5),
        outline: "none",
    },
}
