import { Box } from "@mui/material"
import * as pdfjsLib from "pdfjs-dist"
import { memo, useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { RootState } from "storage/redux/rootReducer"

interface Props {
    file: File
}

pdfjsLib.GlobalWorkerOptions.workerSrc = new URL(
    "pdfjs-dist/build/pdf.worker.min.mjs",
    import.meta.url
).toString()

export const PdfReader = memo<Props>(({ file }) => {
    const [numPages, setNumPages] = useState<number>(1)
    const containerRef = useRef<HTMLDivElement>(null)
    const [rerender, setRerender] = useState<number>(1)
    const canvasRefs = useRef<(HTMLCanvasElement | null)[]>([])
    const isDrawerOpen = useSelector((state: RootState) => state.drawer.open)

    useEffect(() => {
        let isMounted = true
        let currentPdf: pdfjsLib.PDFDocumentProxy | null = null
        let renderTasks: pdfjsLib.RenderTask[] = []

        const loadPdf = async () => {
            if (!file) return

            try {
                // Cancel any existing render tasks
                renderTasks.forEach((task) => task.cancel())
                renderTasks = []

                const arrayBuffer = await file.arrayBuffer()
                const pdf = await pdfjsLib.getDocument({
                    data: arrayBuffer,
                    cMapUrl: "/cmaps/",
                    cMapPacked: true,
                    standardFontDataUrl: "/standard_fonts/",
                    verbosity: 0,
                }).promise

                if (!isMounted) return
                currentPdf = pdf
                setNumPages(pdf.numPages)

                // Render pages sequentially
                const renderPage = async (pageNum: number) => {
                    if (!isMounted) return
                    const page = await pdf.getPage(pageNum)
                    const canvas = canvasRefs.current[pageNum - 1]
                    if (!canvas || !containerRef.current) return

                    const viewport = page.getViewport({ scale: 1.0 })
                    const context = canvas.getContext("2d", { alpha: false })
                    if (!context) return

                    const containerWidth = containerRef.current.clientWidth
                    const scale = containerWidth / viewport.width
                    const pixelRatio = window.devicePixelRatio || 1
                    const scaledViewport = page.getViewport({ scale: scale * pixelRatio })

                    canvas.height = scaledViewport.height
                    canvas.width = scaledViewport.width
                    canvas.style.width = `${scaledViewport.width / pixelRatio}px`
                    canvas.style.height = `${scaledViewport.height / pixelRatio}px`

                    // Clear the canvas before rendering
                    context.clearRect(0, 0, canvas.width, canvas.height)

                    const renderTask = page.render({
                        canvasContext: context,
                        viewport: scaledViewport,
                    })

                    renderTasks.push(renderTask)
                    await renderTask.promise

                    // Add link support
                    const annotations = await page.getAnnotations()
                    const linkLayer = document.createElement("div")
                    linkLayer.className = "pdf-link-layer"
                    linkLayer.style.position = "absolute"
                    linkLayer.style.top = "0"
                    linkLayer.style.left = "0"
                    linkLayer.style.width = "100%"
                    linkLayer.style.height = "100%"
                    linkLayer.style.pointerEvents = "none" // Make the layer itself non-interactive

                    annotations
                        .filter(
                            (annotation) =>
                                annotation.subtype === "Link" && annotation.url?.startsWith("http")
                        )
                        .forEach((link) => {
                            const [x1, y1, x2, y2] = link.rect
                            const normalizedViewport = page.getViewport({ scale })

                            // Convert PDF coordinates to screen coordinates
                            const bounds = pdfjsLib.Util.normalizeRect([
                                x1 * scale,
                                normalizedViewport.height - y2 * scale,
                                x2 * scale,
                                normalizedViewport.height - y1 * scale,
                            ])

                            const linkElement = document.createElement("a")
                            linkElement.href = link.url || "#"
                            linkElement.style.position = "absolute"
                            linkElement.style.left = `${bounds[0]}px`
                            linkElement.style.top = `${bounds[1]}px`
                            linkElement.style.width = `${bounds[2] - bounds[0]}px`
                            linkElement.style.height = `${bounds[3] - bounds[1]}px`
                            linkElement.style.pointerEvents = "all" // Make links interactive

                            if (link.url) {
                                linkElement.target = "_blank"
                                linkElement.rel = "noopener noreferrer"
                            }

                            linkLayer.appendChild(linkElement)
                        })

                    // Make sure we append the link layer to the Box containing the canvas
                    const canvasContainer = canvas.parentElement
                    if (canvasContainer) {
                        canvasContainer.style.position = "relative"
                        canvasContainer.appendChild(linkLayer)
                    }

                    renderTasks = renderTasks.filter((task) => task !== renderTask)
                }

                // Render pages one at a time
                for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
                    if (!isMounted) break
                    await renderPage(pageNum)
                }
            } catch (error) {
                if (error.name !== "RenderingCancelledException") {
                    console.error("Error loading PDF:", error)
                }
            }
        }

        loadPdf()

        return () => {
            isMounted = false
            renderTasks.forEach((task) => task.cancel())
            if (currentPdf) {
                currentPdf.destroy()
            }
            // Clean up link layers
            document.querySelectorAll(".pdf-link-layer").forEach((layer) => layer.remove())
        }
    }, [file, rerender])

    useEffect(() => {
        setRerender((prev) => prev + 1)
    }, [isDrawerOpen])

    if (!file) return null

    return (
        <Box
            sx={{
                width: "100%",
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                margin: "0",
                padding: "0",
                borderRadius: 1,
                overflow: "auto",
                height: "100%",
            }}
            ref={containerRef}
        >
            {Array.from(new Array(numPages), (_, index) => (
                <Box
                    key={`page_${index + 1}`}
                    sx={{
                        mb: 2,
                        position: "relative", // Add this to properly position the link layer
                    }}
                >
                    <canvas
                        ref={(el) => (canvasRefs.current[index] = el)}
                        style={{ width: "100%", height: "auto" }}
                    />
                </Box>
            ))}
        </Box>
    )
})
