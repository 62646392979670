import { Avatar, Box, Chip, ChipProps, SxProps, Theme, alpha } from "@mui/material"
import React, { useMemo } from "react"
import { extractSearchText } from "../../utils/extractSearchText"
import { TotalCountBadge } from "../badges/TotalCountBadge"

interface Props {
    id: string
    name: string
    image?: string
    selected?: boolean
    searchText?: string
    mentionCount?: number
    size?: ChipProps["size"]
    handleClick: (id: string) => void
}

function stringAvatar(name: string) {
    const cleanName = name.replace(/[^\w\s]/g, "")
    const nameParts = cleanName.split(" ")

    return {
        sx: {
            bgcolor: (theme: Theme) => alpha(theme.palette.text.secondary, 0.2),
            borderRadius: 1.2,
            color: (theme: Theme) => theme.palette.text.primary,
        },
        children: nameParts.length > 1 ? `${nameParts[0][0]}${nameParts[1][0]}` : cleanName[0],
    }
}

const ItemChipComponent: React.FC<Props> = ({
    id,
    name,
    image,
    selected,
    searchText,
    size = "small",
    mentionCount,
    handleClick,
}) => {
    const label = useMemo(() => {
        if (!searchText) {
            return <>{name}</>
        }

        const extractedSearch = extractSearchText(name, searchText)

        if (!extractedSearch) {
            return <>{name}</>
        }

        const { beforeSearch, afterSearch, searchedText } = extractedSearch

        const maxDisplayLength = Math.max(searchText.length, 25)
        const beforeSearchLengthLimit = Math.max(maxDisplayLength - searchText.length, 0)

        return (
            <>
                {(beforeSearch.length > beforeSearchLengthLimit ? "..." : "") +
                    (beforeSearchLengthLimit === 0
                        ? ""
                        : beforeSearch.slice(-beforeSearchLengthLimit))}
                <Box component="b" sx={{ color: "secondary.main" }}>
                    {searchedText}
                </Box>
                {afterSearch}
            </>
        )
    }, [searchText])

    return (
        <Chip
            id={id}
            size={size}
            onClick={() => handleClick(id)}
            sx={{
                maxWidth: "210px",
                border: (theme) =>
                    selected ? `1px solid ${theme.palette.secondary.main}` : undefined,
                borderRadius: 1.5,
            }}
            label={label}
            avatar={
                image ? (
                    <Avatar sx={styles.avatar} alt={name} src={image} />
                ) : (
                    <Avatar {...stringAvatar(name)}></Avatar>
                )
            }
            variant="outlined"
            deleteIcon={
                mentionCount ? (
                    <TotalCountBadge
                        name={name}
                        count={mentionCount}
                        onlyOtherMentionCount
                        sx={styles.badge}
                    />
                ) : undefined
            }
            onDelete={mentionCount ? () => {} : undefined}
        />
    )
}

export const ItemChip = React.memo(ItemChipComponent)

const styles: Record<string, SxProps<Theme>> = {
    avatar: {
        bgcolor: (theme) => theme.palette.text.secondary,
        borderRadius: 1,
    },
    badge: {
        borderRadius: 1,
        mr: 0.5,
    },
}
