import { UserCredential } from "firebase/auth"
import {
    LOGIN_EXTENSION_WITH_CREDENTIAL,
    LOGIN_EXTENSION_WITH_CUSTOM_TOKEN,
    LOGIN_EXTENSION_WITH_EMAIL_PASSWORD,
    LOGOUT_EXTENSION,
    checkExtensionStatus,
} from "@recall/common"
import { useRef } from "react"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { extensionService } from "services/extensionService"
import { functions } from "services/firebase/firebaseFunction"
import { SET_EXTENSION_STATE } from "storage/redux/app/actionTypes"
import { extensionStateEnum } from "storage/redux/app/types"

export const useExtensionAuth = () => {
    const dispatch = useDispatch()
    const isLoggingIn = useRef(false)

    const setExtensionState = (state: extensionStateEnum) => {
        dispatch({ type: SET_EXTENSION_STATE, payload: state })
    }

    const login = async (loginFunc: () => Promise<boolean>) => {
        const { isExtensionInstalled, isExtensionLoggedIn } = await checkExtensionStatus()

        if (!isExtensionInstalled) {
            setExtensionState(extensionStateEnum.NOT_INSTALLED)
            return
        }

        if (isExtensionLoggedIn) {
            setExtensionState(extensionStateEnum.LOGGED_IN)
            return
        }

        setExtensionState(extensionStateEnum.LOADING)

        try {
            const isLoggedIn = await loginFunc()
            if (!isLoggedIn) return

            setExtensionState(extensionStateEnum.LOGGED_IN)
            toast.success("Extension successfully logged in", {
                position: "top-right",
            })

            return
        } catch (err) {
            toast.error("Error while communicating with the Recall browser extension", {
                position: "top-right",
            })
        }

        setExtensionState(extensionStateEnum.NOT_LOGGED_IN)
    }

    const loginWithCustomToken = async () => {
        if (isLoggingIn.current) return

        await login(async () => {
            const response = await functions.createCustomAuthToken()
            const token = response.data

            return extensionService.sendMessage({
                type: LOGIN_EXTENSION_WITH_CUSTOM_TOKEN,
                token,
            })
        })
    }

    const loginWithCredential = async (credential: UserCredential) => {
        await login(async () => {
            return extensionService.sendMessage({
                type: LOGIN_EXTENSION_WITH_CREDENTIAL,
                credential: JSON.stringify(credential),
            })
        })
    }

    const loginWithEmailAndPassword = async (email: string, password: string) => {
        await login(async () => {
            return extensionService.sendMessage({
                type: LOGIN_EXTENSION_WITH_EMAIL_PASSWORD,
                email,
                password,
            })
        })
    }

    const logout = async () => {
        const { isExtensionInstalled, isExtensionLoggedIn } = await checkExtensionStatus()

        if (isExtensionInstalled && isExtensionLoggedIn) {
            extensionService.sendMessage({ type: LOGOUT_EXTENSION })
        }
    }

    return {
        loginWithCustomToken,
        loginWithCredential,
        loginWithEmailAndPassword,
        logout,
        isLoggingIn,
    }
}
