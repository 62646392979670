import { Database } from "@nozbe/watermelondb"
import LokiJSAdapter from "@nozbe/watermelondb/adapters/lokijs"
import {
    AssetModel,
    ConnectionModel,
    ConnectionPropertyModel,
    DataMigrationModel,
    EditorBlockModel,
    EditorOrderModel,
    ItemModel,
    ItemTagModel,
    MentionModel,
    QuestionModel,
    QuestionReviewModel,
    SourceModel,
    TagModel,
    TypeModel,
    migrations,
    schema,
} from "@recall/common"

const adapter = new LokiJSAdapter({
    schema,
    useWebWorker: false,
    useIncrementalIndexedDB: true,
    migrations,
    dbName: "recall",
})

export const database = new Database({
    adapter,
    modelClasses: [
        ItemModel,
        TypeModel,
        SourceModel,
        ConnectionModel,
        EditorBlockModel,
        EditorOrderModel,
        ConnectionPropertyModel,
        TagModel,
        ItemTagModel,
        QuestionModel,
        QuestionReviewModel,
        DataMigrationModel,
        AssetModel,
        MentionModel,
    ],
})
