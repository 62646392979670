import { Box, SwipeableDrawer, SxProps, Theme } from "@mui/material"
import { GraphIcon, useIsMobile } from "@recall/common"
import { Loader } from "components/ItemPage/components/item/components/Loader"
import { AppUsageChip } from "components/shared/chips/AppUsageChip"
import { Modals } from "components/shared/modals/Modals"
import { TagsTree } from "components/shared/tags/TagsTree"
import { DRAWER_WIDTH, NAV_WIDTH } from "constants/index"
import { HOME_PATH, KNOWLEDGE_GRAPH_PATH, SETTINGS_PATH, SPACED_REPETITION } from "constants/routes"
import { useOpenItem } from "hooks/items/useOpenItem"
import { GraduationCap, House, Settings } from "lucide-react"
import { FC, PropsWithChildren, useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router"
import { Link } from "react-router-dom"
import { SET_DRAWER_OPEN } from "storage/redux/drawer/actionTypes"
import { RootState } from "storage/redux/rootReducer"
import AppMenu from "../AppMenu/AppMenu"
import { Header } from "../Header/Header"
import { AppLoader } from "../Loader/AppLoader"
import { Logo } from "../Logos/Logo"
import { Onboarding } from "../Onboarding/Onboarding"
import { Tutorial } from "../Tutorial/Tutorial"
import { NavItem } from "./NavItem"

interface Props {
    hasTagsTree?: boolean
}

export const Navigation: FC<PropsWithChildren<Props>> = ({ children, hasTagsTree = false }) => {
    const isAppLoading = useSelector((state: RootState) => state.app.appLoading)
    const isReaderVisible = useSelector((state: RootState) => state.user.settings.isReaderVisible)
    const isDrawerOpen = useSelector((state: RootState) => state.drawer.open)
    const isCardLoading = useSelector((state: RootState) => state.app.loading)
    const isMobile = useIsMobile()
    const location = useLocation()
    const dispatch = useDispatch()
    const { openItemById } = useOpenItem()

    useEffect(() => {
        if (isMobile) dispatch({ type: SET_DRAWER_OPEN, payload: false })
    }, [dispatch, isMobile])

    const handleClickItem = useCallback((id: string) => {
        openItemById(id, { reset: true })

        if (isMobile) {
            dispatch({ type: SET_DRAWER_OPEN, payload: false })
        }
    }, [])

    const handleDrawerClose = () => {
        dispatch({ type: SET_DRAWER_OPEN, payload: false })
    }

    if (isAppLoading) return <AppLoader />

    return (
        <>
            <Onboarding />
            <Tutorial />
            <Modals />
            {isMobile ? (
                <Box>
                    <SwipeableDrawer
                        variant="temporary"
                        anchor="left"
                        open={isDrawerOpen}
                        onClose={handleDrawerClose}
                        onOpen={() => null}
                        PaperProps={{ sx: styles.drawer }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        <Box display="flex">
                            <SideNav />
                            {hasTagsTree && (
                                <Box width={DRAWER_WIDTH}>
                                    <TagsTree
                                        handleClickItem={handleClickItem}
                                        isSwipeable={true}
                                    />
                                </Box>
                            )}
                        </Box>
                    </SwipeableDrawer>
                    {isCardLoading ? (
                        <Loader isReaderVisible={isReaderVisible} />
                    ) : (
                        <>
                            {location.pathname === HOME_PATH && <Header />}
                            <Box flex={1}>{children}</Box>
                        </>
                    )}
                </Box>
            ) : (
                <Box component="main" display="flex" position="relative" sx={{ height: "100vh" }}>
                    <SideNav />
                    {hasTagsTree && (
                        <Box
                            sx={{
                                flexShrink: 0,
                                width: isDrawerOpen ? DRAWER_WIDTH : 0,
                            }}
                        >
                            <Box
                                sx={{
                                    ml: isDrawerOpen ? `${NAV_WIDTH}px` : 0,
                                    width: isDrawerOpen ? DRAWER_WIDTH : 0,
                                    visibility: isDrawerOpen ? "visible" : "hidden",
                                    ...styles.container,
                                }}
                            >
                                <TagsTree handleClickItem={handleClickItem} isSwipeable={false} />
                            </Box>
                        </Box>
                    )}
                    <Box flex={1} mt={1} display="flex" flexDirection="column">
                        {isCardLoading ? (
                            <Loader isReaderVisible={isReaderVisible} />
                        ) : (
                            <Box display="flex" flexDirection="column" flexGrow={1}>
                                {location.pathname === HOME_PATH && (
                                    <Box sx={styles.header}>
                                        <Header />
                                    </Box>
                                )}
                                <Box
                                    sx={{
                                        ...styles.content,
                                        backgroundColor: (theme) =>
                                            location.pathname === KNOWLEDGE_GRAPH_PATH
                                                ? "transparent"
                                                : theme.palette.action.hover,
                                    }}
                                >
                                    {children}
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Box>
            )}
        </>
    )
}

export const SideNav = () => {
    const isMobile = useIsMobile()

    return (
        <Box>
            <Box width={NAV_WIDTH} />
            <Box sx={styles.nav}>
                <Box display="flex" flexDirection="column" alignItems="center" gap={2} flexGrow={1}>
                    <Box mb={1} component={Link} to={HOME_PATH}>
                        <Logo size={37} />
                    </Box>
                    <NavItem icon={<House />} path={HOME_PATH} label="Home" />
                    {!isMobile && (
                        <NavItem
                            icon={<GraphIcon sx={{ color: undefined }} />}
                            path={KNOWLEDGE_GRAPH_PATH}
                            label="Graph"
                        />
                    )}
                    <NavItem icon={<GraduationCap />} path={SPACED_REPETITION} label="Review" />
                    <NavItem icon={<Settings />} path={SETTINGS_PATH} label="Settings" />
                </Box>
                <Box mb={2}>
                    <AppUsageChip direction="column" />
                </Box>
                <AppMenu />
            </Box>
        </Box>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    nav: {
        backgroundColor: (theme) => theme.palette.background.layout,
        position: "fixed",
        top: 0,
        left: 0,
        py: 2,
        zIndex: 1000,
        width: NAV_WIDTH,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
    },

    drawer: {
        backgroundColor: (theme) => theme.palette.background.layout,
    },
    container: {
        height: "calc(100% - 16px)",
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 1000,
        backgroundColor: (theme) => theme.palette.action.hover,
        borderRadius: 1,
        my: 1,
    },
    header: {
        borderRadius: 1,
        backgroundColor: (theme) => theme.palette.action.hover,
        mx: 0.5,
        mb: 0.5,
    },
    activeIcon: {
        color: (theme) => theme.palette.text.primary,
    },
    content: {
        borderRadius: 1,
        mx: 0.5,
        flexGrow: 1,
        mb: 1,
    },
}
