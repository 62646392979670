import { Chunk, Content, ContentType, ItemModel } from "@recall/common"
import { createContext, useContext, useEffect, useState } from "react"
import { useContentType } from "../hooks/useContentType"
import { useItem } from "../hooks/useItem"

interface ItemState {
    item: ItemModel
    chunks: Chunk[]
    markdown: string
    isCreatingTag: boolean
    isCreatingConnections: boolean
    contentType: ContentType
    isLoading: boolean
    isExpanding: boolean
    isLoadingChat: boolean
    isLoadingDelayed: boolean
    expandItem: (item: ItemModel) => Promise<void>
    reformatReader: () => Promise<void>
    createConnections: (
        item: ItemModel,
        chunks: Chunk[],
        content: Pick<Content, "markdown" | "links">,
        url?: string
    ) => Promise<any[]>
}

const ItemContext = createContext<ItemState | null>(null)

export const ItemProvider = ({ children }: { children: React.ReactNode }) => {
    const {
        item,
        chunks,
        markdown,
        isCreatingTag,
        isCreatingConnections,
        isExpanding,
        isLoadingChat,
        expandItem,
        reformatReader,
        createConnections,
    } = useItem()
    const contentType = useContentType(item)

    const [delayedLoading, setDelayedLoading] = useState(false)

    useEffect(() => {
        let timeoutId: NodeJS.Timeout | null = null

        if (!item) {
            timeoutId = setTimeout(() => {
                setDelayedLoading(true)
            }, 300)
        } else {
            setDelayedLoading(false)
        }

        return () => {
            if (timeoutId) clearTimeout(timeoutId)
        }
    }, [item])

    return (
        <ItemContext.Provider
            value={{
                contentType,
                item,
                chunks,
                markdown,
                isCreatingTag,
                isCreatingConnections,
                isLoading: !contentType || !item,
                isLoadingDelayed: delayedLoading,
                isExpanding,
                isLoadingChat,
                expandItem,
                reformatReader,
                createConnections,
            }}
        >
            {children}
        </ItemContext.Provider>
    )
}

export const useItemContext = () => {
    return useContext(ItemContext)
}
