import { ASSET_TYPES, ItemModel } from "@recall/common"
import { useEffect, useState } from "react"

export const useFile = (item: ItemModel) => {
    const [fileAsset, setFileAsset] = useState<{ file: File | null; isLoading: boolean }>({
        file: null,
        isLoading: true,
    })

    useEffect(() => {
        fetchFileAsset()
    }, [item])

    const fetchFileAsset = async () => {
        const assets = await item.assets.fetch()
        const fileAsset = assets.find((asset) => asset.type === ASSET_TYPES.PDF)
        if (!fileAsset) {
            setFileAsset({ file: null, isLoading: false })
            return
        }
        const response = await fetch(fileAsset.url)
        const blob = await response.blob()
        const file = new File([blob], "file.pdf", { type: "application/pdf" })
        setFileAsset({ file, isLoading: false })
    }

    return fileAsset
}
