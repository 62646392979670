import { IconButton, SxProps, Theme } from "@mui/material"
import { PanelLeftOpen } from "lucide-react"
import { FC } from "react"
import { useDispatch, useSelector } from "react-redux"
import { SET_DRAWER_OPEN } from "storage/redux/drawer/actionTypes"
import { RootState } from "storage/redux/rootReducer"

interface Props {
    filtered: boolean
}

export const KnowledgeGraphFilter: FC<Props> = ({ filtered }) => {
    const drawerOpen = useSelector((state: RootState) => state.drawer.open)
    const dispatch = useDispatch()

    const openDrawer = () => {
        dispatch({ type: SET_DRAWER_OPEN, payload: true })
    }

    if (drawerOpen) return null

    return (
        <IconButton
            color="default"
            aria-label="open drawer"
            onClick={openDrawer}
            edge="start"
            sx={styles.drawerButton}
        >
            <PanelLeftOpen size={20} />
        </IconButton>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    drawerButton: {
        position: "absolute",
        left: 20,
        top: 8,
        color: "text.primary",
    },
}
