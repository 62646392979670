import { DialogContent, Typography } from "@mui/material"
import { commonTutorialStyles, Step, tutorials, useIsMobile } from "@recall/common"
import { FC, PropsWithChildren } from "react"
import { AppTutorialBase } from "../../Tutorial/AppTutorialBase"

export const ACTION_PICKER_ID = "id-action-picker"
export const ACTION_TABS_ID = "id-action-tabs"

export const TutorialCreateCard: FC<PropsWithChildren> = ({ children }) => {
    const isMobile = useIsMobile()

    const steps: Step[] = [
        {
            anchorId: ACTION_PICKER_ID,
            placement: isMobile ? "bottom" : "right",
            showArrow: true,
            component: () => (
                <DialogContent sx={commonTutorialStyles.popoverContent}>
                    <Typography sx={commonTutorialStyles.heading} variant={"h4"}>
                        Choose your default action
                    </Typography>
                    <Typography sx={commonTutorialStyles.body}>
                        Pick your default action. Concise and Detailed are summary options, while
                        Reader lets you view the full content.
                    </Typography>
                </DialogContent>
            ),
        },
        {
            anchorId: ACTION_TABS_ID,
            placement: isMobile ? "bottom" : "left",
            showArrow: true,
            component: () => (
                <DialogContent sx={commonTutorialStyles.popoverContent}>
                    <Typography sx={commonTutorialStyles.heading} variant={"h4"}>
                        Add content to Recall
                    </Typography>
                    <Typography sx={commonTutorialStyles.body}>
                        Add content your way—paste a URL, search, upload a PDF, or import bookmarks.
                        The browser extension makes it even easier!
                    </Typography>
                </DialogContent>
            ),
        },
    ]

    return (
        <>
            {children} <AppTutorialBase tutorialName={tutorials.CREATE_CARD} steps={steps} />
        </>
    )
}
