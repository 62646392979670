import { Database } from "@nozbe/watermelondb"
import {
    ItemModel,
    ROOT_TAG_ID,
    SerializationOptions,
    TagModel,
    dates,
    initEditorData,
    itemRepository,
    markdownSerializer,
    posthogService,
    checkIsUrl,
    tagRepository,
} from "@recall/common"
import { EXPORT_ALL_TO_MARKDOWN, EXPORT_CARD_TO_MARKDOWN } from "constants/events"
import saveAs from "file-saver"
import JSZip from "jszip"

const getZipName = () => {
    return `Recall_export_${dates.formatNow()}.zip`
}

const addItemsToFolder = async (
    db: Database,
    tag: TagModel,
    parentFolder: JSZip
): Promise<void> => {
    const items = await tagRepository.getItemsByTagIds(db, [tag.id])

    for (const item of items) {
        const data = await getMarkDownData(db, item)
        const fileName = getFileName(item)
        parentFolder.file(fileName, data)
    }

    const childrenTags = await tagRepository.getByParentTagIds(db, [tag.id])
    for (const childTag of childrenTags) {
        const folder = parentFolder.folder(cleanNameForFileSystem(childTag.name))
        await addItemsToFolder(db, childTag, folder)
    }
}

const cleanNameForFileSystem = (name: string): string => {
    const cleaned = name.replace(/[<>:"/\\|?*]/g, "").trim()
    const safeName = cleaned || "Untitled"
    return safeName.slice(0, 250)
}

const getFileName = (item: ItemModel) => {
    return `${cleanNameForFileSystem(item.name)}.md`
}

const getSourcesMarkdown = async (item: ItemModel): Promise<string> => {
    let text = ""
    const sources = await item.sources.fetch()

    if (!sources || sources.length === 0) {
        return text
    }

    const urlSources = sources.filter((source) => checkIsUrl(source.identifier))

    if (urlSources.length === 0) {
        return text
    }

    text += `## Sources\n`
    urlSources.forEach((source) => {
        text += `- [${source.name}](${source.identifier})\n`
    })

    return text
}

const getFrontmatter = async (item: ItemModel) => {
    const tags = await item.tags.fetch()
    const tagNames = tags.map((tag) => tag.name)
    const tagNamesString = tagNames.join(", ")

    return `---
title: ${item.name}
tags: ${tagNamesString}
createdAt: ${item.createdAt}
updatedAt: ${item.updatedAt}
---
`
}

const getMarkDownData = async (db: Database, item: ItemModel, options?: SerializationOptions) => {
    const value = await initEditorData(db, item.id)
    let markdown = await markdownSerializer.serialize(value, db, options)
    const markdownSources = await getSourcesMarkdown(item)
    const markdownFrontmatter = await getFrontmatter(item)

    markdown = markdownFrontmatter + "\n\n" + markdown + "\n\n" + markdownSources

    const data = new Blob([markdown], { type: "text/markdown" })
    return data
}

const exportCardToMarkdown = async (
    db: Database,
    item: ItemModel,
    options?: SerializationOptions
) => {
    posthogService.captureEvent(EXPORT_CARD_TO_MARKDOWN, { card: item.name })
    const data = await getMarkDownData(db, item, options)
    const fileName = getFileName(item)
    saveAs(data, fileName)
}

const exportAllToMarkdown = async (db: Database) => {
    posthogService.captureEvent(EXPORT_ALL_TO_MARKDOWN)
    const zip = new JSZip()
    const tag = await tagRepository.get(db, ROOT_TAG_ID)
    await addItemsToFolder(db, tag, zip)
    const items = await itemRepository.getUntaggedItems(db)

    for (const item of items) {
        const data = await getMarkDownData(db, item)
        const fileName = getFileName(item)
        zip.file(fileName, data)
    }

    const data = await zip.generateAsync({ type: "blob" })
    saveAs(data, getZipName())
}

export const exportService = { exportCardToMarkdown, exportAllToMarkdown }
