import { alpha, Box, SxProps, Theme, Typography } from "@mui/material"

interface Props {
    label: string
    icon: React.ReactNode
    onClick: () => void
    selected: boolean
}

export const LargeChip = ({ label, icon, onClick, selected }: Props) => {
    return (
        <Box
            sx={{
                ...styles.largeChip,
                border: (theme) =>
                    selected
                        ? `1px solid ${alpha(theme.palette.primary.main, 0.8)}`
                        : `1px solid ${alpha(theme.palette.primary.light, 0.3)}`,
                bgcolor: (theme) =>
                    selected ? alpha(theme.palette.primary.main, 0.2) : "transparent",
                boxShadow: selected ? 1 : "none",
                fontWeight: selected ? "medium" : "regular",
            }}
            onClick={onClick}
        >
            {icon}
            <Typography variant="body2" color={selected ? "primary.main" : "primary.light"}>
                {label}
            </Typography>
        </Box>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    largeChip: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: 1,
        borderRadius: 1,
        p: 1,
        pr: 1.5,
        cursor: "pointer",
        "&:hover": {
            bgcolor: (theme) => alpha(theme.palette.primary.main, 0.2),
            border: (theme) => `1px solid ${alpha(theme.palette.primary.main, 0.6)}`,
        },
    },
}
