import { PaletteMode, PaletteOptions } from "@mui/material"
import { alpha, createTheme } from "@mui/material/styles"
import { ThemeOptions } from "@mui/material/styles/createTheme"

declare module "@mui/material/styles/createPalette" {
    export interface TypeBackground {
        default: string
        paper: string
        layout: string
    }

    export interface TypeAction {
        active: string
        hover: string
        hoverOpacity: number
        selected: string
        selectedOpacity: number
        disabled: string
        disabledOpacity: number
        disabledBackground: string
        focus: string
        focusOpacity: number
        activatedOpacity: number
        activeIcon: string
    }
}

declare module "@mui/material/styles" {
    interface Theme {
        borders: {
            paper: string
        }
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
        borders?: {
            paper?: string
        }
    }
}

export const CONTENT_SCRIPT_Z_INDEX = 9999999999

export const LIGHT_REFERENCE_LINK_COLOR = "#3366cc"
export const DARK_REFERENCE_LINK_COLOR = "#f5d471"

export const lightPalette: PaletteOptions = {
    mode: "light",
    primary: {
        dark: "#2F61A0",
        main: "#478BE0",
        light: "#A8C5DF",
    },
    secondary: {
        main: "#F2B807",
    },
    error: {
        dark: "#EE2D1B",
        main: "#F15244",
        light: "#F47367",
    },
    success: {
        dark: "#15880F",
        main: "#259c1f",
        light: "#4fa44b",
    },
    background: {
        paper: "#FFFFFF",
        default: "#F8F8F9",
        layout: "#f4f6f8",
    },
    action: {
        active: "rgba(47, 56, 63, 0.80)",
        disabled: "rgba(47, 56, 63, 0.26)",
        selected: "rgba(47, 56, 63, 0.14)",
        disabledBackground: "rgba(47, 56, 63, 0.12)",
        hover: "rgba(47, 56, 63, 0.04)",
        activeIcon: "#A8C5DF",
    },
    text: {
        secondary: "rgba(47, 56, 63, 0.84)",
    },
}

// https://material.io/design/color/the-color-system.html#tools-for-picking-colors

export const darkPalette: PaletteOptions = {
    mode: "dark",
    primary: {
        dark: "#2F61A0",
        main: "#478BE0", // #4D80E4
        light: "#A8C5DF",
    },
    secondary: {
        main: "#F2B807",
    },
    error: {
        main: alpha("#d36e65", 0.8),
    },
    success: {
        main: "#6bac65",
    },
    background: {
        paper: "#1b1c20",
        default: "#0C0D12",
        layout: "#0C0D12",
    },
    action: {
        active: "rgba(255, 255, 255, 0.6)",
        disabled: "rgba(255, 255, 255, 0.3)",
        selected: "rgba(255, 255, 255, 0.16)",
        disabledBackground: "rgba(255, 255, 255, 0.12)",
        hover: "rgba(255, 255, 255, 0.03)",
        activeIcon: "#213c5f",
    },
    text: {
        primary: "rgba(255, 255, 255)",
        secondary: "rgba(255, 255, 255, 0.9)",
    },
}

const getTheme = (
    mode: PaletteMode,
    customContainer: HTMLElement | null = null,
    options: Partial<ThemeOptions> = {}
) => {
    const isForExtension = customContainer !== null
    const isDarkMode = mode === "dark"
    const invertedBackground = isDarkMode ? lightPalette.background : darkPalette.background

    let theme = createTheme({
        palette: isDarkMode ? darkPalette : lightPalette,
        shape: {
            borderRadius: 8,
        },
        typography: {
            fontFamily: ["Inter", "sans-serif"].join(","),
            button: {
                textTransform: "none",
            },
            // @ts-ignore
            pxToRem: isForExtension ? (px) => `${px}px` : undefined,
            ...options.typography,
        },
    })
    // TODO - is this needed?
    // theme = responsiveFontSizes(theme)

    theme.borders = {
        paper: `1px solid ${theme.palette.action.disabled}`,
    }

    theme.components = {
        MuiMenu: {
            styleOverrides: {
                list: {
                    backgroundColor: theme.palette.background.layout,
                },
                paper: {
                    border: theme.borders.paper,
                },
            },
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true,
            },
            styleOverrides: {
                containedInherit: {
                    color: theme.palette.text.secondary,
                    backgroundColor: theme.palette.action.disabledBackground,
                    boxShadow: "none",
                    "&:hover": {
                        color: theme.palette.text.primary,
                        backgroundColor: theme.palette.action.disabled,
                        boxShadow: "none",
                    },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                sizeSmall: {
                    fontSize: 10,
                    padding: 0.5,
                },
                root: {
                    borderRadius: 4,
                    padding: 4,
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    borderRadius: 4,
                    padding: 1.5,
                },
            },
        },
        MuiSkeleton: {
            styleOverrides: {
                root: {
                    backgroundColor: theme.palette.action.disabledBackground,
                    borderRadius: theme.shape.borderRadius,
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    color: theme.palette.text.primary,
                    borderColor: theme.palette.action.disabled,
                    "&.MuiOutlinedInput-notchedOutline": {
                        borderColor: theme.palette.action.disabled,
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: theme.palette.action.disabled,
                        borderWidth: 1,
                    },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: theme.palette.text.primary,
                    "&.Mui-focused": {
                        color: theme.palette.text.primary,
                    },
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundImage: "none",
                },
            },
        },
        MuiTooltip: {
            defaultProps: {
                arrow: true,
                enterTouchDelay: 200,
                leaveTouchDelay: 100,
                enterDelay: 700,
                enterNextDelay: 700,
            },
            styleOverrides: {
                tooltip: {
                    borderRadius: theme.spacing(0.5),
                    color: theme.palette.background.default,
                    backgroundColor: invertedBackground?.default,
                    boxShadow: `0px 3px 8px ${alpha(theme.palette.background.default, 0.3)}`,
                    border: `1px solid ${alpha(theme.palette.background.default, 0.3)}`,
                    "& .MuiTooltip-arrow": {
                        "&::before": {
                            border: `1px solid ${alpha(theme.palette.background.default, 0.3)}`,
                            boxSizing: "border-box",
                        },
                    },
                },
                arrow: {
                    color: invertedBackground?.default,
                },
            },
        },
        MuiCssBaseline: {
            styleOverrides: {
                "*::-webkit-scrollbar": {
                    width: 8,
                },
                "*::-webkit-scrollbar-track": {
                    backgroundColor: theme.palette.background.layout,
                    borderRadius: theme.shape.borderRadius,
                },
                "*::-webkit-scrollbar-thumb": {
                    background: alpha(theme.palette.grey[500], 0.3),
                    borderRadius: theme.shape.borderRadius,

                    "&:hover": {
                        background: alpha(theme.palette.grey[500], 0.6),
                    },
                },
                "::selection": {
                    backgroundColor: alpha("#B4D5FE", 0.7),
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                outlined: {
                    borderColor: theme.palette.action.disabled,
                },
                root: {
                    borderRadius: 12,
                },
                sizeSmall: {
                    borderRadius: 10,
                },
            },
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    height: 50,
                    minHeight: 50,
                    "@media (min-width:600px)": {
                        height: 40,
                        minHeight: 40,
                    },
                    "@media (min-width:0px) and (orientation: landscape)": {
                        minHeight: 50,
                    },
                    "@media (min-width:600px) and (orientation: landscape)": {
                        minHeight: 40,
                    },
                },
            },
        },
    }

    if (isForExtension) {
        const componentsToUpdate = {
            MuiPopover: {
                defaultProps: {
                    container: customContainer,
                },
            },
            MuiPopper: {
                defaultProps: {
                    container: customContainer,
                },
            },
            MuiModal: {
                defaultProps: {
                    container: customContainer,
                },
            },
        }

        theme.components = {
            ...theme.components,
            ...componentsToUpdate,
        }

        theme.zIndex.tooltip += CONTENT_SCRIPT_Z_INDEX
        theme.zIndex.modal += CONTENT_SCRIPT_Z_INDEX
    }

    return theme
}

export { getTheme }
