import { Database } from "@nozbe/watermelondb"
import { getDownloadURL, ref, uploadBytes } from "firebase/storage"
import { v4 as uuid } from "uuid"
import { ASSET_TYPES, ItemModel } from "../storage/watermelon"
import { assetRepository } from "../storage/watermelon/repository/assetRepository"
import { sentry } from "../utils"
import { firebase, firebaseConfig } from "./firebaseService"
import { GoogleDocsService } from "./GoogleDocsService"

const getFileName = (itemName: string) => {
    if (GoogleDocsService.isGoogleDoc(itemName)) {
        return `${uuid()}.pdf`
    }

    return itemName
}

const uploadFileAPI = async (
    file: File,
    itemName: string,
    uid: string,
    token: string
): Promise<string | null> => {
    const name = getFileName(itemName)
    const url = `https://firebasestorage.googleapis.com/v0/b/${firebaseConfig.storageBucket}/o/${encodeURIComponent(`user/${uid}/files/${name}`)}`

    const response = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": file.type || "application/pdf",
            Authorization: `Bearer ${token}`,
        },
        body: file,
    })

    if (!response.ok) {
        const errorText = await response.text()
        sentry.captureException(new Error(errorText))
        return null
    }

    const result = await response.json()

    return `https://firebasestorage.googleapis.com/v0/b/${firebaseConfig.storageBucket}/o/${encodeURIComponent(`user/${uid}/files/${name}`)}?alt=media&token=${result.downloadTokens}`
}

async function copyFile(originalUrl: string, newPath: string) {
    const response = await fetch(originalUrl)
    const blob = await response.blob()
    const newRef = ref(firebase.storage, newPath)

    await uploadBytes(newRef, blob)

    const url = await getDownloadURL(newRef)
    return { url, size: blob.size }
}

const uploadFile = async (file: File, name: string, uid: string) => {
    const storageRef = ref(firebase.storage, `user/${uid}/files/${name}`)
    const result = await uploadBytes(storageRef, file)
    const url = await getDownloadURL(result.ref)
    return url
}

const uploadAsset = async ({
    db,
    file,
    item,
    uid,
    type = ASSET_TYPES.PDF,
}: {
    db: Database
    file: File
    item: ItemModel
    uid: string
    type?: ASSET_TYPES
}) => {
    try {
        const url = await uploadFile(file, uuid(), uid)
        await assetRepository.createAsset(db, {
            type,
            item,
            url,
            size: file.size,
        })
    } catch (error) {
        sentry.captureException(error as Error)
    }
}

export const fileService = {
    uploadFileAPI,
    uploadFile,
    uploadAsset,
    copyFile,
}
