import { firebase, tutorials } from "@recall/common"
import { isAfter, startOfDay } from "date-fns"
import { useSelector } from "react-redux"
import { RootState } from "storage/redux/rootReducer"

const NEW_FEATURES_RELEASE_DATE = startOfDay(new Date("2025-03-29"))
const STOP_DATE = startOfDay(new Date("2025-04-30"))

export const useShowNewFeaturesModal = () => {
    const user = useSelector((state: RootState) => state.user)

    // @ts-ignore
    const userSignupTime = Number(firebase?.auth?.currentUser?.metadata?.createdAt || 0)
    if (!userSignupTime) return false

    const isNewFeaturesModalSeen = user.tutorial.completed[tutorials.RAB_RELEASE_MODAL]
    if (isNewFeaturesModalSeen) return false

    const isUserSignupAfterRelease = isAfter(userSignupTime, NEW_FEATURES_RELEASE_DATE)
    if (isUserSignupAfterRelease) return false

    const isAfterStopDate = isAfter(startOfDay(new Date()), STOP_DATE)
    if (isAfterStopDate) return false

    return true
}
