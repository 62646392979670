import { SvgIcon, SvgIconProps } from "@mui/material"

interface PinIconProps extends SvgIconProps {
    isFilled: boolean
}

export const PinIcon = ({ isFilled, ...props }: PinIconProps) => {
    return (
        <SvgIcon sx={{ ...props.sx, color: "action.active" }} {...props}>
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
            >
                {isFilled && (
                    <path d="M21.5307 9.21968L16.1617 14.6066C17.2361 16.7563 16.0004 18.906 15.2138 19.9503C15.1491 20.0353 15.0671 20.1054 14.9731 20.156C14.8791 20.2066 14.7755 20.2368 14.669 20.2441C14.5625 20.2514 14.4557 20.2359 14.3556 20.1987C14.2555 20.1615 14.1647 20.1033 14.0888 20.0281L3.96947 9.91531C3.89479 9.84072 3.8367 9.7512 3.799 9.65261C3.76129 9.55402 3.74482 9.44858 3.75066 9.34319C3.75651 9.2378 3.78453 9.13483 3.83289 9.041C3.88126 8.94719 3.94889 8.86464 4.03135 8.79875C6.81103 6.55625 9.4126 7.86125 9.4126 7.86125L14.7817 2.47437C14.9223 2.33383 15.113 2.25488 15.3119 2.25488C15.5107 2.25488 15.7014 2.33383 15.842 2.47437L21.5307 8.15657C21.6007 8.22625 21.6563 8.30909 21.6942 8.40031C21.7321 8.49152 21.7517 8.58934 21.7517 8.68812C21.7517 8.78692 21.7321 8.88473 21.6942 8.97594C21.6563 9.06716 21.6007 9.15 21.5307 9.21968Z" />
                )}
                <path d="M22.0611 7.62821L16.3715 1.93946C16.2321 1.80013 16.0668 1.68961 15.8847 1.61422C15.7027 1.53881 15.5077 1.5 15.3107 1.5C15.1136 1.5 14.9185 1.53881 14.7366 1.61422C14.5545 1.68961 14.3892 1.80013 14.2499 1.93946L9.22205 6.98321C8.22268 6.67009 5.94079 6.29227 3.55955 8.21509C3.39541 8.34707 3.26092 8.51213 3.1648 8.69953C3.06869 8.88695 3.01312 9.09248 3.00172 9.3028C2.9903 9.5131 3.02332 9.72344 3.09858 9.92015C3.17386 10.1169 3.28972 10.2955 3.43861 10.4445L7.96861 14.9726L3.96923 18.9691C3.8285 19.1099 3.74944 19.3008 3.74944 19.4998C3.74944 19.6988 3.8285 19.8896 3.96923 20.0304C4.10996 20.1712 4.30084 20.2502 4.49986 20.2502C4.69888 20.2502 4.88975 20.1712 5.03048 20.0304L9.02705 16.031L13.5542 20.5582C13.6934 20.698 13.8588 20.809 14.0409 20.8847C14.223 20.9604 14.4182 20.9995 14.6155 20.9998C14.6511 20.9998 14.6858 20.9998 14.7215 20.9998C14.9353 20.985 15.1434 20.9243 15.3317 20.8219C15.5199 20.7194 15.684 20.5777 15.8126 20.4064C17.6539 17.9594 17.4768 15.9701 17.0492 14.7814L22.0621 9.74977C22.2013 9.61042 22.3117 9.445 22.3871 9.26296C22.4624 9.0809 22.5012 8.88581 22.5011 8.6888C22.5009 8.49179 22.4621 8.29672 22.3866 8.11475C22.3111 7.93277 22.2005 7.76744 22.0611 7.62821ZM20.9999 8.68946L15.6308 14.0764C15.5199 14.1876 15.4467 14.3309 15.4218 14.486C15.3967 14.6411 15.4209 14.8002 15.4911 14.9407C16.3779 16.7154 15.3224 18.5585 14.6155 19.4988L4.49986 9.38227C5.63236 8.46914 6.71611 8.22821 7.54486 8.22821C8.07072 8.22103 8.59225 8.3242 9.07579 8.53103C9.21686 8.60162 9.37661 8.62595 9.5323 8.60053C9.68798 8.57512 9.83168 8.50126 9.94298 8.38946L15.3111 2.99977L20.9999 8.68946Z" />
            </svg>
        </SvgIcon>
    )
}
