import { Database, Q } from "@nozbe/watermelondb"
import { Source } from "../../../types/Source"
import { ItemModel } from "../models/ItemModel"
import { SourceModel } from "../models/SourceModel"
import { SOURCES } from "../schema"

const getAll = async (db: Database, sourceName: string) => {
    const query = db.collections
        .get<SourceModel>(SOURCES)
        .query(Q.where("is_saved", true), Q.where("name", sourceName))

    return query.fetch()
}

const get = async (db: Database, sourceIdentifier: string, sourceName: string | null = null) => {
    const query = db.collections
        .get<SourceModel>(SOURCES)
        .query(Q.where("identifier", sourceIdentifier), Q.where("is_saved", true))

    if (sourceName) query.extend(Q.where("name", sourceName))

    const results = await query.fetch()

    return results?.[0] || null
}

const getAny = async (db: Database, sourceIdentifier: string, sourceName: string | null = null) => {
    const query = db.collections
        .get<SourceModel>(SOURCES)
        .query(Q.where("identifier", sourceIdentifier))

    if (sourceName) query.extend(Q.where("name", sourceName))

    const results = await query.fetch()

    return results?.[0] || null
}

const getByIds = async (db: Database, identifiers: string[]) => {
    const query = db.collections
        .get<SourceModel>(SOURCES)
        .query(Q.where("identifier", Q.oneOf(identifiers)), Q.where("is_saved", true))

    const results = await query.fetch()

    return results
}

const getAllById = async (db: Database, identifier: string) => {
    const query = db.collections.get<SourceModel>(SOURCES).query(Q.where("identifier", identifier))

    const results = await query.fetch()

    return results
}

const deleteStale = async (db: Database) => {
    await db.write(async (writer) => {
        const tasks = []
        const sources = await db.collections.get<SourceModel>(SOURCES).query().fetch()

        for (const source of sources) {
            try {
                await source.item.fetch()
            } catch (e) {
                const anotherTask = source.prepareDelete()
                tasks.push(anotherTask)
            }
        }

        await writer.batch(...tasks)
    })
}

const create = async (db: Database, item: ItemModel, source: Source) => {
    await db.write(async () => {
        return db.collections.get<SourceModel>(SOURCES).create((record: SourceModel) => {
            record._raw.id = source.id
            record.item.set(item)
            record.name = source.name
            record.identifier = source.identifier
            record.isSaved = item.isSaved
        })
    })
}

export const sourceRepository = { get, getAny, getByIds, deleteStale, getAll, getAllById, create }
