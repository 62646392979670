import { Box, Button, IconButton, Tooltip } from "@mui/material"
import { useDatabase } from "@nozbe/watermelondb/react"
import {
    DownloadIcon,
    ItemModel,
    sentry,
    ShareIcon,
    tagRepository,
    useIsMobile,
} from "@recall/common"
import { useFileAsset } from "components/ItemPage/hooks/useFileAsset"
import DrawerButton from "components/layouts/components/Drawer/DrawerButton"
import { RERENDER_TAGS_EVENT } from "components/shared/tags/hooks/useGroupedTags"
import { useOpenState } from "hooks/useOpenState"
import { Breadcrumbs } from "../../breadcrumbs/Breadcrumbs"
import { ExpandButton } from "./Expand/Expand"
import { ItemMenu } from "./ItemMenu"
import { PushPinToggle } from "./PushPinToggle"

export const SHARE_BUTTON_DIV_ID = "id-share-button"
export const SAVE_BUTTON_DIV_ID = "id-save-button"

interface Props {
    isSaved: boolean
    item: ItemModel
    handleSave: () => Promise<void>
}

export const ItemHeader: React.FC<Props> = ({ isSaved, item, handleSave }) => {
    const db = useDatabase()

    const fileAsset = useFileAsset(item)
    const {
        isOpen: isShareModalOpen,
        handleClose: handleCloseShareModal,
        handleOpen: handleOpenShareModal,
    } = useOpenState()
    const isMobile = useIsMobile()

    const handleSetIsReference = async (e) => {
        e.preventDefault()
        await item.setIsReference(!item.isReference)
        const tags = await item.tags.fetch()
        await tagRepository.updateTagsReferenceState(db, tags, item)
        document.dispatchEvent(new CustomEvent(RERENDER_TAGS_EVENT))
    }

    const handleDownload = async () => {
        if (fileAsset) {
            try {
                const response = await fetch(fileAsset.url)
                const blob = await response.blob()
                const url = window.URL.createObjectURL(blob)
                const link = document.createElement("a")
                link.href = url
                link.download = item.name.toLowerCase().replace(/ /g, "_")
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
                window.URL.revokeObjectURL(url)
            } catch (error) {
                sentry.captureException(error)
            }
        }
    }

    return (
        <Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" py={1}>
                <Box display="flex" alignItems="center" gap={1} minHeight={36}>
                    <DrawerButton />
                    {!isMobile && <Breadcrumbs />}
                </Box>
                <Box display="flex" alignItems="center" gap={1}>
                    {fileAsset && (
                        <IconButton color="inherit" onClick={handleDownload}>
                            <DownloadIcon fontSize="small" />
                        </IconButton>
                    )}

                    {isSaved ? (
                        <>
                            <ExpandButton />
                            <PushPinToggle
                                pinned={!item.isReference}
                                onClick={handleSetIsReference}
                            />
                            <Tooltip title="Share this card">
                                <IconButton onClick={handleOpenShareModal} id={SHARE_BUTTON_DIV_ID}>
                                    <ShareIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                            <ItemMenu
                                item={item}
                                handleCloseShareModal={handleCloseShareModal}
                                handleOpenShareModal={handleOpenShareModal}
                                isShareModalOpen={isShareModalOpen}
                            />
                        </>
                    ) : (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSave}
                            id={SAVE_BUTTON_DIV_ID}
                        >
                            Save
                        </Button>
                    )}
                </Box>
            </Box>
            {isMobile && <Breadcrumbs />}
        </Box>
    )
}
