import { Box, Button, Tooltip, Typography } from "@mui/material"
import { SxProps, Theme } from "@mui/material/styles"
import { withObservables } from "@nozbe/watermelondb/react"
import { BacklinkIcon, ConnectionModel, MentionItemType, mentionsService } from "@recall/common"
import { useOpenItem } from "hooks/items/useOpenItem"
import { useIsDarkMode } from "hooks/useThemeMode"
import { FC, useEffect, useState } from "react"
import { Mention } from "./Mention"

interface Props {
    connections: ConnectionModel[]
    itemId: string
}

const VISIBLE_MENTIONS_STEP = 8

const MentionsComp: FC<Props> = ({ connections }) => {
    const [mentions, setMentions] = useState<MentionItemType[]>([])
    const [visibleMentions, setVisibleMentions] = useState(VISIBLE_MENTIONS_STEP)
    const isDarkMode = useIsDarkMode()
    const { openItemById } = useOpenItem()

    const getMentions = async () => {
        const mentions = await mentionsService.getByConnections(connections)
        setMentions(mentions)
    }

    useEffect(() => {
        getMentions()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connections])

    if (!mentions.length) return null

    return (
        <Box sx={styles.root}>
            <Box mb={1} display="flex" alignItems="center" gap={1}>
                <Tooltip
                    title={`${connections.length} other card${
                        connections.length > 1 ? "s" : ""
                    } link to this card`}
                >
                    <Box>
                        <BacklinkIcon count={connections.length} />
                    </Box>
                </Tooltip>
                <Tooltip
                    sx={{ cursor: "default" }}
                    title="Backlinks are links from other cards to this one"
                >
                    <Typography variant="h5">Backlinks</Typography>
                </Tooltip>
            </Box>
            <Box sx={styles.mentions}>
                {mentions.slice(0, visibleMentions).map((mention) => (
                    <Mention key={mention.id} mention={mention} openItemById={openItemById} />
                ))}
                {mentions.length > visibleMentions && (
                    <>
                        <Box
                            position="relative"
                            onClick={() =>
                                setVisibleMentions((prev) => prev + VISIBLE_MENTIONS_STEP)
                            }
                        >
                            <Box sx={{ filter: "blur(1px)", pointerEvents: "none" }}>
                                <Mention
                                    mention={mentions[visibleMentions]}
                                    openItemById={openItemById}
                                />
                            </Box>
                            <Button
                                sx={styles.showMore}
                                variant="contained"
                                size="small"
                                color={isDarkMode ? "secondary" : "primary"}
                            >
                                Show more
                            </Button>
                        </Box>
                    </>
                )}
            </Box>
        </Box>
    )
}

const enhance = withObservables(["connections"], ({ connections }) => ({
    connections: connections.observe(),
}))

export const Mentions = enhance(MentionsComp)

const styles: Record<string, SxProps<Theme>> = {
    root: {
        mb: 2,
        width: "100%",
    },
    mentions: {
        background: (theme) => theme.palette.background.default,
        borderRadius: 1,
        overflow: "hidden",
    },
    showMore: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        py: 0.2,
    },
}
