import { SvgIcon, SvgIconProps } from "@mui/material"

export const NotebookIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon sx={{ ...props.sx, color: "action.active" }} {...props}>
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M17.25 10.5C17.25 10.6989 17.171 10.8897 17.0303 11.0303C16.8897 11.171 16.6989 11.25 16.5 11.25H10.5C10.3011 11.25 10.1103 11.171 9.96967 11.0303C9.82902 10.8897 9.75 10.6989 9.75 10.5C9.75 10.3011 9.82902 10.1103 9.96967 9.96967C10.1103 9.82902 10.3011 9.75 10.5 9.75H16.5C16.6989 9.75 16.8897 9.82902 17.0303 9.96967C17.171 10.1103 17.25 10.3011 17.25 10.5ZM16.5 12.75H10.5C10.3011 12.75 10.1103 12.829 9.96967 12.9697C9.82902 13.1103 9.75 13.3011 9.75 13.5C9.75 13.6989 9.82902 13.8897 9.96967 14.0303C10.1103 14.171 10.3011 14.25 10.5 14.25H16.5C16.6989 14.25 16.8897 14.171 17.0303 14.0303C17.171 13.8897 17.25 13.6989 17.25 13.5C17.25 13.3011 17.171 13.1103 17.0303 12.9697C16.8897 12.829 16.6989 12.75 16.5 12.75ZM21 4.5V19.5C21 19.8978 20.842 20.2794 20.5607 20.5607C20.2794 20.842 19.8978 21 19.5 21H4.5C4.10218 21 3.72064 20.842 3.43934 20.5607C3.15804 20.2794 3 19.8978 3 19.5V4.5C3 4.10218 3.15804 3.72064 3.43934 3.43934C3.72064 3.15804 4.10218 3 4.5 3H19.5C19.8978 3 20.2794 3.15804 20.5607 3.43934C20.842 3.72064 21 4.10218 21 4.5ZM4.5 19.5H6.75V4.5H4.5V19.5ZM19.5 19.5V4.5H8.25V19.5H19.5Z" />
            </svg>
        </SvgIcon>
    )
}
