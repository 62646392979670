import { useMediaBreakpoint } from "@recall/common"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { SET_SCROLL_POSITION } from "storage/redux/app/actionTypes"
import { RootState } from "storage/redux/rootReducer"

export const useScrollPosition = (isScrollReady: boolean) => {
    const dispatch = useDispatch()
    const isTouchableDevice = useMediaBreakpoint("md")

    const scrollPosition = useSelector((state: RootState) => state.app.scrollPosition)

    useEffect(() => {
        if (!isScrollReady) return

        let scrollPos = 0

        window.scroll({ top: scrollPosition })

        const handleScroll = () => {
            scrollPos = window.scrollY
        }

        window.addEventListener(isTouchableDevice ? "scroll" : "scrollend", handleScroll, {
            passive: true,
        })

        return () => {
            dispatch({ type: SET_SCROLL_POSITION, payload: scrollPos })
            window.removeEventListener(isTouchableDevice ? "scroll" : "scrollend", handleScroll)
        }
        // eslint-disable-next-line
    }, [isScrollReady])
}
