import { MAX_DESCRIPTION_LENGTH } from "../constants/editor"
import nlp from "compromise"

const REMOVE_PARENTHESES_REGEX = /\(.*?\)/g
const REMOVE_LEADING_TRAILING_PUNCTUATION_REGEX = /^[\p{P}\p{S}]+|[\p{P}\p{S}]+$/gu

export const truncateText = (text: string, length = MAX_DESCRIPTION_LENGTH) => {
    let truncatedText = text
    let truncated = false
    const words = text.split(" ")

    if (text.length > length) {
        truncated = true
        truncatedText = ""

        for (const word of words) {
            if (truncatedText.length >= length) {
                break
            }
            truncatedText += `${word} `
        }
        truncatedText = truncatedText.replace(/(,|:|;)*$/, "")
        truncatedText = `${truncatedText.trim()}...`
    }

    return [truncatedText, truncated]
}

export const removePossessive = (word: string): string => {
    if (word.match(/[’']s$/i)) {
        return word.slice(0, -2)
    }

    return word
}

export const trimPunctuation = (word: string): string => {
    return word.replace(REMOVE_LEADING_TRAILING_PUNCTUATION_REGEX, "")
}

export const normalizeWord = (word: string): string => {
    word = word.replace(REMOVE_PARENTHESES_REGEX, "")
    word = trimPunctuation(word)
    word = removePossessive(word)

    let normalized = nlp(word)
        .normalize({ unicode: true, acronyms: true, honorifics: true })
        .out("text")

    return normalized
}

export const normalizeWords = (words: string[]) => {
    return words.map((word) => normalizeWord(word))
}
