import { alpha, Box, SxProps, Theme, Typography } from "@mui/material"
import { FC } from "react"
import { useHistory, useLocation } from "react-router"

interface Props {
    icon: React.ReactNode
    path: string
    label: string
}

export const NavItem: FC<Props> = ({ icon, path, label }) => {
    const { pathname } = useLocation()
    const history = useHistory()

    const isActive = pathname === path

    const handleClick = () => {
        if (path === pathname) return
        history.push(path)
    }

    return (
        <Box
            onClick={handleClick}
            display="flex"
            flexDirection="column"
            alignItems="center"
            sx={{
                ...styles.navItem,
                color: (theme) =>
                    isActive ? "text.primary" : alpha(theme.palette.text.primary, 0.7),
            }}
        >
            <Box
                sx={{
                    ...styles.icon,
                    backgroundColor: isActive ? "action.activeIcon" : "transparent",
                }}
            >
                {icon}
            </Box>
            <Typography variant="caption" sx={{ fontWeight: 600 }}>
                {label}
            </Typography>
        </Box>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    navItem: {
        cursor: "pointer",

        "&:hover": {
            color: "text.primary",
        },
    },
    icon: {
        p: 0.8,
        mb: 0.1,
        borderRadius: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}
