import { doc, getDoc } from "firebase/firestore"
import { FIRESTORE_COLLECTIONS } from "../constants"
import { User } from "../repositories/userRepository"
import { schema } from "../storage/watermelon/schema"
import { firebase } from "./firebaseService"

const isVersionUpToDate = async (user: User) => {
    if (!user?.schemaVersion) return true

    if (user.schemaVersion > schema.version) {
        const latestVersion = await getLatestVersion()
        return latestVersion <= schema.version
    }

    return true
}

const getLatestVersion = async () => {
    const snapshot = await getDoc(doc(firebase.firestore, FIRESTORE_COLLECTIONS.CONFIG, "schema"))
    return snapshot.data()?.version
}

export const schemaService = { isVersionUpToDate, getLatestVersion }
