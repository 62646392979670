import { buildUrl, HttpClient } from "../utils"
import { BaseApi } from "./BaseApi"
const GENERATE_TAG_PATH = "/tags/generate"

export class TagsApi extends BaseApi {
    constructor(httpClient: HttpClient) {
        super(httpClient)
    }

    async generateTag(
        text: string,
        name: string,
        itemId: string,
        url: string,
        isImporting?: boolean
    ) {
        const response = await this.httpClient.post(
            buildUrl(GENERATE_TAG_PATH, { itemId, url }),
            JSON.stringify({ text, name }),
            this.getHeaders({ isImporting })
        )
        return await response.json()
    }
}
