import { Box, Typography } from "@mui/material"
import { LayoutGrid, StretchHorizontal } from "lucide-react"
import { useDispatch, useSelector } from "react-redux"
import { SET_ITEMS_VIEW } from "storage/redux/items/actionTypes"
import { RootState } from "storage/redux/rootReducer"

export const ViewToggle = () => {
    const view = useSelector((state: RootState) => state.items.view)
    const dispatch = useDispatch()

    const toggleView = () => {
        dispatch({ type: SET_ITEMS_VIEW, payload: view === "grid" ? "list" : "grid" })
    }

    return (
        <Box
            onClick={toggleView}
            sx={{
                cursor: "pointer",
                border: (theme) => `1px solid ${theme.palette.divider}`,
                borderRadius: 1,
                px: 1,
                py: 0.5,
                display: "flex",
                alignItems: "center",
                gap: 0.8,
                "&:hover": {
                    backgroundColor: (theme) => theme.palette.action.hover,
                },
            }}
        >
            {view === "grid" ? <StretchHorizontal size={18} /> : <LayoutGrid size={18} />}
            <Typography variant="body2">{view === "grid" ? "List" : "Grid"}</Typography>
        </Box>
    )
}
