import { pick } from "lodash"
import { WEBSITE } from "../../constants"
import { ConnectionModel, MentionSentence } from "../../storage/watermelon"
import { EditorBlockData } from "../editorData"

export interface MentionItemType {
    id: string
    name: string
    description: string
    image: string
    blocks: MentionSentence[]
    sourceUrl: string | null
}

const getByConnections = async (connections: ConnectionModel[]): Promise<MentionItemType[]> => {
    const items: MentionItemType[] = []

    for (const connection of connections) {
        const item = await connection.from
        const editorBlocks = await item.editorBlocks.fetch()
        const blocks = getMentionedEditorBlocks(editorBlocks, connection.id)
        const websiteSource = await item.getSource(WEBSITE)
        const sourceUrl = websiteSource?.identifier ?? null

        const mentions = await connection.textMentions.fetch()
        const mentionBlocks = mentions.map((mention) => {
            return {
                textBlocks: mention.blocks,
                elementId: mention.elementId,
                isReaderMention: true,
            }
        })

        items.push({
            ...pick(item, ["id", "image", "name", "description"]),
            blocks: [...mentionBlocks, ...blocks],
            sourceUrl,
        })
    }

    return items
}

const getMentionedEditorBlocks = (editorBlocks: any[], connectionId: string) => {
    const mentionedEditorBlocks = editorBlocks.filter((editorBlock) =>
        JSON.stringify(editorBlock.children).includes(connectionId)
    )

    let blocks: MentionSentence[] = []
    for (const editorBlock of mentionedEditorBlocks) {
        const textElements = EditorBlockData.getTextElements([editorBlock])
        const elementBlocks = getMentionSentence(textElements, connectionId)

        if (elementBlocks.length > 0) {
            blocks = [
                ...blocks,
                {
                    textBlocks: elementBlocks,
                    elementId: editorBlock.id + connectionId,
                },
            ]
        }
    }

    return blocks
}

const getMentionSentence = (mentions: any, connectionId: string) => {
    const texts = []
    let accumulator = ""
    let isAdded = false

    for (const mention of mentions) {
        if (mention?.connectionId === connectionId) {
            isAdded = true
            if (accumulator) {
                texts.push({ text: accumulator })
                accumulator = ""
            }

            texts.push({ ...mention, connectionId, isHighlighted: true })
        } else {
            accumulator += mention.text
            if (accumulator.length > 40) {
                if (!isAdded) {
                    accumulator = "..." + accumulator.slice(-40)
                } else {
                    accumulator = accumulator.slice(0, 40).trimEnd() + "..."
                    texts.push({ text: accumulator })
                    accumulator = ""
                    break
                }
            }
        }
    }

    if (accumulator) {
        texts.push({ text: accumulator })
    }

    return texts
}

export const mentionsService = { getByConnections, getMentionedEditorBlocks }
