import { SvgIcon, SvgIconProps } from "@mui/material"

export const ConciseIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon sx={{ ...props.sx, color: "action.active" }} {...props}>
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M21.0001 12.0001C21.0001 12.199 20.9211 12.3897 20.7804 12.5304C20.6398 12.671 20.449 12.7501 20.2501 12.7501H10.5001C10.3012 12.7501 10.1104 12.671 9.96977 12.5304C9.82912 12.3897 9.7501 12.199 9.7501 12.0001C9.7501 11.8011 9.82912 11.6104 9.96977 11.4697C10.1104 11.3291 10.3012 11.2501 10.5001 11.2501H20.2501C20.449 11.2501 20.6398 11.3291 20.7804 11.4697C20.9211 11.6104 21.0001 11.8011 21.0001 12.0001ZM10.5001 6.75005H20.2501C20.449 6.75005 20.6398 6.67103 20.7804 6.53038C20.9211 6.38973 21.0001 6.19896 21.0001 6.00005C21.0001 5.80114 20.9211 5.61037 20.7804 5.46972C20.6398 5.32907 20.449 5.25005 20.2501 5.25005H10.5001C10.3012 5.25005 10.1104 5.32907 9.96977 5.46972C9.82912 5.61037 9.7501 5.80114 9.7501 6.00005C9.7501 6.19896 9.82912 6.38973 9.96977 6.53038C10.1104 6.67103 10.3012 6.75005 10.5001 6.75005ZM20.2501 17.2501H3.7501C3.55119 17.2501 3.36042 17.3291 3.21977 17.4697C3.07912 17.6104 3.0001 17.8011 3.0001 18.0001C3.0001 18.199 3.07912 18.3897 3.21977 18.5304C3.36042 18.671 3.55119 18.7501 3.7501 18.7501H20.2501C20.449 18.7501 20.6398 18.671 20.7804 18.5304C20.9211 18.3897 21.0001 18.199 21.0001 18.0001C21.0001 17.8011 20.9211 17.6104 20.7804 17.4697C20.6398 17.3291 20.449 17.2501 20.2501 17.2501ZM6.7501 13.5001C6.89852 13.5002 7.04364 13.4562 7.16709 13.3738C7.29053 13.2914 7.38675 13.1743 7.44357 13.0371C7.50038 12.9 7.51524 12.7491 7.48625 12.6036C7.45726 12.458 7.38574 12.3243 7.28073 12.2194L4.06041 9.00005L7.28073 5.78068C7.42146 5.63995 7.50052 5.44907 7.50052 5.25005C7.50052 5.05103 7.42146 4.86016 7.28073 4.71943C7.14 4.5787 6.94912 4.49963 6.7501 4.49963C6.55108 4.49963 6.36021 4.5787 6.21948 4.71943L2.46948 8.46943C2.39974 8.53908 2.34442 8.6218 2.30668 8.71285C2.26894 8.80389 2.24951 8.90149 2.24951 9.00005C2.24951 9.09861 2.26894 9.19621 2.30668 9.28726C2.34442 9.3783 2.39974 9.46102 2.46948 9.53068L6.21948 13.2807C6.28918 13.3503 6.37192 13.4055 6.46296 13.4432C6.55401 13.4808 6.65158 13.5001 6.7501 13.5001Z" />
            </svg>
        </SvgIcon>
    )
}
