import { Box, SvgIconProps, alpha } from "@mui/material"
import React from "react"

interface Props extends SvgIconProps {
    icon: React.ElementType
}

export const IllustrationLight = ({ icon }: Props) => {
    return (
        <svg
            width="223"
            height="143"
            viewBox="0 0 223 143"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g opacity="0.6">
                <rect x="24" y="45" width="174" height="98" rx="15" fill="#D8DFE9" />
                <rect
                    x="24.5"
                    y="45.5"
                    width="173"
                    height="97"
                    rx="14.5"
                    stroke="#478BE0"
                    strokeOpacity="0.2"
                />
            </g>
            <rect x="11" y="36" width="201" height="98" rx="15" fill="#D8DFE9" />
            <rect
                x="11.5"
                y="36.5"
                width="200"
                height="97"
                rx="14.5"
                stroke="#478BE0"
                strokeOpacity="0.2"
            />
            <rect y="27" width="223" height="98" rx="15" fill="#D8DFE9" />
            <rect
                x="0.5"
                y="27.5"
                width="222"
                height="97"
                rx="14.5"
                stroke="#478BE0"
                strokeOpacity="0.2"
            />
            <rect x="16" y="49" width="173" height="10" rx="5" fill="#BBCEE7" />
            <rect x="16" y="71" width="191" height="10" rx="5" fill="#BBCEE7" />
            <rect x="16" y="93" width="173" height="10" rx="5" fill="#BBCEE7" />
            <g filter="url(#filter0_d_4096_465)">
                <circle cx="111" cy="36" r="25" fill="#D8DFE9" />
                <circle cx="111" cy="36" r="24.5" stroke="#478BE0" strokeOpacity="0.2" />
                <g transform="translate(99,23)">
                    <Box
                        component={icon}
                        sx={{ color: (theme) => alpha(theme.palette.primary.main, 0.6) }}
                    />
                </g>
            </g>
            <defs>
                <filter
                    id="filter0_d_4096_465"
                    x="71.5"
                    y="0.5"
                    width="79"
                    height="79"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="7.25" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.278431 0 0 0 0 0.545098 0 0 0 0 0.878431 0 0 0 0.2 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_4096_465"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_4096_465"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    )
}
