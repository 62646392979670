import { Button, ButtonProps, CircularProgress, Theme, SxProps, alpha } from "@mui/material"
import { FC, PropsWithChildren } from "react"

interface Props extends ButtonProps {
    loading?: boolean
    size?: "small"
    isSecondary?: boolean
}

export const PrimaryButton: FC<PropsWithChildren<Props>> = ({
    sx = {},
    size,
    loading,
    startIcon,
    children,
    isSecondary = false,
    disabled = false,
    ...props
}) => {
    const isSmall = size === "small"

    const buttonStyles = {
        ...styles.button,
        ...(isSecondary && styles.secondaryButton),
        fontSize: !isSmall ? "16px" : undefined,
        minHeight: !isSmall ? "44px" : undefined,
        ...sx,
    } as SxProps<Theme>

    return (
        <Button
            translate="no"
            variant="contained"
            sx={buttonStyles}
            {...props}
            disabled={loading || disabled}
            startIcon={!loading && startIcon}
        >
            {loading ? <CircularProgress size={22} color="inherit" /> : children}
        </Button>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    button: {
        boxShadow: "0px 1px 0px 0px rgba(166, 171, 218, 0.30) inset",
        fontSize: "16px",
        fontWeight: 500,
        maxWidth: "400px",
        px: 4,
        py: 1,
        color: (theme) => theme.palette.text.secondary,
        backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.8),
        transition: "all 0.2s ease-in-out",
        "&:hover": {
            boxShadow: "0px 1px 0px 0px rgba(166, 171, 218, 0.30) inset",
            backgroundColor: (theme) => theme.palette.primary.dark,
            color: (theme) => alpha(theme.palette.text.primary, 0.7),
        },
        "&:disabled": {
            boxShadow: "0px 1px 0px 0px rgba(166, 171, 218, 0.30) inset",
        },
    },
    secondaryButton: {
        backgroundColor: (theme) => alpha(theme.palette.text.primary, 0.1),
        transition: "all 0.2s ease-in-out",
        "&:hover": {
            boxShadow: "0px 1px 0px 0px rgba(166, 171, 218, 0.30) inset",
            backgroundColor: (theme) => alpha(theme.palette.text.primary, 0.05),
            color: (theme) => alpha(theme.palette.text.primary, 0.7),
        },
    },
}
