import { Box, Chip, ChipProps, SxProps, Theme, Tooltip } from "@mui/material"
import { FC, ReactElement } from "react"
import {
    conciseSummaryAction,
    detailedSummaryAction,
    openReaderAction,
} from "../../../repositories/userRepository"
import { ConciseIcon } from "../../icons/ConciseIcon"
import { DetailedIcon } from "../../icons/DetailedIcon"
import { ReaderIcon } from "../../icons/ReaderIcon"

interface Props extends ChipProps {
    label: string
    icon?: ReactElement
    disabled?: boolean
    sx?: SxProps<Theme>
    includeTooltip?: boolean
}

export const Action: FC<Props> = ({
    id,
    label,
    icon,
    disabled,
    sx,
    includeTooltip = false,
    ...props
}) => {
    const getIcon = () => {
        if (id === conciseSummaryAction.id) return <ConciseIcon fontSize="small" />
        if (id === detailedSummaryAction.id) return <DetailedIcon fontSize="small" />
        if (id === openReaderAction.id) return <ReaderIcon fontSize="small" />
        return undefined
    }

    const getDescription = () => {
        if (id === conciseSummaryAction.id) return "Create a concise summary of the current page"
        if (id === detailedSummaryAction.id) return "Create a detailed summary of the current page"
        if (id === openReaderAction.id)
            return "Open the reader - this will show a cleaned version of the current page"
        return ""
    }

    if (includeTooltip) {
        return (
            <Tooltip title={getDescription()} placement="left">
                <Chip
                    sx={{ ...styles.action, ...sx } as SxProps<Theme>}
                    label={label}
                    icon={<Box sx={styles.icon}>{getIcon()}</Box>}
                    disabled={disabled}
                    {...props}
                />
            </Tooltip>
        )
    }

    return (
        <Chip
            sx={{ ...styles.action, ...sx } as SxProps<Theme>}
            label={label}
            icon={<Box sx={styles.icon}>{getIcon()}</Box>}
            disabled={disabled}
            {...props}
        />
    )
}

const styles: Record<string, SxProps<Theme>> = {
    action: {
        backgroundColor: (theme) => theme.palette.background.default,
        color: (theme) => theme.palette.text.secondary,
        borderRadius: 30,
        fontSize: 12,
        height: "auto",
        py: 0.5,
        "&:hover": {
            backgroundColor: (theme) => theme.palette.action.disabled,
            color: (theme) => theme.palette.text.primary,
        },
    },
    icon: {
        height: 20,
        "&:hover": {
            color: (theme) => theme.palette.text.primary,
        },
    },
}
