import { HOME_PATH, KNOWLEDGE_GRAPH_PATH } from "constants/routes"
import { useMemo } from "react"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router"
import { SET_BREADCRUMBS } from "storage/redux/app/actionTypes"
import {
    addBreadcrumbAction,
    popBreadcrumbAction,
    removeBreadcrumbsAfterAction,
    updateLastLabelAction,
} from "storage/redux/app/actions"
import { BreadcrumbItem } from "storage/redux/app/types"

export const useBreadcrumbActions = () => {
    const history = useHistory()
    const dispatch = useDispatch()

    const setBreadcrumbs = (breadcrumbs: BreadcrumbItem[]) => {
        dispatch({ type: SET_BREADCRUMBS, payload: breadcrumbs })
    }

    const addBreadcrumb = (breadcrumb: BreadcrumbItem, lastPosition: number) => {
        dispatch(addBreadcrumbAction(breadcrumb, lastPosition))
    }

    const addBreadcrumbWithoutRedirect = (breadcrumb: BreadcrumbItem) => {
        dispatch(addBreadcrumbAction(breadcrumb))
    }

    const removeBreadcrumbsAfter = (path: string) => {
        dispatch(removeBreadcrumbsAfterAction(path))
    }

    const resetAndAddBreadcrumb = (breadcrumb: BreadcrumbItem) => {
        setBreadcrumbs([{ label: "Home", path: HOME_PATH }, breadcrumb])
    }

    const resetBreadcrumbs = (redirect = true) => {
        setBreadcrumbs([{ label: "Home", path: HOME_PATH }])
        if (window.location.pathname !== KNOWLEDGE_GRAPH_PATH && redirect) history.push(HOME_PATH)
    }

    const popBreadcrumb = () => {
        dispatch(popBreadcrumbAction(history.push))
    }

    const updateLastLabel = (label: string) => {
        dispatch(updateLastLabelAction(label))
    }

    return useMemo(
        () => ({
            addBreadcrumb,
            addBreadcrumbWithoutRedirect,
            removeBreadcrumbsAfter,
            resetAndAddBreadcrumb,
            resetBreadcrumbs,
            popBreadcrumb,
            updateLastLabel,
        }),
        [] // eslint-disable-line react-hooks/exhaustive-deps
    )
}
