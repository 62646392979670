import { Box, SxProps, Theme } from "@mui/material"
import { useIsMobile } from "@recall/common"
import { DRAWER_WIDTH, NAV_WIDTH } from "constants/index"
import { FC, PropsWithChildren, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { SET_DRAWER_OPEN } from "storage/redux/drawer/actionTypes"
import { RootState } from "storage/redux/rootReducer"
import { TagsTree } from "../TagsTree"

interface Props {
    handleClickItem: (id: string) => void
    selectedItemId?: string
}

export const TagsTreeProvider: FC<PropsWithChildren<Props>> = ({
    children,
    handleClickItem,
    selectedItemId,
}) => {
    const dispatch = useDispatch()
    const isMobile = useIsMobile()
    const isDrawerOpen = useSelector((state: RootState) => state.drawer.open)

    useEffect(() => {
        if (isMobile) dispatch({ type: SET_DRAWER_OPEN, payload: false })
    }, [dispatch, isMobile])

    return (
        <Box display="flex">
            <Box
                sx={{
                    flexShrink: 0,
                    width: isDrawerOpen ? DRAWER_WIDTH : 0,
                }}
            >
                <Box
                    sx={{
                        ml: isDrawerOpen ? `${NAV_WIDTH}px` : 0,
                        width: isDrawerOpen ? DRAWER_WIDTH : 0,
                        visibility: isDrawerOpen ? "visible" : "hidden",
                        ...styles.container,
                    }}
                >
                    <TagsTree
                        handleClickItem={handleClickItem}
                        isSwipeable={false}
                        selectedItemId={selectedItemId}
                    />
                </Box>
            </Box>
            <Box flex={1} sx={styles.content}>
                {children}
            </Box>
        </Box>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    container: {
        position: "fixed",
        top: 0,
        left: 0,
        mt: 1,
        height: "calc(100% - 16px)",
        backgroundColor: (theme) => theme.palette.action.hover,
        borderRadius: 1,
    },
    content: {
        backgroundColor: (theme) => theme.palette.action.hover,
        borderRadius: 1,
    },
}
