import { Box, Button, Dialog, DialogContent, DialogTitle, SxProps, Theme } from "@mui/material"
import TextField from "@mui/material/TextField"
import { firebase, PrimaryButton } from "@recall/common"
import SignUpProvider from "components/UserPages/SignUpPage/SignUpProvider"
import { FirebaseError } from "firebase/app"
import {
    EmailAuthProvider,
    GoogleAuthProvider,
    OAuthProvider,
    reauthenticateWithCredential,
    reauthenticateWithPopup,
} from "firebase/auth"
import { FC, useState } from "react"
import { toast } from "react-toastify"
import { useForm } from "react-hook-form"
import { emailValidation, passwordValidationWithoutMinLength } from "constants/validationRules"

interface LoginFields {
    email: string
    password: string
}

interface Props {
    callback: () => void
    handleClose: () => void
}

export const ReauthenticateModal: FC<Props> = ({ callback, handleClose }) => {
    const [loading, setLoading] = useState(false)

    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
    } = useForm<LoginFields>({
        defaultValues: {
            email: "",
            password: "",
        },
    })

    const onSubmit = async (values: LoginFields) => {
        setLoading(true)
        try {
            await reauthenticateWithCredential(
                firebase.auth.currentUser,
                EmailAuthProvider.credential(values.email.trim(), values.password)
            )
            callback()
        } catch {
            setError("email", { message: "Invalid email or password." })
            setError("password", { message: "Invalid email or password." })
        } finally {
            setLoading(false)
        }
    }

    const makeReauthenticate = (provider: OAuthProvider | GoogleAuthProvider) => async () => {
        try {
            await reauthenticateWithPopup(firebase.auth.currentUser, provider)
        } catch (err) {
            const error = err as FirebaseError

            if (error.code === "auth/user-mismatch") {
                toast.error("Wrong user")
            }
        }
        callback()
    }

    return (
        <Dialog open onClose={handleClose}>
            <DialogTitle my={2} color="error">
                Please reauthenticate to delete your account
            </DialogTitle>
            <DialogContent>
                <SignUpProvider
                    reauthenticateWithGoogle={makeReauthenticate(new GoogleAuthProvider())}
                    reauthenticateWithApple={makeReauthenticate(new OAuthProvider("apple.com"))}
                />
                <Box mt={4} component="form" onSubmit={handleSubmit(onSubmit)}>
                    <TextField
                        fullWidth
                        sx={styles.textField}
                        label="Email Address"
                        variant="outlined"
                        {...register("email", emailValidation)}
                        error={Boolean(errors.email)}
                        helperText={errors.email?.message}
                        data-testid="email"
                    />
                    <TextField
                        fullWidth
                        sx={styles.textField}
                        label="Password"
                        type="password"
                        variant="outlined"
                        {...register("password", passwordValidationWithoutMinLength)}
                        error={Boolean(errors.password)}
                        helperText={errors.password?.message}
                        data-testid="password"
                    />
                    <PrimaryButton sx={{ mt: 2, mb: 1 }} loading={loading} fullWidth type="submit">
                        Reauthenticate to delete
                    </PrimaryButton>
                    <Button
                        sx={{
                            color: (theme) => theme.palette.text.secondary,
                        }}
                        fullWidth
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    textField: {
        margin: (theme) => theme.spacing(1, 0),
    },
    link: {
        color: (theme) => theme.palette.text.secondary,
        textDecorationColor: (theme) => theme.palette.text.secondary,
        "&:hover": {
            color: (theme) => theme.palette.text.primary,
            textDecorationColor: (theme) => theme.palette.text.primary,
        },
    },
}
