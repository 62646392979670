import { Database, Q } from "@nozbe/watermelondb"
import { MyRootBlock } from "../../../editor/types"
import { EditorBlockData } from "../../../services/editorData/EditorBlockData"
import { getFormattedText } from "../helpers"
import { EditorBlockModel } from "../models/EditorBlockModel"
import { EDITOR_BLOCKS } from "../schema"
import { editorOrderRepository } from "./editorOrderRepository"

const getEditorBlocksByItemId = (db: Database, itemId: string) => {
    return db.collections
        .get<EditorBlockModel>(EDITOR_BLOCKS)
        .query(Q.where("item_id", itemId))
        .fetch()
}

const get = async (db: Database, id: string): Promise<EditorBlockModel | null> => {
    try {
        return await db.collections.get<EditorBlockModel>(EDITOR_BLOCKS).find(id)
    } catch {
        return null
    }
}

const getByIds = async (db: Database, ids: string[]): Promise<EditorBlockModel[] | []> => {
    try {
        return await db.collections
            .get<EditorBlockModel>(EDITOR_BLOCKS)
            .query(Q.where("id", Q.oneOf(ids)))
    } catch {
        return []
    }
}

const remove = async (db: Database, id: string): Promise<void> => {
    return await db.write(async (action) => {
        const editorBlock = await get(db, id)
        if (!editorBlock) return
        const itemId = editorBlock.item.id
        const editorBlockId = editorBlock.id

        await action.callWriter(() => editorBlock.delete())

        const editorOrder = await editorOrderRepository.get(db, itemId)
        if (!editorOrder) return

        await editorOrder.update((record) => {
            record.order = record.order.filter((blockId) => blockId !== editorBlockId)
        })
    })
}

const upsert = async (db: Database, itemId: string, editorBlock: MyRootBlock, isSaved = true) => {
    return await db.write(async () => {
        if (!editorBlock.id) return null

        const editorBlockModel = await get(db, editorBlock.id)
        const { id, type, children, options, ...rest } = editorBlock
        const newOptions = { ...rest }
        const text = EditorBlockData.getFormattedText([editorBlock])

        if (editorBlockModel) {
            return editorBlockModel.update((record) => {
                record.type = type
                record.children = children
                record.options = newOptions || {}
                record.text = text
            })
        } else {
            return db.collections.get<EditorBlockModel>(EDITOR_BLOCKS).create((record) => {
                if (editorBlock.id) record._raw.id = editorBlock.id
                record.item.id = itemId
                record.children = children
                record.isSaved = isSaved
                record.type = type
                record.options = newOptions || {}
                record.text = text
            })
        }
    })
}

const createBatch = async (
    db: Database,
    itemId: string,
    rawEditorBlocks: any[],
    isSaved = true
) => {
    return await db.write(async (writer) => {
        let editorOrder = await editorOrderRepository.get(db, itemId)

        if (!editorOrder) {
            editorOrder = await writer.callWriter(() =>
                editorOrderRepository.upsert(db, itemId, [], isSaved)
            )
        }

        const collection = db.collections.get<EditorBlockModel>(EDITOR_BLOCKS)
        const editorBlocks: EditorBlockModel[] = []
        for (const editorBlock of rawEditorBlocks) {
            const { id, children, type, ...options } = editorBlock

            const newEditorBlock = await collection.create((record: EditorBlockModel) => {
                record._raw.id = id
                record.item.id = itemId
                record.children = children
                record.isSaved = isSaved
                record.type = type
                record.options = options || {}
                record.text = getFormattedText([editorBlock])
            })

            editorBlocks.push(newEditorBlock)
        }

        await editorOrder.update((record) => {
            record.order = [...editorOrder.order, ...editorBlocks.map((block) => block.id)]
        })

        return editorBlocks
    })
}

export const editorBlockRepository = {
    getEditorBlocksByItemId,
    getByIds,
    upsert,
    remove,
    get,
    createBatch,
}
