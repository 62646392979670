import { Box } from "@mui/material"
import { useDatabase } from "@nozbe/watermelondb/react"
import {
    Actions,
    ActionWithNotebookElementId,
    AIAction,
    ChatMessage,
    conciseSummaryAction,
    detailedSummaryAction,
    editorBlockRepository,
    itemService,
    MessagesList,
    notebookService,
    RERENDER_EDITOR_BLOCKS_EVENT,
} from "@recall/common"
import { useChatActions, useChatState } from "components/ItemPage/providers/ChatProvider"
import { useItemContext } from "components/ItemPage/providers/ItemProvider"
import { FC, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { SET_IS_AUTH_MODAL_OPEN } from "storage/redux/app/actionTypes"
import { RootState } from "storage/redux/rootReducer"
import { v4 as uuid } from "uuid"
import { ChatInput } from "./ChatInput"
import { MessagesPlaceholder } from "@recall/common"

interface Props {
    handleOpenNotebook: (elementId?: string) => void
}

export const Chat: FC<Props> = ({ handleOpenNotebook }) => {
    const { item, isLoadingChat } = useItemContext()
    const db = useDatabase()
    const dispatch = useDispatch()
    const uid = useSelector((state: RootState) => state.user.uid)

    const { messages, isAnswering } = useChatState()
    const { processQuestion, updateMessages } = useChatActions()
    const [actions, setActions] = useState<ActionWithNotebookElementId[]>([])

    const handleAddToNotebook = async (question: ChatMessage, answer: ChatMessage) => {
        const editorBlock = await notebookService.addChatMessageToNotebook(
            db,
            item,
            question,
            answer
        )
        if (!editorBlock) return

        const updatedMessages = messages.map((message) =>
            message.id === answer.id ? { ...message, notebookElementId: editorBlock.id } : message
        )
        await updateMessages(updatedMessages)
    }

    const getActions = async () => {
        const editorBlocks = await item.getOrderedEditorBlocks()
        const conciseSummaryElement = editorBlocks.find(
            (block) => block.options?.action?.id === conciseSummaryAction.id
        )
        const detailedSummaryElement = editorBlocks.find(
            (block) => block.options?.action?.id === detailedSummaryAction.id
        )

        setActions([
            conciseSummaryElement
                ? { ...conciseSummaryAction, notebookElementId: conciseSummaryElement.id }
                : conciseSummaryAction,
            detailedSummaryElement
                ? { ...detailedSummaryAction, notebookElementId: detailedSummaryElement.id }
                : detailedSummaryAction,
        ])
    }

    useEffect(() => {
        getActions()
    }, [item, messages])

    const handleQuestion = async (question: string) => {
        if (!question.trim()) return
        if (!uid) {
            dispatch({ type: SET_IS_AUTH_MODAL_OPEN, payload: true })
            return
        }
        const questionMessage: ChatMessage = { id: uuid(), role: "user", content: question.trim() }
        await processQuestion([...messages, questionMessage])
    }

    const handleAIAction = async (action: AIAction) => {
        if (!uid) {
            dispatch({ type: SET_IS_AUTH_MODAL_OPEN, payload: true })
            return
        }
        const message = messages.find((message) => message?.action?.id === action.id)
        if (message) {
            focusMessage(message.id)
            return
        }

        const actionMessage: ChatMessage = {
            id: uuid(),
            role: "user",
            content: action.name,
            action,
        }
        await processQuestion([...messages, actionMessage])
    }

    const focusMessage = (messageId: string) => {
        const messageElement = document.getElementById(messageId)
        if (messageElement) {
            messageElement.scrollIntoView({ behavior: "smooth", block: "start" })
            messageElement.classList.add("focused")

            setTimeout(() => {
                messageElement.classList.remove("focused")
            }, 2000)
        }
    }

    const handleRegenerate = async (message: ChatMessage) => {
        const answerIndex = messages.findIndex((m) => m.id === message.id)
        const previousMessages = messages.slice(0, answerIndex)

        const answerProps: Partial<ChatMessage> = {}
        if (message.notebookElementId) {
            answerProps.notebookElementId = message.notebookElementId
        }

        const answer = await processQuestion(previousMessages, answerProps)
        if (!answer) return

        const editorBlock = await editorBlockRepository.get(db, message.notebookElementId)
        if (!editorBlock) return

        const editorBlocks = await itemService.enrichMarkdown(db, answer.content, item)
        await db.write(async () => {
            await editorBlock.update((record) => {
                record.children = editorBlocks
                record.options = { ...record.options, messageId: answer.id }
            })
        })
        document.dispatchEvent(new CustomEvent(RERENDER_EDITOR_BLOCKS_EVENT))
    }

    return (
        <Box
            height="100%"
            display="flex"
            flexDirection="column"
            p={2}
            pr={1}
            rowGap={1}
            justifyContent="space-between"
        >
            {messages.length > 0 ? (
                <MessagesList
                    messages={messages}
                    isAnswering={isAnswering}
                    handleAddToNotebook={handleAddToNotebook}
                    handleOpenNotebook={handleOpenNotebook}
                    handleRegenerate={handleRegenerate}
                />
            ) : (
                <MessagesPlaceholder />
            )}
            <Box pr={1}>
                <Actions
                    actions={actions}
                    processAction={handleAIAction}
                    isAnswering={isAnswering}
                    handleOpenNotebook={handleOpenNotebook}
                />
                <ChatInput
                    handleQuestion={handleQuestion}
                    disabled={isAnswering}
                    isLoading={isLoadingChat}
                />
            </Box>
        </Box>
    )
}
