import {
    ChatMessage,
    chatService,
    conciseSummaryAction,
    findNearestChunks,
    ItemModel,
    messageService,
    SummaryLengthEnum,
    useChatMessages,
    WEBSITE,
} from "@recall/common"
import { orderBy } from "lodash"
import { createContext, FC, PropsWithChildren, useContext } from "react"
import { useDispatch, useSelector } from "react-redux"
import { chatApi } from "services/api"
import { RootState } from "storage/redux/rootReducer"
import { summaryService } from "../services/summaryService"
import { useItemContext } from "./ItemProvider"
import { SET_IS_SCRAPING_MODAL_OPEN } from "storage/redux/app/actionTypes"

interface ChatState {
    messages: ChatMessage[]
    isAnswering: boolean
}

interface ChatActions {
    setMessages: React.Dispatch<React.SetStateAction<ChatMessage[]>>
    processQuestion: (
        messages: ChatMessage[],
        answerProps?: Partial<ChatMessage>
    ) => Promise<ChatMessage | null>
    updateMessages: (messages: ChatMessage[]) => Promise<void>
}

const ChatStateContext = createContext<ChatState | null>(null)
const ChatActionsContext = createContext<ChatActions | null>(null)

export const useChatState = () => {
    const context = useContext(ChatStateContext)
    if (!context) throw new Error("useChatState must be used within ChatProvider")
    return context
}

export const useChatActions = () => {
    const context = useContext(ChatActionsContext)
    if (!context) throw new Error("useChatActions must be used within ChatProvider")
    return context
}

export const ChatProvider: FC<PropsWithChildren> = ({ children }) => {
    const uid = useSelector((state: RootState) => state.user.uid)
    const language = useSelector((state: RootState) => state.user?.settings?.language)
    const dispatch = useDispatch()

    const { item, markdown } = useItemContext()

    const getMessages = async () => {
        if (!uid) return
        try {
            const fetchedMessages = await chatService.get(uid, item.id)
            const cleanedMessages = await messageService.removeDeletedNotebookElementIds(
                item,
                fetchedMessages
            )
            return cleanedMessages
        } catch (error) {
            console.error("Failed to fetch chat messages:", error)
            return []
        }
    }

    const updateMessages = async (messages: ChatMessage[]) => {
        await chatService.update(uid, messages, item.id)
    }

    const getSource = async (item: ItemModel) => {
        const source = await item.getSource(WEBSITE)
        return source?.identifier ?? ""
    }

    const handleSave = async () => {
        if (!item.isSaved) await item.saveDeep()
    }

    const onUsageLimitReached = () => {
        dispatch({ type: SET_IS_SCRAPING_MODAL_OPEN, payload: true })
    }

    const { state, actions } = useChatMessages(item, markdown, {
        getMessages,
        updateMessages,
        questionHandler,
        aiActionHandler: (message, item, markdown) =>
            aiActionHandler(message, item, markdown, language),
        getSource,
        handleSave,
        onUsageLimitReached,
    })

    return (
        <ChatStateContext.Provider value={state}>
            <ChatActionsContext.Provider value={actions}>{children}</ChatActionsContext.Provider>
        </ChatStateContext.Provider>
    )
}

const questionHandler = async (
    messages: ChatMessage[],
    question: string,
    item: ItemModel,
    url: string
) => {
    const { data: chunks } = await findNearestChunks({
        question: question,
        itemId: item.id,
    })

    if (!chunks?.length) {
        throw new Error("No chunks found")
    }

    const response = await chatApi.getResponse(
        item.id,
        question,
        messages,
        orderBy(chunks, "index", "asc").map((chunk) => ({
            id: chunk.id,
            text: chunk.text,
            itemId: item.id,
        })),
        url
    )

    return response.body
}

const aiActionHandler = async (
    message: ChatMessage,
    item: ItemModel,
    markdown: string,
    language: string
) => {
    const md = await summaryService.summarize(
        item,
        markdown,
        message.action.id === conciseSummaryAction.id
            ? SummaryLengthEnum.concise
            : SummaryLengthEnum.detailed,
        language
    )

    return md
}
