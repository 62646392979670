import { Close } from "@mui/icons-material"
import {
    Button,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    IconButton,
    Input,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    SxProps,
    Theme,
    Typography,
} from "@mui/material"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import { posthogService } from "@recall/common"
import { USAGE_SURVEY_EVENT } from "constants/events"
import { useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { userRepository } from "repositories/userRepository"
import { RootState } from "storage/redux/rootReducer"
import { SET_SURVEY_COMPLETED } from "storage/redux/user/actionTypes"
import { Logo } from "../Logos/Logo"
import { USAGE_SURVEY } from "./constants/surveys"

interface FormData {
    workUsage: string | null
    typeOfWork: string
    usage: string
    referrer: string | null
    otherReferrer?: string
}

export const UsageSurveyModal = () => {
    const dispatch = useDispatch()
    const uid = useSelector((state: RootState) => state.user.uid)
    const surveys = useSelector((state: RootState) => state.user.surveys)

    const {
        control,
        watch,
        handleSubmit,
        formState: { isDirty },
        clearErrors,
    } = useForm<FormData>({
        defaultValues: {
            workUsage: null,
            typeOfWork: "",
            usage: "",
            referrer: "",
            otherReferrer: "",
        },
        mode: "onChange",
    })

    const onSubmit = async (data: FormData) => {
        dispatch({ type: SET_SURVEY_COMPLETED, payload: USAGE_SURVEY })
        posthogService.captureEvent(USAGE_SURVEY_EVENT, data)
        await userRepository.upsertUser(uid, { surveys: [...(surveys || []), USAGE_SURVEY] })
    }
    const workUsage = watch("workUsage")
    const isOther = watch("referrer") === "other"

    useEffect(() => {
        if (!isOther) clearErrors("otherReferrer")
        // eslint-disable-next-line
    }, [isOther])

    const handleCloseSurvey = async () => {
        dispatch({ type: SET_SURVEY_COMPLETED, payload: USAGE_SURVEY })
        await userRepository.upsertUser(uid, { surveys: [...(surveys || []), USAGE_SURVEY] })
    }

    if (!uid) return

    return (
        <Modal open onClose={() => {}} sx={styles.modal}>
            <Box sx={styles.content}>
                <Box display="flex" justifyContent="space-between" mb={2}>
                    <Logo size={30} />
                    <IconButton sx={{ mt: "-10px", mr: "-10px" }} onClick={handleCloseSurvey}>
                        <Close />
                    </IconButton>
                </Box>
                <Typography variant="body2">
                    Share with us how you'll use Recall for growth.
                </Typography>
                <Box my={2}>
                    <FormControl>
                        <FormLabel sx={styles.inputLabel} id="question-1">
                            Do you plan to use Recall for work? *
                        </FormLabel>
                        <Controller
                            rules={{ required: "Choose one of the options" }}
                            control={control}
                            name="workUsage"
                            render={({ field, fieldState: { error } }) => (
                                <Box>
                                    <RadioGroup aria-labelledby="question-1" {...field} row>
                                        <FormControlLabel
                                            value="yes"
                                            control={<Radio size="small" />}
                                            label="Yes"
                                        />
                                        <FormControlLabel
                                            value="no"
                                            control={<Radio size="small" />}
                                            label="No"
                                        />
                                    </RadioGroup>
                                    {error && (
                                        <FormHelperText error>{error?.message}</FormHelperText>
                                    )}
                                </Box>
                            )}
                        />
                    </FormControl>
                </Box>

                <Box mb={3}>
                    <FormControl variant="standard" fullWidth>
                        <InputLabel shrink htmlFor="question-2" sx={styles.inputLabel}>
                            What is your profession or industry? {workUsage === "yes" ? "*" : ""}
                        </InputLabel>
                        <Controller
                            control={control}
                            name="typeOfWork"
                            rules={{
                                required: workUsage === "yes" ? "This field is required" : false,
                            }}
                            render={({ field, fieldState: { error } }) => (
                                <>
                                    <Input
                                        sx={styles.input}
                                        id="question-2"
                                        fullWidth
                                        multiline
                                        maxRows={5}
                                        {...field}
                                    />
                                    {error && (
                                        <FormHelperText error>{error.message}</FormHelperText>
                                    )}
                                </>
                            )}
                        />
                    </FormControl>
                </Box>

                <Box mb={3}>
                    <FormControl variant="standard" fullWidth>
                        <InputLabel sx={styles.inputLabel} shrink htmlFor="question-3">
                            What do you use Recall for? {workUsage === "no" ? "*" : ""}
                        </InputLabel>
                        <Controller
                            control={control}
                            name="usage"
                            rules={{
                                required: workUsage === "no" ? "This field is required" : false,
                            }}
                            render={({ field, fieldState: { error } }) => (
                                <>
                                    <Input
                                        sx={styles.input}
                                        id="question-3"
                                        fullWidth
                                        multiline
                                        maxRows={5}
                                        {...field}
                                    />
                                    {error && (
                                        <FormHelperText error>{error.message}</FormHelperText>
                                    )}
                                </>
                            )}
                        />
                    </FormControl>
                </Box>
                <Box mb={isOther ? 0 : 3}>
                    <FormControl fullWidth>
                        <FormLabel sx={styles.inputLabel} id="question-1">
                            Where did you hear about Recall? *
                        </FormLabel>
                        <Controller
                            rules={{ required: "Choose one of the options" }}
                            control={control}
                            name="referrer"
                            render={({ field, fieldState: { error } }) => (
                                <Box mt={2}>
                                    <Select
                                        labelId="referrer-label"
                                        id="referrer"
                                        {...field}
                                        fullWidth
                                    >
                                        <MenuItem value="reddit">Reddit</MenuItem>
                                        <MenuItem value="youtube">YouTube</MenuItem>
                                        <MenuItem value="tiktok">TikTok</MenuItem>
                                        <MenuItem value="producthunt">Product Hunt</MenuItem>
                                        <MenuItem value="linkedin">LinkedIn</MenuItem>
                                        <MenuItem value="twitter">Twitter</MenuItem>
                                        <MenuItem value="google">Google Search</MenuItem>
                                        <MenuItem value="other">Other</MenuItem>
                                    </Select>
                                    {error && (
                                        <FormHelperText error>{error?.message}</FormHelperText>
                                    )}
                                </Box>
                            )}
                        />
                    </FormControl>
                </Box>
                {isOther && (
                    <Box mb={3}>
                        <Controller
                            control={control}
                            name="otherReferrer"
                            rules={{
                                required: "This field is required",
                            }}
                            render={({ field, fieldState: { error } }) => (
                                <>
                                    <Input
                                        inputRef={(ref) => ref && ref.focus()}
                                        sx={styles.input}
                                        fullWidth
                                        multiline
                                        maxRows={5}
                                        {...field}
                                    />
                                    {error && (
                                        <FormHelperText error>{error.message}</FormHelperText>
                                    )}
                                </>
                            )}
                        />
                    </Box>
                )}

                <Button
                    color="secondary"
                    variant="contained"
                    fullWidth
                    disabled={!isDirty}
                    onClick={handleSubmit(onSubmit)}
                >
                    Submit
                </Button>
            </Box>
        </Modal>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    content: {
        outline: "none",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        maxWidth: "90vw",
        bgcolor: "background.default",
        boxShadow: 24,
        p: 2,
        borderRadius: 1,
        border: (theme) => `1px solid ${theme.palette.grey[600]}`,
    },
    modal: {
        outline: "none",
    },
    inputLabel: {
        fontSize: 16,
        transform: "scale(1)",
        color: (theme) => theme.palette.text.primary,
    },
    input: {
        pt: 1,
    },
}
