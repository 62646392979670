import { Box, Typography } from "@mui/material"
import { ChatIcon, Illustration } from "@recall/common"

export const MessagesPlaceholder = () => {
    return (
        <Box
            height="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
        >
            <Illustration icon={ChatIcon} />
            <Typography mt={3} variant="body2" color="text.secondary">
                Start a conversation with your content
            </Typography>
        </Box>
    )
}
