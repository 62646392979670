import { alpha, Box, Fade, Paper, styled, SxProps, Theme } from "@mui/material"
import MuiPopper from "@mui/material/Popper"
import { FC, PropsWithChildren, useCallback, useEffect, useState } from "react"
import { Step } from "./types"

interface PopupProps {
    step: Step
}

export const TutorialPopup: FC<PropsWithChildren<PopupProps>> = ({ step, children }) => {
    const [arrowRef, setArrowRef] = useState<HTMLDivElement | null>(null)
    const [anchorEl, setAnchorEl] = useState<Element | null>(null)
    const documentRoot = step.getDocumentRoot ? step.getDocumentRoot() : document

    const getAnchorEl = (retry = 0) => {
        const nextAnchorEl = documentRoot.getElementById(step.anchorId!)

        if (!nextAnchorEl && retry < 3) {
            setTimeout(() => getAnchorEl(retry + 1), 300 * retry)
            return
        }

        if (!nextAnchorEl) return

        if (step.placement === "top") {
            const rect = nextAnchorEl.getBoundingClientRect()
            window.scrollTo({
                top: window.scrollY + rect.top - 200,
                behavior: "smooth",
            })
        } else {
            nextAnchorEl.scrollIntoView({
                behavior: "smooth",
                block: "center",
            })
        }

        setAnchorEl(nextAnchorEl)
    }

    useEffect(() => {
        if (!step.anchorId) {
            setAnchorEl(null)
            return
        }

        getAnchorEl()
    }, [step.anchorId]) // eslint-disable-line react-hooks/exhaustive-deps

    // If anchor element unomunts and popup loose reference it will go to previous step that has reference(condition from other useEffect) and won't float on top without reference element
    const anchorElementUnmountObserver = useCallback(() => {
        let timeout: NodeJS.Timeout | undefined
        const observer = new MutationObserver((mutationsList) => {
            for (let mutation of mutationsList) {
                if (mutation.type === "childList" && mutation.removedNodes.length > 0) {
                    if (anchorEl && !documentRoot.contains(anchorEl)) {
                        timeout = setTimeout(() => {
                            setAnchorEl(null)
                            if (step.onBack) {
                                step.onBack()
                            }
                        }, 150)

                        return
                    }
                }
            }
        })

        observer.observe(documentRoot, {
            childList: true,
            subtree: true,
        })

        return () => {
            observer.disconnect()
            timeout && clearTimeout(timeout)
        }
    }, [anchorEl]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!anchorEl) return

        const disconnect = anchorElementUnmountObserver()

        return disconnect
    }, [anchorEl]) // eslint-disable-line react-hooks/exhaustive-deps

    if (!anchorEl) return null

    return (
        <Popper
            open
            transition={true}
            anchorEl={anchorEl}
            placement={step.placement ? step.placement : undefined}
            sx={{
                zIndex: (theme) => theme.zIndex.modal + 1,
                maxWidth: { xs: 250, sm: 350 },
            }}
            modifiers={[
                {
                    name: "arrow",
                    enabled: step.showArrow,
                    options: {
                        element: arrowRef,
                    },
                },
                {
                    name: "offset",
                    options: {
                        offset: [0, 10],
                    },
                },
            ]}
        >
            {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={500}>
                    <Box m={1}>
                        {step.showArrow && <Arrow ref={setArrowRef} className="MuiPopper-arrow" />}
                        <Paper sx={styles.paper} elevation={3}>
                            {children}
                        </Paper>
                    </Box>
                </Fade>
            )}
        </Popper>
    )
}

const Popper = styled(MuiPopper, {
    shouldForwardProp: (prop) => prop !== "arrow",
})(({ theme }) => ({
    zIndex: 1,
    "& > div": {
        position: "relative",
    },
    '&[data-popper-placement*="bottom"]': {
        "& .MuiPopper-arrow": {
            top: 0,
            left: 0,
            marginTop: "-1.9em",
            width: "3em",
            height: "1em",
            "&::before": {
                borderWidth: "0 2em 2em 2em",
                borderColor: `transparent transparent ${alpha(
                    theme.palette.primary.main,
                    0.8
                )} transparent`,
            },
        },
    },
    '&[data-popper-placement*="top"]': {
        "& .MuiPopper-arrow": {
            bottom: 0,
            left: 0,
            marginBottom: "-0.9em",
            width: "3em",
            height: "1em",
            "&::before": {
                borderWidth: "2em 2em 0 2em",
                borderColor: `${alpha(
                    theme.palette.primary.main,
                    0.8
                )} transparent transparent transparent`,
            },
        },
    },
    '&[data-popper-placement*="right"]': {
        "& .MuiPopper-arrow": {
            left: 0,
            marginLeft: "-1.9em",
            height: "3em",
            width: "1em",
            "&::before": {
                borderWidth: "2em 2em 2em 0",
                borderColor: `transparent ${alpha(
                    theme.palette.primary.main,
                    0.8
                )} transparent transparent`,
            },
        },
    },
    '&[data-popper-placement*="left"]': {
        "& .MuiPopper-arrow": {
            right: 0,
            marginRight: "-0.9em",
            height: "3em",
            width: "1em",
            "&::before": {
                borderWidth: "2em 0 2em 2em",
                borderColor: `transparent transparent transparent ${alpha(
                    theme.palette.primary.main,
                    0.8
                )}`,
            },
        },
    },
}))

const Arrow = styled("div")({
    position: "absolute",
    fontSize: 7,
    width: "3em",
    height: "3em",
    "&::before": {
        content: '""',
        margin: "auto",
        display: "block",
        width: 0,
        height: 0,
        borderStyle: "solid",
    },
})

const styles: Record<string, SxProps<Theme>> = {
    paper: {
        border: (theme) => `1px solid ${alpha(theme.palette.primary.main, 0.4)}`,
        overflow: "hidden",
        boxShadow: `0 0 15px 0 ${alpha("#000", 0.8)}`,
    },
}
