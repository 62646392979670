import {
    Box,
    IconButton,
    Paper,
    PaperProps,
    SxProps,
    Theme,
    Tooltip,
    Typography,
} from "@mui/material"
import { difference, map } from "lodash"
import { Plus } from "lucide-react"
import { FC, PropsWithChildren, useEffect, useMemo, useState } from "react"
import { useIsMobile } from "../../../hooks/useIsMobile"
import { TagPartial, TagsWithPath } from "../../../storage/watermelon/helpers/tags"
import { ROOT_TAG_ID } from "../../../storage/watermelon/repository/tagRepository"
import { NestedTagChip } from "../../cards/NestedTagChip"
import { SearchAutocomplete } from "../../inputs/SearchAutocomplete"
import { TagInputChip } from "./TagInputChip"

export const TAG_INPUT_ID = "id-tag-input"
const RERENDER_TAGS_EVENT = "rerender-tags-event"

type TagInputProps = {
    itemId: string
    readOnly?: boolean
    allTags: TagsWithPath[]
    itemTags: TagsWithAncestors[]
    detach: (tagId: string, itemId: string) => Promise<void>
    create: (itemId: string, tag: string) => Promise<void>
    getAllTags: () => Promise<void>
    getItemTags: () => Promise<void>
}

export interface TagsWithAncestors {
    tag: TagPartial
    ancestors: TagPartial[]
    nesting: string[]
}

export const TagInput: FC<TagInputProps> = ({
    itemId,
    readOnly = false,
    allTags,
    itemTags,
    detach,
    create,
    getAllTags,
    getItemTags,
}) => {
    const isMobile = useIsMobile()

    const [isInputVisible, setIsInputVisible] = useState(false)
    const [inputValue, setInputValue] = useState("")

    const getTagsByNames = (tagNames: string[]) => {
        return allTags.filter(
            ({ tag }) =>
                tagNames.some(
                    (tagName) => tagName.toLowerCase() === tag.name.toLowerCase().trim()
                ) && tag.id !== ROOT_TAG_ID
        )
    }

    const handleSelectTag = (selectedTags: string[]) => {
        const existingTagNames = map(itemTags, "tag.name")
        const tagsToCreate = difference(selectedTags, existingTagNames)
        const tagNamesToRemove = difference(existingTagNames, selectedTags)

        const tagsToRemove = getTagsByNames(tagNamesToRemove)

        modifyTags(map(tagsToRemove, "tag"), tagsToCreate)
    }

    const modifyTags = async (tagsToRemove: TagPartial[], tagsToCreate: string[]) => {
        for (const tag of tagsToRemove) {
            await detach(tag.id, itemId)
        }
        for (const tag of tagsToCreate) {
            await create(itemId, tag)
        }
        document.dispatchEvent(new CustomEvent(RERENDER_TAGS_EVENT))
    }

    useEffect(() => {
        const refetchTags = () => {
            getAllTags()
            getItemTags()
        }

        document.addEventListener(RERENDER_TAGS_EVENT, refetchTags)

        return () => {
            document.removeEventListener(RERENDER_TAGS_EVENT, refetchTags)
        }
        // eslint-disable-next-line
    }, [])

    const options = useMemo(() => {
        const tags = allTags
            .filter(({ tag }) => tag.id !== ROOT_TAG_ID)
            .map(({ tag, path }) => ({
                value: tag.name,
                label: path,
                id: tag.id,
            }))

        return tags
    }, [allTags, inputValue])

    return (
        <Box display="flex" alignItems="center" flexWrap="wrap" gap={0.5} id={TAG_INPUT_ID}>
            {itemTags.map(({ tag, nesting }, index) => {
                return (
                    <Tooltip
                        key={tag.id + index}
                        title={isMobile && tag.name.length > 15 ? tag.name : undefined}
                    >
                        <Box>
                            <NestedTagChip
                                isMobile={isMobile}
                                size="medium"
                                tagNesting={nesting}
                                onDelete={
                                    readOnly
                                        ? undefined
                                        : async () => {
                                              await detach(tag.id, itemId)
                                              document.dispatchEvent(
                                                  new CustomEvent(RERENDER_TAGS_EVENT)
                                              )
                                          }
                                }
                                data-testid="card-input-tag"
                            />
                        </Box>
                    </Tooltip>
                )
            })}
            {!readOnly && (
                <>
                    {!isInputVisible ? (
                        itemTags.length > 0 ? (
                            <Tooltip title="Add tag">
                                <IconButton onClick={() => setIsInputVisible(true)} size="small">
                                    <Plus size={20} />
                                </IconButton>
                            </Tooltip>
                        ) : (
                            <TagInputChip setIsHidden={setIsInputVisible} />
                        )
                    ) : (
                        <Box flexGrow={1} minWidth={250}>
                            <SearchAutocomplete
                                inputValue={inputValue}
                                setInputValue={setInputValue}
                                options={options}
                                optionName="tag"
                                value={itemTags.map(({ tag }) => ({
                                    label: tag.name,
                                    value: tag.name,
                                }))}
                                handleChange={handleSelectTag}
                                sx={styles.autocomplete}
                                textFieldProps={{
                                    placeholder: "Add tag",
                                    variant: "standard",
                                    sx: !itemTags.length ? styles.emptyInput : {},
                                    InputProps: { disableUnderline: true },
                                    onBlur: () => setIsInputVisible(false),
                                }}
                                paper={CustomPaper}
                            />
                        </Box>
                    )}
                </>
            )}
        </Box>
    )
}

const CustomPaper: FC<PropsWithChildren<PaperProps>> = ({ children, ...props }) => {
    return (
        <Paper {...props}>
            {children}
            <Typography variant="caption" sx={styles.hint}>
                Use{" "}
                <Box
                    component="span"
                    fontWeight="bold"
                    color="primary.main"
                    display="inline-block"
                    px={0.1}
                    fontSize={16}
                >
                    /
                </Box>{" "}
                to create nested tags.
            </Typography>
        </Paper>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    autocomplete: {
        px: 1,
        maxWidth: 350,
        borderRadius: 1,
        backgroundColor: (theme) => theme.palette.action.hover,
        transition: (theme: Theme) =>
            theme.transitions.create(["background-color", "transform"], {
                duration: theme.transitions.duration.shorter,
            }),
        "&:focus-within": {
            backgroundColor: (theme) => theme.palette.action.selected,
        },
        "& .MuiAutocomplete-tag": {
            ml: 0,
            mr: 0.5,
        },
    },
    emptyInput: {
        ml: 1,
    },
    hint: {
        py: 0.5,
        textAlign: "center",
        display: "block",
    },
}
