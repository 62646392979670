import { SyncDatabaseChangeSet } from "@nozbe/watermelondb/sync"
import { httpsCallable } from "firebase/functions"
import { Chunk } from ".."
import { firebase } from "./firebaseServices"

interface SaveChunks {
    itemId: string
    chunks: Chunk[]
}

interface PushDatabaseChanges {
    changes: SyncDatabaseChangeSet
    sessionId: string
    lastPulledAt: number
}

interface FindNearestChunks {
    itemId: string
    question: string
}

export const saveChunks = httpsCallable<SaveChunks, void>(firebase.functions, "saveReaderChunks")
export const pushDatabaseChanges = httpsCallable<PushDatabaseChanges, void>(
    firebase.functions,
    "pushDatabaseChanges"
)
export const findNearestChunks = httpsCallable<FindNearestChunks, any[]>(
    firebase.functions,
    "findNearestChunks"
)
