import { alpha, Box, SxProps, Theme } from "@mui/material"
import { PrimaryButton } from "@recall/common"
import { FC } from "react"
import { TitleContainer } from "./TitleContainer"

interface Props {
    handleContinue: () => void
}

export const IntroductionStep: FC<Props> = ({ handleContinue }) => {
    return (
        <>
            <TitleContainer title="Get the Hang of Recall in Minutes" />
            <Box
                sx={styles.video}
                component="iframe"
                src="https://www.youtube.com/embed/2s81mlFtHio"
                title="March 2025: Detailed Recall Tutorial"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                frameBorder="0"
            />
            <PrimaryButton onClick={handleContinue} fullWidth>
                I've watched the video
            </PrimaryButton>
        </>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    video: {
        borderRadius: 1,
        width: { xs: "100%", md: "640px" },
        minHeight: { xs: "300px", md: "360px" },
        my: 2,
        border: (theme) => `1px solid ${alpha(theme.palette.primary.light, 0.2)}`,
    },
}
