import { Box, Button, SxProps, TextField, Theme, Typography } from "@mui/material"
import { CONTACT_US_EMAIL, PrimaryButton, sentry, Subtitle, Title } from "@recall/common"
import { DarkLayout } from "components/layouts/DarkLayout"
import { useAuth } from "hooks/auth/useAuth"
import { useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import { functions } from "services/firebase/firebaseFunction"
import { RootState } from "storage/redux/rootReducer"
import { UPDATE_USER } from "storage/redux/user/actionTypes"

const CODE_LENGTH = 6
const DEFAULT_VERIFICATION_CODE = Array(CODE_LENGTH).fill("")

export const VerifyEmailPage = () => {
    const [verificationCode, setVerificationCode] = useState(DEFAULT_VERIFICATION_CODE)
    const [isLoading, setIsLoading] = useState(false)
    const inputRefs = useRef<(HTMLInputElement | null)[]>([])
    const auth = useAuth()
    const email = useSelector((state: RootState) => state.user.email)
    const dispatch = useDispatch()

    const isValidNumber = (value: string) => {
        return value.length <= 1 && /^\d*$/.test(value)
    }

    const handleChange = (index: number, value: string) => {
        if (isValidNumber(value)) {
            const newCode = [...verificationCode]
            newCode[index] = value

            setVerificationCode(newCode)

            if (value && index < 5) {
                inputRefs.current[index + 1]?.focus()
            }
        }
    }

    const handleKeyDown = (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Backspace" && !verificationCode[index] && index > 0) {
            inputRefs.current[index - 1]?.focus()
        }
    }

    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
        e.preventDefault()
        const pastedData = e.clipboardData.getData("text").replace(/\D/g, "")
        const pastedArray = pastedData
            .split("")
            .slice(0, CODE_LENGTH)
            .concat(Array(CODE_LENGTH).fill(""))
            .slice(0, CODE_LENGTH)

        const validNumbers = pastedArray.map((char) => {
            if (isValidNumber(char)) {
                return char
            } else {
                return ""
            }
        })

        setVerificationCode(validNumbers)

        if (pastedData.length > 0 && inputRefs.current[pastedData.length - 1]) {
            inputRefs.current[pastedData.length - 1].focus()
        }
    }

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        setIsLoading(true)
        const code = verificationCode.join("")
        try {
            const result = await functions.verifyEmail({ verificationCode: code })
            const { success, message } = result.data

            if (success) {
                toast.success("Email verified successfully")
                dispatch({ type: UPDATE_USER, payload: { emailVerified: true } })
            } else {
                toast.error(message || "Failed to verify email")
                setVerificationCode(DEFAULT_VERIFICATION_CODE)
            }
        } catch (error) {
            sentry.captureException(error)
            toast.error("Failed to verify email")
            setVerificationCode(DEFAULT_VERIFICATION_CODE)
        } finally {
            setIsLoading(false)
        }
    }

    const handleResendVerification = async () => {
        setIsLoading(true)
        try {
            await functions.sendEmailVerification()
            toast.success(`Verification email sent to ${email}`)
        } catch (error) {
            toast.error("Failed to send verification email")
            sentry.captureException(error)
        } finally {
            setIsLoading(false)
        }
    }

    const handleLogout = async () => {
        await auth.signOut()
        toast.success("Logged out successfully")
    }

    return (
        <DarkLayout>
            <Box sx={styles.step}>
                <Title>Please Check your Email</Title>
                <Subtitle>Enter the 6-digit code sent to your email address.</Subtitle>
                <Box
                    component="form"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    onSubmit={handleSubmit}
                >
                    <Box sx={styles.codeInputContainer}>
                        {verificationCode.map((digit, index) => (
                            <TextField
                                key={index}
                                value={digit}
                                onChange={(e) => handleChange(index, e.target.value)}
                                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
                                    handleKeyDown(index, e)
                                }
                                onPaste={handlePaste}
                                inputRef={(el) => (inputRefs.current[index] = el)}
                                variant="outlined"
                                type="number"
                                inputProps={{
                                    maxLength: 1,
                                    style: { textAlign: "center" },
                                    inputMode: "numeric",
                                    pattern: "[0-9]*",
                                }}
                                sx={styles.codeInput}
                                required
                            />
                        ))}
                    </Box>
                    <Box sx={styles.buttonContainer}>
                        <PrimaryButton
                            loading={isLoading}
                            type="submit"
                            sx={styles.button}
                            disabled={verificationCode.some((digit) => digit === "") || isLoading}
                            fullWidth
                        >
                            Verify
                        </PrimaryButton>
                        <Button
                            color="inherit"
                            variant="text"
                            onClick={handleResendVerification}
                            disabled={isLoading}
                        >
                            Resend Verification Email
                        </Button>
                    </Box>
                </Box>
            </Box>
            <Button
                sx={styles.logoutButton}
                color="inherit"
                variant="text"
                onClick={handleLogout}
                disabled={isLoading}
            >
                Logout
            </Button>
            <Box sx={styles.footer}>
                <Typography textAlign="center" variant="body2" color="text.secondary">
                    Having trouble? Contact {CONTACT_US_EMAIL}
                </Typography>
            </Box>
        </DarkLayout>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    step: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 2,
        p: 3,
        minHeight: 300,
        width: { xs: "100%", md: "700px" },
    },
    codeInputContainer: {
        display: "flex",
        justifyContent: "center",
        gap: 1,
        mb: 2,
    },
    codeInput: {
        width: "3rem",
        "& input": {
            padding: "0.5rem",
            fontSize: "1.5rem",
            "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
                WebkitAppearance: "none",
                margin: 0,
            },
            "&[type=number]": {
                MozAppearance: "textfield",
            },
        },
    },
    buttonContainer: {
        display: "flex",
        flexDirection: "column",
        gap: 1,
        mt: 2,
        width: "100%",
    },
    footer: {
        position: "absolute",
        bottom: 20,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    logoutButton: {
        position: "absolute",
        top: 16,
        right: 16,
    },
}
