import { SxProps, Theme, Typography, TypographyProps } from "@mui/material"
import { FC } from "react"

export const Title: FC<TypographyProps> = ({ children, sx, ...props }) => {
    const style = { ...styles.title, ...sx } as SxProps<Theme>
    return (
        <Typography variant="h2" sx={style} {...props}>
            {children}
        </Typography>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    title: {
        textAlign: "center",
        fontWeight: 600,
        maxWidth: 800,
        fontSize: 35,
        // background:
        //     "radial-gradient(70.71% 70.71% at 50% 50%,  #FFF 0%, rgba(255, 255, 255, 0.60)  100%)",
        // backgroundClip: "text",
        // WebkitBackgroundClip: "text",
        color: (theme) => theme.palette.text.secondary,
    },
}
