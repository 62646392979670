import { UsageChip } from "@recall/common"
import { useOpenPricingPage } from "hooks/useOpenPricingPage"
import { memo } from "react"
import { useSelector } from "react-redux"
import { RootState } from "storage/redux/rootReducer"

interface Props {
    direction?: "row" | "column"
}

export const AppUsageChip = memo(({ direction = "row" }: Props) => {
    const { isPremiumUser, usage } = useSelector((state: RootState) => state.user)
    const { openPricingPage } = useOpenPricingPage()

    const handleUpgrade = () => {
        openPricingPage()
    }

    if (isPremiumUser) return null

    return <UsageChip usage={usage} handleUpgrade={handleUpgrade} direction={direction} />
})
