import {
    ActionsApi,
    ChatApi,
    EmailValidatorApi,
    EntitiesApi,
    QuestionsApi,
    ScraperApi,
    SummariesApi,
    TagsApi,
} from "@recall/common"
import { httpClient } from "./httpClient"

export const summariesApi = new SummariesApi(httpClient)
export const questionsApi = new QuestionsApi(httpClient)
export const emailValidatorApi = new EmailValidatorApi(httpClient)
export const chatApi = new ChatApi(httpClient)
export const entitiesApi = new EntitiesApi(httpClient)
export const scraperApi = new ScraperApi(httpClient)
export const tagsApi = new TagsApi(httpClient)
export const actionsApi = new ActionsApi(httpClient)
