import { alpha, Avatar, Box, SxProps, Theme, Typography } from "@mui/material"
import { NewspaperIcon, OpenUrlIcon, PodcastIcon, YouTubeIcon } from "@recall/common"
import { Interest } from "./types"

type Props = Interest["details"]

export const ExampleCard = ({ imageUrl, title, type, url }: Props) => {
    const getIcon = () => {
        if (type === "YouTube Video") return <YouTubeIcon sx={styles.newspaperIcon} />
        if (type === "Podcast") return <PodcastIcon sx={styles.newspaperIcon} />
        return <NewspaperIcon sx={styles.newspaperIcon} />
    }

    return (
        <Box onClick={() => window.open(url, "_blank")} sx={styles.container}>
            <Avatar src={imageUrl} variant="square" sx={styles.avatar} />
            <Box sx={styles.content}>
                <Typography sx={styles.title}>
                    {title}
                    <OpenUrlIcon sx={styles.openUrlIcon} />
                </Typography>
                <Typography sx={styles.subtitle}>
                    {getIcon()}
                    {type}
                </Typography>
            </Box>
        </Box>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    container: {
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        border: (theme) => `1px solid ${alpha(theme.palette.primary.main, 0.3)}`,
        borderRadius: 1,
        m: 2,
        cursor: "pointer",
        transition: "all 0.2s ease-in-out",
        "&:hover": {
            backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.1),
            border: (theme) => `1px solid ${alpha(theme.palette.primary.main, 0.4)}`,
        },
    },
    content: {
        flex: 1,
        p: 1,
        display: "flex",
        flexDirection: "column",
        gap: 1,
    },
    title: {
        fontSize: "0.8rem",
        fontWeight: 400,
    },
    subtitle: {
        fontSize: "0.7rem",
        color: "primary.light",
    },
    avatar: {
        width: "151px",
        height: "85px",
        borderRadius: 1,
        m: 0.6,
    },
    openUrlIcon: {
        verticalAlign: "sub",
        ml: 0.5,
        fontSize: "1rem",
        color: "text.secondary",
    },
    newspaperIcon: {
        verticalAlign: "sub",
        mr: 0.5,
        fontSize: "0.9rem",
        color: "text.secondary",
    },
}
