import { useDatabase } from "@nozbe/watermelondb/react"
import { ItemModel, itemRepository, tutorials } from "@recall/common"
import { useOpenItem } from "hooks/items/useOpenItem"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useParams } from "react-router"
import { toast } from "react-toastify"
import { RootState } from "storage/redux/rootReducer"
import { SET_ACTIVE_TUTORIAL } from "storage/redux/user/actionTypes"
import { useOpenOnboarding } from "../../Onboarding/hooks/useOpenOnboarding"

const MIN_BLOCKS_FOR_LINKS_TUTORIAL = 2

export const useOpenTutorial = () => {
    const db = useDatabase()
    const { closeOnboarding } = useOpenOnboarding()

    const dispatch = useDispatch()
    const location = useLocation()
    const { openItem, getItemPath } = useOpenItem()
    const { id } = useParams<{ id: string }>()

    const activeTutorial = useSelector((state: RootState) => state.user.tutorial.active)

    const getItemForLinkTutorial = async (items: ItemModel[]) => {
        for (const item of items) {
            const editorBlocksCount = await item.editorBlocks.count
            if (editorBlocksCount < MIN_BLOCKS_FOR_LINKS_TUTORIAL) continue
            return item
        }

        return null
    }

    const prepareCreateLinkTutorial = async () => {
        if (id && location.pathname === getItemPath(id)) {
            const openedItems = await itemRepository.getByItemIds(db, [id])
            const item = await getItemForLinkTutorial(openedItems)

            if (item) {
                closeOnboarding()
                return
            }
        }

        const items = await itemRepository.getQuery(db, false).fetch()
        const item = await getItemForLinkTutorial(items)

        if (!items.length || !item) {
            dispatch({ type: SET_ACTIVE_TUTORIAL, payload: "none" })
            toast.warn("Create at least one card before starting this tutorial.")
            return
        }

        openItem(item)
    }

    useEffect(() => {
        if (activeTutorial === tutorials.CREATE_LINK) prepareCreateLinkTutorial()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTutorial])
}
