import { Bolt } from "@mui/icons-material"
import { Box, Typography } from "@mui/material"
import { NoLinks, WEBSITE, WIKIPEDIA_PAGE } from "@recall/common"
import { useCanRunAI } from "components/ItemPage/hooks/useCanRunAI"
import { useItemContext } from "components/ItemPage/providers/ItemProvider"
import { useOpenPricingPage } from "hooks/useOpenPricingPage"
import { FC, useEffect, useState } from "react"
import { Links } from "./Links/Links"
import { Mentions } from "./Links/Mentions"

export const ItemConnections: FC = () => {
    const [isEmpty, setIsEmpty] = useState<boolean | undefined>(undefined)
    const { createConnections, item, chunks, markdown } = useItemContext()

    const canRunAI = useCanRunAI()
    const { openPricingPage } = useOpenPricingPage()

    const getIsEmpty = async () => {
        const mentionsCount = await item.mentions.fetchCount()
        const linksCount = await item.links.fetchCount()
        setIsEmpty(mentionsCount === 0 && linksCount === 0)
    }

    useEffect(() => {
        getIsEmpty()
    }, [])

    const handleRegenerate = async () => {
        const sources = await item.sources.fetch()
        const url = sources.find(
            (source) => source.name === WEBSITE || source.name === WIKIPEDIA_PAGE
        )?.identifier

        await createConnections(item, chunks, { markdown }, url)
    }

    if (isEmpty === undefined) return null

    return (
        <>
            <Typography
                variant="body2"
                sx={{
                    px: 2,
                    py: 0.5,
                    bgcolor: "action.hover",
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                }}
            >
                Create connections in the Notebook by clicking{" "}
                <Bolt color="secondary" fontSize="inherit" /> icon or typing /
            </Typography>
            <Box p={2} height="100%" overflow="auto">
                {isEmpty ? (
                    <NoLinks
                        handleUpgrade={openPricingPage}
                        canRunAction={canRunAI}
                        handleRegenerate={handleRegenerate}
                    />
                ) : (
                    <>
                        <Mentions itemId={item.id} connections={item.mentions} />
                        <Links connections={item.links} />
                    </>
                )}
            </Box>
        </>
    )
}
