export class UsageLimitReachedError extends Error {
    public status: number
    public url: string
    public detail: Record<string, any>

    constructor(url: string, detail: Record<string, any> = {}) {
        super("Usage limit reached")
        this.name = "UsageLimitReachedError"
        this.status = 429
        this.url = url
        this.detail = detail
    }
}
