import { useDatabase } from "@nozbe/watermelondb/react"
import {
    Chunk,
    chunksService,
    EditorBlockData,
    ItemModel,
    posthogService,
    Question,
    QuestionModel,
    sentry,
    UsageLimitReachedError,
} from "@recall/common"
import { GENERATE_MORE_QUESTIONS_EVENT, GENERATE_QUESTIONS_EVENT } from "constants/events"
import { map } from "lodash"
import { Dispatch, SetStateAction, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import { questionsApi } from "services/api"
import { questionReviewService } from "services/questionReviewService"
import {
    ADD_LOADING_QUESTION_ITEM_ID,
    REMOVE_LOADING_QUESTION_ITEM_ID,
    SET_IS_AUTH_MODAL_OPEN,
    SET_IS_SCRAPING_MODAL_OPEN,
} from "storage/redux/app/actionTypes"
import { RootState } from "storage/redux/rootReducer"

export const useGenerateQuestions = ({
    item,
    chunks,
    questions,
    setQuestions,
}: {
    setQuestions: Dispatch<SetStateAction<QuestionModel[]>>
    questions: QuestionModel[]
    item: ItemModel
    chunks: Chunk[]
}) => {
    const db = useDatabase()
    const uid = useSelector((state: RootState) => state.user.uid)
    const settings = useSelector((state: RootState) => state.user.settings)
    const [isGenerateFailed, setIsGenerateFailed] = useState(false)

    const dispatch = useDispatch()

    const createQuestions = async (questions: Question[]) => {
        const createdQuestions: QuestionModel[] = []

        for (const question of questions) {
            const options = question.options.map((option) => option.trim())
            const correctAnswer = question.answer.trim()

            if (!options.includes(correctAnswer)) continue

            const createdQuestion = await questionReviewService.createQuestionAndScheduleReview(
                uid,
                {
                    db,
                    item,
                    options,
                    question: question.question.trim(),
                    correctAnswer,
                }
            )

            createdQuestions.push(createdQuestion)
        }

        return createdQuestions
    }

    const handleGenerateQuestions = async () => {
        if (!uid) {
            dispatch({ type: SET_IS_AUTH_MODAL_OPEN, payload: true })
            return
        }
        dispatch({ type: ADD_LOADING_QUESTION_ITEM_ID, payload: item.id })
        posthogService.captureEvent(GENERATE_QUESTIONS_EVENT)

        const text = await getItemText()

        const questionsResponse = await generateQuestions(text)

        if (questionsResponse === null) {
            setIsGenerateFailed(true)
            return
        }

        const questions = await createQuestions(questionsResponse)
        if (questions.length) toast.success(`${questions.length} questions generated`)

        setQuestions(questions)
        dispatch({ type: REMOVE_LOADING_QUESTION_ITEM_ID, payload: item.id })
    }

    const handleGenerateMoreQuestions = async () => {
        dispatch({ type: ADD_LOADING_QUESTION_ITEM_ID, payload: item.id })
        posthogService.captureEvent(GENERATE_MORE_QUESTIONS_EVENT)

        const text = await getItemText()

        const questionsResponse = await generateQuestions(text)

        if (questionsResponse === null) {
            return
        }

        const createdQuestions = await createQuestions(
            questionsResponse.filter((question) =>
                questions.every((q) => q.question !== question.question)
            )
        )
        if (createdQuestions.length) toast.success(`${createdQuestions.length} questions generated`)

        setQuestions((prev) => [...createdQuestions, ...prev])
        dispatch({ type: REMOVE_LOADING_QUESTION_ITEM_ID, payload: item.id })
    }

    const getItemText = async () => {
        if (chunks.length === 0) return await getEditorBlockText()
        const markdown = chunks.map((chunk) => chunk.markdown).join(" ")
        const text = chunksService.markdownToText(markdown)
        return text
    }

    const getEditorBlockText = async () => {
        const editorBlocks = await item.getOrderedEditorBlocks()
        const text = EditorBlockData.getText(editorBlocks, null)
        return text
    }

    const generateQuestions = async (text: string, retry = 0) => {
        const existingQuestions = questions.length ? map(questions, "question") : undefined
        let data = null
        try {
            data = await questionsApi.generateQuestions(
                item.name + ". " + text,
                item.id,
                existingQuestions,
                {
                    language: settings.language || "auto",
                }
            )
        } catch (err) {
            if (err instanceof UsageLimitReachedError) {
                dispatch({ type: SET_IS_SCRAPING_MODAL_OPEN, payload: true })
                return []
            }
            sentry.captureException(err, { message: "Failed to generate questions" })
        }

        if (data === null && retry < 3) {
            return await generateQuestions(text, retry + 1)
        }

        return data
    }

    return { handleGenerateQuestions, handleGenerateMoreQuestions, isGenerateFailed }
}
