import { User, UserRepository } from "../../repositories"

export const USAGE_LIMIT = 10

export class SummaryUsageService {
    userRepository: UserRepository

    constructor(userRepository: UserRepository) {
        this.userRepository = userRepository
    }

    isUsageExceeded = async (userId: string, isImporting: boolean) => {
        const summariesGeneratedCount = await this.getUsage(userId, isImporting)
        return summariesGeneratedCount >= USAGE_LIMIT
    }

    getUsage = async (userId: string, isImporting: boolean) => {
        const user = await this.userRepository.getUser(userId)
        if (!user) return 0
        return this.getUsageFromUser(user, isImporting)
    }

    getUsageFromUser = (user: User, isImporting: boolean) => {
        const itemIds = isImporting
            ? user?.usage?.importItemIds || []
            : user?.usage?.extensionItemIds || []

        return itemIds.length
    }
}
