import { SvgIcon, SvgIconProps } from "@mui/material"

export const YouTubeIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon sx={{ ...props.sx, color: "action.active" }} {...props}>
            <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M12.8469 8.72969L9.09688 6.22969C9.00273 6.16687 8.89329 6.1308 8.78024 6.12533C8.6672 6.11985 8.55479 6.14517 8.45501 6.19859C8.35523 6.25201 8.27183 6.33152 8.21371 6.42863C8.15559 6.52575 8.12493 6.63682 8.125 6.75V11.75C8.12493 11.8632 8.15559 11.9743 8.21371 12.0714C8.27183 12.1685 8.35523 12.248 8.45501 12.3014C8.55479 12.3548 8.6672 12.3801 8.78024 12.3747C8.89329 12.3692 9.00273 12.3331 9.09688 12.2703L12.8469 9.77031C12.9326 9.71326 13.0029 9.63591 13.0515 9.54513C13.1002 9.45436 13.1256 9.35298 13.1256 9.25C13.1256 9.14702 13.1002 9.04564 13.0515 8.95487C13.0029 8.86409 12.9326 8.78674 12.8469 8.72969ZM9.375 10.582V7.92188L11.3734 9.25L9.375 10.582ZM16.875 3.625H3.125C2.79348 3.625 2.47554 3.7567 2.24112 3.99112C2.0067 4.22554 1.875 4.54348 1.875 4.875V13.625C1.875 13.9565 2.0067 14.2745 2.24112 14.5089C2.47554 14.7433 2.79348 14.875 3.125 14.875H16.875C17.2065 14.875 17.5245 14.7433 17.7589 14.5089C17.9933 14.2745 18.125 13.9565 18.125 13.625V4.875C18.125 4.54348 17.9933 4.22554 17.7589 3.99112C17.5245 3.7567 17.2065 3.625 16.875 3.625ZM16.875 13.625H3.125V4.875H16.875V13.625ZM18.125 16.75C18.125 16.9158 18.0592 17.0747 17.9419 17.1919C17.8247 17.3092 17.6658 17.375 17.5 17.375H2.5C2.33424 17.375 2.17527 17.3092 2.05806 17.1919C1.94085 17.0747 1.875 16.9158 1.875 16.75C1.875 16.5842 1.94085 16.4253 2.05806 16.3081C2.17527 16.1908 2.33424 16.125 2.5 16.125H17.5C17.6658 16.125 17.8247 16.1908 17.9419 16.3081C18.0592 16.4253 18.125 16.5842 18.125 16.75Z"
                    fill="#A8C5DF"
                />
            </svg>
        </SvgIcon>
    )
}
