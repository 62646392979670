import { Avatar, SxProps, Theme } from "@mui/material"
import React from "react"

interface Props {
    count: number
    sx?: SxProps<Theme>
}

const CountBadge: React.FC<React.PropsWithChildren<Props>> = ({ count, sx }) => {
    return (
        <Avatar
            sizes={"small"}
            sx={{
                width: (theme) => theme.spacing(3),
                height: (theme) => theme.spacing(3),
                fontSize: "1em",
                color: (theme) => theme.palette.text.secondary,
                backgroundColor: (theme) => theme.palette.action.disabledBackground,
                ...sx,
            }}
        >
            {count}
        </Avatar>
    )
}

export default CountBadge
