import { useCallback, useEffect, useRef } from "react"

const INTERSECTION_OPTIONS = {
    root: null,
    rootMargin: "20px",
    threshold: 0,
}

export const useRefObserver = (handleAction: () => void, options = INTERSECTION_OPTIONS) => {
    const ref = useRef(null)
    const handleObserver = useCallback(
        (entries: any) => {
            const target = entries[0]

            if (!target?.isIntersecting) return

            handleAction()
        },
        [handleAction]
    )
    useEffect(() => {
        const currentRef = ref.current

        if (currentRef) {
            const observer = new IntersectionObserver(handleObserver, options)
            observer.observe(currentRef)
            return () => observer.unobserve(currentRef)
        }
    }, [handleAction, handleObserver, ref])

    return ref
}
