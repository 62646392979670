import { ItemModel } from "../../storage/watermelon/models/ItemModel"
import { ChatMessage } from "./chatService"

const removeDeletedNotebookElementIds = async (item: ItemModel, messages: ChatMessage[]) => {
    const editorBlocks = await item.getOrderedEditorBlocks()

    return messages.map((message) => {
        if (
            message.notebookElementId &&
            editorBlocks.some((block) => block.id === message.notebookElementId)
        )
            return message

        delete message.notebookElementId
        return message
    })
}

export const messageService = {
    removeDeletedNotebookElementIds,
}
