import { SvgIconProps, useTheme } from "@mui/material"
import React from "react"
import { IllustrationDark } from "./IllustrationDark"
import { IllustrationLight } from "./IllustrationLight"

interface Props extends SvgIconProps {
    icon: React.ElementType
}

export const Illustration = ({ icon }: Props) => {
    const theme = useTheme()
    const isDark = theme.palette.mode === "dark"
    return isDark ? <IllustrationDark icon={icon} /> : <IllustrationLight icon={icon} />
}
