import { DEFAULT_LANGUAGE, DEFAULT_SEARCH_LANGUAGE, languages } from "../constants"
import { HttpClient } from "../utils"

export interface Options {
    language?: keyof typeof languages | typeof DEFAULT_LANGUAGE | typeof DEFAULT_SEARCH_LANGUAGE
    isSaveInBackgroundAllowed?: boolean
    contentType?: string
    isImporting?: boolean
}

export class BaseApi {
    protected httpClient: HttpClient

    constructor(httpClient: HttpClient) {
        this.httpClient = httpClient
    }

    protected getHeaders(options: Options): HeadersInit {
        const headers: HeadersInit = { "Content-Type": "application/json" }

        if (options.language) {
            headers["Accept-Language"] = options.language || DEFAULT_LANGUAGE
        }
        if (options.contentType) {
            headers["Content-Type"] = options.contentType
        }
        if (options.isImporting) {
            headers["Is-Importing"] = "true"
        }

        return headers
    }
}
