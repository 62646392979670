import { Breakpoint, useMediaQuery, useTheme } from "@mui/material"

export const useMediaBreakpoint = (breakpoint: Breakpoint) => {
    const theme = useTheme()

    const isMobile = useMediaQuery(theme.breakpoints.down(breakpoint), {
        matchMedia: (query) => window.matchMedia(query),
    })

    return isMobile
}
