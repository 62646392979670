import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import { Box, SxProps, Theme } from "@mui/material"
import { posthogService, PrimaryButton } from "@recall/common"
import { ReactComponent as AIAndTechIcon } from "assets/svg/ai-and-tech.svg"
import { ReactComponent as ClimateAndSustainabilityIcon } from "assets/svg/climate-and-sustainability.svg"
import { ReactComponent as FinanceAndInvestingIcon } from "assets/svg/finance-and-investing.svg"
import { ReactComponent as HealthAndFitnessIcon } from "assets/svg/health-and-fitness.svg"
import { ReactComponent as MedicineAndScienceIcon } from "assets/svg/medicine-and-science.svg"
import { ReactComponent as ProductivityIcon } from "assets/svg/productivity-and-self-improvement.svg"
import { FC, useEffect, useState } from "react"
import { LargeChip } from "./LargeChip"
import { TitleContainer } from "./TitleContainer"
import { Interest } from "./types"
import { CHOOSE_INTERESTS_EXTENSION_EVENT } from "constants/events"

interface Props {
    handleContinue: (selectedInterest: Interest | null) => void
}

const interests: Interest[] = [
    {
        id: "Productivity",
        label: "Productivity & Self-Improvement",
        icon: <ProductivityIcon />,
        url: "https://www.youtube.com/watch?v=8KPLs-ZFuPo",
        details: {
            url: "https://www.nature.com/articles/d41586-024-02381-x",
            title: "Science must protect thinking time in a world of instant communication",
            type: "News Article",
            imageUrl:
                "https://media.nature.com/lw1200/magazine-assets/d41586-024-02381-x/d41586-024-02381-x_27373782.jpg",
        },
    },
    {
        id: "AI",
        label: "AI & Tech",
        icon: <AIAndTechIcon />,
        url: "https://www.youtube.com/watch?v=jvqFAi7vkBc",
        details: {
            url: "https://techcrunch.com/2025/03/05/the-hottest-ai-models-what-they-do-and-how-to-use-them/",
            title: "The hottest AI models, what they do, and how to use them",
            type: "News Article",
            imageUrl:
                "https://techcrunch.com/wp-content/uploads/2025/02/GettyImages-ai-generated-26a3b7ec-3d37-4c32-acf0-2b9ca10aff76.jpg?w=1024",
        },
    },
    {
        id: "Health",
        label: "Health & Fitness",
        icon: <HealthAndFitnessIcon />,
        url: "https://www.youtube.com/watch?v=DkS1pkKpILY",
        details: {
            url: "https://www.bbc.com/future/article/20250131-how-the-brains-blue-dot-regulates-your-sleep",
            title: "How the brain's little blue dot regulates your sleep",
            type: "News Article",
            imageUrl: "https://ychef.files.bbci.co.uk/624x351/p0kncw45.jpg",
        },
    },
    {
        id: "Finance",
        label: "Finance & Investing",
        icon: <FinanceAndInvestingIcon />,
        url: "https://www.youtube.com/watch?v=C4ylGGYD-3c",
        details: {
            url: "https://www.reuters.com/markets/the-algebra-wealth-professor-galloways-fiscal-formula-2024-05-22",
            title: "'The Algebra of Wealth': Professor Galloway’s fiscal formula",
            type: "News Article",
            imageUrl:
                "https://www.reuters.com/resizer/v2/BDYS7RWQLFESLNZ677M6AMGHWQ.jpg?auth=cc6aac9133a222a77bb391eb3195a8dcb6486f39bf791dd91696a1805ee43ff9&width=1200&quality=80",
        },
    },
    {
        id: "Medicine",
        label: "Medicine & Science",
        icon: <MedicineAndScienceIcon />,
        url: "https://www.youtube.com/watch?v=P_fHJIYENdI",
        details: {
            url: "https://www.nature.com/articles/s41467-024-54046-y",
            title: "Deciphering the folding code of collagens",
            type: "Research Article",
            imageUrl:
                "https://media.springernature.com/m685/springer-static/image/art%3A10.1038%2Fs41467-024-54046-y/MediaObjects/41467_2024_54046_Fig1_HTML.png",
        },
    },
    {
        id: "Climate",
        label: "Climate & Sustainability",
        icon: <ClimateAndSustainabilityIcon />,
        url: "https://www.youtube.com/watch?v=Vl6VhCAeEfQ",
        details: {
            url: "https://www.nature.com/articles/s41558-025-02246-9",
            title: "A year above 1.5 °C signals that Earth is most probably within the 20-year period that will reach the Paris Agreement limit",
            type: "News Article",
            imageUrl:
                "https://media.springernature.com/m685/springer-static/image/art%3A10.1038%2Fs41558-025-02246-9/MediaObjects/41558_2025_2246_Fig1_HTML.png",
        },
    },
]

export const ChooseInterestsStep: FC<Props> = ({ handleContinue }) => {
    const [selectedInterest, setSelectedInterest] = useState<Interest | null>(null)

    useEffect(() => {
        posthogService.captureEvent(CHOOSE_INTERESTS_EXTENSION_EVENT)
    }, [])

    const handleInterestClick = (interest: Interest) => {
        setSelectedInterest((prev) => {
            if (prev === interest) {
                return null
            }
            return interest
        })
    }

    return (
        <>
            <TitleContainer
                title="Jump-Start your Knowledge base"
                subtitle="Select a topic - this will preload content into your knowledge base, giving you a taste of how Augmented Browsing works"
            />
            <Box sx={styles.chipsContainer}>
                {interests.map((interest) => (
                    <LargeChip
                        key={interest.id}
                        label={interest.label}
                        icon={interest.icon}
                        onClick={() => handleInterestClick(interest)}
                        selected={selectedInterest === interest}
                    />
                ))}
            </Box>

            <PrimaryButton
                onClick={() => handleContinue(selectedInterest)}
                endIcon={<NavigateNextIcon />}
                disabled={!selectedInterest}
                fullWidth
            >
                Continue
            </PrimaryButton>
        </>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    chipsContainer: {
        display: "flex",
        flexWrap: "wrap",
        gap: 2,
        justifyContent: "center",
        alignItems: "center",
        my: 2,
    },
}
