import { MENTIONS } from "../schema"

import { MentionModel } from "../models/MentionModel"

import { Database, Q } from "@nozbe/watermelondb"
import { ConnectionModel } from "../models/ConnectionModel"
import { ItemModel } from "../models/ItemModel"
import { MentionSentence } from "../models/MentionModel"

interface CreateMentionParams {
    db: Database
    editorBlockId: string
    mention: MentionSentence
    itemModel: ItemModel
    connection: ConnectionModel
}

const create = async ({
    db,
    mention,
    itemModel,
    connection,
    editorBlockId,
}: CreateMentionParams) => {
    return await db.write<MentionModel>(() => {
        return db.collections.get<MentionModel>(MENTIONS).create((record: MentionModel) => {
            record.blocks = mention.textBlocks
            record.elementId = mention.elementId
            record.editorBlockId = editorBlockId
            record.isSaved = itemModel.isSaved
            record.item.set(itemModel)
            record.connection.set(connection)
        })
    })
}

const getByConnection = async (db: Database, connection: string) => {
    return db.collections
        .get<MentionModel>(MENTIONS)
        .query(Q.where("connection_id", Q.eq(connection)))
        .fetch()
}

const deleteByItemId = async (db: Database, itemId: string) => {
    const mentions = await db.collections
        .get<MentionModel>(MENTIONS)
        .query(Q.where("item_id", Q.eq(itemId)))
        .fetch()

    for (const mention of mentions) {
        await mention.delete()
    }
}

export const mentionsRepository = {
    create,
    getByConnection,
    deleteByItemId,
}
