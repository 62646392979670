import { alpha, Box, Fade, Grid, Skeleton, SxProps, Theme } from "@mui/material"

export const LinksLoading = () => {
    return (
        <Fade in={true} timeout={{ enter: 400, exit: 0 }}>
            <Grid item xs={12} alignItems="center">
                <Box mb={1} display="flex" alignItems="center" gap={1}>
                    <Box display="flex" alignItems="center" gap={1}>
                        <Skeleton variant="circular" width={30} height={30} />
                        <Skeleton variant="rectangular" width={100} height={20} />
                    </Box>
                </Box>
                <Grid container spacing={0} sx={{ borderRadius: 1, overflow: "hidden" }}>
                    <LinksSkeleton />
                </Grid>
            </Grid>
        </Fade>
    )
}
const LinkSkeleton = ({ width }: { width: number }) => {
    return (
        <Box sx={styles.container}>
            <Skeleton variant="rounded" width={`${width}px`} height={32} />
            <Box sx={styles.avatar} />
        </Box>
    )
}

export const LinksSkeleton = () => {
    return (
        <Box sx={styles.links}>
            <LinkSkeleton width={140} />
            <LinkSkeleton width={100} />
            <LinkSkeleton width={120} />
            <LinkSkeleton width={160} />
        </Box>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    container: {
        position: "relative",
    },
    links: {
        display: "flex",
        flexWrap: "wrap",
        backgroundColor: "background.default",
        gap: 1,
        mb: 0.2,
        p: 1,
    },
    avatar: {
        position: "absolute",
        top: "50%",
        left: 4,
        transform: "translateY(-50%)",
        width: "24px",
        height: "24px",
        backgroundColor: alpha("#fff", 0.03),
        borderRadius: "10px",
        zIndex: 1,
    },
}
