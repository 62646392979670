import { ExpandLess, ExpandMore } from "@mui/icons-material"
import { Box, Menu, MenuItem, SxProps, Theme, Tooltip } from "@mui/material"
import {
    Action,
    AIAction,
    conciseSummaryAction,
    detailedSummaryAction,
    openReaderAction,
} from "@recall/common"
import { FC, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { settingsService } from "services/settingsService"
import { RootState } from "storage/redux/rootReducer"
import { UPDATE_USER } from "storage/redux/user/actionTypes"

const actions = [openReaderAction, conciseSummaryAction, detailedSummaryAction]

interface Props {
    disabled?: boolean
}

export const ActionPicker: FC<Props> = ({ disabled }) => {
    const selectedTab = useSelector((state: RootState) => state.app.addModalState.tab)
    const defaultAction =
        useSelector((root: RootState) => root.user?.settings?.defaultAction) || openReaderAction
    const dispatch = useDispatch()
    const uid = useSelector((state: RootState) => state.user.uid)
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
    const actionOptions = actions.filter((action) => action.id !== defaultAction.id)

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleActionClick = async (action: AIAction) => {
        handleClose()
        dispatch({ type: UPDATE_USER, payload: { settings: { defaultAction: action } } })
        await settingsService.update(uid, {
            defaultAction: action,
        })
    }

    const getTitle = () => {
        if (selectedTab === "bookmarksImport") return "Only reader action will be applied"
        if (selectedTab === "entitySearch") return "No AI actions will be applied"
        return "Select your default action"
    }

    return (
        <Box>
            <Tooltip title={getTitle()}>
                <Box
                    display="flex"
                    alignItems="center"
                    onClick={(e) => {
                        if (disabled) return
                        setAnchorEl(e.currentTarget)
                    }}
                    sx={styles.actionSelect}
                >
                    <Action
                        id={defaultAction.id}
                        label={defaultAction.name}
                        sx={{ ...styles.action, flexGrow: 1, justifyContent: "start" }}
                        disabled={disabled}
                    />
                    <Box
                        color={disabled ? "action.disabled" : "text.primary"}
                        display="flex"
                        alignItems="center"
                        justifyContent="end"
                        pr={0.5}
                    >
                        {anchorEl ? <ExpandLess /> : <ExpandMore />}
                    </Box>
                </Box>
            </Tooltip>

            <Menu
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                sx={styles.menu}
            >
                {actionOptions.map((action) => (
                    <MenuItem
                        key={action.id}
                        onClick={() => handleActionClick(action)}
                        sx={styles.menuItem}
                    >
                        <Action id={action.id} label={action.name} sx={styles.action} />
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    action: {
        backgroundColor: "transparent",
        "&:hover": {},
        ".MuiChip-label": {
            pr: 0.5,
        },
    },
    menu: {
        mt: 0.5,
        "& .MuiList-root": {
            py: 0,
        },
    },
    menuItem: {
        minWidth: 178,
        pl: 1,
        backgroundColor: (theme) => theme.palette.background.paper,
        "&:hover": {
            backgroundColor: (theme) => theme.palette.action.disabledBackground,
        },
    },
    actionSelect: {
        minWidth: 178,
        border: "1px solid",
        borderColor: (theme) => theme.palette.divider,
        borderRadius: 1,
        p: 0.5,
        cursor: "pointer",
        "&:hover": {
            backgroundColor: (theme) => theme.palette.action.hover,
        },
    },
}
