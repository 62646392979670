import { ASSET_TYPES, AssetModel, ItemModel } from "@recall/common"
import { useEffect, useState } from "react"

export const useFileAsset = (item: ItemModel) => {
    const [fileAsset, setFileAsset] = useState<AssetModel | null>(null)

    useEffect(() => {
        fetchFileAsset()
    }, [item])

    const fetchFileAsset = async () => {
        const assets = await item.assets.fetch()
        const fileAsset = assets.find((asset) => asset.type === ASSET_TYPES.PDF)
        setFileAsset(fileAsset)
    }

    return fileAsset
}
