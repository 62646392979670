import { useDatabase } from "@nozbe/watermelondb/react"
import { itemRepository, itemService } from "@recall/common"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router"
import { RootState } from "storage/redux/rootReducer"

export const useDeleteStale = () => {
    const { id } = useParams<{ id: string }>()
    const db = useDatabase()
    const uid = useSelector((state: RootState) => state.user.uid)

    useEffect(() => {
        const removeItem = async () => {
            if (!id) return
            const item = await itemRepository.get(db, id)
            if (!item || item.isSaved) return
            await itemService.deleteItem(db, item, uid)
        }

        window.addEventListener("beforeunload", removeItem)

        return () => {
            removeItem()
            window.removeEventListener("beforeunload", removeItem)
        }
    }, [])
}
