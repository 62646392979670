import { ButtonProps } from "@mui/material"
import { useOpenPricingPage } from "hooks/useOpenPricingPage"
import { FC } from "react"
import { UpgradeButton } from "@recall/common"

interface Props extends ButtonProps {
    onClick?: () => void
}

export const AppUpgradeButton: FC<Props> = ({ onClick }) => {
    const { openPricingPage } = useOpenPricingPage()

    const handleOnClick = async () => {
        await openPricingPage()
        if (onClick) onClick()
    }

    return <UpgradeButton onClick={handleOnClick} />
}
