import { buildUrl, HttpClient } from "../utils"
import { BaseApi } from "./BaseApi"

const EXTRACT_ENTITIES_PATH = "/entities/extract/"

export class EntitiesApi extends BaseApi {
    constructor(httpClient: HttpClient) {
        super(httpClient)
    }

    async extractEntities(text: string, itemId: string, url: string, isImporting?: boolean) {
        const response = await this.httpClient.post(
            buildUrl(EXTRACT_ENTITIES_PATH, { itemId, url }),
            JSON.stringify({ text }),
            this.getHeaders({ isImporting })
        )
        return await response.json()
    }
}
