import { Box, Fade, Typography, useScrollTrigger } from "@mui/material"
import { memo } from "react"
import { DrawerButton } from "../Drawer/DrawerButton"
import { AddButton } from "./AddButton/AddButton"
import { BookmarksImportButton } from "./BookmarksImportButton"
import { OfflineStatus } from "./OfflineStatus"

const HeaderComp = () => {
    const trigger = useScrollTrigger({ disableHysteresis: true })

    return (
        <>
            <Box
                display="flex"
                flexWrap="wrap"
                alignItems="center"
                justifyContent="space-between"
                zIndex={1300}
                px={2}
                py={1}
            >
                <Box display="flex" alignItems="center" columnGap={1}>
                    <DrawerButton />
                    <Typography variant="body1" fontWeight={500}>
                        Home
                    </Typography>
                    <OfflineStatus />
                    <BookmarksImportButton />
                </Box>
                <Box display="flex" alignItems="center">
                    <AddButton />
                </Box>
            </Box>
            <Fade in={trigger} unmountOnExit>
                <Box zIndex={1300} position="fixed" top={10} right={12}>
                    <AddButton />
                </Box>
            </Fade>
        </>
    )
}

export const Header = memo(HeaderComp)
