import { buildUrl, HttpClient } from "../utils"
import { BaseApi, Options } from "./BaseApi"

const QUESTIONS_PATH = "/questions/"

export class QuestionsApi extends BaseApi {
    constructor(httpClient: HttpClient) {
        super(httpClient)
    }

    async generateQuestions(
        text: string,
        itemId: string,
        existingQuestions?: string[],
        options: Options = {},
        model: "gpt-4" | "gpt-4o" = "gpt-4"
    ) {
        const response = await this.httpClient.post(
            buildUrl(QUESTIONS_PATH, { itemId }),
            JSON.stringify({
                text,
                existing_questions: existingQuestions || null,
                model,
            }),
            this.getHeaders(options)
        )
        return await response.json()
    }
}
