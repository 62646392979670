import { Add } from "@mui/icons-material"
import { Button, SxProps, Theme } from "@mui/material"
import { COMPLETE_TUTORIAL_EVENT, posthogService, tutorials, useIsMobile } from "@recall/common"
import { memo } from "react"
import { useDispatch } from "react-redux"
import { SET_IS_ADD_MODAL_OPEN } from "storage/redux/app/actionTypes"
import { TUTORIAL_COMPLETE } from "storage/redux/user/actionTypes"

export const ADD_NEW_BUTTON_DIV_ID = "id-add-new-button-div"

export const AddButton = memo(() => {
    const dispatch = useDispatch()

    const isMobile = useIsMobile()

    const handleOpenAddModal = () => {
        completeTutorial()
        dispatch({ type: SET_IS_ADD_MODAL_OPEN, payload: true })
    }

    const completeTutorial = () => {
        dispatch({ type: TUTORIAL_COMPLETE, payload: tutorials.ITEMS_INTRO })
        posthogService.captureEvent(COMPLETE_TUTORIAL_EVENT, {
            tutorialName: tutorials.ITEMS_INTRO,
        })
    }

    return (
        <Button
            onClick={handleOpenAddModal}
            sx={styles.addButton}
            color="primary"
            variant="contained"
            startIcon={<Add />}
            size={isMobile ? "large" : "small"}
            id={ADD_NEW_BUTTON_DIV_ID}
        >
            Create card
        </Button>
    )
})

const styles: Record<string, SxProps<Theme>> = {
    addButton: {
        fontSize: 16,
        py: 0.5,
        pl: 1.5,
        pr: 2,
    },
}
