import {
    CANCEL_TUTORIAL_EVENT,
    COMPLETE_TUTORIAL_EVENT,
    posthogService,
    Step,
    TutorialBase,
} from "@recall/common"
import { isEqual } from "lodash"
import { memo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "storage/redux/rootReducer"
import { SET_ACTIVE_TUTORIAL, TUTORIAL_COMPLETE } from "storage/redux/user/actionTypes"
import { useOpenOnboarding } from "../Onboarding/hooks/useOpenOnboarding"

interface Props {
    tutorialName: string
    steps: Step[]
}

const AppTutorialBaseComponent = (props: Props) => {
    const { tutorialName, steps } = props
    const dispatch = useDispatch()
    const { isOnboardingOpen } = useOpenOnboarding()

    const isCompleted = !!useSelector(
        (state: RootState) => state.user.tutorial.completed[tutorialName]
    )

    const handleClose = () => {
        dispatch({ type: SET_ACTIVE_TUTORIAL, payload: "none" })
    }

    const handleFinish = () => {
        dispatch({ type: TUTORIAL_COMPLETE, payload: tutorialName })
        posthogService.captureEvent(COMPLETE_TUTORIAL_EVENT, { tutorialName })
    }

    const handleCancel = (activeStep: number) => {
        dispatch({ type: TUTORIAL_COMPLETE, payload: tutorialName })
        posthogService.captureEvent(CANCEL_TUTORIAL_EVENT, {
            tutorialName,
            tutorialStep: activeStep,
        })
    }

    if (isCompleted || isOnboardingOpen) return null

    return (
        <TutorialBase
            steps={steps}
            onClose={handleClose}
            onFinish={handleFinish}
            onCancel={handleCancel}
        />
    )
}

export const AppTutorialBase = memo(AppTutorialBaseComponent, (prev, next) => {
    return prev.tutorialName === next.tutorialName && isEqual(prev.steps, next.steps)
})
