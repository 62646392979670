import { Box, Button, Typography } from "@mui/material"
import { WIKIPEDIA_PAGE } from "@recall/common"
import { ReactComponent as UnlockToUse } from "assets/svg/unlock_to_use.svg"
import { useSources } from "components/ItemPage/hooks/useSources"
import { useItemContext } from "components/ItemPage/providers/ItemProvider"
import { FC, PropsWithChildren } from "react"

interface Props extends PropsWithChildren {
    isExpanding: boolean
    label: string
    handleExpand: () => Promise<void>
}

export const ExpandWrapper: FC<Props> = ({ children, isExpanding, label, handleExpand }) => {
    const { item, contentType } = useItemContext()
    const { sources } = useSources(item)

    if (
        contentType === "entity" &&
        !item.isExpanded &&
        sources.some((source) => source.name === WIKIPEDIA_PAGE)
    )
        return (
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height="100%"
                flex={1}
            >
                <UnlockToUse />
                <Typography variant="h6" mt={1}>
                    Expand to unlock
                </Typography>
                <Typography variant="body2" mb={2} color="text.secondary">
                    {label}
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleExpand}
                    disabled={isExpanding}
                >
                    Expand
                </Button>
            </Box>
        )

    return <>{children}</>
}
