import { Database } from "@nozbe/watermelondb"
import { groupBy, keyBy, map, orderBy } from "lodash"
import { itemRepository } from "../repository/itemRepository"
import { ROOT_TAG_ID, tagRepository } from "../repository/tagRepository"

export interface TagPartial {
    id: string
    name: string
    parentId: string
    updatedAt: Date
}

export interface TagsWithPath {
    path: string
    tag: TagPartial
}

const orderTagsByRecentUsage = async (db: Database, tags: TagsWithPath[]) => {
    const itemTags = await tagRepository.getItemTags(db)
    const groupedItemTagsByTagId = groupBy(itemTags, "tag.id")

    const tagsWithDates = []
    for (const tagWithPath of tags) {
        const itemTags = groupedItemTagsByTagId[tagWithPath.tag.id]
        const dates = [...map(itemTags, "createdAt"), tagWithPath.tag.updatedAt]
        const latestDate = Math.max(...dates.map((date) => date.getTime()))
        tagsWithDates.push({ tag: tagWithPath, date: latestDate })
    }
    const orderedTags = map(orderBy(tagsWithDates, "date", "desc"), "tag")

    return orderedTags
}

export const getTags = async (db: Database) => {
    const tags = await tagRepository.getAll(db)
    const groupedTags = keyBy(tags, "id")
    const tagsWithPaths: TagsWithPath[] = []

    for (const tag of tags) {
        let path = tag.name
        let parentId = tag.parent.id
        while (parentId && parentId !== ROOT_TAG_ID) {
            const parentTag = groupedTags[parentId]
            if (!parentTag) {
                break
            }

            parentId = parentTag.parent.id
            path = parentTag.name + "/" + path
        }
        tagsWithPaths.push({
            tag: {
                id: tag.id,
                name: tag.name,
                parentId: tag.parent.id,
                updatedAt: tag.updatedAt,
            },
            path,
        })
    }

    const orderedTags = await orderTagsByRecentUsage(db, tagsWithPaths)
    return orderedTags
}

export const handleCreate = async (db: Database, itemId: string, tagName: string) => {
    const item = await itemRepository.get(db, itemId)
    if (!item) return

    const tagsToCreate = tagName.split("/")

    let parentId = ROOT_TAG_ID
    for (let i = 0; i < tagsToCreate.length; i++) {
        if (!tagsToCreate[i]) continue

        if (i === tagsToCreate.length - 1) {
            await tagRepository.create({
                db,
                name: tagsToCreate[i],
                item,
                parentId,
                isSaved: item.isSaved,
            })
        } else {
            const newTag = await tagRepository.create({
                db,
                name: tagsToCreate[i],
                parentId,
                isSaved: item.isSaved,
            })
            if (!newTag) continue

            parentId = newTag.id
        }
    }
}

export const handleDetach = async (db: Database, tagId: string, itemId: string) => {
    await tagRepository.detach(db, tagId, itemId)
}
