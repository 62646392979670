import { Box } from "@mui/material"
import { FC, useState } from "react"

interface Props {
    image: string
}

export const ListImage: FC<Props> = ({ image }) => {
    const [hasFailedToLoad, setHasFailedToLoad] = useState(false)

    if (hasFailedToLoad) return null

    return (
        <Box sx={{ float: "right", pl: 1 }}>
            <Box
                component="img"
                src={image}
                onError={() => setHasFailedToLoad(true)}
                sx={{
                    display: "block",
                    width: 60,
                    height: 60,
                    background: (theme) => theme.palette.action.hover,
                    objectFit: "cover",
                    borderRadius: 0.8,
                }}
            />
        </Box>
    )
}
