import { Clear } from "@mui/icons-material"
import { Box, IconButton, InputBase, SxProps, Theme, Tooltip } from "@mui/material"
import { useIsMobile } from "@recall/common"
import { ChevronsDownUp, PanelLeftClose, Search } from "lucide-react"
import { FC, memo, useState } from "react"
import { useDispatch } from "react-redux"
import { SET_DRAWER_OPEN } from "storage/redux/drawer/actionTypes"
import { ReferencesSwitch } from "./ReferencesSwitch"
interface Props {
    search: (searchText: string) => void
    hideCloseButton: boolean
    collapseTagsTree: () => void
}

const TagsTreeSearchComponent: FC<Props> = ({ search, collapseTagsTree, hideCloseButton }) => {
    const dispatch = useDispatch()
    const [searchValue, setSearchValue] = useState("")
    const isMobile = useIsMobile()

    const handleSearch = (value: string) => {
        setSearchValue(value)
        search(value)
    }

    const handleClear = () => {
        setSearchValue("")
        search("")
    }

    const handleDrawerClose = () => {
        dispatch({ type: SET_DRAWER_OPEN, payload: false })
    }

    return (
        <Box sx={styles.container}>
            <Box sx={styles.wrapper}>
                <Box sx={styles.icon}>
                    <Search size={18} />
                </Box>
                <InputBase
                    value={searchValue}
                    onChange={(e) => handleSearch(e.target.value)}
                    sx={{ ...styles.input, fontSize: isMobile ? "1.1rem" : "1rem" }}
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    endAdornment={searchValue && <Clear onClick={handleClear} sx={styles.close} />}
                />
            </Box>

            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    pl: 0.2,
                }}
            >
                <Tooltip title="Collapse tags">
                    <IconButton onClick={collapseTagsTree}>
                        <ChevronsDownUp size={20} />
                    </IconButton>
                </Tooltip>
                <ReferencesSwitch />
                {!hideCloseButton && (
                    <IconButton onClick={handleDrawerClose}>
                        <PanelLeftClose size={20} />
                    </IconButton>
                )}
            </Box>
        </Box>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        p: 1,
        pb: 0.5,
    },
    wrapper: {
        position: "relative",
        borderRadius: 0.8,
        border: "1px solid",
        borderColor: (theme) => theme.palette.divider,
        flex: 1,
        "&:hover": {
            backgroundColor: (theme) => theme.palette.action.hover,
        },
    },
    icon: {
        paddingLeft: 0.9,
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: (theme) => theme.palette.grey[theme.palette.mode === "dark" ? 400 : 600],
    },
    input: {
        color: "inherit",
        paddingLeft: (theme) => `calc(1em + ${theme.spacing(2.4)})`,
        width: "100%",
    },
    close: {
        mr: 1,
        cursor: "pointer",
    },
}

export const TagsTreeHeader = memo(TagsTreeSearchComponent, () => true)
