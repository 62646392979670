import {
    Collapse,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    ListSubheader,
    SxProps,
    Theme,
} from "@mui/material"
import { isKeyHotkey } from "is-hotkey"
import { isEqual } from "lodash"
import { FC, MouseEvent, memo, useEffect } from "react"
import { TransitionGroup } from "react-transition-group"
import { Result } from "../../editor/hooks/useSearchItems"
import { DropdownPopper } from "./components/DropdownPopper"
import LoadingSearchResult from "./components/LoadingSearchResult"
import SearchResultComp from "./components/SearchResult"

interface Props {
    onClose: (insertForwardSlash?: boolean) => void
    onSelectResult: Function
    subheaderText?: string
    loading: boolean
    results: Result[]
    anchorEl: HTMLElement | null
    closeOnEmptyBackspace?: boolean
    open: boolean
    value: string
    selectedIndex: number
    setSelectedIndex: (index: number) => void
}

const SearchResultsComp: FC<Props> = ({
    results,
    loading,
    onSelectResult,
    closeOnEmptyBackspace,
    value,
    open,
    selectedIndex,
    setSelectedIndex,
    anchorEl,
    subheaderText,
    onClose,
}) => {
    useEffect(() => {
        const handleSearchKeyDown = (e: KeyboardEvent) => {
            if (isKeyHotkey("enter", e)) {
                e.preventDefault()
                const result = results[selectedIndex]

                if (result) {
                    onSelectResult(result)
                }
            } else if (isKeyHotkey("backspace", e)) {
                if (value.length === 0 && closeOnEmptyBackspace === true) {
                    e.preventDefault()
                    onClose(true)
                }
            } else if (isKeyHotkey("escape", e)) {
                e.preventDefault()
                onClose(true)
            } else if (isKeyHotkey("down", e)) {
                e.preventDefault()

                const nextSelectedIndex = selectedIndex + 1

                if (nextSelectedIndex < results.length) {
                    setSelectedIndex(nextSelectedIndex)
                }
            } else if (isKeyHotkey("up", e)) {
                e.preventDefault()

                const nextSelectedIndex = selectedIndex - 1

                if (nextSelectedIndex >= 0) {
                    setSelectedIndex(nextSelectedIndex)
                }
            }
        }

        if (open) {
            document.addEventListener("keydown", handleSearchKeyDown)
        }

        return () => document.removeEventListener("keydown", handleSearchKeyDown)
    }, [open, selectedIndex, results, value])

    const preventFocus = (e: MouseEvent<HTMLDivElement>) => {
        e.preventDefault()
        e.stopPropagation()
    }

    let content: any = <></>

    if (loading) {
        content = (
            <Collapse>
                <LoadingSearchResult />
                <LoadingSearchResult />
                <LoadingSearchResult />
                <LoadingSearchResult />
            </Collapse>
        )
    } else if (results.length) {
        content = results.map((result, index) => {
            let key = "id-create-option"

            let isCreateOption = !!result?.isCreateOption

            if (!isCreateOption) {
                key = result.id
            }

            return (
                <Collapse key={key}>
                    <ListItemButton
                        sx={
                            isCreateOption
                                ? {
                                      ...styles.listItemRoot,
                                      justifyContent: "center",
                                      backgroundColor: "action.selected",
                                      pb: 2,
                                  }
                                : styles.listItemRoot
                        }
                        selected={selectedIndex === index}
                        onClick={() => onSelectResult(result)}
                        onMouseDown={preventFocus}
                        data-testid="cards-search-result"
                    >
                        <SearchResultComp
                            result={result}
                            value={value}
                            selected={selectedIndex === index}
                        />
                    </ListItemButton>
                    {results.length === 1 && !results[0]?.isGenerateSummaryOption && (
                        <ListItem>
                            <ListItemText primary="No matching results." />
                        </ListItem>
                    )}
                </Collapse>
            )
        })
    } else {
        content = subheaderText ? (
            <Collapse>
                <ListSubheader
                    sx={{
                        fontSize: "17px",
                        lineHeight: "1.5em",
                        py: 1.5,
                    }}
                    component="div"
                >
                    {subheaderText}
                </ListSubheader>
            </Collapse>
        ) : null
    }

    return (
        <DropdownPopper open={open} anchorEl={anchorEl}>
            <List component="nav" sx={styles.popover}>
                <TransitionGroup>{content}</TransitionGroup>
            </List>
        </DropdownPopper>
    )
}

export const SearchResults = memo(
    SearchResultsComp,
    (prev, next) =>
        isEqual(prev.results, next.results) &&
        prev.loading === next.loading &&
        prev.open === next.open &&
        prev.selectedIndex === next.selectedIndex
)

const styles: Record<string, SxProps<Theme>> = {
    listItemRoot: {
        p: (theme) => `${theme.spacing(0.5)} ${theme.spacing(1)} !important`,
    },
    popover: {
        paddingTop: 0,
        paddingBottom: 0,
        backgroundColor: (theme) => theme.palette.background.paper,
    },
}
