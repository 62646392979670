import { Box, Container, Grid, Skeleton } from "@mui/material"
import { useIsMobile } from "@recall/common"

interface Props {
    isReaderVisible: boolean
}

export const Loader = ({ isReaderVisible }: Props) => {
    const isMobile = useIsMobile()

    if (isMobile)
        return (
            <Box p={2}>
                <Box display="flex" justifyContent="space-between" alignItems="center" pb={5}>
                    <Skeleton variant="rounded" height={25} width={25} />
                    <Box display="flex" gap={1}>
                        <Skeleton variant="circular" height={25} width={25} />
                        <Skeleton variant="circular" height={25} width={25} />
                        <Skeleton variant="circular" height={25} width={25} />
                        <Skeleton variant="circular" height={25} width={25} />
                    </Box>
                </Box>
                <Skeleton variant="rounded" height={25} width={250} />
                <Skeleton variant="rounded" height={32} width={200} sx={{ mt: 1 }} />
                <Skeleton variant="text" height={50} width={300} />
                <Box display="flex" gap={2} pb={2}>
                    <Skeleton variant="rounded" height={25} width={140} />
                    <Skeleton variant="rounded" height={25} width={90} />
                </Box>

                <Box display="flex" flexDirection="column" gap={2}>
                    <Skeleton variant="rounded" height={40} />
                    <Skeleton variant="rounded" height={544} />
                </Box>
            </Box>
        )

    return (
        <Box p={2}>
            <Box display="flex" justifyContent="space-between" alignItems="center" pb={2}>
                <Skeleton variant="rounded" height={25} width={300} />
                <Box display="flex" gap={1}>
                    <Skeleton variant="circular" height={25} width={25} />
                    <Skeleton variant="circular" height={25} width={25} />
                    <Skeleton variant="circular" height={25} width={25} />
                    <Skeleton variant="circular" height={25} width={25} />
                </Box>
            </Box>
            <Container
                disableGutters={isReaderVisible}
                maxWidth="md"
                sx={{ m: isReaderVisible ? 0 : undefined, mt: 1 }}
            >
                <Skeleton variant="rounded" height={32} width={200} />
                <Skeleton variant="text" height={50} width={600} />
            </Container>
            <Box>
                <Container
                    disableGutters={isReaderVisible}
                    maxWidth="md"
                    sx={{ display: "flex", gap: 2, m: isReaderVisible ? 0 : undefined }}
                >
                    <Skeleton variant="rounded" height={25} width={140} />
                    <Skeleton variant="rounded" height={25} width={90} />
                </Container>
            </Box>
            <Grid container spacing={2} pt={2}>
                {isReaderVisible && (
                    <Grid item xs={6}>
                        <Skeleton variant="rounded" height={600} />
                    </Grid>
                )}
                <Grid item xs={isReaderVisible ? 6 : 12}>
                    <Container
                        disableGutters={isReaderVisible}
                        maxWidth="md"
                        sx={{ m: isReaderVisible ? 0 : undefined }}
                    >
                        <Skeleton variant="rounded" height={40} sx={{ mb: 2 }} />
                        <Skeleton variant="rounded" height={544} />
                    </Container>
                </Grid>
            </Grid>
        </Box>
    )
}
