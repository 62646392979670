export enum tutorials {
    ITEMS_INTRO = "items-intro",
    ITEM_INTRO = "item-intro",
    EXPAND_INTRO = "expand-intro",
    CREATE_LINK = "create-link",
    ADD_CARD = "add-card",
    EXTENSION = "extension",
    SUMMARY_LENGTH = "summary-length",
    CREATE_CARD = "create-card",
    RAB_RELEASE_MODAL = "rab-release-modal",
}
