import { Box, SxProps, Theme, Typography } from "@mui/material"
import { Illustration } from "@recall/common"
import { EyeOff } from "lucide-react"

export const UnReadable = () => {
    return (
        <Box sx={styles.container}>
            <Box sx={styles.content}>
                <Typography variant="h6" fontWeight={600}>
                    No Reader for this page
                </Typography>
                <Illustration icon={EyeOff} />
                <Typography color="text.secondary" my={2} variant="body2">
                    The readable content could not be parsed from this page. You can still run AI
                    actions or chat with the page content.
                </Typography>
            </Box>
        </Box>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    container: {
        borderRadius: 1,
        p: 4,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: 1,
        textAlign: "center",
    },
    content: {
        maxWidth: 400,
    },
}
