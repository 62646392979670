import { Box, Container, Grid, SxProps, Theme } from "@mui/material"
import { useDatabase } from "@nozbe/watermelondb/react"
import {
    IS_EXTENSION_TUTORIAL_CARD,
    itemService,
    tutorials,
    useMediaBreakpoint,
} from "@recall/common"
import { useBreadcrumbActions } from "hooks/useBreadcrumbActions"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router"
import { SET_IS_AUTH_MODAL_OPEN } from "storage/redux/app/actionTypes"
import { RootState } from "storage/redux/rootReducer"
import { SET_ACTIVE_TUTORIAL, TUTORIAL_COMPLETE } from "storage/redux/user/actionTypes"
import { ItemHeader } from "./components/item/components/ItemHeader"
import { ItemImage } from "./components/item/components/ItemImage"
import { ItemName } from "./components/item/components/ItemName"
import { ItemTabs } from "./components/item/components/ItemTabs"
import { Loader } from "./components/item/components/Loader"
import { Reader } from "./components/item/components/Reader/Reader"
import { ReaderWrapper } from "./components/item/components/Reader/ReaderWrapper"
import { Sources } from "./components/item/components/Sources"
import { Tags } from "./components/item/components/Tags/Tags"
import { useCurrentBreadcrumbs } from "./hooks/useCurrentBreadcrumbs"
import { useDeleteStale } from "./hooks/useDeleteStale"
import { useReaderVisibility } from "./hooks/useReaderVisibility"
import { ChatProvider } from "./providers/ChatProvider"
import { ItemProvider, useItemContext } from "./providers/ItemProvider"
import { TabProvider } from "./providers/TabProvider"

export const ELEMENT_ID = "elementId"

const ItemPage: React.FC<React.PropsWithChildren<unknown>> = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const isTablet = useMediaBreakpoint("md")
    const { isReaderVisible, toggleReader } = useReaderVisibility()
    const { isLoading, item, contentType, isLoadingDelayed } = useItemContext()
    const uid = useSelector((state: RootState) => state.user.uid)
    const db = useDatabase()
    const { resetBreadcrumbs } = useBreadcrumbActions()

    const isReaderPage = contentType === "page" && isReaderVisible

    useCurrentBreadcrumbs(item)

    useEffect(() => {
        const isExtensionTutorialCard = location.search.includes(IS_EXTENSION_TUTORIAL_CARD)

        if (isExtensionTutorialCard)
            dispatch({ type: TUTORIAL_COMPLETE, payload: tutorials.EXTENSION })

        return () => {
            resetBreadcrumbs(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!item) return

        return () => {
            const deleteIfEmpty = async () => {
                dispatch({ type: SET_ACTIVE_TUTORIAL, payload: "none" })
                const isEmpty = await item.isEmpty()
                if (isEmpty) await itemService.deleteItem(db, item, uid)
            }
            deleteIfEmpty()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item])

    useDeleteStale()

    const handleSave = async () => {
        if (!uid) {
            dispatch({ type: SET_IS_AUTH_MODAL_OPEN, payload: true })
            return
        }
        if (!item) return
        await item.saveDeep()
    }
    if (isLoadingDelayed) return <Loader isReaderVisible={isReaderVisible} />

    if (isLoading) return null

    return (
        <ChatProvider>
            <Grid container justifyContent="center" px={2} key={item.id}>
                <Grid item xs={12}>
                    <ItemHeader isSaved={item.isSaved} item={item} handleSave={handleSave} />
                </Grid>
                <Grid item xs={12}>
                    <Container
                        sx={{
                            ...styles.container,
                            pt: 3,
                            m: isReaderPage ? 0 : undefined,
                            display: "flex",
                            alignItems: "flex-start",
                        }}
                        maxWidth={isReaderPage || isTablet ? false : "md"}
                        disableGutters={isReaderPage || isTablet}
                    >
                        {item.image && (
                            <Box sx={{ display: { xs: "none", md: "block" }, height: "100%" }}>
                                <ItemImage item={item} />
                            </Box>
                        )}

                        <Box
                            flex={1}
                            sx={{ pl: { xs: 0, md: item.image ? 2 : 0 } }}
                            display="flex"
                            flexDirection="column"
                            gap={1}
                        >
                            <Sources item={item} />
                            <Tags />
                            <ItemName item={item} showImage={contentType === "entity"} />
                        </Box>
                    </Container>
                </Grid>

                <Grid container item xs={12} key={item.id} columnSpacing={2}>
                    {isReaderVisible && contentType === "page" && !isTablet && (
                        <Grid item xs={6} position="sticky" top={0} pt={2}>
                            <ReaderWrapper
                                isReaderVisible={isReaderVisible}
                                toggleReader={toggleReader}
                            >
                                <Reader />
                            </ReaderWrapper>
                        </Grid>
                    )}
                    <Grid
                        item
                        xs={!isReaderVisible || contentType !== "page" || isTablet ? 12 : 6}
                        pb={2}
                    >
                        <Container
                            sx={{
                                ...styles.container,
                                m: isReaderPage ? 0 : undefined,
                            }}
                            disableGutters={isReaderPage || isTablet}
                            maxWidth="md"
                        >
                            {item.image && (
                                <Box py={2} sx={{ display: { xs: "block", md: "none" } }}>
                                    <ItemImage item={item} />
                                </Box>
                            )}
                            <ItemTabs
                                isReaderVisible={isReaderVisible}
                                toggleReader={toggleReader}
                            />
                        </Container>
                    </Grid>
                </Grid>
            </Grid>
        </ChatProvider>
    )
}

const ItemPageWrapper = () => {
    return (
        <TabProvider>
            <ItemProvider>
                <ItemPage />
            </ItemProvider>
        </TabProvider>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    container: {
        textAlign: "left",
        height: "100%",
    },
}

export default ItemPageWrapper
