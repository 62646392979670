import { useDispatch, useSelector } from "react-redux"
import { settingsService } from "services/settingsService"
import { RootState } from "storage/redux/rootReducer"
import { UPDATE_USER } from "storage/redux/user/actionTypes"
import { useTabActions } from "../providers/TabProvider"

export const useReaderVisibility = () => {
    const isReaderVisible = useSelector((state: RootState) => state.user.settings.isReaderVisible)
    const uid = useSelector((state: RootState) => state.user.uid)
    const { setTab } = useTabActions()
    const dispatch = useDispatch()

    const toggleReader = async () => {
        if (isReaderVisible) {
            setTab("reader")
        }
        dispatch({
            type: UPDATE_USER,
            payload: { settings: { isReaderVisible: !isReaderVisible } },
        })
        await settingsService.update(uid, { isReaderVisible: !isReaderVisible })
    }

    return { isReaderVisible, toggleReader }
}
