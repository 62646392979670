import { Model, Query, Relation } from "@nozbe/watermelondb"
import { BelongsToAssociation, HasManyAssociation } from "@nozbe/watermelondb/Model"
import { children, field, relation, writer } from "@nozbe/watermelondb/decorators"
import { dispatchConnectionDeleted } from "../../../types"
import { CONNECTION_PROPERTIES, CONNECTIONS, ITEMS, MENTIONS } from "../schema"
import { itemService } from "../services/itemService"
import { ConnectionPropertyModel } from "./ConnectionPropertyModel"
import { ItemModel } from "./ItemModel"
import { MentionModel } from "./MentionModel"

export class ConnectionModel extends Model {
    static table = CONNECTIONS

    static associations = {
        property: { type: "belongs_to", key: "property_id" } as BelongsToAssociation,
        mentions: { type: "has_many", foreignKey: "connection_id" } as HasManyAssociation,
    }

    @field("is_saved") isSaved: boolean
    @relation(ITEMS, "from_id") from: Relation<ItemModel>
    @relation(ITEMS, "to_id") to: Relation<ItemModel>
    @relation(CONNECTION_PROPERTIES, "property_id") property: Relation<ConnectionPropertyModel>

    @children(MENTIONS) textMentions: Query<MentionModel>

    prepareSave = () => {
        if (this.isSaved === false)
            return this.prepareUpdate((record: ConnectionModel) => {
                record.isSaved = true
            })

        return null
    }

    @writer async deleteWithStaleItem(dispatchEvent = true) {
        const tasks = await this.prepareDeleteWithStaleItem(dispatchEvent)
        await this.batch(...tasks)
    }

    prepareDeleteWithStaleItem = async (dispatchEvent = true) => {
        let tasks: any[] = []

        const task = this.prepareDelete(dispatchEvent)

        try {
            const toItem = await this.to.fetch()
            const count = await toItem.mentions.count
            const contentType = await itemService.getContentType(toItem)

            if (
                contentType === "entity" &&
                count <= 1 &&
                toItem._preparedState !== "markAsDeleted" &&
                toItem._preparedState !== "destroyPermanently"
            )
                tasks = await toItem.prepareDelete()
        } catch {}

        const textMentions = await this.textMentions.fetch()

        for (const mention of textMentions) {
            tasks.push(mention.prepareDelete())
        }

        tasks.push(task)
        return tasks
    }

    prepareDelete = (dispatchEvent = true) => {
        if (this._preparedState === null) {
            if (dispatchEvent === true) {
                dispatchConnectionDeleted(this.id)
            }

            if (this.isSaved) {
                return this.prepareMarkAsDeleted()
            } else {
                return this.prepareDestroyPermanently()
            }
        }
    }

    @writer async delete(dispatchEvent = true) {
        const task = this.prepareDelete(dispatchEvent)

        if (!task) return

        await this.batch(task)
    }
}
