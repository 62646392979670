import { useMemo } from "react"
import { useHistory, useLocation } from "react-router-dom"

export const useQueryParameter = (queryParam: string) => {
    const history = useHistory()
    let location = useLocation()

    const queryParams = new URLSearchParams(location.search)
    const isPresent = queryParams.has(queryParam)

    const add = (value: string) => {
        queryParams.set(queryParam, value)
        history.replace({ search: queryParams.toString() })
    }

    const remove = () => {
        queryParams.delete(queryParam)
        history.replace({ search: queryParams.toString() })
    }

    return useMemo(
        () => ({
            isPresent,
            param: queryParams.get(queryParam),
            add,
            remove,
        }),
        // eslint-disable-next-line
        [isPresent, queryParam]
    )
}
