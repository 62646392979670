import { Box, SxProps, Theme } from "@mui/material"
import React, { useRef, useState } from "react"
import { PlaceHolderImage } from "./PlaceHolderImage"

interface Props {
    image: string
    name: string
    isPdf?: boolean
}

const VERY_WIDE_ASPECT_RATIO = 3

export const GridViewImage: React.FC<React.PropsWithChildren<Props>> = ({ image, name, isPdf }) => {
    const [showPlaceholder, setShowPlaceholder] = useState(false)
    const [isPortrait, setIsPortrait] = useState(false)
    const [aspectRatio, setAspectRatio] = useState(null)
    const imgRef = useRef<HTMLImageElement>(null)

    const handleImageLoad = () => {
        if (imgRef.current) {
            const { naturalWidth, naturalHeight } = imgRef.current
            const ratio = naturalWidth / naturalHeight
            setIsPortrait(naturalHeight > naturalWidth)
            setAspectRatio(ratio)
        }
    }

    if (!image || showPlaceholder)
        return (
            <Box pt={1} px={1} mb={0.5}>
                <Box sx={styles.container}>
                    <PlaceHolderImage sx={styles.image} isPdf={isPdf} />
                </Box>
            </Box>
        )

    const isVeryWide = aspectRatio && aspectRatio >= VERY_WIDE_ASPECT_RATIO

    return (
        <Box pt={1} px={1}>
            <Box sx={styles.container}>
                <Box
                    ref={imgRef}
                    component="img"
                    alt={name}
                    sx={{
                        ...styles.image,
                        objectFit: isPortrait || isVeryWide ? "contain" : "cover",
                    }}
                    src={image}
                    onLoad={handleImageLoad}
                    onError={() => {
                        setShowPlaceholder(true)
                    }}
                />
            </Box>
        </Box>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    image: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        objectFit: "cover",
        mb: 0.5,
        borderRadius: 0.6,
        backgroundColor: (theme) => theme.palette.action.hover,
    },
    container: {
        pb: "56.25%",
        position: "relative",
    },
}
