import { Model, Relation } from "@nozbe/watermelondb"
import { BelongsToAssociation } from "@nozbe/watermelondb/Model"
import { field, json, relation, writer } from "@nozbe/watermelondb/decorators"
import { dispatchConnectionDeleted } from "../../../types"
import { CONNECTIONS, ITEMS, MENTIONS } from "../schema"
import { ConnectionModel } from "./ConnectionModel"
import { ItemModel } from "./ItemModel"

interface TextBlock {
    text: string
    isHighlighted?: boolean
    connectionId?: string
}

export interface MentionSentence {
    textBlocks: TextBlock[]
    elementId: string
    isReaderMention?: boolean
}

const sanitizeMentionBlocks = (data: any) => {
    return data
}

export class MentionModel extends Model {
    static table = MENTIONS

    static associations = {
        connection: { type: "belongs_to", key: "connection_id" } as BelongsToAssociation,
        item: { type: "belongs_to", key: "item_id" } as BelongsToAssociation,
    }

    @field("is_saved") isSaved: boolean
    @field("editor_block_id") editorBlockId: string
    @json("blocks", sanitizeMentionBlocks) blocks: TextBlock[]
    @field("element_id") elementId: string
    @relation(ITEMS, "item_id") item: Relation<ItemModel>
    @relation(CONNECTIONS, "connection_id") connection: Relation<ConnectionModel>

    prepareSave = () => {
        if (this.isSaved === false)
            return this.prepareUpdate((record: MentionModel) => {
                record.isSaved = true
            })

        return null
    }

    prepareDelete = (dispatchEvent = true) => {
        if (this._preparedState === null) {
            if (dispatchEvent === true) {
                dispatchConnectionDeleted(this.id)
            }

            if (this.isSaved) {
                return this.prepareMarkAsDeleted()
            } else {
                return this.prepareDestroyPermanently()
            }
        }
    }

    @writer async delete(dispatchEvent = true) {
        const task = this.prepareDelete(dispatchEvent)

        if (!task) return

        await this.batch(task)
    }
}
