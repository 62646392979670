const formatLink = (url: string, text: string) => {
    return `[${text}](${url})`
}

const formatImage = (url: string, text?: string) => {
    return `![${text ?? ""}](${url})\n`
}

const formatHeading = (text: string, level: number) => {
    return `\n${"#".repeat(level)} ${text}\n`
}

const removeImages = (markdown: string) => {
    return markdown.replace(/!\[.*?\]\(.*?\)/g, "")
}

const removeLinks = (markdown: string) => {
    return markdown.replace(/\[(.*?)\]\(.*?\)/g, "$1")
}

const removeInlineFormatting = (markdown: string) => {
    return markdown.replace(/(\*\*|__|\*|_)/g, "")
}

const removeTimestamps = (markdown: string) => {
    return markdown.replace(/\(\d{2}:\d{2}:\d{2}\)/g, "")
}

const stripMarkdownFormatting = (markdown: string) => {
    let result = markdown
    result = removeImages(result)
    result = removeLinks(result)
    result = removeInlineFormatting(result)
    result = removeTimestamps(result)
    result = result.trim()
    return result
}

export const markdownService = {
    formatLink,
    formatImage,
    formatHeading,
    removeImages,
    removeLinks,
    removeInlineFormatting,
    removeTimestamps,
    stripMarkdownFormatting,
}
