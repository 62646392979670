import { Database } from "@nozbe/watermelondb"
import {
    AIAction,
    Chunk,
    conciseSummaryAction,
    Content,
    contentService,
    convertMarkdownToEditorBlocks,
    DEFAULT_LANGUAGE,
    editorBlockRepository,
    ELEMENT_AI_ACTION,
    getSlugAndLanguage,
    GoogleDocsService,
    handleCreate,
    isWikipediaUrl,
    isYoutubeVideo,
    ItemModel,
    itemRepository,
    RERENDER_EDITOR_BLOCKS_EVENT,
    SummaryLengthEnum,
    youtubeTranscriptService,
} from "@recall/common"
import { ELEMENT_PARAGRAPH } from "@udecode/plate-paragraph"
import { scraperApi, summariesApi, tagsApi } from "services/api"
import { database } from "storage/watermelon/database"
import { v4 as uuid } from "uuid"
import { summaryService } from "./summaryService"

export interface Item {
    item: ItemModel
    chunks: Chunk[]
    markdown: string
}

export interface CreateTagProps {
    db: Database
    itemId: string
    text: string
    name: string
    url?: string
    isImporting?: boolean
}

const parseContent = async (
    url: string,
    language = DEFAULT_LANGUAGE,
    file?: File | null
): Promise<Content> => {
    if (url && isYoutubeVideo(url)) {
        const html = await scraperApi.scrapeYoutube(url)
        if (!html) return null
        const transcript = await youtubeTranscriptService.fetchTranscript(html, language)
        if (!transcript)
            throw new Error(
                "Unfortunately, this type of content (YouTube without a transcript) isn't supported at the moment."
            )

        return contentService.parseYoutubeVideo(url, html, transcript, language)
    } else if (url && isWikipediaUrl(url)) {
        const { lang, slug } = getSlugAndLanguage(url)
        if (!slug) return null
        const response = await summariesApi.summarizeWikipediaPage(slug, lang)
        if (!response) return null
        const image = response?.images?.[0]?.urlOriginal || ""

        return {
            markdown: response.markdown,
            links: response.links,
            name: response.name,
            image,
            description: "",
            isReadable: false,
        }
    } else if (GoogleDocsService.isGoogleDoc(url)) {
        const fileResponse = await GoogleDocsService.getExportedDoc(url)

        if (!fileResponse) {
            throw new Error(
                "This Google Doc is private. Please either share it with 'Anyone with the link' or use the Recall browser extension to save it."
            )
        }

        const file = new File([fileResponse], url, {
            type: "application/pdf",
        })

        const formData = new FormData()
        formData.append("file", file)
        const response = await scraperApi.scrapePdf(formData)

        if (!response) return null

        return {
            name: url,
            description: "",
            image: "",
            markdown: response.text,
            isReadable: true,
            pdfFile: file,
        }
    } else if (file) {
        const name = file.name.replace(".pdf", "")
        const formData = new FormData()
        formData.append("file", file)
        const response = await scraperApi.scrapePdf(formData)
        if (!response) return null

        if (!response.text) {
            throw new Error(
                "Unfortunately we can't process this PDF format. While we support most PDFs, this format is not yet supported."
            )
        }

        return {
            name,
            description: "",
            image: "",
            markdown: response.text,
            isReadable: true,
        }
    } else {
        const response = await scraperApi.scrape(url)

        if (response?.pdfFile) {
            const formData = new FormData()
            formData.append("file", response.pdfFile)
            const pdfResponse = await scraperApi.scrapePdf(formData)

            return {
                name: response.pdfFile.name,
                description: "",
                image: "",
                markdown: pdfResponse.text,
                isReadable: true,
                pdfFile: response.pdfFile,
            }
        }

        if (!response?.html) return null
        const doc = new DOMParser().parseFromString(response.html, "text/html")
        return contentService.parseDOM(doc, url)
    }
}

const createTag = async ({
    db,
    itemId,
    text,
    name,
    url,
    isImporting,
}: CreateTagProps): Promise<void> => {
    const response = await tagsApi.generateTag(text, name, itemId, url, isImporting)
    if (!response || !response.tag) return null
    await handleCreate(db, itemId, response.tag)
}

const getItem = async (id: string, url: string): Promise<ItemModel> => {
    let item = await itemRepository.get(database, id)

    if (!item && url)
        item = await itemRepository.getBySource({ db: database, sourceIdentifier: url })
    if (!item) return null

    return item
}

const executeAIAction = async (
    db: Database,
    item: ItemModel,
    md: string,
    action: AIAction,
    language: string
): Promise<string | null> => {
    const editorBlocks = await editorBlockRepository.createBatch(
        db,
        item.id,
        [
            {
                type: ELEMENT_AI_ACTION,
                id: uuid(),
                action,
                isLoading: true,
                isExpanded: true,
                children: [{ text: "" }],
            },
            {
                type: ELEMENT_PARAGRAPH,
                id: uuid(),
                children: [{ text: "" }],
            },
        ],
        item.isSaved
    )

    if (!editorBlocks) return null

    const editorBlock = editorBlocks[0]

    const markdown = await summaryService.summarize(
        item,
        md,
        action.id === conciseSummaryAction.id
            ? SummaryLengthEnum.concise
            : SummaryLengthEnum.detailed,
        language
    )

    if (markdown) {
        const summaryEditorBlocks = convertMarkdownToEditorBlocks(markdown)

        await db.write(async () => {
            await editorBlock.update((record) => {
                record.children = summaryEditorBlocks
                record.options = { isLoading: false, action, isExpanded: true }
            })
        })
    } else {
        await editorBlockRepository.remove(db, editorBlock.id)
    }

    document.dispatchEvent(new CustomEvent(RERENDER_EDITOR_BLOCKS_EVENT))

    return markdown
}

export const itemCreatorService = {
    getItem,
    parseContent,
    createTag,
    executeAIAction,
}
