import { Database } from "@nozbe/watermelondb"

import { ELEMENT_BLOCKQUOTE } from "@udecode/plate-block-quote"
import { ELEMENT_CODE_BLOCK, ELEMENT_CODE_LINE } from "@udecode/plate-code-block"
import {
    ELEMENT_H1,
    ELEMENT_H2,
    ELEMENT_H3,
    ELEMENT_H4,
    ELEMENT_H5,
    ELEMENT_H6,
} from "@udecode/plate-heading"
import { ELEMENT_HR } from "@udecode/plate-horizontal-rule"
import { ELEMENT_LINK } from "@udecode/plate-link"
import { ELEMENT_LI, ELEMENT_OL, ELEMENT_UL } from "@udecode/plate-list"
import { ELEMENT_IMAGE } from "@udecode/plate-media"
import { ELEMENT_PARAGRAPH } from "@udecode/plate-paragraph"
import { WEBSITE } from "../../constants"
import { ELEMENT_AI_ACTION } from "../../editor/plugins/ai-action"
import { ELEMENT_CUSTOM_IMAGE } from "../../editor/plugins/image"
import { ELEMENT_CUSTOM_PARAGRAPH } from "../../editor/plugins/paragraph"
import { YOUTUBE_TIMESTAMP } from "../../editor/plugins/youtube-timestamp"
import { connectionRepository } from "../../storage/watermelon/repository/connectionRepository"
import { sentry } from "../../utils/sentry"
import {
    EDITOR_BLOCK_TYPE,
    HEADING_FIVE_TYPE,
    HEADING_FOUR_TYPE,
    HEADING_ONE_TYPE,
    HEADING_SIX_TYPE,
    HEADING_THREE_TYPE,
    HEADING_TWO_TYPE,
    LINK_TYPE,
    REFERENCE_TYPE,
} from "../editorData"
import { markdownService } from "../markdownService"

// The following code used this as a reference: https://github.com/hanford/remark-slate/blob/master/src/serialize.ts
// The code is modified to fit our needs

const ITALIC_FORMAT = "*"
const BOLD_FORMAT = "**"
const BOLD_ITALIC_FORMAT = "***"

export interface SerializationOptions {
    useHyperlinks: boolean
}

const serialize = async (
    blocks: any,
    db: Database,
    options?: SerializationOptions
): Promise<string> => {
    let exportTexted = ""

    for (const block of blocks) {
        const serializedBlock = await serializeBlock(block, db, options)
        exportTexted += serializedBlock
    }

    return exportTexted
}

const serializeBlock = async (
    block: any,
    db: Database,
    options?: SerializationOptions
): Promise<string> => {
    let blockText = ""

    if ([EDITOR_BLOCK_TYPE, ELEMENT_UL, ELEMENT_OL, ELEMENT_AI_ACTION].includes(block.type)) {
        for (const child of block.children) {
            if (
                [EDITOR_BLOCK_TYPE, ELEMENT_UL, ELEMENT_OL, ELEMENT_AI_ACTION].includes(child.type)
            ) {
                blockText += await serializeBlock(child, db)
            } else if (child.type) {
                blockText += await serializeBlockChild(child, db, options)
            } else {
                blockText += await formatText(child)
            }
        }
    } else {
        blockText += await serializeBlockChild(block, db, options)
    }

    return blockText.endsWith("\n") ? blockText : blockText + "\n\n"
}

const serializeBlockChild = async (
    child: any,
    db: Database,
    options?: SerializationOptions
): Promise<string> => {
    switch (child.type) {
        case ELEMENT_CODE_LINE:
        case ELEMENT_PARAGRAPH:
        case ELEMENT_CUSTOM_PARAGRAPH: {
            if (!child.listStyleType) {
                const paragraphText = await serializeParagraph(child, db)
                return paragraphText
            } else {
                return await serializeBlockChild({ ...child, type: ELEMENT_LI }, db, options)
            }
        }
        case ELEMENT_CUSTOM_IMAGE:
        case ELEMENT_IMAGE: {
            const image = formatImage(child)
            return image
        }
        case ELEMENT_H6:
        case HEADING_SIX_TYPE: {
            const heading = await formatHeading(child, 6, db, options)
            return heading
        }
        case ELEMENT_H5:
        case HEADING_FIVE_TYPE: {
            const heading = await formatHeading(child, 5, db, options)
            return heading
        }
        case ELEMENT_H4:
        case HEADING_FOUR_TYPE: {
            const heading = await formatHeading(child, 4, db, options)
            return heading
        }
        case ELEMENT_H3:
        case HEADING_THREE_TYPE: {
            const heading = await formatHeading(child, 3, db, options)
            return heading
        }
        case ELEMENT_H2:
        case HEADING_TWO_TYPE: {
            const heading = await formatHeading(child, 2, db, options)
            return heading
        }
        case ELEMENT_H1:
        case HEADING_ONE_TYPE: {
            const heading = await formatHeading(child, 1, db, options)
            return heading
        }
        case REFERENCE_TYPE: {
            const reference = await formatReference(child, db, options)
            return reference
        }
        case ELEMENT_CODE_BLOCK: {
            if (!child?.children) return ""
            const codeBlocks = await Promise.all(
                child.children.map((child: any) => serializeBlockChild(child, db, options))
            )

            return "```\n" + codeBlocks.join("") + "\n```"
        }
        case ELEMENT_LI: {
            let value = ""
            if (child.children) {
                for (const childElement of child.children) {
                    if (childElement.type) {
                        value += await serializeBlockChild(childElement, db, options)
                    } else if (childElement.text) {
                        value += await formatText(childElement)
                    }
                }
            } else {
                value = await serializeParagraph(child, db)
            }

            if (!value) return ""

            const isOrderedList = child?.listStyleType === "decimal"
            let indent = child?.indent || 1

            if (isOrderedList) {
                return formatOrderedListItem(value, child?.listStart || 1, indent)
            } else {
                return formatListItem(value, indent)
            }
        }
        case ELEMENT_LINK: {
            return formatLink(child)
        }
        case ELEMENT_HR: {
            return "\n\n---\n\n"
        }
        case YOUTUBE_TIMESTAMP: {
            const timestamp = child?.children?.[0]?.text
            const url = child.url

            if (!url || !timestamp) return ""

            return `[${timestamp}](${url})`
        }
        case ELEMENT_BLOCKQUOTE: {
            let blockquoteText = ""
            for (const childElement of child.children) {
                if (childElement.type) {
                    blockquoteText += await serializeBlockChild(childElement, db, options)
                } else {
                    blockquoteText += await formatText(childElement)
                }
            }
            return formatBlockquote(blockquoteText)
        }
        default:
            sentry.captureMessage(`Unknown block type: ${JSON.stringify(child)}`)
    }

    return ""
}

const serializeParagraph = async (paragraph: any, db: Database): Promise<string> => {
    let paragraphText = ""

    for (const element of paragraph?.children || []) {
        if ("type" in element) {
            if (element.type === REFERENCE_TYPE) {
                const reference = await formatReference(element, db)
                paragraphText += reference
            }

            if (element.type === LINK_TYPE) {
                paragraphText += formatLink(element)
            }
        } else {
            paragraphText += formatText(element)
        }
    }

    return paragraphText
}

const reverseString = (string: string): string => {
    return string.split("").reverse().join("")
}

// This function handles the case of a string like this: "   foo   "
// Where it would be invalid markdown to generate this: "**   foo   **"
// We instead, want to trim the whitespace out, apply formatting, and then
// bring the whitespace back. So our returned string looks like this: "   **foo**   "
const formatText = (textNode: any) => {
    const text = textNode.text
    let format = ""

    if (textNode.italic && textNode.bold) {
        format = BOLD_ITALIC_FORMAT
    } else if (textNode.italic) {
        format = ITALIC_FORMAT
    } else if (textNode.bold) {
        format = BOLD_FORMAT
    } else {
        return text
    }

    const trimmedText = text.trim()

    // We reverse the right side formatting, to properly handle bold/italic/strikethrough
    // formats, so we can create ~~***FooBar***~~
    const formattedTrimmedText = `${format}${trimmedText}${reverseString(format)}`

    // This conditions accounts for no whitespace in our string
    // if we don't have any, we can return early.
    if (trimmedText.length === text.length) {
        return formattedTrimmedText
    }

    // if we do have whitespace replace the non-whitespace content of the original string with the formatted text.
    return text.replace(trimmedText, formattedTrimmedText)
}

const formatReference = async (reference: any, db: Database, options?: SerializationOptions) => {
    const connection = await connectionRepository.get(db, reference.connectionId)
    if (!connection) return ""

    const item = await connection.to.fetch()
    const name = item.name

    if (options?.useHyperlinks) {
        const source = await item.getSource(WEBSITE)

        if (!source) return name

        return `[${name}](${source.identifier})`
    }

    const displayName = reference.children.map((unit: any) => unit.text).join("")
    if (name === displayName) {
        return `[[${name}]]`
    } else {
        return `[[${name} | ${displayName}]]`
    }
}

const formatListItem = (value: string, indent: number) => {
    return `${" ".repeat((indent - 1) * 4)}- ${value.trim()}\n`
}

const formatOrderedListItem = (value: string, counter: number, indent: number) => {
    return `${" ".repeat((indent - 1) * 4)}${counter}. ${value.trim()}\n`
}

const formatLink = (link: any) => {
    return markdownService.formatLink(link.url, link.children[0].text)
}

const formatImage = (img: any) => {
    return markdownService.formatImage(img.urlOriginal || img?.options?.url)
}

const formatHeading = async (
    heading: any,
    level: number,
    db: Database,
    options?: SerializationOptions
) => {
    const text = await Promise.all(
        heading.children.map(async (child: any) => {
            if (child.type) {
                return await serializeBlockChild(child, db, options)
            } else {
                return child.text
            }
        })
    )

    return markdownService.formatHeading(text.join(""), level)
}

const formatBlockquote = (text: string) => {
    return (
        text
            .split("\n")
            .map((line) => `> ${line}`)
            .join("\n") + "\n\n"
    )
}

export const markdownSerializer = { serialize }
