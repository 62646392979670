import { Box, Skeleton } from "@mui/material"
import { useDatabase } from "@nozbe/watermelondb/react"
import { handleCreate, handleDetach, TagInput, tagRepository } from "@recall/common"
import { useItemContext } from "components/ItemPage/providers/ItemProvider"
import { useItemTags } from "components/shared/tags/hooks/useItemTags"
import { useTagsWithPaths } from "components/shared/tags/hooks/useTagsWithPaths"
import { FC, useEffect } from "react"

interface Props {
    readOnly?: boolean
}

export const Tags: FC<Props> = ({ readOnly = false }) => {
    const { item, isCreatingTag } = useItemContext()
    const db = useDatabase()
    const { allTags, getAllTags } = useTagsWithPaths()
    const { itemTags, getItemTags } = useItemTags(item)

    useEffect(() => {
        if (isCreatingTag) return

        const subscription = tagRepository.observeCount(db).subscribe(() => {
            getAllTags()
            getItemTags()
        })

        return () => {
            subscription.unsubscribe()
        }
        // eslint-disable-next-line
    }, [isCreatingTag])

    if (isCreatingTag)
        return (
            <Box display="flex" gap={2}>
                <Skeleton variant="rounded" height={25} width={140} />
                <Skeleton variant="rounded" height={25} width={90} />
            </Box>
        )

    return (
        <TagInput
            readOnly={readOnly}
            itemId={item.id}
            allTags={allTags}
            getAllTags={getAllTags}
            itemTags={itemTags}
            getItemTags={getItemTags}
            detach={(tagId: string, itemId: string) => handleDetach(db, tagId, itemId)}
            create={(itemId: string, tag: string) => handleCreate(db, itemId, tag)}
        />
    )
}
