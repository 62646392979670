import { deleteDoc, doc, getDoc, setDoc, updateDoc } from "firebase/firestore"
import { FIRESTORE_COLLECTIONS } from "../constants"
import { firebase } from "../services"
import { FirebaseError } from "firebase/app"

interface UpsertParams {
    id: string
    nextReviewTime: Date
    question: string
    item: {
        name: string
        url?: string
    }
}

const update = async (params: Partial<UpsertParams> & { id: string }) => {
    await updateDoc(doc(firebase.firestore, FIRESTORE_COLLECTIONS.REVIEWS_SCHEDULE, params.id), {
        ...params,
    })
}

const upsert = async (uid: string, params: UpsertParams) => {
    await setDoc(doc(firebase.firestore, FIRESTORE_COLLECTIONS.REVIEWS_SCHEDULE, params.id), {
        uid,
        ...params,
    })
}

const remove = async (id: string) => {
    try {
        const docRef = doc(firebase.firestore, FIRESTORE_COLLECTIONS.REVIEWS_SCHEDULE, id)
        const document = await getDoc(docRef)
        if (document.exists()) {
            await deleteDoc(docRef)
        }
    } catch {
        // FirebaseError insufficient permission is thrown when the document is not found. This will happen for old questions
        // that were created before the reviews schedule was implemented.
    }
}

export const reviewsScheduleRepository = {
    upsert,
    update,
    remove,
}
