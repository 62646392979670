import { Box, Fade, Skeleton } from "@mui/material"

export const EditorSkeleton = () => {
    return (
        <Fade in={true} timeout={{ enter: 400, exit: 0 }}>
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Skeleton variant="text" width="60%" sx={{ fontSize: "1.4rem" }} />
                <Skeleton variant="rectangular" width="100%" height={100} />
                <Skeleton variant="text" width="70%" sx={{ fontSize: "1.4rem", mt: 1 }} />
                <Skeleton variant="rectangular" width="100%" height={90} />
                <Skeleton variant="text" width="55%" sx={{ fontSize: "1.4rem", mt: 1 }} />
                <Skeleton variant="rectangular" width="100%" height={120} />
                <Skeleton variant="text" width="65%" sx={{ fontSize: "1.4rem", mt: 1 }} />
                <Skeleton variant="rectangular" width="100%" height={130} />
            </Box>
        </Fade>
    )
}
