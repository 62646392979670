import { appSchema, tableSchema } from "@nozbe/watermelondb"

export const ITEMS = "items"
export const EDITOR_ORDERS = "editor_orders"
export const EDITOR_BLOCKS = "editor_blocks"
export const TYPES = "types"
export const SOURCES = "sources"
export const CONNECTIONS = "connections"
export const CONNECTION_PROPERTIES = "connection_properties"
export const TAGS = "tags"
export const ITEM_TAG = "item_tag"
export const ITEM_REVIEWS = "item_reviews"
export const QUESTIONS = "review_questions"
export const QUESTION_REVIEWS = "question_reviews"
export const ASSETS = "assets"
export const DATA_MIGRATIONS = "data_migrations"
export const MENTIONS = "mentions"

export const COLLECTIONS = [
    ITEMS,
    EDITOR_ORDERS,
    EDITOR_BLOCKS,
    TYPES,
    SOURCES,
    CONNECTIONS,
    CONNECTION_PROPERTIES,
    TAGS,
    ITEM_TAG,
    QUESTIONS,
    DATA_MIGRATIONS,
    QUESTION_REVIEWS,
    ASSETS,
    MENTIONS,
]

export const schema = appSchema({
    version: 37,
    tables: [
        tableSchema({
            name: ITEMS,
            columns: [
                { name: "name", type: "string", isIndexed: true },
                { name: "type_id", type: "string", isIndexed: true },
                { name: "created_at", type: "number" },
                { name: "updated_at", type: "number" },
                { name: "is_reference", type: "boolean" },
                { name: "is_hidden_in_rab", type: "boolean", isIndexed: true },
                { name: "active_source_name", type: "string" },
                { name: "is_saved", type: "boolean" },
                { name: "is_expanded", type: "boolean", isOptional: true },
                { name: "language", type: "string", isOptional: true },
                { name: "description", type: "string" },
                { name: "image", type: "string" },
                { name: "length", type: "string" },
                { name: "aliases", type: "string", isIndexed: true },
                { name: "is_readable", type: "boolean", isOptional: true, isIndexed: true },
                { name: "is_loading", type: "boolean", isOptional: true },
            ],
        }),
        tableSchema({
            name: EDITOR_ORDERS,
            columns: [
                { name: "order", type: "string" },
                { name: "item_id", type: "string", isIndexed: true },
                { name: "is_saved", type: "boolean" },
            ],
        }),
        tableSchema({
            name: EDITOR_BLOCKS,
            columns: [
                { name: "children", type: "string" },
                { name: "item_id", type: "string", isIndexed: true },
                { name: "is_saved", type: "boolean" },
                { name: "type", type: "string" },
                { name: "options", type: "string" },
                { name: "text", type: "string" },
            ],
        }),
        tableSchema({
            name: TYPES,
            columns: [
                { name: "name", type: "string", isIndexed: true },
                { name: "display", type: "string" },
                { name: "plural_display", type: "string", isOptional: true },
                { name: "user_created", type: "boolean" },
                { name: "wikidata_id", type: "string", isOptional: true },
                { name: "parent_id", type: "string", isIndexed: true, isOptional: true },
                { name: "is_saved", type: "boolean" },
            ],
        }),
        tableSchema({
            name: SOURCES,
            columns: [
                { name: "item_id", type: "string", isIndexed: true },
                { name: "identifier", type: "string" },
                { name: "name", type: "string" },
                { name: "is_saved", type: "boolean" },
            ],
        }),
        tableSchema({
            name: CONNECTIONS,
            columns: [
                { name: "to_id", type: "string", isIndexed: true },
                { name: "from_id", type: "string", isIndexed: true },
                { name: "property_id", type: "string", isIndexed: true, isOptional: true },
                { name: "is_saved", type: "boolean" },
            ],
        }),
        tableSchema({
            name: CONNECTION_PROPERTIES,
            columns: [
                { name: "name", type: "string" },
                { name: "display", type: "string" },
                { name: "description", type: "string", isOptional: true },
                { name: "wikidata_pid", type: "string", isOptional: true },
                { name: "is_saved", type: "boolean" },
            ],
        }),
        tableSchema({
            name: TAGS,
            columns: [
                { name: "name", type: "string", isIndexed: true },
                { name: "is_saved", type: "boolean" },
                { name: "children", type: "string" },
                { name: "is_reference", type: "boolean" },
                { name: "parent_id", type: "string", isIndexed: true },
                { name: "created_at", type: "number" },
                { name: "updated_at", type: "number" },
            ],
        }),
        tableSchema({
            name: ITEM_TAG,
            columns: [
                { name: "tag_id", type: "string", isIndexed: true },
                { name: "item_id", type: "string", isIndexed: true },
                { name: "is_saved", type: "boolean" },
                { name: "created_at", type: "number" },
            ],
        }),
        tableSchema({
            name: QUESTION_REVIEWS,
            columns: [
                { name: "item_id", type: "string", isIndexed: true },
                { name: "question_id", type: "string", isIndexed: true },
                { name: "is_saved", type: "boolean" },
                { name: "question", type: "string" },
                { name: "answer", type: "string" },
                { name: "options", type: "string" },
                { name: "correct_answer", type: "string" },
                { name: "is_correct", type: "boolean" },
                { name: "created_at", type: "number" },
                { name: "updated_at", type: "number" },
            ],
        }),
        tableSchema({
            name: QUESTIONS,
            columns: [
                { name: "item_id", type: "string", isIndexed: true },
                { name: "is_saved", type: "boolean" },
                { name: "question", type: "string" },
                { name: "answer", type: "string" },
                { name: "correct_answer", type: "string" },
                { name: "options", type: "string" },
                { name: "created_at", type: "number" },
                { name: "updated_at", type: "number" },
                { name: "next_review_time", type: "number" },
            ],
        }),
        tableSchema({
            name: DATA_MIGRATIONS,
            columns: [
                { name: "name", type: "string", isIndexed: true },
                { name: "created_at", type: "number" },
                { name: "updated_at", type: "number" },
                { name: "is_saved", type: "boolean" },
            ],
        }),
        tableSchema({
            name: ASSETS,
            columns: [
                { name: "url", type: "string" },
                { name: "size", type: "number" },
                { name: "type", type: "string" },
                { name: "item_id", type: "string", isIndexed: true },
                { name: "is_saved", type: "boolean" },
                { name: "created_at", type: "number" },
            ],
        }),
        tableSchema({
            name: MENTIONS,
            columns: [
                { name: "blocks", type: "string" },
                { name: "element_id", type: "string" },
                { name: "is_saved", type: "boolean" },
                { name: "item_id", type: "string", isIndexed: true },
                { name: "editor_block_id", type: "string", isIndexed: true },
                { name: "connection_id", type: "string", isIndexed: true },
            ],
        }),
    ],
})
