import { Box } from "@mui/material"
import { FC } from "react"
import { ReferenceItem } from "../../../editor/components/ReferenceElement"
import { useHoverDelay } from "../../../hooks/useHoverDelay"
import { useIsMobile } from "../../../hooks/useIsMobile"
import { ItemChip } from "../../chips/ItemChip"
import { ItemPreviewPopper, Options } from "../ItemPreviewPopper"

interface Props {
    item: ReferenceItem
    connectionId: string
    readOnly?: boolean
    handleDelete: (id: string) => Promise<void>
    openItemById: (id: string, options?: Options) => void
}

const HOVER_DELAY = 250

export const Link: FC<Props> = ({
    item,
    connectionId,
    readOnly = false,
    openItemById,
    handleDelete,
}) => {
    const isMobile = useIsMobile()

    const { isOpen, handleMouseEnter, handleMouseLeave } = useHoverDelay(HOVER_DELAY)

    const handleOnClick = () => {
        openItemById(item.id)
    }

    const deleteConnection = async () => {
        handleDelete(connectionId)
    }

    return (
        <Box
            sx={{ px: 0.5, py: 0.3, display: "inline-block" }}
            id={item.id}
            onMouseEnter={isMobile ? undefined : handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <ItemPreviewPopper
                readOnly={readOnly}
                item={item}
                open={isOpen}
                openItemById={openItemById}
                deleteConnection={deleteConnection}
            >
                <ItemChip
                    size="medium"
                    id={item.id}
                    name={item.name}
                    image={item.image}
                    mentionCount={item.mentionsCount}
                    handleClick={isMobile ? handleMouseEnter : handleOnClick}
                />
            </ItemPreviewPopper>
        </Box>
    )
}
