import { Cached, DoneAll } from "@mui/icons-material"
import { alpha, Avatar, Box, Chip, IconButton, keyframes, SxProps, Theme, Tooltip } from "@mui/material"
import { ChatMessage } from "@recall/common"
import { FC, useMemo } from "react"
import Showdown from "showdown"
import { ChatIcon } from "../../icons/ChatIcon"
import { NotebookIcon } from "../../icons/NotebookIcon"
import { Action } from "./Action"

interface Props {
    message: ChatMessage
    isAnswering?: boolean
    handleAddToNotebook: () => void
    handleOpenNotebook: (elementId?: string) => void
    handleRegenerate: (message: ChatMessage) => void
}

export const Message: FC<Props> = ({
    message,
    isAnswering,
    handleAddToNotebook,
    handleOpenNotebook,
    handleRegenerate,
}) => {
    const isAdded = Boolean(message.notebookElementId)

    let html = useMemo(() => {
        const markdownConverter = new Showdown.Converter({
            disableForced4SpacesIndentedSublists: true,
            smartIndentationFix: true,
            openLinksInNewWindow: true,
            extensions: [
                {
                    type: "output",
                    regex: /<a([^>]*)>\((\d{2}:\d{2}:\d{2})\)<\/a>/g,
                    replace: '<a$1 class="timestamp" data-time="$2">($2)</a>',
                },
            ],
        })

        return markdownConverter.makeHtml(message.content)
    }, [message])

    const handleAdd = () => {
        handleAddToNotebook()
    }

    const handleOpen = () => {
        handleOpenNotebook(message.notebookElementId)
    }

    if (message.action) {
        return (
            <Box alignSelf="flex-end" id={message.id} sx={styles.action}>
                <Action label={message.action.name} id={message.action.id} className="action" />
            </Box>
        )
    }

    return (
        <Box
            id={message.id}
            sx={{
                ...styles.container,
                p: message.role === "user" ? 1 : 0,
                ml: message.role === "user" ? 6 : 0,
                mt: message.role === "user" ? 1.5 : 0,
                alignSelf: message.role === "user" ? "flex-end" : "flex-start",
                bgcolor: (theme) =>
                    message.role === "user" ? theme.palette.background.layout : "transparent",
            }}
        >
            <Box display="flex" alignItems={message.role === "user" ? "flex-end" : "flex-start"}>
                {message.role === "assistant" && (
                    <Avatar sx={styles.avatar}>
                        <ChatIcon fontSize="small" />
                    </Avatar>
                )}
                <Box display="flex" flexDirection="column">
                    <Box
                        sx={{
                            ...styles.message,
                            pl: message.role === "assistant" ? 1 : 0,
                            mt: message.role === "assistant" ? 0.5 : 0,
                        }}
                        dangerouslySetInnerHTML={{ __html: html }}
                    />

                    {!message.error && !isAnswering && message.role === "assistant" && (
                        <Box display="flex" alignItems="center" mt={0.5}>
                            <Chip
                                sx={{ alignSelf: "flex-start", pl: 0.5, mr: 1 }}
                                onClick={isAdded ? handleOpen : handleAdd}
                                variant="outlined"
                                size="small"
                                label={isAdded ? "Open in NoteBook" : "Add to NoteBook"}
                                icon={isAdded ? <DoneAll /> : <NotebookIcon />}
                            />
                            <Tooltip title="Regenerate this response">
                                <IconButton onClick={() => handleRegenerate(message)} size="small">
                                    <Cached fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    )}
                    {isAnswering && !message.content && !message.error && <LoadingMessage />}
                </Box>
            </Box>
        </Box>
    )
}

const LoadingMessage = () => {
    return (
        <Box sx={styles.loading}>
            <span>.</span>
            <span>.</span>
            <span>.</span>
        </Box>
    )
}

const loadingAnimation = keyframes`
    0%, 100% {
        opacity: 0.2;
    }
    50% {
        opacity: 1;
    }
`

const styles: Record<string, SxProps<Theme>> = {
    action: {
        "&.focused": {
            ".action": {
                backgroundColor: (theme) => `${theme.palette.primary.main} !important`,
            },
        },
    },
    container: {
        borderRadius: 2,
        fontSize: 14,
        color: (theme) => theme.palette.text.primary,
    },
    avatar: {
        width: 32,
        height: 32,
        backgroundColor: "transparent",
        border: "1px solid",
        borderColor: (theme) => alpha(theme.palette.text.primary, 0.2),
    },
    addButton: {
        ml: 1,
        mt: 0.5,
    },
    loading: {
        display: "flex",
        ml: 1,
        "& span": {
            animation: `${loadingAnimation} 1.5s infinite`,
            fontSize: 24,
            lineHeight: 1,
            "&:nth-of-type(2)": {
                animationDelay: "0.2s",
            },
            "&:nth-of-type(3)": {
                animationDelay: "0.4s",
            },
        },
    },
    message: {
        fontSize: 14,
        flex: 1,
        fontFamily: (theme) => theme.typography.fontFamily,

        "& p": {
            m: 0,
        },
        "& li": {
            mb: 1,
        },
        "& ul, & ol": {
            pl: 2,
            mt: 0.5,
            mb: 0,
        },

        "h1, h2, h3, h4, h5, h6": {
            ":first-child": {
                mt: 0,
            },
        },

        "& .timestamp": {
            cursor: "pointer",
            color: (theme) => alpha(theme.palette.text.primary, 0.5),
            fontStyle: "italic",
            textDecoration: "none",
            fontSize: 14,
            fontWeight: "normal",
            "&:hover": {
                textDecoration: "underline",
                color: "#3366ccee",
            },
        },
    },
}
