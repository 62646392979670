import { conciseSummaryAction, Settings, UserRepository } from "../../repositories/userRepository"

export class SettingsService {
    userRepository: UserRepository

    constructor(userRepository: UserRepository) {
        this.userRepository = userRepository
    }

    getDefaultSettings = (): Settings => {
        return {
            language: "auto",
            searchLanguage: "en",
            defaultAction: conciseSummaryAction,
            isReaderVisible: true,
        }
    }

    get = async (uid: string): Promise<Settings> => {
        const user = await this.userRepository.getUser(uid)

        const defaultOptions = this.getDefaultSettings()

        const mergedOptions = {
            ...defaultOptions,
            ...user?.settings,
        }

        return mergedOptions
    }

    update = async (uid: string, settings: Partial<Settings>) => {
        const oldSettings = await this.get(uid)
        await this.userRepository.upsertUser(uid, {
            settings: { ...oldSettings, ...settings },
        })
    }
}
