import { Box, SxProps, Theme, useTheme } from "@mui/material"
import { useIsDarkMode } from "hooks/useThemeMode"
import { FC, PropsWithChildren } from "react"

interface Props {
    sx?: SxProps<Theme>
    hasGradient?: boolean
}

export const SurfaceBox: FC<PropsWithChildren<Props>> = ({
    children,
    sx = {},
    hasGradient = false,
}) => {
    const theme = useTheme()
    const isDarkMode = useIsDarkMode()
    return (
        <Box
            sx={{
                background:
                    "linear-gradient(180deg, rgba(166, 171, 218, 0.15) 0%, rgba(166, 171, 218, 0.05) 100%)",
                boxShadow: "0px 2px 0px 0px rgba(166, 171, 218, 0.30) inset",

                // backgroundColor: (theme) => alpha(theme.palette.background.paper, 0.5),
                // background: hasGradient
                //     ? `linear-gradient(180deg, ${alpha(theme.palette.text.primary, 0.1)} 0%,${alpha(
                //           theme.palette.text.primary,
                //           0.06
                //       )}  100%)`
                //     : undefined,

                // boxShadow: `0px 1px 1px 0px ${alpha(theme.palette.text.primary, 0.25)} inset`,
                border: isDarkMode ? undefined : `1px solid ${theme.palette.divider}`,
                p: 2,
                borderRadius: 1,
                ...sx,
            }}
        >
            {children}
        </Box>
    )
}
