import { Box, Button, Container, Divider, SxProps, Theme, Typography } from "@mui/material"
import { useDatabase } from "@nozbe/watermelondb/react"
import { ChatIcon, QuestionModel, questionRepository, QuizIcon, Spinner } from "@recall/common"
import { useItemContext } from "components/ItemPage/providers/ItemProvider"
import { Loader } from "components/layouts/components/Loader/AppLoader"
import { SPACED_REPETITION } from "constants/routes"
import { useGenerateQuestions } from "hooks/useGenerateQuestions"
import { orderBy } from "lodash"
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router"
import { isLoadingQuestionsSelector } from "storage/redux/app/selectors"
import { RootState } from "storage/redux/rootReducer"
import { SurfaceBox } from "../surfaces/SurfaceBox"
import { CreateQuestion } from "./CreateQuestion"
import { FormQuestion, QuestionForm } from "./QuestionForm"
import { QuestionsLoader } from "./QuestionsLoader"

interface Props {
    questions: QuestionModel[]
    setQuestions: Dispatch<SetStateAction<QuestionModel[]>>
}

const VISIBLE_QUESTIONS_STEP = 4

export const Questions: FC<Props> = ({ questions, setQuestions }) => {
    const { item, chunks } = useItemContext()
    const isLoadingQuestions = useSelector((state: RootState) =>
        isLoadingQuestionsSelector(state, item.id)
    )
    const [visibleCount, setVisibleCount] = useState(VISIBLE_QUESTIONS_STEP)
    const [isManageQuestionsOpen, setIsManageQuestionsOpen] = useState(false)

    const { handleGenerateMoreQuestions, handleGenerateQuestions, isGenerateFailed } =
        useGenerateQuestions({ chunks, item, questions, setQuestions })

    const db = useDatabase()
    const history = useHistory()

    useEffect(() => {
        if (!isLoadingQuestions && !questions?.length) getQuestions()
        // eslint-disable-next-line
    }, [isLoadingQuestions])

    const getQuestions = async () => {
        const questions = await questionRepository.getQuestionsByItemId(db, item.id)
        setQuestions(orderBy(questions, ["createdAt", "id"]))
    }

    const handleSubmit = async (question: QuestionModel, data: FormQuestion) => {
        await questionRepository.update({
            db,
            question,
            data: { ...data, answer: null },
        })
    }

    const deleteQuestion = async (question: QuestionModel) => {
        setQuestions((prev) => prev.filter((q) => q.id !== question.id))
    }

    const handleIncreaseVisible = () => {
        setVisibleCount((prev) => prev + VISIBLE_QUESTIONS_STEP)
    }

    const handleReviewCard = () => {
        history.push(`${SPACED_REPETITION}?itemId=${item.id}`)
    }

    if (isLoadingQuestions && questions.length === 0) {
        return (
            <Box sx={styles.empty}>
                <Loader loadingText="Generating Questions..." />
            </Box>
        )
    }

    if (!questions.length) {
        return (
            <Container maxWidth="sm" sx={styles.empty}>
                {isGenerateFailed && <Typography mb={2}>Failed to generate questions</Typography>}

                <Box
                    display="flex"
                    gap={1}
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                >
                    <Button
                        disabled={isLoadingQuestions}
                        onClick={handleGenerateQuestions}
                        startIcon={isLoadingQuestions ? <Spinner size={20} /> : <ChatIcon />}
                        color="primary"
                        variant="outlined"
                        sx={styles.button}
                    >
                        {isGenerateFailed ? "Try Again" : "Generate Questions"}
                    </Button>
                    <CreateQuestion
                        disabled={isLoadingQuestions}
                        refetchQuestions={getQuestions}
                        item={item}
                    />
                </Box>
            </Container>
        )
    }

    const visibleQuestions = questions.slice(0, visibleCount)

    return (
        <Container maxWidth="sm" sx={styles.container}>
            <Typography textAlign="center" variant="h5" fontWeight={500} mt={4} mb={1}>
                Manage Card Questions
            </Typography>
            <Typography textAlign="center" variant="body1" mb={1} color="text.secondary">
                You can edit questions, modify answers, and change the correct answers for each
                question.
            </Typography>
            <Box display="flex" flexWrap="wrap" gap={1} justifyContent="center" my={1}>
                <Button
                    variant="outlined"
                    color="primary"
                    sx={styles.button}
                    onClick={() => setIsManageQuestionsOpen((prev) => !prev)}
                >
                    Manage questions
                </Button>
                <Button
                    disabled={isLoadingQuestions}
                    onClick={handleReviewCard}
                    startIcon={<QuizIcon />}
                    sx={styles.button}
                    color="primary"
                    variant="contained"
                >
                    Start Review
                </Button>
            </Box>
            {isManageQuestionsOpen && (
                <Box width="100%">
                    <Divider sx={{ my: 2 }} />
                    <Box display="flex" flexWrap="wrap" gap={1} justifyContent="center">
                        <Button
                            disabled={isLoadingQuestions}
                            onClick={handleGenerateMoreQuestions}
                            startIcon={isLoadingQuestions ? <Spinner size={20} /> : <ChatIcon />}
                            color="primary"
                            variant="outlined"
                            sx={styles.button}
                        >
                            Generate Questions
                        </Button>
                        <CreateQuestion
                            disabled={isLoadingQuestions}
                            refetchQuestions={getQuestions}
                            item={item}
                        />
                    </Box>
                    <Box sx={styles.questions}>
                        {visibleQuestions.map((question) => (
                            <SurfaceBox key={question.id} sx={styles.questionContainer}>
                                <QuestionForm
                                    key={question.id}
                                    handleClose={() => {}}
                                    handleFormSubmit={handleSubmit}
                                    question={question}
                                    deleteQuestion={deleteQuestion}
                                />
                            </SurfaceBox>
                        ))}
                        <QuestionsLoader
                            isLoaded={visibleCount >= questions.length}
                            increaseVisibleQuestions={handleIncreaseVisible}
                        />
                    </Box>
                </Box>
            )}
        </Container>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    container: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        flex: 1,
        pb: 2,
    },
    questions: {
        display: "flex",
        flexDirection: "column",
        gap: 2,
        mt: 3,
        width: "100%",
    },
    questionContainer: {
        px: 2,
        py: 1,
    },
    empty: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        flex: 1,
    },
    button: {
        color: "text.primary",
        "&:not(:disabled) svg": {
            path: {
                fill: (theme) => theme.palette.text.primary,
            },
        },
    },
}
