import { useDatabase } from "@nozbe/watermelondb/react"
import {
    ASSET_TYPES,
    Chunk,
    chunksService,
    Content,
    fileService,
    getItemSourceUrl,
    getSlugAndLanguage,
    isWikipediaUrl,
    ItemModel,
    itemRepository,
    itemService,
    markdownService,
    mentionsRepository,
    notebookService,
    openReaderAction,
    parseUrl,
    RERENDER_EDITOR_BLOCKS_EVENT,
    saveChunks,
    sentry,
    SharedItem,
    UsageLimitReachedError,
    WIKIPEDIA_PAGE,
} from "@recall/common"
import { RERENDER_ITEMS_LIST } from "components/ItemsPage/hooks/useGroupedItems"
import { HOME_PATH } from "constants/routes"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useLocation, useParams } from "react-router"
import { toast } from "react-toastify"
import { actionsApi, entitiesApi, summariesApi } from "services/api"
import { SET_IS_SCRAPING_MODAL_OPEN } from "storage/redux/app/actionTypes"
import { RootState } from "storage/redux/rootReducer"
import { useTabActions } from "../providers/TabProvider"
import { Item, itemCreatorService } from "../services/itemCreatorService"
import { useCanRunAI } from "./useCanRunAI"
import { useFocusTab } from "./useFocusTab"

interface LocationState {
    sourceUrl?: string
    sourcePdf?: File
    sharedItem?: SharedItem
}

export const useItem = () => {
    const [itemState, setItemState] = useState<Item | null>(null)
    const [loadingState, setLoadingState] = useState({
        isCreatingTag: true,
        isCreatingConnections: true,
        isLoadingChat: true,
        isExpanding: false,
    })
    const isInitialSyncFinished = useSelector((state: RootState) => state.app.isInitialSyncFinished)

    const canRunAI = useCanRunAI()

    const uid = useSelector((state: RootState) => state.user.uid)
    const language = useSelector((state: RootState) => state.user?.settings?.language)

    const db = useDatabase()
    const { id } = useParams<{ id: string }>()
    const location = useLocation<LocationState>()
    const history = useHistory()
    const sourceUrl = location.state?.sourceUrl
    const sourcePdf = location.state?.sourcePdf
    const sharedItem = location.state?.sharedItem
    const defaultAction = useSelector((state: RootState) => state.user.settings.defaultAction)

    const dispatch = useDispatch()

    const { setTab } = useTabActions()
    const { focusTab } = useFocusTab()

    useEffect(() => {
        if (!isInitialSyncFinished) return
        resetItemState()
        init()
    }, [id, isInitialSyncFinished])

    useEffect(() => {
        if (!itemState) return

        const subscription = itemState.item.observe().subscribe((item) => {
            setItemState((prev) => ({ ...prev, item }))
        })

        return () => {
            subscription.unsubscribe()
        }
    }, [itemState?.item?.id])

    const resetItemState = () => {
        setItemState(null)
        setTab(null)
        setLoadingState({
            isCreatingTag: true,
            isCreatingConnections: true,
            isExpanding: false,
            isLoadingChat: true,
        })
    }

    const init = async (retry = 0) => {
        try {
            if (sharedItem) return await createSharedItem()

            const item = await itemCreatorService.getItem(id, sourceUrl)

            if (item?.isLoading) {
                if (retry > 3) return history.push(HOME_PATH)
                return setTimeout(() => init(retry + 1), retry * 800)
            }

            if (!item) {
                const isReadyToCreateItem = Boolean(sourceUrl || sourcePdf)

                if (!isReadyToCreateItem) {
                    if (retry > 3) return history.push(HOME_PATH)
                    return setTimeout(() => init(retry + 1), retry * 800)
                }

                return await createItem()
            }

            setItemState({ item, chunks: [], markdown: "" })
            focusTab()
            setLoadingState((prev) => ({
                ...prev,
                isCreatingTag: false,
                isCreatingConnections: false,
            }))

            const chunks = await chunksService.getChunks(uid, item.id)
            const markdown = chunksService.getMarkdown(chunks)
            const itemState = { item, chunks, markdown }

            const needsChunks = await shouldCreateChunks(itemState)
            if (!needsChunks) return setItemState(itemState)

            await createChunks(itemState.item)
        } catch (error) {
            const item = await itemRepository.get(db, id)
            if (item) await updateIsLoading(item, false)
            const message = error?.message || "Something went wrong"
            toast.error(message)
            sentry.captureException(error as Error, { url: sourceUrl })
            history.push(HOME_PATH)
        } finally {
            setLoadingState({
                isCreatingTag: false,
                isCreatingConnections: false,
                isExpanding: false,
                isLoadingChat: false,
            })
        }
    }

    const createEntity = async () => {
        const { lang, slug } = getSlugAndLanguage(sourceUrl)
        if (!slug) throw new Error("Invalid Wikipedia URL")

        const response = await summariesApi.summarizeWikipediaPage(slug, lang)
        if (!response) throw new Error("Failed to fetch Wikipedia page")

        const item = await itemService.create({ db, itemApi: response })
        setItemState({ item, chunks: [], markdown: "" })
        setTab("notebook")
    }

    const createSharedItem = async () => {
        const { item, chunks, markdown } = await itemService.createSharedItem(
            db,
            sharedItem,
            sharedItem.id,
            uid
        )
        setItemState({ item, chunks, markdown })
        selectTab()

        if (!chunks.length) {
            await createChunks(item)
        }

        setLoadingState({
            isCreatingTag: false,
            isCreatingConnections: false,
            isExpanding: false,
            isLoadingChat: false,
        })
        history.replace({ pathname: location.pathname, state: null })
    }

    const shouldCreateChunks = async (itemState: Item) => {
        const contentType = await itemService.getContentType(itemState.item)
        return contentType === "page" && !Boolean(itemState.chunks.length)
    }

    const updateIsLoading = async (item: ItemModel, isLoading: boolean) => {
        await item.updateIsLoading(isLoading)
        document.dispatchEvent(new CustomEvent(RERENDER_ITEMS_LIST))
    }

    const createItem = async () => {
        if (isWikipediaUrl(sourceUrl)) {
            await createEntity()
            return
        }

        const content = await itemCreatorService.parseContent(sourceUrl, language, sourcePdf)
        if (!content) throw new Error("Failed to parse content")

        const url = (sourceUrl && parseUrl(sourceUrl)) || undefined

        const item = await itemService.createBaseItem({
            db,
            id,
            url,
            name: content.name,
            description: content.description,
            image: content.image,
            isSaved: true,
            isReadable: content.isReadable,
            isLoading: true,
        })

        const pdfFile = content.pdfFile || sourcePdf
        if (pdfFile) await fileService.uploadAsset({ item, file: pdfFile, db, uid })

        const chunks = chunksService.chunkMarkdown(content.markdown)
        setItemState({ item, chunks, markdown: chunksService.getMarkdown(chunks) })

        if (!canRunAI) {
            setLoadingState((prev) => ({
                ...prev,
                isCreatingConnections: false,
                isCreatingTag: false,
                isLoadingChat: false,
            }))
            setTab("reader")
            await updateIsLoading(item, false)
            return
        }
        selectTab()

        const hasDefaultAction = defaultAction && defaultAction.id !== openReaderAction.id

        const [_, __, markdown] = await Promise.all([
            createTag(item, content, url),
            createConnections(item, chunks, content, url),
            hasDefaultAction &&
                itemCreatorService.executeAIAction(
                    db,
                    item,
                    content.markdown,
                    defaultAction,
                    language
                ),
        ])

        if (hasDefaultAction) {
            await notebookService.enrichAIActionElement(db, item, markdown, defaultAction)
            document.dispatchEvent(new CustomEvent(RERENDER_EDITOR_BLOCKS_EVENT))
        }

        await updateIsLoading(item, false)
    }

    const selectTab = () => {
        if (defaultAction && defaultAction.id === openReaderAction.id) {
            setTab("reader")
        } else if (defaultAction) {
            setTab("notebook")
        } else {
            setTab("reader")
        }
    }

    const injectConnections = async (item: ItemModel, chunks: Chunk[]) => {
        const connections = await item.links.fetch()
        const linksConnections = await Promise.all(
            connections.map(async (connection) => {
                const link = await connection.to
                return {
                    link,
                    connection,
                }
            })
        )
        const chunksWithLinks = await itemService.createChunksMentions(
            db,
            chunks,
            linksConnections,
            item
        )

        await saveChunks({
            itemId: item.id,
            chunks: chunksWithLinks,
        })
        setItemState((prev) => ({
            ...prev,
            chunks: chunksWithLinks,
        }))
    }

    const createConnections = async (
        item: ItemModel,
        chunks: Chunk[],
        content: Pick<Content, "markdown" | "links">,
        url?: string
    ) => {
        try {
            setLoadingState((prev) => ({
                ...prev,
                isCreatingConnections: true,
            }))

            let entities = []

            const cleanedMarkdown = markdownService.stripMarkdownFormatting(content.markdown)

            try {
                entities =
                    content.links ||
                    (await entitiesApi.extractEntities(cleanedMarkdown, item.id, url))
            } catch {}

            const chunksWithEntities = await itemService.saveReaderConnections(db, item, entities, [
                ...chunks,
            ])

            setItemState((prev) => ({
                ...prev,
                chunks: chunksWithEntities,
            }))
            setLoadingState((prev) => ({
                ...prev,
                isCreatingConnections: false,
            }))
            await chunksService.removeChunks(uid, item.id)
            await saveChunks({
                itemId: item.id,
                chunks: chunksWithEntities,
            })
            setLoadingState((prev) => ({
                ...prev,
                isLoadingChat: false,
            }))

            return entities
        } catch (error) {
            sentry.captureException(error as Error, { url })
            return []
        }
    }

    const createTag = async (item: ItemModel, content: Content, url?: string) => {
        try {
            await itemCreatorService.createTag({
                db,
                itemId: item.id,
                text: content.markdown,
                name: content.name,
                url,
            })
        } catch (error) {
            sentry.captureException(error as Error, { url })
        }
        setLoadingState((prev) => ({
            ...prev,
            isCreatingTag: false,
        }))
    }

    const createChunks = async (item: ItemModel) => {
        const url = await getItemSourceUrl(item)
        let file = null

        if (!url) file = await getPdfFile(item)

        if (!file && !url) {
            sentry.captureException(new Error("Failed to create chunks"), { itemId: item.id })
            return
        }

        const content = await itemCreatorService.parseContent(url, language, file)
        if (!content) {
            sentry.captureException(new Error("Failed to parse content"), { url })
            return null
        }

        const chunks = chunksService.chunkMarkdown(content.markdown)
        setItemState({ item, chunks, markdown: chunksService.getMarkdown(chunks) })
        await item.setIsReadable(content.isReadable)
        await injectConnections(item, chunks)
    }

    const getPdfFile = async (item: ItemModel) => {
        const assets = await item.assets.fetch()
        const pdfAsset = assets.find((asset) => asset.type === ASSET_TYPES.PDF)
        if (!pdfAsset) return

        const response = await fetch(pdfAsset.url)
        const blob = await response.blob()
        const file = new File([blob], "file.pdf", { type: "application/pdf" })
        return file
    }

    const expandItem = async (item: ItemModel) => {
        setLoadingState((prev) => ({
            ...prev,
            isExpanding: true,
        }))
        const sources = await item.sources.fetch()

        const wikipediaSource = sources.find((source) => source.name === WIKIPEDIA_PAGE)
        if (!wikipediaSource) return

        const { lang, slug } = getSlugAndLanguage(wikipediaSource.identifier)
        if (!slug) return

        const response = await summariesApi.summarizeWikipediaPage(slug, lang)

        if (!response) {
            setLoadingState((prev) => ({
                ...prev,
                isExpanding: false,
            }))
            return
        }
        const expandedItem = await itemService.create({ db, itemApi: response })
        await item.merge(expandedItem)

        setLoadingState((prev) => ({
            ...prev,
            isExpanding: false,
        }))

        document.dispatchEvent(new CustomEvent(RERENDER_EDITOR_BLOCKS_EVENT))
    }

    const reformatReader = async () => {
        const url = (sourceUrl && parseUrl(sourceUrl)) || undefined
        setItemState((prev) => ({ ...prev, chunks: [] }))
        if (!itemState) return

        const originalChunks = itemState.chunks
        const itemId = itemState.item.id
        const markdown = originalChunks.map((chunk) => chunk.markdown).join("\n")

        let reformattedMarkdown = ""
        try {
            reformattedMarkdown = await actionsApi.autoFormat(markdown, itemId, url)
        } catch (error) {
            if (error instanceof UsageLimitReachedError) {
                dispatch({ type: SET_IS_SCRAPING_MODAL_OPEN, payload: true })
                setTab("reader")
            }
            setItemState((prev) => ({ ...prev, chunks: originalChunks }))
            return
        }
        await chunksService.removeChunks(uid, itemId)
        const reformattedChunks = chunksService.chunkMarkdown(reformattedMarkdown)
        await mentionsRepository.deleteByItemId(db, itemId)
        await injectConnections(itemState.item, reformattedChunks)
    }

    return { ...itemState, ...loadingState, reformatReader, expandItem, createConnections }
}
