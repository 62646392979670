import { IconButton, Tooltip, Typography } from "@mui/material"
import { PinIcon } from "@recall/common"
import React from "react"

interface Props {
    pinned: boolean
    onClick: (e) => Promise<void>
}

export const PushPinToggle: React.FC<Props> = ({ onClick, pinned }) => {
    return (
        <Tooltip
            title={
                <>
                    <Typography variant="body2">{pinned ? "Unpin" : "Pin"} this card</Typography>
                    <i>
                        {pinned
                            ? 'Unpinned cards will not show up in the left-side menu (unless you click "Show all cards")'
                            : "Pinned cards will always show up in the left-side menu"}
                    </i>
                </>
            }
        >
            <IconButton onClick={onClick}>
                <PinIcon fontSize="small" isFilled={pinned} />
            </IconButton>
        </Tooltip>
    )
}
