import { Box } from "@mui/material"
import { PlateElementProps } from "@udecode/plate-common"

import { PlateElement } from "@udecode/plate-common"

export const EditorBlockElement = ({ children, ...props }: PlateElementProps) => {
    return (
        <PlateElement asChild {...props}>
            <Box mb={1}>{children}</Box>
        </PlateElement>
    )
}
