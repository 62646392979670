import { Box, SxProps, Theme } from "@mui/material"
import { Subtitle, Title } from "@recall/common"
import { FC } from "react"

interface Props {
    title: string | React.ReactNode
    subtitle?: string | React.ReactNode
}

export const TitleContainer: FC<Props> = ({ title, subtitle }) => {
    return (
        <Box sx={styles.container}>
            <Title>{title}</Title>
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
        </Box>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: "600px",
        gap: 2,
    },
}
