import { Box, SxProps, Theme, Tooltip, Typography } from "@mui/material"
import { Zap } from "lucide-react"
import { FC } from "react"
import { USAGE_LIMIT } from "../../services/usage/summaryUsageService"

interface Props {
    usage: number | undefined
    handleUpgrade: () => void
    direction?: "row" | "column"
}

export const UsageChip: FC<Props> = ({ usage, handleUpgrade, direction = "row" }) => {
    if (usage === undefined) return null

    return (
        <Tooltip title="This is how many cards you can create before you need to upgrade.">
            <Box sx={{ ...styles.count, flexDirection: direction }} onClick={handleUpgrade}>
                <Zap size={20} />
                <Typography variant="caption" display="flex" fontSize="12px" gap={0.3}>
                    <span>{Math.min(usage, USAGE_LIMIT)}</span>/<span>{USAGE_LIMIT}</span>
                </Typography>
            </Box>
        </Tooltip>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    count: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: 0.5,
        color: (theme) => theme.palette.text.secondary,
        position: "relative",
        borderRadius: 1,
        p: 0.8,
        cursor: "pointer",
        "&:before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            borderRadius: "inherit",
            padding: "1px",
            background: "linear-gradient(45deg, #DB4E41, #4D80E4)",
            WebkitMask: "linear-gradient(#fff 0 0) content-box, " + "linear-gradient(#fff 0 0)",
            WebkitMaskComposite: "xor",
            maskComposite: "exclude",
            pointerEvents: "none",
        },
        "&:hover": {
            background: (theme) => theme.palette.action.hover,
        },
    },
}
