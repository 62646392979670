import { alpha, Box, SxProps, Theme } from "@mui/material"
import { assets, PrimaryButton } from "@recall/common"
import { FC } from "react"
import { CardStep } from "./CardStep"
import { ExampleCard } from "./ExampleCard"
import { TitleContainer } from "./TitleContainer"
import { Video } from "./Video"

interface Props {
    handleSkip: () => void
}

const examples = [
    {
        title: "What Alcohol Does to Your Body, Brain & Health",
        type: "Podcast",
        imageUrl: `https://i.ytimg.com/vi/DkS1pkKpILY/maxresdefault.jpg`,
        url: "https://www.youtube.com/watch?v=DkS1pkKpILY",
    },
    {
        title: "Git & GitHub Crash Course 2025",
        type: "YouTube Video",
        imageUrl: "https://i.ytimg.com/vi/vA5TTz6BXhY/maxresdefault.jpg",
        url: "https://www.youtube.com/watch?v=vA5TTz6BXhY",
    },
    {
        title: "Microsoft announces quantum computing breakthrough with new Majorana 1 chip",
        type: "Blog",
        imageUrl:
            "https://platform.theverge.com/wp-content/uploads/sites/2/2025/02/Majorana-1-005-4000px.jpg?quality=100&strip=all&w=200",
        url: "https://www.theverge.com/news/614205/microsoft-quantum-computing-majorana-1-processor",
    },
    {
        title: "Global sea-level rise in the early Holocene revealed from North Sea peats",
        type: "Research Article",
        imageUrl:
            "https://media.springernature.com/m685/springer-static/image/art%3A10.1038%2Fs41586-025-08769-7/MediaObjects/41586_2025_8769_Fig1_HTML.png",
        url: "https://www.nature.com/articles/s41586-025-08769-7",
    },
]

export const CreateExtensionCardStep: FC<Props> = ({ handleSkip }) => {
    return (
        <Box>
            <Box display="flex" justifyContent="center">
                <TitleContainer
                    title={
                        <>
                            You are All Set! <br />
                            Try Recall in your Browser Now.
                        </>
                    }
                    subtitle="Transform the way you engage with your content forever"
                />
            </Box>
            <Box display="flex" gap={2} mt={3}>
                <CardStep
                    number={1}
                    title="Open one of the examples below"
                    subtitle=""
                    component={
                        <Box>
                            {examples.map((example) => (
                                <ExampleCard key={example.title} {...example} />
                            ))}
                        </Box>
                    }
                />

                <CardStep
                    number={2}
                    title="Then click the extension icon and save your first summary"
                    subtitle=""
                    component={
                        <Box px={2} pb={2}>
                            <Video videoUrl={assets.INSTALL_BROWSER_EXTENSION.url} />
                        </Box>
                    }
                />
            </Box>
            <Box
                display="flex"
                flexDirection="column"
                gap={2}
                justifyContent="center"
                alignItems="center"
            >
                <PrimaryButton sx={styles.button} fullWidth onClick={handleSkip}>
                    I've saved a summary
                </PrimaryButton>
            </Box>
        </Box>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    button: {
        mt: 2,
    },
    avatar: {
        width: 25,
        height: 25,
        backgroundColor: alpha("#FFF", 0.6),
    },
}
