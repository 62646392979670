import { Database } from "@nozbe/watermelondb"
import { useDatabase } from "@nozbe/watermelondb/react"
import { map } from "lodash"
import { useCallback } from "react"
import { toast } from "react-toastify"
import { v4 as uuid } from "uuid"
import { EditorBlockData } from "../../services"
import { initItemEditorData } from "../../services/editorData/initEditorData"

import { ASSET_TYPES } from "../../storage/watermelon/models/AssetModel"
import { ItemModel } from "../../storage/watermelon/models/ItemModel"
import { assetRepository } from "../../storage/watermelon/repository/assetRepository"
import { editorBlockRepository } from "../../storage/watermelon/repository/editorBlockRepository"
import { editorOrderRepository } from "../../storage/watermelon/repository/editorOrderRepository"
import { MAX_IMAGE_SIZE_IN_BYTES } from "../constants/assets"
import { uploadArrayBufferImage } from "../plugins"
import { EditorElements } from "../types"

export const usePlateHelpers = (item: ItemModel, uid: string) => {
    const db = useDatabase()

    const initEditorBlocks = useCallback(() => {
        return initItemEditorData(item)
    }, [item])

    return {
        initEditorBlocks,
        updateEditorBlocks: (newValue: EditorElements) => updateEditorBlocks(db, item, newValue),
        uploadImage: (arrayBuffer: ArrayBuffer, contentType: string) =>
            uploadImage(db, item, uid, arrayBuffer, contentType),
    }
}

export const initEditorBlocks = (item: ItemModel) => {
    return initItemEditorData(item)
}

const upsertEditorBlocks = async (db: Database, item: ItemModel, editorBlocks: EditorElements) => {
    for (let editorBlock of editorBlocks) {
        await editorBlockRepository.upsert(db, item.id, editorBlock)
    }
}

const updateItemDetails = async (item: ItemModel, newValue: EditorElements) => {
    const description = EditorBlockData.getTextWithoutHeadings(newValue)
    if (item.description !== description) await item.updateDescription(description)
}

export const updateEditorBlocks = async (
    db: Database,
    item: ItemModel,
    newValue: EditorElements
) => {
    const existingIds: string[] = []
    for (const editorBlock of newValue) {
        // Plate editor creates custom elements with same ID when you hit enter, so it can cause duplicates without this fix
        if (!editorBlock?.id || existingIds.includes(editorBlock.id)) {
            editorBlock.id = uuid()
        }
        existingIds.push(editorBlock.id)
    }

    const editorOrder = map(newValue, "id") as string[]
    await upsertEditorBlocks(db, item, newValue)
    await editorOrderRepository.upsert(db, item.id, editorOrder)
    await updateItemDetails(item, newValue)
    await item.updateUpdatedAt()
}

export const uploadImage = async (
    db: Database,
    item: ItemModel,
    uid: string,
    arrayBuffer: ArrayBuffer,
    contentType: string
) => {
    const size = arrayBuffer.byteLength

    if (size > MAX_IMAGE_SIZE_IN_BYTES) {
        toast.error(`Max image size is ${MAX_IMAGE_SIZE_IN_BYTES / (1024 * 1024)}MB`)
        return null
    }

    const url = await uploadArrayBufferImage(arrayBuffer, contentType, uid)

    if (!url) return null

    await assetRepository.createAsset(db, {
        item,
        url,
        size,
        type: ASSET_TYPES.IMAGE,
    })

    if (!item.image) {
        await item.updateImage(url)
    }

    return url
}
