import { useQueryParameter } from "hooks/useQueryParameter"
import { useSelector } from "react-redux"
import { useLocation } from "react-router"
import { RootState } from "storage/redux/rootReducer"
import { useTabActions } from "../providers/TabProvider"

interface LocationState {
    elementId?: string
    isReaderMention?: boolean
}

export const useFocusTab = () => {
    const { param: isReaderMentionParam } = useQueryParameter("isReaderMention")
    const { setTab } = useTabActions()
    const isReaderVisible = useSelector((state: RootState) => state.user.settings.isReaderVisible)

    const location = useLocation<LocationState>()

    const focusTab = async () => {
        const isReaderMention = location?.state?.isReaderMention || isReaderMentionParam === "true"

        if (isReaderMention && !isReaderVisible) {
            setTab("reader")
        } else {
            setTab("notebook")
        }
    }

    return { focusTab }
}
