import { Database } from "@nozbe/watermelondb"
import {
    ItemModel,
    ItemPartial,
    itemService,
    libraryRepository,
    sentry,
    SummaryLengthEnum,
    toItemPartial,
} from "@recall/common"

import { summariesApi } from "services/api"

const searchWikipedia = async (query: string, language = "en"): Promise<ItemPartial[]> => {
    try {
        const itemApis = await summariesApi.findWikipediaSummary(query, language)
        if (!itemApis) return []
        return itemApis.map((item) => toItemPartial(item)).filter(Boolean)
    } catch (e) {
        sentry.captureException(e)
        return []
    }
}

const getAndSaveWikipedia = async (db: Database, slug: string, language = "en") => {
    try {
        const itemApi = await summariesApi.summarizeWikipediaPage(slug, language)
        if (!itemApi) return null
        const itemModel = await itemService.create({ db, itemApi, isSaved: false })
        return itemModel
    } catch (e) {
        sentry.captureException(e)
        return null
    }
}

const getAndSaveLibraryItem = async (
    db: Database,
    url: string,
    summaryLength: SummaryLengthEnum,
    language: string
): Promise<ItemModel | null> => {
    if (!libraryRepository.isValidRequest(language, summaryLength)) return null

    const itemApi = await libraryRepository.getLibraryItemByUrlOrVideoId(url)
    if (!itemApi) return null

    const itemModel = await itemService.create({ db, itemApi })

    return itemModel
}

export const itemAPI = {
    searchWikipedia,
    getAndSaveWikipedia,
    getAndSaveLibraryItem,
}
