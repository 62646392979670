import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import TreeView from "@mui/lab/TreeView"
import { Box, SxProps, Theme, alpha } from "@mui/material"
import { useDatabase } from "@nozbe/watermelondb/react"
import { ROOT_TAG_ID, tagRepository, useIsMobile } from "@recall/common"
import { map, orderBy } from "lodash"
import { FC, memo, useCallback, useEffect, useMemo, useState } from "react"
import { ConfirmModal } from "../modals/ConfirmModal"
import { FocusItemProvider } from "./FocusItemProvider"
import { TagUpdateModal } from "./TagUpdateModal"
import { TagsLoader } from "./TagsLoader"
import { TagsTreeEmptyState } from "./TagsTreeEmptyState"
import { TagsTreeHeader } from "./TagsTreeHeader"
import { TagsTreeItem } from "./TagsTreeItem"
import { TagsTreeSkeleton } from "./TagsTreeSkeleton"
import { TagsTreeUntaggedItems } from "./TagsTreeUntaggedItems"
import { useExpandedTags } from "./hooks/useExpandedTags"
import { RERENDER_TAGS_EVENT, Tag, useGroupedTags } from "./hooks/useGroupedTags"
import { useSelectedTags } from "./hooks/useSelectedTags"

interface Props {
    selectedItemId?: string
    handleClickItem: (id: string) => void
    isSwipeable: boolean
}

const TagsTreeComponent: FC<Props> = ({ selectedItemId, handleClickItem, isSwipeable }) => {
    const db = useDatabase()
    const isMobile = useIsMobile()
    const [searchExpandedState, setSearchExpandState] = useState([])
    const [visibleTags, setVisibleTags] = useState(40)
    const [isUntaggedSectionExpanded, setIsUntaggedSectionExpanded] = useState(false)

    const { tags, searchText, isEmpty, setSearchText } = useGroupedTags()

    const rootTagChildren = useMemo(() => {
        return tags?.[ROOT_TAG_ID]?.children
            ? orderBy(
                  tags[ROOT_TAG_ID].children
                      .filter((child) => tags[child])
                      ?.map((child) => tags[child]),
                  (tag) => tag?.name?.toLowerCase()
              )
            : []
    }, [tags])

    const makeTagVisible = useCallback(
        (tagId: string) => {
            const tagIndex = map(rootTagChildren, "id").indexOf(tagId)

            if (tagIndex === -1) return

            setVisibleTags((prev) => {
                if (prev <= tagIndex) {
                    return tagIndex + 10
                }
                return prev
            })
        },
        [rootTagChildren]
    )

    const { selectedTagIds, deselectTags, toggleSelectTag } = useSelectedTags(tags)
    const { expandedTags, handleChangeExpanded, expandTag } = useExpandedTags(tags)

    const [tagToDelete, setTagToDelete] = useState<Tag | null>(null)
    const [tagToUpdate, setTagToUpdate] = useState<Tag | null>(null)

    const [isDraggedOver, setIsDraggedOver] = useState(false)

    useEffect(() => {
        if (!searchText && searchExpandedState.length) {
            setSearchExpandState([])
        }
    }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

    const collapseTagsTree = () => {
        handleChangeExpanded([])
        setIsUntaggedSectionExpanded(false)
    }

    const handleToggle = (_: React.SyntheticEvent, nodeIds: string[]) => {
        if (searchText) {
            setSearchExpandState(nodeIds)
        } else {
            handleChangeExpanded(nodeIds)
        }
    }

    const expandedSearchedTags = searchExpandedState.length ? searchExpandedState : map(tags, "id")

    const handleDropTag = async (tagId: string) => {
        if (!tagId) return

        const childTag = await tagRepository.get(db, tagId)

        if (childTag.parent.id === ROOT_TAG_ID) return

        const existingTag = await tagRepository.getTagByNameAndParentId(
            db,
            childTag.name,
            ROOT_TAG_ID
        )

        if (existingTag) {
            await tagRepository.mergeTags(db, existingTag, childTag)
            return
        }

        await tagRepository.updateParent(db, ROOT_TAG_ID, tagId)
    }

    const increaseVisibleTags = useCallback(() => {
        setVisibleTags((prev) => prev + 40)
    }, [])

    const areAllTagsLoaded = rootTagChildren.length <= visibleTags

    return (
        <Box sx={styles.container}>
            <FocusItemProvider
                tags={tags}
                expandedTags={expandedTags}
                handleChangeExpanded={handleChangeExpanded}
                makeTagVisibleInTree={makeTagVisible}
            />
            <TagsTreeHeader
                search={setSearchText}
                hideCloseButton={isSwipeable}
                collapseTagsTree={collapseTagsTree}
            />

            <Box
                sx={{
                    ...styles.tagsTree,
                    background: (theme) => isDraggedOver && theme.palette.action.disabledBackground,
                }}
                onDragOver={(e) => {
                    e.preventDefault()
                    if (!isDraggedOver) setIsDraggedOver(true)
                }}
                onDragLeave={() => {
                    setIsDraggedOver(false)
                }}
                onDrop={async (e) => {
                    setIsDraggedOver(false)

                    const data = e.dataTransfer.getData("application/json")
                    if (!data) return

                    const { tagId, isTag, itemId } = JSON.parse(data)

                    if (isTag) {
                        await handleDropTag(tagId)
                    } else {
                        await tagRepository.detach(db, tagId, itemId)
                    }
                    document.dispatchEvent(new CustomEvent(RERENDER_TAGS_EVENT))
                }}
            >
                {isEmpty ? (
                    <TagsTreeEmptyState />
                ) : (
                    <>
                        {tagToDelete && (
                            <ConfirmModal
                                isOpen
                                onClose={() => setTagToDelete(null)}
                                title="Delete tag"
                                description={`Are you sure you want to delete tag ${tagToDelete.name}? All associations to this tag will be removed.`}
                                onConfirm={async () => {
                                    deselectTags([tagToDelete.id])
                                    await tagRepository.deleteTag(db, tagToDelete.id)
                                    document.dispatchEvent(new CustomEvent(RERENDER_TAGS_EVENT))
                                    setTagToDelete(null)
                                }}
                                confirmText="Delete"
                                closeText="Cancel"
                            />
                        )}
                        {tagToUpdate && (
                            <TagUpdateModal
                                name={tagToUpdate.name}
                                parentId={tagToUpdate.parentId}
                                tagId={tagToUpdate.id}
                                onClose={() => {
                                    setTagToUpdate(null)
                                }}
                            />
                        )}

                        <Box pr={1}>
                            <TreeView
                                defaultCollapseIcon={
                                    <ChevronRightIcon
                                        sx={{
                                            ...styles.collapseIcon,
                                            fontSize: isMobile ? "24px !important" : "inherit",
                                        }}
                                    />
                                }
                                defaultExpandIcon={
                                    <ChevronRightIcon
                                        sx={{
                                            ...styles.expandIcon,
                                            fontSize: isMobile ? "24px !important" : "inherit",
                                        }}
                                    />
                                }
                                expanded={searchText ? expandedSearchedTags : expandedTags}
                                onNodeToggle={handleToggle}
                            >
                                {tags[ROOT_TAG_ID] ? (
                                    rootTagChildren
                                        .slice(0, visibleTags)
                                        .map((tag) => (
                                            <TagsTreeItem
                                                key={tag.id}
                                                selectedItemId={selectedItemId}
                                                toggleSelect={toggleSelectTag}
                                                selectedTagIds={selectedTagIds}
                                                tags={tags}
                                                tag={tag}
                                                setTagToDelete={setTagToDelete}
                                                setTagToUpdate={setTagToUpdate}
                                                handleClickItem={handleClickItem}
                                                expandTag={expandTag}
                                                searchText={searchText}
                                            />
                                        ))
                                ) : (
                                    <TagsTreeSkeleton />
                                )}
                                {areAllTagsLoaded && (
                                    <TagsTreeUntaggedItems
                                        isExpanded={
                                            isUntaggedSectionExpanded || Boolean(searchText)
                                        }
                                        setIsExpanded={setIsUntaggedSectionExpanded}
                                        searchText={searchText}
                                        handleClickItem={handleClickItem}
                                    />
                                )}
                                <TagsLoader
                                    tagsCount={rootTagChildren.length}
                                    isVisible={!areAllTagsLoaded}
                                    increaseVisibleTags={increaseVisibleTags}
                                />
                            </TreeView>
                        </Box>
                    </>
                )}
            </Box>
        </Box>
    )
}

export const TagsTree = memo(TagsTreeComponent)

const styles: Record<string, SxProps<Theme>> = {
    container: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
    },
    tagsTree: {
        overflow: "auto",
        overflowY: "scroll",
        flexGrow: 2,
        mb: 0,
        pl: 1,
        "&::-webkit-scrollbar": {
            height: 0,
            width: "6px",
        },

        "&::-webkit-scrollbar-track": {
            backgroundColor: "transparent",
        },

        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "transparent",
        },

        "&:hover::-webkit-scrollbar-thumb": {
            background: (theme) => alpha(theme.palette.grey[500], 0.2),
            "&:hover": {
                background: (theme) => alpha(theme.palette.grey[500], 0.6),
            },
        },
    },
    expandIcon: {
        opacity: 0.6,
        color: (theme) => theme.palette.grey[500],
        transition: "transform 0.1s linear",
        "&:hover": {
            opacity: 1,
        },
    },
    collapseIcon: {
        opacity: 0.6,
        color: (theme) => theme.palette.grey[500],
        transform: "rotate(90deg)",
        transition: "transform 0.1s linear",
        "&:hover": {
            opacity: 1,
        },
    },
}
