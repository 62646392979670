export class GTMService {
    private static pushToDataLayer(event: Record<string, any>): void {
        if (typeof window !== "undefined") {
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push(event)
        }
    }

    static trackPurchase(): void {
        this.pushToDataLayer({
            event: "purchase",
        })
    }

    static trackSignup(): void {
        this.pushToDataLayer({
            event: "sign_up",
        })
    }

    static trackExtensionInstall(): void {
        this.pushToDataLayer({
            event: "extension_install",
        })
    }

    static trackThreeCardsMilestone(): void {
        this.pushToDataLayer({
            event: "three_cards_milestone",
        })
    }

    static trackCustomEvent(eventName: string, eventData: Record<string, any>): void {
        this.pushToDataLayer({
            event: eventName,
            ...eventData,
        })
    }
}
