import { Box, Button, Grid, Snackbar, Typography } from "@mui/material"
import { useDatabase } from "@nozbe/watermelondb/react"
import { itemRepository, itemService } from "@recall/common"
import { ViewToggle } from "components/layouts/components/Header/ViewToggle"
import { ItemView } from "components/shared/ItemPartial/ItemView"
import { isEmpty } from "lodash"
import { Fragment, useCallback, useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { RootState } from "storage/redux/rootReducer"
import { RERENDER_ITEMS_LIST, useGroupedItems } from "../hooks/useGroupedItems"
import { useScrollPosition } from "../hooks/useScrollPosition"
import { BulkDeleteItemsDialog } from "./BulkDeleteItemsDialog"
import { ItemsEmpty } from "./ItemsEmpty"
import { ItemsLoader } from "./ItemsLoader"
import { ItemsNotFound } from "./ItemsNotFound"
import { NewUserMessage } from "./NewUserMessage"
import { OrderItemsDropdown } from "./OrderItemsDropdown"

type Selected = {
    items: Set<string>
    lastSelectedIndex?: number
}

export const ItemsList = () => {
    const db = useDatabase()
    const selectedTagIds = useSelector((state: RootState) => state.drawer.selectedTagIds)
    const [selected, setSelected] = useState<Selected>({ items: new Set() })
    const dialogRef = useRef<HTMLDivElement>()
    const [isConfirmDelete, setIsConfirmDelete] = useState(false)

    const history = useHistory()
    const uid = useSelector((state: RootState) => state.user.uid)
    const view = useSelector((state: RootState) => state.items.view)
    const isDrawerOpen = useSelector((state: RootState) => state.drawer.open)

    const isFilterApplied = Boolean(selectedTagIds.length)

    const { groupedItems, order, itemsCount, isLoading } = useGroupedItems()

    const orderedItems = order.map((group) => groupedItems[group]).flat()
    const isEmptyFilteredList = isFilterApplied && orderedItems.length === 0 && !isLoading
    const isEmptyList = orderedItems.length === 0 && !isLoading

    useScrollPosition(!isEmpty(groupedItems))

    const toggleSelected = useCallback(
        (id: string, event?: React.MouseEvent) => {
            const currIndex = orderedItems.findIndex((item) => item.id === id)
            setSelected((prev) => {
                const newItems = new Set(prev.items)
                const isCurrentlySelected = newItems.has(id)

                // Handle shift+click multi-select
                if (
                    event?.shiftKey &&
                    prev.lastSelectedIndex !== undefined &&
                    !isCurrentlySelected
                ) {
                    const start = Math.min(currIndex, prev.lastSelectedIndex)
                    const end = Math.max(currIndex, prev.lastSelectedIndex)
                    orderedItems.slice(start, end + 1).forEach((item) => newItems.add(item.id))

                    return {
                        items: newItems,
                        lastSelectedIndex: currIndex,
                    }
                }

                // Handle single item toggle
                if (isCurrentlySelected) {
                    newItems.delete(id)
                    return {
                        items: newItems,
                        lastSelectedIndex: undefined,
                    }
                } else {
                    newItems.add(id)
                    return {
                        items: newItems,
                        lastSelectedIndex: currIndex,
                    }
                }
            })
        },
        [orderedItems]
    )

    const handleDeleteItems = async () => {
        const selectedItems = await itemRepository.getByIds(db, Array.from(selected.items))
        setIsConfirmDelete(false)

        for (const item of selectedItems) {
            await itemService.deleteItem(db, item, uid)
        }

        setSelected({
            items: new Set(),
            lastSelectedIndex: undefined,
        })
        document.dispatchEvent(new CustomEvent(RERENDER_ITEMS_LIST))
    }

    const handleInitiateDeleteItems = (e: React.MouseEvent) => {
        e.stopPropagation()
        setIsConfirmDelete(true)
    }

    useEffect(() => {
        const deselectAll = (e: MouseEvent) => {
            const clickFromDialog =
                dialogRef.current && dialogRef.current.contains(e.target as Node)
            if (clickFromDialog) {
                return
            }

            setSelected({
                items: new Set(),
                lastSelectedIndex: undefined,
            })
        }
        document.body.addEventListener("click", deselectAll)

        return () => {
            document.body.removeEventListener("click", deselectAll)
        }
    }, [dialogRef.current])

    const getFormattedGroupTitle = (group: string) => {
        if (group === new Date().toDateString()) return "TODAY"
        if (group === new Date(new Date().setDate(new Date().getDate() - 1)).toDateString())
            return "YESTERDAY"
        return group
    }

    if (isEmptyFilteredList) return <ItemsNotFound />
    if (isEmptyList) return <ItemsEmpty />

    return (
        <>
            {orderedItems.length !== 0 && (
                <Box display="flex" justifyContent="flex-end" gap={1} pt={2}>
                    <ViewToggle />
                    <OrderItemsDropdown />
                </Box>
            )}
            {order.map((group, index) => (
                <Fragment key={group}>
                    <Grid item xs={12} pt={index === 0 ? "0 !important" : 1}>
                        <Typography
                            py={index === 0 ? 0 : 2}
                            pb={index === 0 ? 2 : undefined}
                            fontSize={14}
                            fontWeight={500}
                        >
                            {getFormattedGroupTitle(group)}
                        </Typography>
                    </Grid>
                    <Grid item container spacing={2}>
                        {groupedItems[group].map((item) => {
                            return (
                                <Grid
                                    xs={12}
                                    sm={isDrawerOpen ? 12 : 6}
                                    md={isDrawerOpen ? 6 : 4}
                                    lg={
                                        view === "grid"
                                            ? isDrawerOpen
                                                ? 3
                                                : 2.4
                                            : isDrawerOpen
                                              ? 4
                                              : 3
                                    }
                                    xl={isDrawerOpen ? 2.4 : 2}
                                    item
                                    key={item.id}
                                    sx={{ textDecoration: "none" }}
                                >
                                    <ItemView
                                        isSelected={selected.items.has(item.id)}
                                        toggleSelected={toggleSelected}
                                        isSelectionActive={Boolean(selected.items.size)}
                                        item={item}
                                        view={view}
                                        onClick={() => {
                                            if (item.isLoading) return
                                            history.push(`/item/${item.id}`)
                                        }}
                                    />
                                </Grid>
                            )
                        })}
                    </Grid>
                </Fragment>
            ))}
            {Boolean(order.length) && (
                <ItemsLoader itemsCount={itemsCount} isVisible={Boolean(orderedItems.length)} />
            )}
            {!isFilterApplied && <NewUserMessage />}
            {Boolean(selected.items.size) && (
                <Snackbar
                    sx={{ mx: 2 }}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    open
                    message={`${selected.items.size} Selected`}
                    action={
                        <Button
                            onClick={handleInitiateDeleteItems}
                            sx={{
                                color: (theme) =>
                                    theme.palette.mode === "light" ? "secondary.main" : undefined,
                            }}
                        >
                            Delete
                        </Button>
                    }
                />
            )}
            {isConfirmDelete && (
                <BulkDeleteItemsDialog
                    onClose={() => setIsConfirmDelete(false)}
                    onConfirm={handleDeleteItems}
                    selectedItems={selected.items}
                    ref={dialogRef}
                />
            )}
        </>
    )
}
