import { conciseSummaryAction, tutorials } from "@recall/common"
import produce from "immer"
import { uniq } from "lodash"
import {
    SET_ACTIVE_TUTORIAL,
    SET_CLOSE_REVIEW_MODAL,
    SET_DB_VERSION,
    SET_DID_UPDATE,
    SET_INITIALIZING_USER,
    SET_IS_APP_INSTALLED,
    SET_ONBOARDING,
    SET_SURVEY_COMPLETED,
    SET_USER,
    TUTORIAL_COMPLETE,
    TUTORIAL_RESTART,
    TUTORIAL_RESTART_SINGLE,
    UPDATE_USER,
} from "./actionTypes"

import { UserState } from "./types"

interface UserReducerAction {
    type: string
    payload: any
}

const initialState: UserState = {
    uid: null,
    email: null,
    emailVerified: false,
    initializing: true,
    didUpdate: false,
    isAppInstalled: false,
    sharedCards: [],
    tutorial: {
        completed: {} as Record<tutorials, boolean>,
        active: "none",
    },
    onboarding: {
        finished: [],
        skipped: [],
    },
    menu: {
        expandedTags: undefined,
    },
    isPremiumUser: false,
    surveys: [],
    dbVersion: null,
    modals: null,
    isReviewEmailEnabled: true,
    isOnboardingBannerVisible: true,
    usage: 0,
    settings: {
        defaultAction: conciseSummaryAction,
        language: "auto",
        searchLanguage: "en",
        isReaderVisible: true,
    },
}

export const userReducer = (state = initialState, action: UserReducerAction) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case SET_USER: {
                const {
                    email,
                    uid,
                    emailVerified,
                    completedTutorials,
                    isPremiumUser,
                    surveys,
                    modals,
                    menu,
                    sharedCards,
                    settings,
                    isReviewEmailEnabled,
                    isOnboardingBannerVisible,
                    usage,
                } = action.payload
                draftState.uid = uid
                draftState.email = email
                draftState.emailVerified = emailVerified
                draftState.tutorial.completed = completedTutorials
                draftState.isPremiumUser = isPremiumUser
                draftState.surveys = surveys
                draftState.modals = modals
                draftState.menu = menu
                draftState.settings = settings
                    ? { ...initialState.settings, ...state.settings, ...settings }
                    : initialState.settings
                draftState.sharedCards = sharedCards || []
                draftState.isReviewEmailEnabled = isReviewEmailEnabled
                draftState.isOnboardingBannerVisible =
                    isOnboardingBannerVisible === undefined
                        ? state.isOnboardingBannerVisible
                        : isOnboardingBannerVisible
                draftState.usage = usage
                break
            }
            case UPDATE_USER: {
                const {
                    emailVerified,
                    modals,
                    surveys,
                    onboarding,
                    tutorial,
                    menu,
                    settings,
                    sharedCards,
                    isReviewEmailEnabled,
                    isOnboardingBannerVisible,
                    usage,
                }: Partial<UserState> = action.payload
                draftState.emailVerified = emailVerified || state.emailVerified
                draftState.modals = modals || state.modals
                draftState.surveys = surveys || state.surveys
                draftState.onboarding = onboarding
                    ? { ...state.onboarding, ...onboarding }
                    : state.onboarding
                draftState.tutorial = tutorial || state.tutorial
                draftState.menu = menu || state.menu
                draftState.sharedCards = sharedCards || state.sharedCards
                draftState.isReviewEmailEnabled =
                    isReviewEmailEnabled === undefined
                        ? state.isReviewEmailEnabled
                        : isReviewEmailEnabled
                draftState.isOnboardingBannerVisible =
                    isOnboardingBannerVisible === undefined
                        ? state.isOnboardingBannerVisible
                        : isOnboardingBannerVisible
                draftState.settings = settings ? { ...state.settings, ...settings } : state.settings
                draftState.usage = usage || state.usage
                break
            }
            case SET_INITIALIZING_USER: {
                const initializing = action.payload
                draftState.initializing = initializing
                break
            }

            case TUTORIAL_COMPLETE: {
                const tutorialName = action.payload
                draftState.tutorial.completed[tutorialName] = true
                draftState.tutorial.active = "none"
                break
            }
            case TUTORIAL_RESTART_SINGLE: {
                const tutorialName = action.payload
                draftState.tutorial.completed[tutorialName] = false
                break
            }
            case TUTORIAL_RESTART: {
                draftState.tutorial.completed = {
                    [tutorials.RAB_RELEASE_MODAL]:
                        state.tutorial.completed[tutorials.RAB_RELEASE_MODAL],
                } as Record<tutorials, boolean>
                break
            }
            case SET_DID_UPDATE: {
                const didUpdate = action.payload
                draftState.didUpdate = didUpdate
                break
            }
            case SET_IS_APP_INSTALLED: {
                const isAppInstalled = action.payload
                draftState.isAppInstalled = isAppInstalled
                break
            }
            case SET_ONBOARDING: {
                const onboarding = action.payload
                draftState.onboarding = { ...state.onboarding, ...onboarding }
                break
            }
            case SET_ACTIVE_TUTORIAL: {
                const tutorial = action.payload
                draftState.tutorial.active = tutorial
                break
            }
            case SET_SURVEY_COMPLETED: {
                const survey = action.payload
                draftState.surveys = uniq([...(state.surveys || []), survey])
                break
            }
            case SET_DB_VERSION: {
                const dbVersion = action.payload
                draftState.dbVersion = dbVersion
                break
            }
            case SET_CLOSE_REVIEW_MODAL: {
                draftState.modals = uniq([...(state.surveys || []), action.payload])
                break
            }
            default: {
                return state
            }
        }
    })
