import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore"
import { SummaryLengthEnum } from ".."
import { ItemApi } from "../api"
import { FIRESTORE_COLLECTIONS } from "../constants"
import { firebase } from "../services"
import { isYoutubeVideo, sentry } from "../utils"

interface LibraryItem {
    itemApi: ItemApi
    videoId?: string
    id: string
}

const isValidRequest = (language: string, summaryLength: SummaryLengthEnum) => {
    return (
        (language === "en" || language === "auto") && summaryLength === SummaryLengthEnum.detailed
    )
}

const migrateItemApi = (itemApi: ItemApi): ItemApi => {
    const links = itemApi?.links
    if (!links) return itemApi

    const newLinks = links.map((link) => {
        if (link.item.markdown) return link

        // @ts-ignore
        link.item.markdown = link.item.description
        return link
    })

    return { ...itemApi, links: newLinks }
}

const getLibraryItemApi = async (id: string): Promise<ItemApi | null> => {
    try {
        const libraryItemRef = doc(firebase.firestore, FIRESTORE_COLLECTIONS.LIB_ITEMS, id)
        const snapshot = await getDoc(libraryItemRef)

        if (!snapshot.exists()) return null

        const libraryItem = snapshot.data()

        if (!libraryItem?.itemApi) return null

        return migrateItemApi(libraryItem.itemApi)
    } catch (e) {
        const err = e as Error
        sentry.captureException(err)
        return null
    }
}

const getLibraryItemByVideoId = async (videoId: string): Promise<LibraryItem | null> => {
    try {
        const libraryCollectionRef = collection(firebase.firestore, FIRESTORE_COLLECTIONS.LIB_ITEMS)
        const querySnapshot = await getDocs(
            query(libraryCollectionRef, where("videoId", "==", videoId))
        )

        if (querySnapshot.empty) return null

        const libraryItem = querySnapshot.docs[0].data()

        return (libraryItem as LibraryItem) || null
    } catch (e) {
        const err = e as Error
        sentry.captureException(err)
        return null
    }
}

const getLibraryItemByUrl = async (url: string): Promise<LibraryItem | null> => {
    try {
        const libraryCollectionRef = collection(firebase.firestore, FIRESTORE_COLLECTIONS.LIB_ITEMS)
        const querySnapshot = await getDocs(query(libraryCollectionRef, where("url", "==", url)))

        if (querySnapshot.empty) return null

        const libraryItem = querySnapshot.docs[0].data()
        return (libraryItem as LibraryItem) || null
    } catch (e) {
        const err = e as Error
        sentry.captureException(err)
        return null
    }
}

const getLibraryItemByUrlOrVideoId = async (url: string): Promise<ItemApi | null> => {
    try {
        if (isYoutubeVideo(url)) {
            const urlParams = new URLSearchParams(new URL(url).search)
            const videoId = urlParams.get("v")
            if (!videoId) return null
            const libraryItem = await getLibraryItemByVideoId(videoId)

            if (!libraryItem?.itemApi) return null
            return migrateItemApi(libraryItem.itemApi)
        } else {
            const libraryItem = await getLibraryItemByUrl(url)
            if (!libraryItem?.itemApi) return null
            return migrateItemApi(libraryItem.itemApi)
        }
    } catch (e) {
        sentry.captureException(e as Error, { url })
        return null
    }
}

export const libraryRepository = {
    getLibraryItemApi,
    getLibraryItemByUrlOrVideoId,
    isValidRequest,
}
