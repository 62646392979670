import { alpha, Chip, ChipProps, SxProps, Theme } from "@mui/material"
import React from "react"
import { Favicon } from "./Favicon"

interface Props extends ChipProps {
    url: string
}

export const WebsiteSource: React.FC<Props> = ({ url, sx, ...props }) => {
    const getDomain = (url: string) => {
        try {
            const domain = new URL(url).hostname
            return domain.replace(/^www\./, "")
        } catch {
            return url
        }
    }

    const handleClick = (e: React.MouseEvent) => {
        e.stopPropagation()
        window.open(url, "_blank", "noopener,noreferrer")
    }

    return (
        <Chip
            sx={[styles.chip, sx] as SxProps<Theme>}
            avatar={<Favicon url={url} sx={styles.favicon} />}
            label={getDomain(url)}
            onClick={handleClick}
            clickable
            variant="filled"
            {...props}
        />
    )
}

const styles: Record<string, SxProps<Theme>> = {
    chip: {
        height: "28px",
        maxWidth: "fit-content",
        borderRadius: 0.5,
        backgroundColor: "transparent",
        "&:hover": {
            backgroundColor: "transparent",
            textDecoration: "underline",
        },
        fontSize: "12px",
        color: (theme) => alpha(theme.palette.text.secondary, 0.6),
        "& .MuiChip-label": {
            pl: 1,
            py: 0,
        },
    },
    favicon: {
        width: 16,
        height: 16,
        borderRadius: 0,
    },
}
