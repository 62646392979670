import { useQueryParameter } from "hooks/useQueryParameter"
import { useEffect } from "react"
import { useHistory, useLocation } from "react-router"
import { useItemContext } from "../providers/ItemProvider"

export const useFocusElement = () => {
    const { param: elementIdParam } = useQueryParameter("focusedElementId")
    const { item, chunks, contentType } = useItemContext()
    const location = useLocation<{ elementId?: string; isReaderMention?: boolean }>()
    const history = useHistory()
    const queryParams = new URLSearchParams(location.search)

    useEffect(() => {
        if (!item || (!chunks.length && contentType === "page")) return

        const elementId = location?.state?.elementId || elementIdParam

        if (!elementId) return
        const isFocused = focusElement(elementId)
        if (isFocused) return

        setTimeout(() => {
            focusElement(elementId)
        }, 50)

        cleanQueryParams()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item, chunks])

    const focusElement = (elementId: string) => {
        const connection = document.getElementById(elementId)
        if (!connection) return false

        connection.classList.add("focus-animation")
        connection.scrollIntoView({ block: "center" })
        setTimeout(() => {
            connection.classList.remove("focus-animation")
        }, 4000)

        return true
    }

    const cleanQueryParams = () => {
        queryParams.delete("focusedElementId")
        queryParams.delete("isReaderMention")
        history.replace({ search: queryParams.toString() })
    }
}
