import { sentry, YoutubeLiveUrlRegex } from "../../utils"

const getYouTubeLiveVideoId = (url: string) => {
    const match = url.match(YoutubeLiveUrlRegex)
    return match ? match[4] : null
}

const getVideoId = (url: string): string | null => {
    const parsedUrl = new URL(url)
    const params = new URLSearchParams(parsedUrl.search)
    return params.get("v")
}

const getHtml = async (url: string) => {
    try {
        const response = await fetch(url)
        return await response.text()
    } catch (e) {
        const err = e as Error
        sentry.captureException(err)
        return null
    }
}

export const youtubeService = { getVideoId, getYouTubeLiveVideoId, getHtml }
