import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp"
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Box,
    SxProps,
    Theme,
    Typography,
    alpha,
} from "@mui/material"
import { MentionItemType, MentionText, Options } from "@recall/common"
import { FC, memo } from "react"

interface Props {
    mention: MentionItemType
    openItemById: (id: string, options?: Options) => void
}

export const Mention: FC<Props> = memo(({ mention, openItemById }) => {
    const handleClick = () => {
        openItemById(mention.id)
    }

    const hasTextMentions = mention.blocks.length > 1

    return (
        <Accordion sx={styles.container} disableGutters elevation={0}>
            <AccordionSummary
                sx={{
                    minHeight: "0 !important",
                    m: "0 !important",
                    flexDirection: "row-reverse",
                    px: 1,
                    gap: 0.5,
                }}
                expandIcon={
                    hasTextMentions ? (
                        <ArrowForwardIosSharpIcon sx={styles.arrowIcon} />
                    ) : (
                        <Box width={14.4} />
                    )
                }
                onClick={!hasTextMentions ? handleClick : undefined}
            >
                <Box sx={styles.mentionHeader}>
                    <Box sx={styles.itemContent} onClick={handleClick}>
                        {mention.image && (
                            <Box pr={1}>
                                <Avatar
                                    sx={{
                                        width: 25,
                                        height: 25,
                                        bgcolor: (theme) => theme.palette.text.secondary,
                                    }}
                                    src={mention.image}
                                />
                            </Box>
                        )}
                        <Typography variant="body2" mr={1} fontWeight={500}>
                            {mention.name}
                        </Typography>
                    </Box>
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <Box px={1} sx={{ cursor: "pointer" }}>
                    {mention.blocks.map(({ textBlocks, elementId, isReaderMention }) => (
                        <MentionText
                            key={elementId}
                            textBlocks={textBlocks}
                            handleClick={(e) => {
                                e.stopPropagation()
                                openItemById(mention.id, { state: { elementId, isReaderMention } })
                            }}
                        />
                    ))}
                </Box>
            </AccordionDetails>
        </Accordion>
    )
})

const styles: Record<string, SxProps<Theme>> = {
    container: {
        m: "0px !important",
        p: 0,
        position: "relative",
        background: (theme) => theme.palette.background.default,
        "& .MuiAccordionSummary-contentGutters": {
            my: 0,
        },
        "& .MuiAccordionSummary-content": {
            m: 0,
        },
        ":not(:nth-child(1))::before": {
            opacity: "1 !important",
            display: "block !important",
        },
        "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
            transform: "rotate(90deg)",
        },
    },
    mentionHeader: {
        display: "flex",
        alignItems: "center",
        py: 0.8,
    },
    itemContent: {
        display: "flex",
        alignItems: "center",
        px: 0.5,
        py: 0.2,
        "&:hover": {
            cursor: "pointer",
            backgroundColor: (theme) => theme.palette.action.hover,
            borderRadius: 1,
        },
    },
    arrowIcon: {
        fontSize: "0.9rem",
        color: (theme) => alpha(theme.palette.text.primary, 0.5),
        "&:hover": {
            color: (theme) => alpha(theme.palette.text.primary, 0.8),
        },
    },
}
