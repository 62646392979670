import { orderBy, uniqBy } from "lodash"
import { ReferenceItem } from "../../../editor"
import { mentionsService } from "../../../services/links/mentionsService"
import { ConnectionModel } from "../models/ConnectionModel"
import { ItemModel } from "../models/ItemModel"

export interface LinkItem {
    id: string
    item: ReferenceItem
}

interface LinkItemModel extends Omit<LinkItem, "item"> {
    item: ItemModel
}

interface LinkGroup {
    key: string
    value: LinkItem[]
}
export const getGroupedLinks = async (connections: ConnectionModel[]) => {
    const links = await getLinks(connections)
    const groupedLinks = await groupByTags(links)
    const sortedGroupLinks = sortLinksByMentionsCount(groupedLinks)
    const sortedGroups = sortGroupsAlphabetically(sortedGroupLinks)
    return sortedGroups
}

const getLinks = async (connections: ConnectionModel[]) => {
    return (await Promise.all(
        connections
            .map(async (connection) => {
                if (!connection.to.id) return null

                const item = await connection.to
                if (!item) return null

                const link = {
                    id: connection.id,
                    item,
                }

                return link
            })
            .filter(Boolean)
    )) as LinkItemModel[]
}

const getReferenceItem = async (item: ItemModel, connectionId: string) => {
    const mentions = await item.mentions
    const otherMentions = mentions.filter(({ id, isSaved }) => isSaved && id !== connectionId)
    const mentionItems = await mentionsService.getByConnections(otherMentions)
    const tagsNesting = await item.getTagsNesting()

    return {
        isSaved: item.isSaved,
        id: item.id,
        name: item.name,
        description: item.description,
        image: item.image,
        mentionsCount: otherMentions.length,
        mentions: mentionItems,
        tagsNesting,
        isHiddenInRAB: item.isHiddenInRAB,
    }
}

const groupByTags = async (links: LinkItemModel[]): Promise<Record<string, LinkItem[]>> => {
    const linksTags = await Promise.all(links.map((link) => link.item.tags.fetch()))

    const groupedLinks: Record<string, LinkItem[]> = {}

    linksTags.flat().forEach((tag) => {
        groupedLinks[tag.name] = []
    })

    for (const [index, link] of links.entries()) {
        const tags = uniqBy(linksTags[index], "name")

        const item = await getReferenceItem(link.item, link.id)

        const groupedLink = {
            ...link,
            item,
        }

        if (!tags.length) {
            groupedLinks[""] = groupedLinks[""] || []
            groupedLinks[""].push(groupedLink)
            continue
        }

        for (const tag of tags) {
            groupedLinks[tag.name].push(groupedLink)
        }
    }

    return groupedLinks
}

const sortLinksByMentionsCount = (groupedLinks: Record<string, LinkItem[]>): LinkGroup[] => {
    return Object.keys(groupedLinks).map((groupKey) => {
        const links = groupedLinks[groupKey]
        const orderedLinks = orderBy(links, "item.mentionsCount", "desc")

        return {
            key: groupKey,
            value: orderedLinks,
        }
    })
}

const sortGroupsAlphabetically = (linkGroups: LinkGroup[]) => {
    return linkGroups.sort((a, b) => {
        if (a.key === "" || b.key === "") return a.key === "" ? -1 : 1

        const lengthCompare = b.value.length - a.value.length
        if (lengthCompare === 0) return a.key.localeCompare(b.key)

        return lengthCompare
    })
}
