import { useDatabase } from "@nozbe/watermelondb/react"
import {
    chunksService,
    conciseSummaryAction,
    createPlatePlugins,
    editorBlockRepository,
    ItemModel,
    itemService,
    makeUploadImage,
    markdownSerializer,
    notebookService,
    RERENDER_EDITOR_BLOCKS_EVENT,
    SummaryLengthEnum,
    usePluginHelpers,
} from "@recall/common"
import { useChatActions, useChatState } from "components/ItemPage/providers/ChatProvider"
import { summaryService } from "components/ItemPage/services/summaryService"
import { useOpenItem } from "hooks/items/useOpenItem"
import { useMemo } from "react"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import { summariesApi } from "services/api"
import { itemAPI } from "storage/api/items/Item"
import { RootState } from "storage/redux/rootReducer"

export const usePlatePlugins = (item: ItemModel, readOnly = false) => {
    const db = useDatabase()
    const uid = useSelector((state: RootState) => state.user.uid)
    const { openItemById } = useOpenItem()
    const {
        getItemByConnectionId,
        deleteConnectionById,
        createConnection,
        updateImage,
        isMainImage,
    } = usePluginHelpers(item)

    const language = useSelector((state: RootState) => state.user.settings.searchLanguage)

    const searchWikipedia = async (query: string) => {
        return await itemAPI.searchWikipedia(query, language)
    }

    const { messages } = useChatState()
    const { updateMessages } = useChatActions()

    const handleDelete = async (id: string) => {
        const messageId = await notebookService.handleDeleteAIAction(db, id)
        if (!messageId) return

        const updatedMessages = messages.map((message) => {
            if (message.id === messageId) {
                const { notebookElementId, ...rest } = message
                return { ...rest }
            }
            return message
        })

        await updateMessages(updatedMessages)
    }

    const handleRegenerateAIAction = async (id: string) => {
        const editorBlock = await editorBlockRepository.get(db, id)
        if (!editorBlock) return

        await db.write(async () => {
            await editorBlock.update((record) => {
                record.children = [{ text: "" }]
                record.options = { ...record.options, isExpanded: true, isLoading: true }
            })
        })
        document.dispatchEvent(new CustomEvent(RERENDER_EDITOR_BLOCKS_EVENT))

        const chunks = await chunksService.getChunks(uid, item.id)
        const itemMarkdown = chunksService.getMarkdown(chunks)

        const markdown = await summaryService.summarize(
            item,
            itemMarkdown,
            editorBlock.options.action.id === conciseSummaryAction.id
                ? SummaryLengthEnum.concise
                : SummaryLengthEnum.detailed,
            language
        )

        const editorBlocks = await itemService.enrichMarkdown(db, markdown, item)

        await db.write(async () => {
            await editorBlock.update((record) => {
                record.children = editorBlocks
                record.options = { ...record.options, isLoading: false, isExpanded: true }
            })
        })

        document.dispatchEvent(new CustomEvent(RERENDER_EDITOR_BLOCKS_EVENT))

        if (editorBlock.options.messageId) {
            const updatedMessages = messages.map((message) => {
                if (message.id === editorBlock.options.messageId) {
                    return { ...message, content: markdown }
                }
                return message
            })
            await updateMessages(updatedMessages)
        }
    }

    const handleCopyAIAction = async (id: string) => {
        try {
            const editorBlock = await editorBlockRepository.get(db, id)
            if (!editorBlock) throw new Error("Editor block not found")
            const markdown = await markdownSerializer.serialize([editorBlock], db)
            await navigator.clipboard.writeText(markdown)
            toast.success("Copied to clipboard")
        } catch (err) {
            toast.error("Failed to copy to clipboard")
        }
    }

    const toggleAIAction = async (id: string) => {
        return await notebookService.handleToggleAIAction(db, id)
    }

    const plugins = useMemo(() => {
        return createPlatePlugins({
            readOnly,
            isMainImage,
            updateImage,
            uploadImage: makeUploadImage(db, item, uid),
            openItemById,
            getItemByConnectionId,
            deleteConnectionById,
            createConnection,
            searchWikipedia,
            getSummaryPreview: summariesApi.getSummaryPreview,
            handleToggleAIAction: toggleAIAction,
            handleDeleteAIAction: handleDelete,
            handleRegenerateAIAction,
            handleCopyAIAction,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item])

    return plugins
}
