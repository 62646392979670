import { DialogContent, Typography } from "@mui/material"
import { SUMMARY_LENGTH_DIV_ID, tutorials } from "@recall/common"
import React, { memo } from "react"
import { AppTutorialBase } from "./AppTutorialBase"
import { Step, commonTutorialStyles } from "@recall/common"

const TutorialSummaryLengthComponent: React.FC<React.PropsWithChildren<unknown>> = () => {
    const steps: Step[] = [
        {
            anchorId: SUMMARY_LENGTH_DIV_ID,
            placement: "bottom",
            showArrow: true,
            component: () => (
                <DialogContent sx={commonTutorialStyles.popoverContent}>
                    <Typography sx={commonTutorialStyles.heading}>Summary length</Typography>
                    <Typography sx={commonTutorialStyles.body}>
                        Switch between concise and detailed summaries.
                    </Typography>
                </DialogContent>
            ),
        },
    ]

    return <AppTutorialBase tutorialName={tutorials.SUMMARY_LENGTH} steps={steps} />
}

export const TutorialSummaryLength = memo(TutorialSummaryLengthComponent)
