import { DEFAULT_LANGUAGE, DEFAULT_SEARCH_LANGUAGE, languages } from "../constants"
import { SummaryLengthEnum } from "../repositories/userRepository"
import { HttpClient } from "../utils"
import { buildPath, buildUrl } from "../utils/httpClient/common"
import { ItemApi } from "./types"
import { BaseApi, Options } from "./BaseApi"

export interface YouTubeData {
    url: string
    transcript: string[] | null
    includesSentenceTimestamps: boolean
    metaData: YouTubeMetaData
    summaryLength: SummaryLengthEnum
}

export interface YouTubeTranscriptPayload {
    url: string
    transcript: string[]
    summaryLength: SummaryLengthEnum
    name: string
}

export interface YouTubeSectionsPayload {
    url: string
    sections: Section[]
    summaryLength: SummaryLengthEnum
    name: string
}

export interface YouTubeSectionsData {
    url: string
    sections: Section[] | null
    includesSentenceTimestamps: boolean
    metaData: YouTubeMetaData
    summaryLength: SummaryLengthEnum
}

export interface YouTubeMetaData {
    name: string
    author: string
    description: string
    imageUrl: string
}

export interface Section {
    heading: string
    sentences: string[]
    start: number
    end: number
}

export interface SearchPreview {
    title: string | null
    image: string | null
    description: string | null
}

const PAGE_SUMMARY_PATH = "/scraper/"
const PAGE_HTML_SUMMARY_PATH = "/scraper/encoded-html/"
const MARKDOWN_SUMMARY_PATH = "/summary/markdown/"
const YOUTUBE_TRANSCRIPT_SUMMARY_PATH = "/summary/youtube/transcript/"
const YOUTUBE_MARKDOWN_SUMMARY_PATH = "/summary/youtube/markdown/"
const YOUTUBE_SECTIONS_SUMMARY_PATH = "/summary/youtube/sections/"
const PDF_HTML_SUMMARY_PATH = "/scraper/pdf/encoded-html/"
const PDF_FILE_SUMMARY_PATH = "/scraper/pdf/upload/"
const WIKIPEDIA_SUMMARY_PATH = "/scraper/wikipedia/"
const WIKI_SUMMARY_PATH = "/items/get"
const WIKI_SUMMARY_SEARCH_PATH = "/items/search"
const SUMMARY_PREVIEW_PATH = "/search-preview/"
const EXPAND_SUMMARY_URL_PATH = "/expand_url/"
const ALLOW_SAVE_IN_BACKGROUND_HEADER = "Allow-Save-In-Background"

export class SummariesApi extends BaseApi {
    constructor(httpClient: HttpClient) {
        super(httpClient)
    }

    async summarizeMarkdown(
        url: string,
        itemId: string,
        summaryLength: SummaryLengthEnum,
        markdown: string,
        name: string,
        options: Options = {}
    ) {
        const response = await this.httpClient.post(
            buildUrl(MARKDOWN_SUMMARY_PATH, { url, itemId }),
            JSON.stringify({ url, markdown, summaryLength, name }),
            this.getHeaders(options)
        )
        return await response.json()
    }

    async summarizeYoutubeMarkdown(
        url: string,
        itemId: string,
        summaryLength: SummaryLengthEnum,
        markdown: string,
        name: string,
        options: Options = {}
    ) {
        const response = await this.httpClient.post(
            buildUrl(YOUTUBE_MARKDOWN_SUMMARY_PATH, { url, itemId }),
            JSON.stringify({ url, markdown, summaryLength, name }),
            this.getHeaders(options)
        )
        return await response.json()
    }

    async summarizeWikipediaPage(
        slug: string,
        language = DEFAULT_SEARCH_LANGUAGE
    ): Promise<ItemApi> {
        const response = await this.httpClient.get(
            buildUrl(WIKI_SUMMARY_PATH, { query: slug, language })
        )
        return await response.json()
    }

    async findWikipediaSummary(
        query: string,
        language = DEFAULT_SEARCH_LANGUAGE
    ): Promise<ItemApi[]> {
        const response = await this.httpClient.get(
            buildUrl(WIKI_SUMMARY_SEARCH_PATH, { query, language })
        )
        return await response.json()
    }

    async getSummaryPreview(url: string): Promise<SearchPreview> {
        const response = await this.httpClient.get(buildUrl(SUMMARY_PREVIEW_PATH, { url }))
        return await response.json()
    }

    async expandSummaryUrl(url: string): Promise<string | null> {
        const response = await this.httpClient.get(buildUrl(EXPAND_SUMMARY_URL_PATH, { url }))
        const data = await response.json()
        return data?.url || null
    }
}
