import { HOME_PATH } from "constants/routes"
import produce from "immer"
import {
    ADD_LOADING_QUESTION_ITEM_ID,
    INCREASE_VISIBLE_ITEMS,
    REMOVE_LOADING_QUESTION_ITEM_ID,
    RESET_SCROLL_STATE,
    SET_ADD_MODAL_TAB,
    SET_APP_LOADING,
    SET_BOOKMARKS_IMPORT_STATE,
    SET_BREADCRUMBS,
    SET_BRIGHTNESS_MODE,
    SET_EXTENSION_STATE,
    SET_IMPORT_STATE,
    SET_IS_ADD_MODAL_OPEN,
    SET_IS_AUTH_MODAL_OPEN,
    SET_IS_INITIAL_SYNC_FINISHED,
    SET_IS_NATIVE,
    SET_IS_SCRAPING_MODAL_OPEN,
    SET_IS_UPGRADE_MODAL_OPEN,
    SET_LOADING,
    SET_SCROLL_POSITION,
    SET_SEARCH_TERM,
    SET_USER_LOADING,
    SET_IS_DB_MIGRATING,
} from "./actionTypes"

import { TAB_URL_SEARCH } from "./constants"
import { AddModalState, AppState, extensionStateEnum, importEnum, themeModeEnum } from "./types"

const ITEMS_PER_PAGE = 25
export const defaultBreadcrumb = { label: "Home", path: HOME_PATH }

export const initialBookmarkState = {
    created: 0,
    existing: 0,
    count: 0,
    failedBookmarks: [],
}

export const initialAddModalState: AddModalState = {
    isOpen: false,
    tab: TAB_URL_SEARCH,
}

const initialState: AppState = {
    platform: "web",
    isNative: false,
    themeMode: themeModeEnum.DARK,
    loading: false,
    appLoading: false,
    userLoading: true,
    importState: importEnum.CLOSED,
    addModalState: initialAddModalState,
    extensionState: extensionStateEnum.NOT_LOGGED_IN,
    isUpgradePlanModalOpen: false,
    isScrapingModalOpen: false,
    visibleItems: ITEMS_PER_PAGE,
    scrollPosition: 0,
    questionsLoadingItemIds: [],
    breadcrumbs: [defaultBreadcrumb],
    isInitialSyncFinished: false,
    searchTerm: "",
    bookmarks: initialBookmarkState,
    isAuthModalOpen: false,
    isDbMigrating: false,
}

export const appReducer = (state = initialState, action) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case SET_BRIGHTNESS_MODE: {
                const themeMode = action.payload
                draftState.themeMode = themeMode
                break
            }
            case SET_LOADING: {
                const loading = action.payload
                draftState.loading = loading
                break
            }
            case SET_IMPORT_STATE: {
                draftState.importState = action.payload
                break
            }
            case SET_APP_LOADING: {
                draftState.appLoading = action.payload
                break
            }
            case SET_EXTENSION_STATE: {
                draftState.extensionState = action.payload
                break
            }
            case SET_IS_UPGRADE_MODAL_OPEN: {
                draftState.isUpgradePlanModalOpen = action.payload
                break
            }
            case SET_IS_ADD_MODAL_OPEN: {
                draftState.addModalState.isOpen = action.payload
                break
            }
            case SET_ADD_MODAL_TAB: {
                draftState.addModalState.tab = action.payload
                break
            }
            case SET_IS_SCRAPING_MODAL_OPEN: {
                draftState.isScrapingModalOpen = action.payload
                break
            }
            case INCREASE_VISIBLE_ITEMS: {
                draftState.visibleItems = state.visibleItems + ITEMS_PER_PAGE
                break
            }
            case SET_SCROLL_POSITION: {
                draftState.scrollPosition = action.payload
                break
            }
            case RESET_SCROLL_STATE: {
                draftState.visibleItems = ITEMS_PER_PAGE
                draftState.scrollPosition = 0
                break
            }
            case SET_USER_LOADING: {
                draftState.userLoading = action.payload
                break
            }
            case SET_IS_NATIVE: {
                draftState.isNative = true
                draftState.platform = action.payload
                break
            }
            case ADD_LOADING_QUESTION_ITEM_ID: {
                draftState.questionsLoadingItemIds = [
                    ...state.questionsLoadingItemIds,
                    action.payload,
                ]
                break
            }
            case REMOVE_LOADING_QUESTION_ITEM_ID: {
                draftState.questionsLoadingItemIds = state.questionsLoadingItemIds.filter(
                    (id) => id !== action.payload
                )
                break
            }
            case SET_BREADCRUMBS: {
                draftState.breadcrumbs = action.payload
                break
            }
            case SET_IS_INITIAL_SYNC_FINISHED: {
                draftState.isInitialSyncFinished = action.payload
                break
            }
            case SET_SEARCH_TERM: {
                const searchTerm = action.payload
                draftState.searchTerm = searchTerm
                break
            }
            case SET_BOOKMARKS_IMPORT_STATE: {
                const bookmarksState = action.payload
                draftState.bookmarks = bookmarksState
                break
            }
            case SET_IS_AUTH_MODAL_OPEN: {
                draftState.isAuthModalOpen = action.payload
                break
            }
            case SET_IS_DB_MIGRATING: {
                draftState.isDbMigrating = action.payload
                break
            }
            default:
                return state
        }
    })
