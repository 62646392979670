import { Box, Fade, SxProps, Theme } from "@mui/material"
import { useDatabase } from "@nozbe/watermelondb/react"
import {
    checkExtensionStatus,
    COMPLETE_TUTORIAL_EVENT,
    itemRepository,
    posthogService,
    sentry,
    tutorials,
    useIsMobile,
    useTabFocus,
} from "@recall/common"
import { DarkLayout } from "components/layouts/DarkLayout"
import { CHOOSE_INTERESTS_EVENT } from "constants/events"
import { HOME_PATH } from "constants/routes"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"
import { createItemService } from "services/createItemService"
import { extensionOptionsService } from "services/extensionOptionsService"
import { RootState } from "storage/redux/rootReducer"
import { SET_ACTIVE_TUTORIAL, TUTORIAL_COMPLETE } from "storage/redux/user/actionTypes"
import { ChooseInterestsStep } from "./components/ChooseInterestsStep"
import { CreateExtensionCardStep } from "./components/CreateExtensionCardStep"
import { ExperienceBrowserStep } from "./components/ExperienceBrowserStep"
import { InstallExtensionStep } from "./components/InstallExtensionStep"
import { IntroductionStep } from "./components/IntroductionStep"
import { MobileStep } from "./components/MobileStep"
import { PinTheExtensionStep } from "./components/PinTheExtensionStep"
import { Interest } from "./components/types"

const FadeWrapper: React.FC<React.PropsWithChildren<{ in: boolean }>> = ({
    children,
    in: inProp,
}) => (
    <Fade unmountOnExit in={inProp} timeout={1000}>
        <Box>{inProp && <Box sx={styles.step}>{children}</Box>}</Box>
    </Fade>
)

const InstallExtensionPage = () => {
    const [activeStep, setActiveStep] = useState<
        | "mobile"
        | "introduction"
        | "choose-interests"
        | "install"
        | "pin"
        | "summarize"
        | "extension-examples"
    >("introduction")

    const history = useHistory()
    const dispatch = useDispatch()
    const isMobile = useIsMobile()
    const db = useDatabase()
    const uid = useSelector((state: RootState) => state.user.uid)
    const isAugmentedBrowserOnboarding = useMemo(() => {
        return posthogService.getFeatureFlag("onboarding-flow") === "augmented-browsing"
    }, [])

    const [selectedInterest, setSelectedInterest] = useState<Interest | null>(null)

    useEffect(() => {
        if (isMobile) setActiveStep("mobile")
    }, [isMobile])

    const enableWidgetAndRabForNewUsers = async () => {
        if (!uid) return

        await extensionOptionsService.update(uid, {
            widgetDisabled: false,
            augmentedBrowserDisabled: false,
        })
    }

    useEffect(() => {
        if (!uid) return
        enableWidgetAndRabForNewUsers()
    }, [uid])

    useEffect(() => {
        dispatch({ type: SET_ACTIVE_TUTORIAL, payload: tutorials.EXTENSION })
        getExtensionStatus()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getExtensionStatus = useCallback(async () => {
        const { isExtensionInstalled } = await checkExtensionStatus()

        if (!isMobile && isExtensionInstalled && activeStep === "install") {
            setActiveStep("pin")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeStep])

    useEffect(() => {
        getExtensionStatus()
    }, [getExtensionStatus])

    useTabFocus(getExtensionStatus)

    const handleSkip = () => {
        posthogService.captureEvent(COMPLETE_TUTORIAL_EVENT, {
            tutorialName: tutorials.EXTENSION,
            skipped: true,
            step: activeStep,
        })
        dispatch({ type: TUTORIAL_COMPLETE, payload: tutorials.EXTENSION })
        dispatch({ type: SET_ACTIVE_TUTORIAL, payload: "none" })
        history.push(HOME_PATH)
    }

    const handleChooseInterests = async (interest: Interest) => {
        try {
            setActiveStep("summarize")
            setSelectedInterest(interest)
            posthogService.captureEvent(CHOOSE_INTERESTS_EVENT, {
                interest,
            })

            const existingItem = await itemRepository.getBySource({
                db,
                sourceIdentifier: interest.url,
            })

            if (existingItem) return

            await createItemService.createItemByInterest(db, interest)
        } catch (error) {
            sentry.captureException(error)
        }
    }

    return (
        <DarkLayout>
            <FadeWrapper in={activeStep === "mobile"}>
                <MobileStep handleContinue={() => handleSkip()} />
            </FadeWrapper>
            <FadeWrapper in={activeStep === "introduction"}>
                <IntroductionStep handleContinue={() => setActiveStep("install")} />
            </FadeWrapper>
            <FadeWrapper in={activeStep === "install"}>
                <InstallExtensionStep handleSkip={handleSkip} />
            </FadeWrapper>
            <FadeWrapper in={activeStep === "pin"}>
                <PinTheExtensionStep
                    handleContinue={() =>
                        setActiveStep(
                            isAugmentedBrowserOnboarding ? "choose-interests" : "extension-examples"
                        )
                    }
                />
            </FadeWrapper>
            <FadeWrapper in={activeStep === "extension-examples"}>
                <CreateExtensionCardStep handleSkip={handleSkip} />
            </FadeWrapper>
            <FadeWrapper in={activeStep === "choose-interests"}>
                <ChooseInterestsStep handleContinue={handleChooseInterests} />
            </FadeWrapper>
            <FadeWrapper in={activeStep === "summarize"}>
                <ExperienceBrowserStep
                    handleSkip={handleSkip}
                    selectedInterest={selectedInterest}
                />
            </FadeWrapper>
        </DarkLayout>
    )
}

export default InstallExtensionPage

const styles: Record<string, SxProps<Theme>> = {
    step: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 1,
        py: 2,
        px: 3,
        minHeight: 400,
        width: { xs: "100%", md: "1000px" },
    },
}
