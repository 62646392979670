import { Wind } from "lucide-react"

import { Box, Typography } from "@mui/material"
import { Illustration } from "@recall/common"
import { AddButton } from "components/layouts/components/Header/AddButton/AddButton"

export const ItemsEmpty = () => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="100%"
            gap={2}
        >
            <Illustration icon={Wind} />
            <Typography variant="body1" textAlign="center">
                You have no cards
            </Typography>
            <AddButton />
        </Box>
    )
}
