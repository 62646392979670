import { debounce, Input } from "@mui/material"
import { ItemModel } from "@recall/common"
import { useBreadcrumbActions } from "hooks/useBreadcrumbActions"
import { FC, useCallback, useState } from "react"

interface Props {
    item: ItemModel
    showImage?: boolean
}

export const ItemName: FC<Props> = ({ item, showImage }) => {
    const [name, setName] = useState(item.name)
    const { updateLastLabel } = useBreadcrumbActions()

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        setName(value)
        updateLastLabel(value)
        handleChange(value)
    }

    const handleChange = useCallback(
        debounce(async (value: string) => {
            await item.updateName(value)
        }, 500),
        []
    )

    return (
        <Input
            placeholder="Untitled"
            multiline
            sx={{
                width: "100%",
                flex: 1,
                border: "none",
                fontSize: "24px",
                fontWeight: "bold",
                p: 0,
            }}
            value={name}
            disableUnderline
            onChange={handleNameChange}
        />
    )
}
