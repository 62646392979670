import { IconButton } from "@mui/material"
import { useIsMobile } from "@recall/common"
import { PanelLeftOpen } from "lucide-react"
import { FC } from "react"
import { useDispatch, useSelector } from "react-redux"
import { SET_DRAWER_OPEN } from "storage/redux/drawer/actionTypes"
import { RootState } from "storage/redux/rootReducer"

export const DrawerButton: FC = () => {
    const dispatch = useDispatch()
    const drawerOpen = useSelector((state: RootState) => state.drawer.open)
    const isMobile = useIsMobile()

    const handleDrawerOpen = () => {
        dispatch({ type: SET_DRAWER_OPEN, payload: true })
    }

    if (!isMobile && drawerOpen) {
        return null
    }

    return (
        <IconButton
            color="default"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ ml: 0, color: "text.primary" }}
        >
            <PanelLeftOpen size={isMobile ? 24 : 20} />
        </IconButton>
    )
}

export default DrawerButton
