import { Box, keyframes, SxProps, Theme } from "@mui/material"
import { Plate, PlateProvider } from "@udecode/plate-common"
import { ELEMENT_PARAGRAPH } from "@udecode/plate-paragraph"
import { isEqual } from "lodash"
import { FC, useCallback, useEffect, useMemo, useState } from "react"
import { v4 as uuid } from "uuid"
import { isFirefox } from "../utils"
import { PlateConfig } from "./PlateConfig"
import { EditorSkeleton } from "./components/EditorSkeleton"
import { FixedToolbar } from "./components/Toolbar/FixedToolbar"
import { FloatingToolbar } from "./components/Toolbar/FloatingToolbar"
import { editableProps } from "./config"
import { RERENDER_EDITOR_BLOCKS_EVENT } from "./constants"
import { EditorElements, MyPlatePlugin } from "./types"

interface Props {
    id: string
    readOnly?: boolean
    plugins: MyPlatePlugin[]
    isExtension?: boolean
    sx?: SxProps<Theme>
    initEditorBlocks: () => Promise<EditorElements>
    updateEditorBlocks: (_: EditorElements) => Promise<void>
    cleanUpCopiedBlocks: (blocks: EditorElements, depth?: number) => Promise<EditorElements>
    uploadImage: (arrayBuffer: ArrayBuffer, contentType: string) => Promise<string>
    isLoading?: boolean
    focus?: boolean
}

export const PlateEditor: FC<Props> = ({
    id,
    readOnly = false,
    plugins,
    sx = {},
    isExtension = false,
    initEditorBlocks,
    updateEditorBlocks,
    cleanUpCopiedBlocks,
    uploadImage,
    isLoading = false,
    focus = false,
}) => {
    const [editorBlocks, setEditorBlocks] = useState<EditorElements | null>(null)
    // Force rerenders editor, after image deletion for e.g., changing editorBlock don't cause rerender so this is hacky solution
    const [plateEditorKey, setPlateEditorKey] = useState(uuid())

    const getEditorBlocks = useCallback(async () => {
        const editorBlocks = await initEditorBlocks()
        setEditorBlocks(editorBlocks)
        setPlateEditorKey(uuid())
    }, [initEditorBlocks])

    useEffect(() => {
        if (!id && !isLoading) return
        getEditorBlocks()

        document.addEventListener(RERENDER_EDITOR_BLOCKS_EVENT, getEditorBlocks)

        return () => {
            document.removeEventListener(RERENDER_EDITOR_BLOCKS_EVENT, getEditorBlocks)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, getEditorBlocks])

    const update = async (newValue: EditorElements) => {
        await updateEditorBlocks(newValue)
        setEditorBlocks(newValue)
    }

    const handleChange = async (newValue: EditorElements) => {
        if (isEqual(editorBlocks, newValue)) return
        const cleanedUpValue = await cleanUpCopiedBlocks(newValue)
        await update(cleanedUpValue)
    }

    const isEmpty = useMemo(() => {
        if (!editorBlocks || editorBlocks.length > 1) return false

        return editorBlocks.every(
            (block) =>
                block.type === ELEMENT_PARAGRAPH &&
                block?.children?.every((child) => child.text === "")
        )
    }, [editorBlocks])

    if (!editorBlocks || isLoading) return <EditorSkeleton />

    return (
        <PlateProvider
            id={id}
            key={plateEditorKey}
            initialValue={editorBlocks}
            value={editorBlocks}
            onChange={handleChange}
            plugins={plugins}
        >
            <Box sx={[styles.editor, ...(Array.isArray(sx) ? sx : [sx])]}>
                <Plate<EditorElements>
                    id={id}
                    key={plateEditorKey}
                    plugins={plugins}
                    initialValue={editorBlocks}
                    value={editorBlocks}
                    onChange={handleChange}
                    editableProps={{
                        ...editableProps,
                        placeholder: isEmpty ? "Write something…" : undefined,
                        readOnly: (isExtension && isFirefox()) || readOnly,
                        className: "plate-editor",
                    }}
                >
                    <PlateConfig focus={focus} />
                    {!readOnly && <FloatingToolbar isExtension={isExtension} />}
                </Plate>
            </Box>
            {!isExtension && !readOnly && <FixedToolbar uploadImage={uploadImage} />}
        </PlateProvider>
    )
}

const highlighted = keyframes`
    0% {
        background: #F2B80720;
        padding: 2px;
    }

    70% {
        background: #F2B80760;
        padding: 2px;
    }

    100% {
        background: ##F2B80700;
        padding: 0;
    }
`

const highlightedAiAction = keyframes`
    0% {
        background: #F2B80720;
    }

    70% {
        background: #F2B80760;
    }

    100% {
        background: ##F2B80700;
    }
`

const styles: Record<string, SxProps<Theme>> = {
    editor: {
        fontSize: "14px",
        backgroundColor: "background.default",
        borderRadius: 1,
        p: 0,
        pb: 2,
        maxWidth: "100%",
        wordBreak: "break-word",
        position: "relative",
        flex: 1,
        overflow: "auto",
        display: "flex",
        flexDirection: "column",

        ".plate-editor": {
            height: "100%",
            flex: 1,
            "h1, h2, h3, h4, h5, h6": {
                lineHeight: 1.3,
                pt: 1.5,
                pb: 1,
                mt: 0,
                mb: 0,
            },
        },

        ".focus-animation": {
            animation: `${highlighted} 2s ease`,
            borderRadius: 1,
        },
        ".focus-ai-action-animation": {
            animation: `${highlightedAiAction} 2s ease`,
            borderRadius: 1,
        },
    },
}
