import { IconButton, Stack, SxProps, Theme, Tooltip } from "@mui/material"
import { flip, offset, shift, useFloatingToolbar } from "@udecode/plate-floating"
import { FC } from "react"
import { MarkButtons } from "./MarkButtons"
import { TextTypeMenu } from "./TextTypeMenu"

import { Bolt } from "@mui/icons-material"
import { useIsMobile } from "../../../hooks/useIsMobile"
import { CREATE_LINK_BUTTON_CLICKED_EVENT, CREATE_LINK_BUTTON_ID } from "../../constants"
import { getSelection, useFloatingToolbarState } from "../../hooks/useFloatingToolbarState"
import { useMyEditorRef } from "../../types"
import CustomEditor from "../../utils/CustomEditor"
import { LinkToolbarButton } from "./LinkToolbarButton"

export interface FloatingToolbarProps {
    isExtension?: boolean
}

export const FloatingToolbar: FC<FloatingToolbarProps> = ({ isExtension = false, ...props }) => {
    const isMobile = useIsMobile()
    const selection = getSelection(isExtension)

    const isTextSelected =
        selection?.anchorNode?.nodeName === "#text" && selection?.type === "Range"

    const editor = useMyEditorRef()

    const floatingToolbarState = useFloatingToolbarState({
        floatingOptions: {
            placement: "top",
            middleware: [
                offset(isMobile ? 55 : 10),
                flip({
                    padding: 12,
                }),
                shift({ padding: 5 }),
            ],
        },
        isExtension,
    })

    const { ref: floatingRef, props: rootProps, hidden } = useFloatingToolbar(floatingToolbarState)

    const shouldHideToolbar = hidden || (isExtension ? false : !isTextSelected)

    const insertReference = () => {
        CustomEditor.insertSearchReferenceText(editor, window?.getSelection()?.toString())
        document.dispatchEvent(new CustomEvent(CREATE_LINK_BUTTON_CLICKED_EVENT))
    }

    if (isMobile && shouldHideToolbar && !floatingToolbarState.isMenuOpen) return null
    if (!isMobile && shouldHideToolbar) return null

    return (
        <Stack
            ref={floatingRef}
            spacing={0.4}
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
                ...styles.stack,
                backgroundColor: (theme) =>
                    theme.palette.background[isExtension ? "paper" : "layout"],
            }}
            {...rootProps}
            {...props}
            onMouseDown={(e) => {
                e.preventDefault()
            }}
        >
            <TextTypeMenu
                isOpen={floatingToolbarState.isMenuOpen}
                setIsOpen={floatingToolbarState.setIsMenuOpen}
            />
            <MarkButtons isCodeVisible={!isMobile} />
            {!isExtension && <LinkToolbarButton />}
            {!isExtension && (
                <Tooltip title="Create link">
                    <IconButton
                        onClick={insertReference}
                        color="secondary"
                        id={CREATE_LINK_BUTTON_ID}
                    >
                        <Bolt />
                    </IconButton>
                </Tooltip>
            )}
        </Stack>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    stack: {
        zIndex: 1201,
        borderRadius: 1,
        px: 0.6,
        py: 0.3,
        boxShadow: (theme) => theme.shadows[4],
    },
}
