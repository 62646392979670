import ExpandIcon from "@mui/icons-material/Expand"
import { Tooltip } from "@mui/material"
import { WIKIPEDIA_PAGE } from "@recall/common"
import { useSources } from "components/ItemPage/hooks/useSources"
import { useItemContext } from "components/ItemPage/providers/ItemProvider"
import { LoadingButton } from "components/shared/buttons/LoadingButton"
import { FC } from "react"

export const EXPAND_DIV_ID = "id-expand-button"

export const ExpandButton: FC = () => {
    const { item, isExpanding, contentType, expandItem } = useItemContext()
    const { sources } = useSources(item)

    if (
        contentType !== "entity" ||
        item.isExpanded ||
        sources.every((source) => source.name !== WIKIPEDIA_PAGE)
    )
        return null

    return (
        <Tooltip title={`Expand "${item.name}" with info from the web.`}>
            <div>
                <LoadingButton
                    id={EXPAND_DIV_ID}
                    onClick={() => expandItem(item)}
                    variant="contained"
                    color="primary"
                    size="small"
                    loading={isExpanding}
                    startIcon={<ExpandIcon />}
                    sx={{
                        boxShadow: "none",
                        mr: 0.5,
                        minWidth: "94px",
                    }}
                >
                    Expand
                </LoadingButton>
            </div>
        </Tooltip>
    )
}
