import { Box, SxProps, Tab, Tabs, Theme } from "@mui/material"
import { useDatabase } from "@nozbe/watermelondb/react"
import {
    ChatIcon,
    ConnectionsIcon,
    editorBlockRepository,
    GraphIcon,
    LinksLoading,
    NotebookIcon,
    QuizIcon,
    ReaderIcon,
    useMediaBreakpoint,
} from "@recall/common"
import { useFocusElement } from "components/ItemPage/hooks/useFocusElement"
import { ITEM_CONTENT_ID, useTabHeight } from "components/ItemPage/hooks/useTabHeight"
import { useItemContext } from "components/ItemPage/providers/ItemProvider"
import { useTabActions, useTabState } from "components/ItemPage/providers/TabProvider"
import KnowledgeGraphPage from "components/KnowledgeGraphPage/KnowledgeGraphPage"
import { Editor } from "components/shared/editor/Editor"
import { FC, useRef } from "react"
import { Chat } from "./Chat"
import { ExpandWrapper } from "./ExpandWrapper"
import { ItemConnections } from "./ItemConnections"
import { Quiz } from "./Quiz"
import { Reader } from "./Reader/Reader"
import { ReaderWrapper } from "./Reader/ReaderWrapper"

export type TabType = "chat" | "notebook" | "connections" | "graph" | "quiz" | "reader"

export const READER_TAB_ID = "id-reader-tab"
export const NOTEBOOK_TAB_ID = "id-notebook-tab"
export const CHAT_TAB_ID = "id-chat-tab"
export const CONNECTIONS_TAB_ID = "id-connections-tab"

interface Props {
    isReaderVisible: boolean
    toggleReader: () => void
}

export const ItemTabs: FC<Props> = ({ isReaderVisible, toggleReader }) => {
    const { item, isExpanding, contentType, isCreatingConnections, expandItem } = useItemContext()
    const contentRef = useRef<HTMLDivElement>(null)
    const sentinelRef = useRef<HTMLDivElement>(null)
    const db = useDatabase()
    const { tab } = useTabState()
    const { setTab } = useTabActions()
    const { height, minHeight } = useTabHeight(contentRef, sentinelRef, contentType)
    const isTablet = useMediaBreakpoint("md")

    useFocusElement()

    const handleTabChange = (_: React.SyntheticEvent, newValue: TabType) => {
        setTab(newValue)
    }

    const expandEditorBlock = async (elementId: string) => {
        const editorBlock = await editorBlockRepository.get(db, elementId)
        if (!editorBlock) {
            setTab("notebook")
            return
        }

        await db.write(async () => {
            await editorBlock.update((record) => {
                record.options = {
                    ...record.options,
                    isExpanded: true,
                }
            })
        })
    }

    const openMessageInNotebook = async (elementId?: string) => {
        if (!elementId) {
            setTab("notebook")
            return
        }

        await expandEditorBlock(elementId)

        setTab("notebook")

        setTimeout(() => {
            const editorBlockElement = document.getElementById(elementId)
            if (!editorBlockElement) return false

            editorBlockElement.classList.add("focus-ai-action-animation")
            editorBlockElement.scrollIntoView({ block: "start" })

            setTimeout(() => {
                editorBlockElement.classList.remove("focus-ai-action-animation")
            }, 4000)
        }, 50)
    }

    if (!tab) return null

    return (
        <Box position="sticky" top={0} pt={2}>
            <Tabs
                TabIndicatorProps={{
                    sx: {
                        backgroundColor: "transparent",
                    },
                }}
                scrollButtons
                allowScrollButtonsMobile
                variant="scrollable"
                textColor="inherit"
                sx={styles.tabs}
                value={tab}
                onChange={handleTabChange}
            >
                <Tab
                    id={NOTEBOOK_TAB_ID}
                    iconPosition="start"
                    disableRipple
                    sx={styles.tab}
                    icon={<NotebookIcon />}
                    label="Notebook"
                    value="notebook"
                />
                {contentType === "page" && (!isReaderVisible || isTablet) && (
                    <Tab
                        id={READER_TAB_ID}
                        iconPosition="start"
                        disableRipple
                        sx={styles.tab}
                        value="reader"
                        label="Reader"
                        icon={<ReaderIcon />}
                    />
                )}
                {contentType === "page" && (
                    <Tab
                        id={CHAT_TAB_ID}
                        iconPosition="start"
                        disableRipple
                        sx={styles.tab}
                        icon={<ChatIcon />}
                        label="Chat"
                        value="chat"
                    />
                )}
                <Tab
                    iconPosition="start"
                    disableRipple
                    sx={styles.tab}
                    icon={<QuizIcon />}
                    label="Quiz"
                    value="quiz"
                />
                <Tab
                    iconPosition="start"
                    disableRipple
                    sx={styles.tab}
                    id={CONNECTIONS_TAB_ID}
                    icon={<ConnectionsIcon />}
                    label="Connections"
                    value="connections"
                />
                <Tab
                    iconPosition="start"
                    disableRipple
                    sx={styles.tab}
                    icon={<GraphIcon />}
                    label="Graph"
                    value="graph"
                />
            </Tabs>

            <Box
                sx={{
                    ...styles.content,
                    height,
                    minHeight,
                }}
                id={ITEM_CONTENT_ID}
                ref={contentRef}
            >
                {tab === "reader" && (
                    <ReaderWrapper isReaderVisible={isReaderVisible} toggleReader={toggleReader}>
                        <Reader />
                    </ReaderWrapper>
                )}
                {tab === "chat" && <Chat handleOpenNotebook={openMessageInNotebook} />}
                {tab === "notebook" && <Editor item={item} focus={true} />}
                {tab === "connections" &&
                    (!isCreatingConnections ? (
                        <ItemConnections />
                    ) : (
                        <Box p={2}>
                            <LinksLoading />
                        </Box>
                    ))}
                {tab === "graph" && (
                    <Box>
                        <KnowledgeGraphPage
                            width={contentRef?.current?.offsetWidth}
                            height={contentRef?.current?.offsetHeight}
                            item={item}
                        />
                    </Box>
                )}
                {tab === "quiz" && (
                    <ExpandWrapper
                        label="To unlock quiz, expand the item"
                        handleExpand={() => expandItem(item)}
                        isExpanding={isExpanding}
                    >
                        <Quiz item={item} />
                    </ExpandWrapper>
                )}
            </Box>
            <Box ref={sentinelRef} />
        </Box>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    tabs: {
        backgroundColor: "background.paper",
        borderRadius: 1,
        minHeight: "unset",
        p: 0.25,
        position: { xs: "sticky", md: "static" },
        top: 0,
        zIndex: 1000,
        "& .MuiTabScrollButton-root": {
            width: "unset",
        },
        "& .MuiTab-root": {
            color: (theme) => theme.palette.action.active,
            fontSize: { xs: 12, sm: 13, md: 14 },
            fontWeight: "500",
            minHeight: "unset",
            minWidth: "unset",
            svg: {
                height: { xs: 16, sm: 18, md: 20 },
                width: { xs: 16, sm: 18, md: 20 },
                path: {
                    fill: (theme) => theme.palette.action.active,
                },
            },
        },
        "& .Mui-selected": {
            color: (theme) => `${theme.palette.text.primary} !important`,
            svg: {
                path: {
                    fill: (theme) => theme.palette.text.primary,
                },
            },
        },
        "& .MuiTabs-scrollButtons.Mui-disabled": {
            display: "none",
        },
    },
    tab: {
        minHeight: 0,
        borderRadius: 0.5,
        m: 0.5,
        minWidth: 0,
        "&.Mui-selected": {
            backgroundColor: "background.layout",
        },
    },
    content: {
        bgcolor: "background.paper",
        borderRadius: 1,
        mt: 2,
        transition: "height 0.3s ease",
        display: "flex",
        flexDirection: "column",
    },
}
