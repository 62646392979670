import { addColumns, createTable, schemaMigrations } from "@nozbe/watermelondb/Schema/migrations"
import {
    ASSETS,
    CONNECTIONS,
    CONNECTION_PROPERTIES,
    DATA_MIGRATIONS,
    EDITOR_BLOCKS,
    ITEMS,
    ITEM_TAG,
    MENTIONS,
    QUESTIONS,
    QUESTION_REVIEWS,
    TAGS,
} from "./schema"

export const migrations = schemaMigrations({
    migrations: [
        {
            toVersion: 23,
            steps: [],
        },
        {
            toVersion: 24,
            steps: [
                createTable({
                    name: CONNECTION_PROPERTIES,
                    columns: [
                        { name: "name", type: "string" },
                        { name: "display", type: "string" },
                        { name: "description", type: "string", isOptional: true },
                        { name: "wikidata_pid", type: "string", isOptional: true },
                        { name: "is_saved", type: "boolean" },
                    ],
                }),
                addColumns({
                    table: CONNECTIONS,
                    columns: [
                        { name: "property_id", type: "string", isIndexed: true, isOptional: true },
                    ],
                }),
            ],
        },
        {
            toVersion: 25,
            steps: [
                createTable({
                    name: TAGS,
                    columns: [
                        { name: "name", type: "string", isIndexed: true },
                        { name: "is_saved", type: "boolean" },
                    ],
                }),
                createTable({
                    name: ITEM_TAG,
                    columns: [
                        { name: "tag_id", type: "string", isIndexed: true },
                        { name: "item_id", type: "string", isIndexed: true },
                        { name: "is_saved", type: "boolean" },
                        { name: "created_at", type: "number" },
                    ],
                }),
            ],
        },
        {
            toVersion: 26,
            steps: [
                addColumns({
                    table: TAGS,
                    columns: [
                        { name: "children", type: "string" },
                        { name: "is_reference", type: "boolean" },
                    ],
                }),
            ],
        },
        {
            toVersion: 27,
            steps: [
                createTable({
                    name: QUESTIONS,
                    columns: [
                        { name: "item_id", type: "string", isIndexed: true },
                        { name: "is_saved", type: "boolean" },
                        { name: "question", type: "string" },
                        { name: "answer", type: "string" },
                        { name: "correct_answer", type: "string" },
                        { name: "options", type: "string" },
                        { name: "created_at", type: "number" },
                        { name: "updated_at", type: "number" },
                    ],
                }),
            ],
        },
        {
            toVersion: 28,
            steps: [
                addColumns({
                    table: EDITOR_BLOCKS,
                    columns: [
                        { name: "type", type: "string" },
                        { name: "options", type: "string" },
                    ],
                }),
            ],
        },
        {
            toVersion: 29,
            steps: [
                createTable({
                    name: DATA_MIGRATIONS,
                    columns: [
                        { name: "name", type: "string", isIndexed: true },
                        { name: "created_at", type: "number" },
                        { name: "updated_at", type: "number" },
                        { name: "is_saved", type: "boolean" },
                    ],
                }),
                addColumns({
                    table: ITEMS,
                    columns: [{ name: "is_expanded", type: "boolean", isOptional: true }],
                }),
            ],
        },
        {
            toVersion: 30,
            steps: [
                addColumns({
                    table: EDITOR_BLOCKS,
                    columns: [{ name: "text", type: "string" }],
                }),
            ],
        },
        {
            toVersion: 31,
            steps: [
                addColumns({
                    table: ITEMS,
                    columns: [{ name: "language", type: "string" }],
                }),
            ],
        },
        {
            toVersion: 32,
            steps: [
                addColumns({
                    table: QUESTIONS,
                    columns: [{ name: "next_review_time", type: "boolean" }],
                }),
                createTable({
                    name: QUESTION_REVIEWS,
                    columns: [
                        { name: "item_id", type: "string", isIndexed: true },
                        { name: "question_id", type: "string", isIndexed: true },
                        { name: "is_saved", type: "boolean" },
                        { name: "question", type: "string" },
                        { name: "answer", type: "string" },
                        { name: "options", type: "string" },
                        { name: "correct_answer", type: "string" },
                        { name: "is_correct", type: "boolean" },
                        { name: "created_at", type: "number" },
                        { name: "updated_at", type: "number" },
                    ],
                }),
            ],
        },
        {
            toVersion: 33,
            steps: [
                addColumns({
                    table: ITEMS,
                    columns: [
                        { name: "description", type: "string" },
                        { name: "image", type: "string" },
                    ],
                }),
            ],
        },
        {
            toVersion: 34,
            steps: [
                createTable({
                    name: ASSETS,
                    columns: [
                        { name: "url", type: "string" },
                        { name: "size", type: "number" },
                        { name: "type", type: "string" },
                        { name: "item_id", type: "string", isIndexed: true },
                        { name: "is_saved", type: "boolean" },
                        { name: "created_at", type: "number" },
                    ],
                }),
            ],
        },
        {
            toVersion: 35,
            steps: [
                addColumns({
                    table: TAGS,
                    columns: [
                        { name: "parent_id", type: "string", isIndexed: true },
                        { name: "created_at", type: "number" },
                        { name: "updated_at", type: "number" },
                    ],
                }),
            ],
        },
        {
            toVersion: 36,
            steps: [
                addColumns({
                    table: ITEMS,
                    columns: [{ name: "length", type: "string" }],
                }),
            ],
        },
        {
            toVersion: 37,
            steps: [
                createTable({
                    name: MENTIONS,
                    columns: [
                        { name: "blocks", type: "string" },
                        { name: "element_id", type: "string" },
                        { name: "item_id", type: "string", isIndexed: true },
                        { name: "is_saved", type: "boolean" },
                        { name: "editor_block_id", type: "string", isIndexed: true },
                        { name: "connection_id", type: "string", isIndexed: true },
                    ],
                }),
                addColumns({
                    table: ITEMS,
                    columns: [
                        { name: "aliases", type: "string", isIndexed: true },
                        { name: "is_hidden_in_rab", type: "boolean", isIndexed: true },
                        { name: "is_readable", type: "boolean", isOptional: true, isIndexed: true },
                        { name: "is_loading", type: "boolean", isOptional: true },
                    ],
                }),
            ],
        },
    ],
})
