import { Info } from "@mui/icons-material"
import { Box, IconButton, SxProps, Theme, Tooltip, Typography } from "@mui/material"
import { useDatabase } from "@nozbe/watermelondb/react"
import { ItemModel, QuestionModel, questionRepository, useOpenInNewTab } from "@recall/common"
import { Questions } from "components/shared/questions/Questions"
import { RECALL_REVIEW_DOCS_URL } from "components/SpacedRepetition/constants"
import { FC, useEffect, useState } from "react"

interface Props {
    item: ItemModel
}

export const Quiz: FC<Props> = ({ item }) => {
    const db = useDatabase()
    const [questions, setQuestions] = useState<QuestionModel[]>([])
    const { openInNewTab } = useOpenInNewTab()

    useEffect(() => {
        getQuestions()
    }, [item.id])

    const getQuestions = async () => {
        const questions = await questionRepository.getQuestionsByItemId(db, item.id)
        setQuestions(questions)
    }

    const handleOpenReviewDocs = () => {
        openInNewTab(RECALL_REVIEW_DOCS_URL)
    }

    return (
        <Box sx={styles.container}>
            <Box sx={styles.info}>
                <Tooltip
                    title={
                        <>
                            <Typography variant="h6">How does this work?</Typography>
                            This will generate questions based on the information in this knowledge
                            card. You can then answer these questions to test your knowledge on the
                            content.
                            <Typography variant="h6">The Recall Review</Typography>
                            Additionally the questions will be added to your Recall Review. The
                            Recall Review will schedule these questions to be shown to you at the
                            optimal time for you to remember the information.
                        </>
                    }
                >
                    <IconButton onClick={handleOpenReviewDocs}>
                        <Info />
                    </IconButton>
                </Tooltip>
            </Box>
            <Questions questions={questions} setQuestions={setQuestions} />
        </Box>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    container: {
        overflow: "auto",
        position: "relative",
        flex: 1,
        display: "flex",
        flexDirection: "column",
    },
    info: {
        position: "absolute",
        top: 10,
        right: 10,
    },
}
