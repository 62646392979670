import { Close } from "@mui/icons-material"
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    IconButton,
    SxProps,
    Theme,
} from "@mui/material"
import { CONTACT_US_EMAIL, TitleTertiary } from "@recall/common"
import { FC } from "react"

interface Props {
    closeModal(): void
    isPremiumUser: boolean
    upgradeButton: React.ReactNode
}

export const UsageExceededModal: FC<Props> = ({ closeModal, isPremiumUser, upgradeButton }) => {
    return (
        <Dialog onClose={closeModal} open sx={styles.container} maxWidth="xs" fullWidth>
            <TitleTertiary sx={styles.title}>
                You have reached your AI limit
                <IconButton aria-label="close" onClick={closeModal} sx={styles.closeButton}>
                    <Close />
                </IconButton>
            </TitleTertiary>
            <DialogContent>
                {isPremiumUser ? (
                    <>
                        <DialogContentText>
                            We apologize for the inconvenience, but it appears that you have
                            exceeded the usage limit for our service. Please don't worry, as this is
                            just a preventative measure to ensure the best experience for all of our
                            users. To resolve this issue, please reach out to our support team and
                            we'll be happy to assist you. Thank you for your understanding.
                        </DialogContentText>
                        <DialogContentText mt={2}>Contact: {CONTACT_US_EMAIL}</DialogContentText>
                    </>
                ) : (
                    <DialogContentText>
                        You can still add cards and use the Recall Reader, but AI features (chat,
                        summaries, connections) require upgrading to Recall Plus.
                    </DialogContentText>
                )}
            </DialogContent>
            {!isPremiumUser && <DialogActions>{upgradeButton}</DialogActions>}
        </Dialog>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        padding: 2,
    },
    title: {
        p: 2,
        mr: 4,
        fontWeight: 600,
    },
    closeButton: {
        position: "absolute",
        right: 8,
        top: 8,
        color: (theme) => theme.palette.action.active,
    },
}
