import { Button, ButtonProps } from "@mui/material"
import { Zap } from "lucide-react"
import { FC } from "react"

interface Props extends ButtonProps {
    onClick: () => void
}

export const UpgradeButton: FC<Props> = ({ onClick }) => {
    return (
        <Button
            variant="contained"
            color="primary"
            sx={{
                minWidth: "130px",
            }}
            onClick={() => onClick()}
            endIcon={<Zap size={20} />}
        >
            Upgrade
        </Button>
    )
}
