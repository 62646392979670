import { ExtensionOptions, UserRepository } from "../../repositories/userRepository"

export class ExtensionOptionsService {
    userRepository: UserRepository

    constructor(userRepository: UserRepository) {
        this.userRepository = userRepository
    }

    getDefault = (): ExtensionOptions => {
        return {
            widgetPosition: 200,
            widgetDisabled: true,
            widgetDisabledWebsites: [],
            augmentedBrowserDisabled: true,
            autosave: false,
        }
    }

    get = async (uid: string): Promise<ExtensionOptions> => {
        const user = await this.userRepository.getUser(uid)

        const defaultOptions = this.getDefault()

        const mergedOptions = {
            ...defaultOptions,
            ...user?.extension,
        }

        return mergedOptions
    }

    update = async (uid: string, options: Partial<ExtensionOptions>): Promise<ExtensionOptions> => {
        const defaultOptions = this.getDefault()

        const oldOptions = await this.get(uid)

        const newOptions = { ...defaultOptions, ...oldOptions, ...options }

        await this.userRepository.upsertUser(uid, {
            extension: newOptions,
        })

        return newOptions
    }
}
