import { SxProps, Theme, Typography, TypographyProps } from "@mui/material"
import { FC } from "react"

export const TitleTertiary: FC<TypographyProps> = ({ children, sx, ...props }) => {
    const style = {
        ...styles.title,
        ...sx,
    } as SxProps<Theme>

    return (
        <Typography variant="h3" sx={style} {...props}>
            {children}
        </Typography>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    title: {
        textAlign: "center",
        fontWeight: 500,
        fontSize: 30,
    },
}
