import { IconButton, Tooltip } from "@mui/material"
import React from "react"

interface Props {
    tooltip: React.ReactNode
    onClick: () => void
    color?: "default" | "inherit" | "primary" | "secondary" | "error"
}

export const ActionButton: React.FC<React.PropsWithChildren<Props>> = ({
    tooltip,
    onClick,
    children,
    color = "default",
}) => {
    return (
        <Tooltip title={tooltip}>
            <IconButton sx={{ m: 0.5, fontSize: "20px" }} onClick={onClick} color={color}>
                {children}
            </IconButton>
        </Tooltip>
    )
}
