import {
    alpha,
    Box,
    IconButton,
    Menu,
    MenuItem,
    Skeleton,
    SxProps,
    Theme,
    Tooltip,
} from "@mui/material"
import { PlateElementProps } from "@udecode/plate-common"

import { MoreVert, UnfoldLess, UnfoldMore } from "@mui/icons-material"
import { PlateElement } from "@udecode/plate-common"
import { useState } from "react"
import { Action } from "../../components/item/chat/Action"
import { AIAction } from "../../repositories/userRepository"
import { RERENDER_EDITOR_BLOCKS_EVENT } from "../constants"
import { MyAIActionElement } from "../types"

interface Props extends PlateElementProps<MyAIActionElement[]> {
    handleToggle: (id: string) => Promise<void>
    handleDelete: (id: string) => Promise<void>
    handleRegenerate: (id: string) => Promise<void>
    handleCopy: (id: string) => Promise<void>
    isExtension?: boolean
}

const MAX_ACTION_NAME_LENGTH = 40

export const AIActionElement = ({
    children,
    handleToggle,
    handleDelete,
    handleRegenerate,
    handleCopy,
    isExtension,
    ...props
}: Props) => {
    const [isExpanded, setIsExpanded] = useState(props.element?.isExpanded)
    const element = props.element
    const action = element?.action as AIAction
    const isLoading = element?.isLoading
    const isChatMessage = action?.isChatMessage

    const handleToggleAction = async () => {
        handleToggle(element.id)
        setIsExpanded(!isExpanded)
    }

    const handleDeleteAction = async () => {
        await handleDelete(element.id)
        document.dispatchEvent(new CustomEvent(RERENDER_EDITOR_BLOCKS_EVENT))
    }

    const handleRegenerateAction = async () => {
        await handleRegenerate(element.id)
    }

    const handleCopyAction = async () => {
        await handleCopy(element.id)
    }

    const shouldTruncate =
        isExtension && action?.name && action.name.length > MAX_ACTION_NAME_LENGTH

    const actionName = shouldTruncate
        ? action.name.slice(0, MAX_ACTION_NAME_LENGTH) + "..."
        : action.name

    return (
        <PlateElement asChild {...props} id={element.id}>
            <Box sx={styles.aiAction} contentEditable={isLoading ? false : undefined}>
                <Box
                    contentEditable={false}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Action
                        label={actionName}
                        title={shouldTruncate ? action.name : undefined}
                        id={action?.id}
                        contentEditable={false}
                        sx={{ backgroundColor: undefined }}
                    />
                    <Box display="flex" alignItems="center">
                        <Tooltip title={isExpanded ? "Collapse" : "Expand"}>
                            <IconButton onClick={handleToggleAction}>
                                {isExpanded ? <UnfoldLess /> : <UnfoldMore />}
                            </IconButton>
                        </Tooltip>
                        <ActionMenu
                            isChatMessage={isChatMessage}
                            handleDelete={handleDeleteAction}
                            handleRegenerate={handleRegenerateAction}
                            handleCopy={handleCopyAction}
                        />
                    </Box>
                </Box>
                {isLoading && <LoadingSkeleton />}
                <Box display={isExpanded ? "block" : "none"} mt={isExpanded ? 1 : undefined}>
                    {children}
                </Box>
            </Box>
        </PlateElement>
    )
}

const LoadingSkeleton = () => {
    return (
        <Box contentEditable={false}>
            <Skeleton variant="text" width={160} height={40} sx={{ mt: 1 }} />
            <Skeleton variant="rectangular" width="100%" height={60} />

            <Skeleton variant="text" width={200} height={40} sx={{ mt: 1 }} />
            <Skeleton variant="rectangular" width="100%" height={100} />

            <Skeleton variant="text" width={180} height={40} sx={{ mt: 1 }} />
            <Skeleton variant="rectangular" width="100%" height={80} />
        </Box>
    )
}

interface ActionMenuProps {
    isChatMessage?: boolean
    handleDelete: () => void
    handleRegenerate: () => void
    handleCopy: () => void
}

const ActionMenu = ({
    isChatMessage,
    handleDelete,
    handleRegenerate,
    handleCopy,
}: ActionMenuProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <>
            <IconButton onClick={handleClick}>
                <MoreVert />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                {!isChatMessage && <MenuItem onClick={handleRegenerate}>Regenerate</MenuItem>}
                <MenuItem
                    onClick={() => {
                        handleCopy()
                        handleClose()
                    }}
                >
                    Copy
                </MenuItem>
                <MenuItem onClick={handleDelete}>Delete</MenuItem>
            </Menu>
        </>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    aiAction: {
        border: (theme) => `1px solid ${alpha(theme.palette.primary.main, 0.4)}`,
        borderRadius: "10px",
        padding: "10px",
        mb: 1,
    },
}
