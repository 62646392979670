import { Database } from "@nozbe/watermelondb"
import { itemRepository } from "@recall/common"
import { createItemService } from "services/createItemService"
import { defaultCard } from "storage/data/defaultCard"

const initializeDefaultCard = async (db: Database, uid: string) => {
    const allItems = await itemRepository.getAll(db)
    if (allItems.length > 0) return

    await createItemService.createItemWithPredefinedData(
        db,
        defaultCard,
        "https://www.youtube.com/watch?v=2s81mlFtHio"
    )
}

export const itemHelper = { initializeDefaultCard }
