import { USAGE_LIMIT } from "@recall/common"
import { useSelector } from "react-redux"
import { RootState } from "storage/redux/rootReducer"

export const useCanRunAI = () => {
    const isPremiumUser = useSelector((state: RootState) => state.user.isPremiumUser)
    const usage = useSelector((state: RootState) => state.user.usage)
    if (isPremiumUser) return true

    return usage < USAGE_LIMIT
}
