import { Box, DialogContent, Typography } from "@mui/material"
import { useDatabase } from "@nozbe/watermelondb/react"
import { Step, commonTutorialStyles, tutorials } from "@recall/common"
import LogoDark from "assets/svg/logo_full_dark.svg"
import LogoLight from "assets/svg/logo_full_light.svg"
import { useIsDarkMode } from "hooks/useThemeMode"
import React, { FC, memo, useEffect } from "react"
import { useSelector } from "react-redux"
import { RootState } from "storage/redux/rootReducer"
import { itemHelper } from "storage/watermelon/helper/item"
import { ADD_NEW_BUTTON_DIV_ID } from "../Header/AddButton/AddButton"
import { AppTutorialBase } from "./AppTutorialBase"

export const itemsIntroTutorialName = "itemsIntro"

export interface Props {
    goNext: Function
}

const AddNewButtonExampleStep: FC<Props> = ({ goNext }) => {
    useEffect(() => {
        const handleOnClick = () => {
            setTimeout(() => {
                goNext()
            })
        }

        const button = document.getElementById(ADD_NEW_BUTTON_DIV_ID)

        if (button) {
            button.addEventListener("click", handleOnClick)
            return () => button.removeEventListener("click", handleOnClick)
        }
    }, [goNext])

    return (
        <DialogContent sx={{ ...commonTutorialStyles.popoverContent }}>
            <Typography sx={commonTutorialStyles.heading}>Add content</Typography>
            <Typography sx={commonTutorialStyles.body}>
                Click here to add YouTube videos, PDFs, articles, and more to Recall.
            </Typography>
        </DialogContent>
    )
}

const TutorialItemsIntroComponent: React.FC = () => {
    const isDarkMode = useIsDarkMode()

    const db = useDatabase()
    const uid = useSelector((state: RootState) => state.user.uid)

    useEffect(() => {
        itemHelper.initializeDefaultCard(db, uid)

        // eslint-disable-next-line
    }, [])

    const steps: Step[] = [
        {
            anchorId: null,
            placement: null,
            showArrow: false,
            nextText: "Get Started",
            hideClose: true,
            hideCounter: true,
            component: () => (
                <DialogContent sx={commonTutorialStyles.dialogContent}>
                    <Box display="flex" justifyContent="center">
                        <Box
                            component="img"
                            alt="recall logo"
                            height={35}
                            src={isDarkMode ? LogoDark : LogoLight}
                        />
                    </Box>
                    <Typography
                        pt={1.5}
                        style={{ textAlign: "center", fontSize: 28 }}
                        variant="h5"
                        fontWeight={500}
                    >
                        Welcome to your Knowledge Base
                    </Typography>
                    <Typography pt={1.5} variant="body1">
                        Save content to read later, summarize and chat with it, review on a spaced
                        repetition schedule, and let Recall resurface content while you browse.
                    </Typography>
                </DialogContent>
            ),
        },
        {
            anchorId: ADD_NEW_BUTTON_DIV_ID,
            placement: "bottom",
            showArrow: true,
            hideCounter: true,
            hideBack: true,
            hideNext: true,
            component: (props: Props) => <AddNewButtonExampleStep {...props} />,
        },
    ]

    return <AppTutorialBase tutorialName={tutorials.ITEMS_INTRO} steps={steps} />
}

export const TutorialItemsIntro = memo(TutorialItemsIntroComponent)
