import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from "@mui/material"
import React from "react"

interface Props {
    onClose: () => void
    onConfirm: () => void
    selectedItems: Set<string>
}

export const BulkDeleteItemsDialog = React.forwardRef(function BulkDeleteItemsDialog(
    { onClose, selectedItems, onConfirm }: Props,
    ref: React.Ref<HTMLDivElement>
) {
    return (
        <Dialog open onClose={onClose} id="bulk-delete-items-dialog" ref={ref}>
            <DialogTitle color="error">Confirm delete</DialogTitle>
            <DialogContent>
                <Typography>
                    Are you sure you want to delete the selected {selectedItems.size}{" "}
                    {`${selectedItems.size > 1 ? "items" : "item"}`}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={onConfirm}>Confirm</Button>
            </DialogActions>
        </Dialog>
    )
})
