import { Box, DialogContent, Link, List, ListItem, SxProps, Theme, Typography } from "@mui/material"
import { commonTutorialStyles, Step, tutorials } from "@recall/common"
import LogoDark from "assets/svg/logo_full_dark.svg"
import LogoLight from "assets/svg/logo_full_light.svg"
import { useIsDarkMode } from "hooks/useThemeMode"
import { FC } from "react"
import { AppTutorialBase } from "./AppTutorialBase"

const NEW_FEATURE_UPDATE_URL =
    "https://recall.canny.io/changelog/our-biggest-update-yet-march-18-2025-augmented-browsing-chat-more"

export const TutorialNewFeatures: FC = () => {
    const isDarkMode = useIsDarkMode()

    const steps: Step[] = [
        {
            anchorId: null,
            placement: null,
            showArrow: false,
            nextText: "Got it",
            hideClose: true,
            hideCounter: true,
            component: () => (
                <DialogContent sx={commonTutorialStyles.dialogContent}>
                    <Box display="flex" justifyContent="center">
                        <Box
                            component="img"
                            alt="recall logo"
                            height={35}
                            src={isDarkMode ? LogoDark : LogoLight}
                        />
                    </Box>
                    <Typography
                        pt={1.5}
                        style={{ textAlign: "center", fontSize: 28 }}
                        variant="h5"
                        fontWeight={500}
                    >
                        Recall's Biggest Update Yet
                    </Typography>
                    <Typography pt={1.5} variant="body1">
                        Things may look a little different around here! We just released some major
                        improvements, including:
                    </Typography>
                    <List component="ul" sx={styles.list}>
                        <ListItem sx={styles.listItem}>
                            <Typography variant="body1">
                                <strong>Reader:</strong> Stores a read-only copy of the original
                                content
                            </Typography>
                        </ListItem>
                        <ListItem sx={styles.listItem}>
                            <Typography variant="body1">
                                <strong>Notebook:</strong> Contains your past summaries and is fully
                                editable.
                            </Typography>
                        </ListItem>
                        <ListItem sx={styles.listItem}>
                            <Typography variant="body1">
                                <strong>Chat:</strong> Ask questions and get insights from your
                                content.
                            </Typography>
                        </ListItem>
                        <ListItem sx={styles.listItem}>
                            <Typography variant="body1">
                                <strong>Augmented Browsing:</strong> Resurfaces related content from
                                your knowledge base as you browse.{" "}
                                <em>
                                    Note: This feature is off by default, so you'll need to enable
                                    it in the browser extension settings.
                                </em>
                            </Typography>
                        </ListItem>
                    </List>
                    <Link color="inherit" href={NEW_FEATURE_UPDATE_URL} target="_blank">
                        Learn more about the update here.
                    </Link>
                </DialogContent>
            ),
        },
    ]

    return <AppTutorialBase tutorialName={tutorials.RAB_RELEASE_MODAL} steps={steps} />
}

const styles: Record<string, SxProps<Theme>> = {
    list: { listStyleType: "disc", pl: 4 },
    listItem: { display: "list-item", pl: 0 },
}
