import { Folder } from "@mui/icons-material"
import DeleteIcon from "@mui/icons-material/Delete"
import DownloadIcon from "@mui/icons-material/Download"
import { useDatabase } from "@nozbe/watermelondb/react"
import { ItemModel, itemService, sentry, ShareIcon } from "@recall/common"
import { SharedBadge } from "components/shared/badges/SharedBadge"
import { useBreadcrumbActions } from "hooks/useBreadcrumbActions"
import { useIsNative } from "hooks/useIsNative"
import { useOpenState } from "hooks/useOpenState"
import { FC } from "react"
import { useSelector } from "react-redux"
import { exportService } from "services/export/exportService"
import { RootState } from "storage/redux/rootReducer"
import { isCardShared } from "storage/redux/user/selector"
import { AssetsModal } from "./Assets/AssetsModal"
import { Menu } from "./Menu"
import { ShareModal } from "./ShareModal/ShareModal"

interface Props {
    item: ItemModel
    isShareModalOpen: boolean
    handleOpenShareModal: () => void
    handleCloseShareModal: () => void
}

export const ItemMenu: FC<Props> = ({
    item,
    isShareModalOpen,
    handleOpenShareModal,
    handleCloseShareModal,
}) => {
    const db = useDatabase()

    const { popBreadcrumb } = useBreadcrumbActions()
    const isNative = useIsNative()
    const isShared = useSelector((state: RootState) => isCardShared(state, item.id))

    const uid = useSelector((state: RootState) => state.user.uid)

    const {
        isOpen: isAssetsModalOpen,
        handleClose: handleCloseAssetsModal,
        handleOpen: handleOpenAssetsModal,
    } = useOpenState()

    const handleDelete = async () => {
        try {
            await itemService.deleteItem(db, item, uid)
            popBreadcrumb()
        } catch (e) {
            sentry.captureException(e as Error, { item: item?.name, id: item?.id })
        }
    }
    const handleExportMarkdown = async () => {
        exportService.exportCardToMarkdown(db, item, { useHyperlinks: true })
    }

    const itemOptions = [
        item.isSaved && {
            name: "Share",
            callback: handleOpenShareModal,
            icon: () => <ShareIcon fontSize="small" />,
            badge: isShared ? <SharedBadge /> : null,
        },
        {
            name: `Export to Markdown ${isNative ? "(Desktop Only)" : ""}`,
            callback: handleExportMarkdown,
            icon: () => <DownloadIcon fontSize="small" />,
            disabled: isNative,
        },
        {
            name: "Images",
            callback: handleOpenAssetsModal,
            icon: () => <Folder fontSize="small" />,
        },
        {
            name: "Delete",
            callback: handleDelete,
            icon: () => <DeleteIcon fontSize="small" />,
        },
    ].filter(Boolean)

    return (
        <>
            <Menu
                updatedAt={item.updatedAt}
                createdAt={item.createdAt}
                options={itemOptions}
                vertical={false}
            />

            {isAssetsModalOpen && <AssetsModal item={item} closeModal={handleCloseAssetsModal} />}
            {isShareModalOpen && <ShareModal item={item} closeModal={handleCloseShareModal} />}
        </>
    )
}
