import { useEffect } from "react"
import { ChatMessage } from "../../../services/chat/chatService"

export const useTimestampClickListener = (
    ref: React.RefObject<HTMLDivElement>,
    messages: ChatMessage[]
) => {
    useEffect(() => {
        if (!ref.current) return

        const timestamps = ref.current.querySelectorAll(".timestamp")
        const video = document.querySelector("video")

        const handleTimestampClick: EventListener = (e) => {
            e.preventDefault()
            const target = e.target as HTMLAnchorElement
            const value = target.dataset.time
            if (!value) return

            const [hours, minutes, seconds] = value.split(":").map(Number)
            const totalSeconds = seconds + minutes * 60 + hours * 3600

            if (video) {
                video.currentTime = totalSeconds

                if (video.paused) video.play()
            } else {
                window.open(target.href, "_blank")
            }
        }
        timestamps.forEach((timestamp) => {
            timestamp.addEventListener("click", handleTimestampClick)
        })

        return () => {
            timestamps.forEach((timestamp) => {
                timestamp.removeEventListener("click", handleTimestampClick)
            })
        }
    }, [ref, messages])
}
