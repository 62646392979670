import { buildUrl, HttpClient } from "../utils"
import { ChatMessage } from "../services/chat/chatService"
import { BaseApi } from "./BaseApi"

const CHAT_QUESTION_PATH = "/chat/question"

export class ChatApi extends BaseApi {
    constructor(httpClient: HttpClient) {
        super(httpClient)
    }
    async getResponse(
        itemId: string,
        question: string,
        messages: ChatMessage[],
        chunks: any[],
        url: string
    ) {
        return await this.httpClient.post(
            buildUrl(CHAT_QUESTION_PATH, { itemId, url }),
            JSON.stringify({ question, messages, chunks }),
            this.getHeaders({})
        )
    }
}
