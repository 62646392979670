import { Box } from "@mui/material"

export const SharedBadge = () => {
    return (
        <Box
            sx={{
                bgcolor: (theme) => theme.palette.primary.dark,
                color: (theme) => theme.palette.primary.contrastText,
                fontSize: 12,
                borderRadius: 0.8,
                px: 0.6,
                py: 0.2,
                fontWeight: 500,
            }}
        >
            Shared
        </Box>
    )
}
