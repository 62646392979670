import { createPluginFactory } from "@udecode/plate-common"

export const ELEMENT_SKIP = "skip"

export const createSkipPlugin = createPluginFactory({
    key: ELEMENT_SKIP,
    isElement: true,
    isInline: true,
    isVoid: true,
})
