import { Avatar, Box, Paper, Typography } from "@mui/material"
import { SxProps, Theme } from "@mui/material/styles"
import React from "react"
import { ReferenceItem } from "../../../editor"
import { NestedTagChip } from "../../cards/NestedTagChip"
import { Options } from "../ItemPreviewPopper"
import { Mentions } from "./components/Mentions"

interface Props {
    item: ReferenceItem
    onClick?: () => void
    openItemById: (id: string, options?: Options) => void
    style?: {
        image?: SxProps<Theme>
        paper?: SxProps<Theme>
    }
}

export const ItemPreview: React.FC<Props> = ({ onClick, item, style, openItemById }) => {
    return (
        <Paper
            elevation={0}
            onClick={onClick}
            sx={
                style?.paper
                    ? ({
                          ...styles.paper,
                          ...style.paper,
                      } as SxProps<Theme>)
                    : styles.paper
            }
        >
            <Box display="flex" justifyContent="space-between" height="100%" sx={styles.preview}>
                <Box
                    p={1}
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    flex={3}
                    flexGrow={3}
                >
                    <Box display="flex" flexDirection="column" gap={0.5}>
                        <Typography sx={styles.name} variant="h6">
                            {item.name}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" sx={styles.description}>
                            {item.description}
                        </Typography>
                        <Box display="flex" flexWrap="wrap" gap={0.5} mt={0.5}>
                            {item.tagsNesting.map((tagNesting) => (
                                <NestedTagChip
                                    key={tagNesting.join("-")}
                                    tagNesting={tagNesting}
                                    maxCharacters={25}
                                />
                            ))}
                        </Box>
                    </Box>
                </Box>
                {item.image && <Avatar src={item.image} sx={styles.image} />}
            </Box>
            {item.mentions.length > 0 && (
                <>
                    <Box display="flex" pl={1} pb={0.5} gap={0.75} alignItems="center">
                        <Typography sx={styles.connectionsLabel}>
                            {item.mentions.length} Connection{item.mentions.length > 1 ? "s" : ""}
                        </Typography>
                    </Box>
                    <Mentions mentions={item.mentions} openItemById={openItemById} />
                </>
            )}
        </Paper>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    paper: {
        cursor: "pointer",
        maxHeight: "400px",
        height: "100%",
        overflow: "auto",
        position: "relative",
        fontSize: 16,
        borderRadius: 0,
        color: (theme) => theme.palette.text.primary,
        backgroundColor: (theme) => theme.palette.background.layout,
        "&:hover": {
            backgroundColor: (theme) => theme.palette.action.hover,
        },
        "&::-webkit-scrollbar": {
            height: 0,
            width: 0,
        },
    },
    preview: {
        borderRadius: 0,
    },
    name: {
        lineHeight: 1.2,
        wordBreak: "break-word",
        fontSize: "1.4em",
        fontWeight: 500,
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: 1,
        WebkitBoxOrient: "vertical",
    },
    description: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: 2,
        WebkitBoxOrient: "vertical",
        fontSize: "0.8em",
    },
    image: {
        borderRadius: 1,
        width: 100,
        height: 100,
        m: 1,
        bgcolor: (theme) => theme.palette.text.secondary,
    },
    connectionsLabel: { fontWeight: 500 },
}
