import { Box, Grid, SxProps, Theme, Tooltip, Typography } from "@mui/material"
import { FC } from "react"
import { LINKS_DIV_ID } from "../../../constants"
import { LinkItem } from "../../../storage/watermelon/helpers/links"
import { OutgoingLinkIcon } from "../../icons/LinkIcons/OutgoingLinkIcon"
import { Options } from "../ItemPreviewPopper"
import { Link } from "./Link"

interface Props {
    groupedLinks: { key: string; value: LinkItem[] }[]
    connectionsCount: number
    readOnly: boolean
    handleDelete: (id: string) => Promise<void>
    openItemById: (id: string, state?: Options) => void
}

export const Links: FC<Props> = ({
    groupedLinks,
    connectionsCount,
    readOnly,
    handleDelete,
    openItemById,
}) => {
    return (
        <Grid item xs={12} alignItems="center">
            <Box id={LINKS_DIV_ID} mb={1} display="flex" alignItems="center" gap={1}>
                <Tooltip
                    title={`This card links to ${connectionsCount} other card${
                        connectionsCount > 1 ? "s" : ""
                    }`}
                >
                    <Box>
                        <OutgoingLinkIcon count={connectionsCount} />
                    </Box>
                </Tooltip>
                <Typography variant="h5">Links</Typography>
            </Box>
            <Grid container spacing={0} sx={{ borderRadius: 1, overflow: "hidden" }}>
                {groupedLinks.map((sortedGroup) => (
                    <Grid container item xs={12} mb={1} key={sortedGroup.key}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">{sortedGroup.key}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {sortedGroup.value.map((link: LinkItem) => (
                                <Link
                                    readOnly={readOnly}
                                    key={link.id}
                                    item={link.item}
                                    connectionId={link.id}
                                    handleDelete={handleDelete}
                                    openItemById={openItemById}
                                />
                            ))}
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    )
}
