import { dates } from "../.."

const extractTimestamp = (sentence: string) => {
    const match = sentence.match(/\[(\d+(\.\d+)?)\]/)
    if (match) {
        return Math.floor(parseFloat(match[1]))
    }
    return null
}

const cleanSentence = (sentence: string) => {
    return sentence
        .replace(/\[(?!\d+(\.\d+)?).*?\]/g, "")
        .replace(/\[(\d+(\.\d+)?)\]/, "")
        .replace(/\n/g, " ")
        .trim()
}

export const groupSentences = (url: string, sentences: string[]) => {
    const groupedSentences = []

    let lastTimestamp = -30
    let currentGroup: string[] = []

    sentences.forEach((sentence) => {
        const timestamp = extractTimestamp(sentence)
        const cleanedSentence = cleanSentence(sentence)

        if (timestamp !== null && timestamp >= lastTimestamp + 30) {
            if (currentGroup.length > 0) {
                groupedSentences.push(currentGroup)
            }

            if (!cleanedSentence) {
                currentGroup = []
                return
            }

            currentGroup = [
                `[(${dates.formatSecondsToHHMMSS(timestamp)})](${url}&t=${timestamp}) ${cleanedSentence}`,
            ]
            lastTimestamp = timestamp
        } else if (timestamp !== null && cleanedSentence) {
            const formattedSentence = `[(${dates.formatSecondsToHHMMSS(timestamp)})](${url}&t=${timestamp}) ${cleanedSentence}`
            currentGroup.push(formattedSentence)
        } else if (cleanedSentence) {
            currentGroup.push(cleanedSentence)
        }
    })

    if (currentGroup.length > 0) {
        groupedSentences.push(currentGroup)
    }

    return groupedSentences
}

export const generateMarkdown = (url: string, sections: any[]) => {
    let markdown = ""
    for (const section of sections) {
        markdown += `## ${section.heading}\n`

        const groupedSentences = groupSentences(url, section.sentences)

        groupedSentences.forEach((group) => {
            group.forEach((line) => {
                markdown += ` ${line}`
            })
            markdown += `\n`
        })
    }

    return markdown
}
