import { Box, Typography } from "@mui/material"
import { useDatabase } from "@nozbe/watermelondb/react"
import { checkIsUrl, DropdownPopper, itemRepository, parseUrl, useIsMobile } from "@recall/common"
import { ReactComponent as WebsiteIcon } from "assets/social/website_outlined.svg"
import { ReactComponent as YouTubeIcon } from "assets/social/youtube_outlined.svg"
import { ReactComponent as MSWordIcon } from "assets/svg/msword_outlined.svg"
import { ReactComponent as PictureAsPdfIcon } from "assets/svg/pdf_outlined.svg"
import { ReactComponent as SlideshowIcon } from "assets/svg/ppt_outlined.svg"
import SearchInputComp from "components/shared/inputs/SearchInput"
import { NoticeBox } from "components/shared/surfaces/NoticeBox"
import { ITEM_PATH } from "constants/routes"
import { FC, useEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router"
import { finishCreateCardInSearchOnboardingAction } from "storage/redux/user/actions"
import { v4 as uuid } from "uuid"
import { AddButtonFooter } from "../AddButtonFooter"
import { SupportList } from "../SupportList"
import { URLSearchPreview } from "./URLSearchPreview"

const SUPPORTED_URLS = [
    { icon: <YouTubeIcon />, label: "YouTube videos" },
    { icon: <WebsiteIcon />, label: "Websites, Articles & Blogs" },
    { icon: <PictureAsPdfIcon />, label: "Online PDFs" },
    { icon: <MSWordIcon />, label: "Google Docs" },
    { icon: <SlideshowIcon />, label: "Google Slides" },
]

interface Props {
    onClose: () => void
}

const ERROR_INVALID_URL = "Please enter a valid URL"

export const URLSearchTab: FC<Props> = ({ onClose }) => {
    const dispatch = useDispatch()
    const isMobile = useIsMobile()
    const db = useDatabase()

    const history = useHistory()
    const ref = useRef<HTMLDivElement>(null)
    const [error, setError] = useState<string | null>(null)
    const [url, setUrl] = useState<string>("")

    const handleSubmit = async () => {
        if (checkIsUrl(url)) {
            const parsedUrl = parseUrl(url)
            const item = await itemRepository.getBySource({ db, sourceIdentifier: parsedUrl })
            history.push(`${ITEM_PATH.replace(":id", item?.id || uuid())}`, {
                sourceUrl: parsedUrl,
            })
            dispatch(finishCreateCardInSearchOnboardingAction())
            onClose()
            setUrl("")
        } else {
            setError(ERROR_INVALID_URL)
        }
    }

    useEffect(() => {
        if (url && !checkIsUrl(url)) {
            setError(ERROR_INVALID_URL)
        } else {
            setError(null)
        }
    }, [url])

    useEffect(() => {
        setUrl("")
    }, [])

    const isDropdownOpen = Boolean(url) && Boolean(!error)

    return (
        <>
            <SearchInputComp
                value={url}
                onChange={setUrl}
                onPressEnter={handleSubmit}
                placeholder="Paste a URL here"
                error={error}
            />
            <Box ref={ref}>
                <DropdownPopper open={isDropdownOpen} anchorEl={ref.current}>
                    {ref.current && <URLSearchPreview url={url} handleSubmit={handleSubmit} />}
                </DropdownPopper>
            </Box>
            <SupportList title="Supports" supportedSources={SUPPORTED_URLS} />
            {isMobile && (
                <NoticeBox sx={{ mt: 3 }}>
                    <Typography variant="caption">
                        <strong>Tip:</strong> Use your browser's share option to quickly add content
                        to Recall.
                    </Typography>
                </NoticeBox>
            )}
            <AddButtonFooter showActionPicker onClick={handleSubmit} onClose={onClose} />
        </>
    )
}
