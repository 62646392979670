import DarkModeIcon from "@mui/icons-material/DarkMode"
import LightModeIcon from "@mui/icons-material/LightMode"
import { Box, Button, ButtonGroup, SxProps, Theme, Typography } from "@mui/material"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { SET_BRIGHTNESS_MODE } from "storage/redux/app/actionTypes"
import { themeModeEnum } from "storage/redux/app/types"
import { RootState } from "storage/redux/rootReducer"

export const ModeSelection: React.FC<React.PropsWithChildren<unknown>> = () => {
    const themeMode = useSelector((state: RootState) => state.app.themeMode)

    const dispatch = useDispatch()

    const handleChange = (newthemeMode: themeModeEnum) => {
        dispatch({ type: SET_BRIGHTNESS_MODE, payload: newthemeMode })
    }

    return (
        <Box>
            <Typography fontWeight={500} variant="h5" component="h5" mb={2}>
                Mode
            </Typography>
            <ButtonGroup sx={{ color: "text.primary" }}>
                <Button
                    color="inherit"
                    onClick={() => handleChange(themeModeEnum.DARK)}
                    sx={styles.button}
                    variant={themeMode === themeModeEnum.DARK ? "contained" : "text"}
                >
                    <DarkModeIcon />
                    Dark
                </Button>
                <Button
                    color="inherit"
                    onClick={() => handleChange(themeModeEnum.LIGHT)}
                    sx={styles.button}
                    variant={themeMode === themeModeEnum.LIGHT ? "contained" : "text"}
                >
                    <LightModeIcon />
                    Light
                </Button>
            </ButtonGroup>
        </Box>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    button: {
        gap: 1,
        px: 2,
        py: 1,
        border: "1px solid",
        borderColor: "text.primary",
    },
}
