import { Box, Button, Paper, SxProps, Theme } from "@mui/material"
import { useDatabase } from "@nozbe/watermelondb/react"
import {
    ConnectionModel,
    ItemModel,
    connectionRepository,
    getTheme,
    itemRepository,
    tagRepository,
    useIsMobile,
} from "@recall/common"
import { Loader } from "components/layouts/components/Loader/AppLoader"
import { ItemView } from "components/shared/ItemPartial/ItemView"
import { TagsTreeProvider } from "components/shared/tags/providers/TagsTreeProvider"
import { HOME_PATH, KNOWLEDGE_GRAPH_PATH } from "constants/routes"
import { useOpenItem } from "hooks/items/useOpenItem"
import { useIsDarkMode } from "hooks/useThemeMode"
import { debounce, map, uniqBy } from "lodash"
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react"
import ForceGraph2D, { ForceGraphMethods } from "react-force-graph-2d"
import { useSelector } from "react-redux"
import { useHistory } from "react-router"
import { toast } from "react-toastify"
import { RootState } from "storage/redux/rootReducer"
import { KnowledgeGraphFilter } from "./components/KnowledgeGraphFilter"

const ZOOM_ACTIONS_LIMIT = 0.3

interface Props {
    width?: number
    height?: number
    item?: ItemModel
}

const MARGINS = 16

const KnowledgeGraphPage: FC<Props> = ({
    width = window.innerWidth,
    height = window.innerHeight - MARGINS,
    item,
}) => {
    const db = useDatabase()
    const [graphData, setGraphData] = useState({ nodes: [], links: [], isLoaded: false })
    const [averageMentions, setAverageMentions] = useState(1)
    const [hoveredItem, setHoveredItem] = useState<{
        item: ItemModel | null
        node: any
        backlinkIds: string[] | null
    }>({
        item: null,
        node: null,
        backlinkIds: null,
    })
    const isDark = useIsDarkMode()
    const [isStrengthSet, setIsStrengthSet] = useState(false)
    const [cardToZoom, setCardToZoom] = useState(item?.id || null)
    const [lastZoomed, setLastZoomed] = useState(null)
    const selectedTagIds = useSelector((state: RootState) => state.drawer.selectedTagIds)
    const forceGraphRef = useRef<ForceGraphMethods>()
    const { openItemById, openItem } = useOpenItem()
    const [isShowMoreDisabled, setIsShowMoreDisabled] = useState(false)
    const history = useHistory()
    const isMobile = useIsMobile()
    const theme = getTheme("dark")

    const zoom = useMemo(() => {
        let zoom = 0.05

        if (graphData.nodes.length < 2000) {
            zoom = 0.2
        }

        if (graphData.nodes.length < 400) {
            zoom = 0.4
        }

        if (graphData.nodes.length < 100) {
            zoom = 0.6
        }
        if (graphData.nodes.length < 50) {
            zoom = 1.5
        }
        if (graphData.nodes.length < 25) {
            zoom = 1.7
        }

        return zoom
    }, [graphData.nodes.length])
    const [linkedNodes, setLinkedNodes] = useState([])

    useEffect(() => {
        if (isMobile && !item) {
            history.push(HOME_PATH)
        }
    }, [isMobile, item])

    useEffect(() => {
        const forceGraph = forceGraphRef.current

        if (forceGraph && linkedNodes.length === 0) {
            forceGraph.centerAt(0, 0)
            forceGraph.zoom(zoom)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [graphData.nodes.length])

    useEffect(() => {
        const forceGraph = forceGraphRef.current

        if (forceGraph && !isStrengthSet) {
            forceGraph.d3Force("charge").strength(() => -250)
            forceGraph.d3ReheatSimulation()
            forceGraph.zoom(zoom)
            setIsStrengthSet(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceGraphRef.current])

    const checkIsShowMoreDisabled = async (linkedNodes: ItemModel[], allNodes: ItemModel[]) => {
        const nextLinkedNodes = (
            await Promise.all(
                linkedNodes.map(async (link) => {
                    const links = await link.getLinkedItems()
                    const backlinks = await link.getMentionedItems()
                    return uniqBy([...links, ...backlinks], "id")
                })
            )
        ).flat()

        const allNodesHasSet = new Set()
        allNodes.forEach((node) => allNodesHasSet.add(node.id))

        const isShowMoreEnabled = nextLinkedNodes.some(
            (linkedNode) => !allNodesHasSet.has(linkedNode.id)
        )

        setIsShowMoreDisabled(!isShowMoreEnabled)
    }

    const getItemItems = async (showMore?: boolean) => {
        if (showMore) {
            if (!linkedNodes.length) return [item]

            const prevLinked = linkedNodes[linkedNodes.length - 1]

            const allItemsLinks = await Promise.all([
                ...prevLinked.map((item) => item.getLinkedItems()),
                ...prevLinked.map((item) => item.getMentionedItems()),
            ])
            const newLinkedNodes = uniqBy(allItemsLinks.flat(), "id")
            setLinkedNodes((prev) => [...prev, newLinkedNodes])
            const allItems = uniqBy([item, ...linkedNodes.flat(), ...allItemsLinks.flat()], "id")
            checkIsShowMoreDisabled(newLinkedNodes, allItems)
            return allItems
        }

        if (showMore === false) {
            if (linkedNodes.length <= 1) return [item, ...linkedNodes.flat()]
            setLinkedNodes(linkedNodes.slice(0, linkedNodes.length - 1))
            const allItems = uniqBy(
                [item, ...linkedNodes.slice(0, linkedNodes.length - 1).flat()],
                "id"
            )
            checkIsShowMoreDisabled(linkedNodes.slice(0, linkedNodes.length - 1).flat(), allItems)
            return allItems
        }
        const links = await item.getLinkedItems()
        const backlinks = await item.getMentionedItems()
        const newLinkedNodes = uniqBy([...links, ...backlinks], "id")
        const allItems = [item, ...newLinkedNodes]
        checkIsShowMoreDisabled(newLinkedNodes, allItems)
        setLinkedNodes((prev) => [...prev, newLinkedNodes])

        return allItems
    }

    const getItems = async () => {
        if (!selectedTagIds.length) return await itemRepository.getAll(db)

        const items = await tagRepository.getItemsByTagIds(db, selectedTagIds)
        const allItemsLinks = await Promise.all([
            ...items.map((item) => item.getLinkedItems()),
            ...items.map((item) => item.getMentionedItems()),
        ])
        return uniqBy([...items, ...allItemsLinks.flat()], "id")
    }

    const generateGraphData = async (
        items: ItemModel[],
        connections: ConnectionModel[],
        fixedNodeId?: string
    ) => {
        const isItemDefined = Boolean(item)
        const itemsHashSet = new Set()
        items.forEach((item) => itemsHashSet.add(item.id))
        const nodes = await Promise.all(
            items.map(async (graphItem) => {
                const existingNode = graphData.nodes.find((node) => node.id === graphItem.id)
                const mentions = await graphItem.getMentionedItems()
                const links = await graphItem.getLinkedItems()

                const isExpandable =
                    !window.location.href.includes(KNOWLEDGE_GRAPH_PATH) &&
                    [...mentions, ...links].some((item) => !itemsHashSet.has(item.id))

                return {
                    id: graphItem.id,
                    name: graphItem.name,
                    val: isItemDefined ? 1 : await graphItem.mentions.fetchCount(),
                    isExpandable,
                    item: graphItem,
                    x: existingNode?.x,
                    y: existingNode?.y,
                    fx: fixedNodeId === graphItem.id ? existingNode?.x : undefined,
                    fy: fixedNodeId === graphItem.id ? existingNode?.y : undefined,
                }
            })
        )

        const numberOfMentions = nodes.reduce((acc, cur) => acc + cur.val, 0)
        setAverageMentions(numberOfMentions / nodes.length)

        const links = connections
            .map(({ from, to }) =>
                itemsHashSet.has(from.id) && itemsHashSet.has(to.id)
                    ? {
                          source: from.id,
                          target: to.id,
                      }
                    : null
            )
            .filter((item) => Boolean(item))

        setGraphData({ nodes, links, isLoaded: true })
    }

    const getGraphData = async (showMore?: boolean) => {
        const [items, connections] = await Promise.all([
            item ? getItemItems(showMore) : getItems(),
            connectionRepository.getAll(db),
        ])
        generateGraphData(items, connections)
    }

    useEffect(() => {
        getGraphData()
        // eslint-disable-next-line
    }, [selectedTagIds])

    const handleOnNodeHover = async (node: { item?: ItemModel }) => {
        if (node?.item) {
            const mentionedItems = await node.item.getMentionedItems()

            setHoveredItem({ item: node?.item, node, backlinkIds: map(mentionedItems, "id") })
        } else {
            setHoveredItem({ item: null, node: null, backlinkIds: null })
        }
    }

    const handleOnNodeClick = async (node) => {
        if (item && node.isExpandable) {
            const mentions = await node.item.getMentionedItems()
            const links = await node.item.getLinkedItems()
            const connections = await connectionRepository.getAll(db)
            const allNodes = uniqBy([item, ...mentions, ...links, ...linkedNodes.flat()], "id")

            await generateGraphData(allNodes, connections, node.item.id)

            const lastIndex = linkedNodes.length - 1
            const lastLinkedNodes = lastIndex >= 0 ? linkedNodes[lastIndex] : []
            const expandedLinkedNodes = uniqBy([...lastLinkedNodes, ...mentions, ...links], "id")
            const newLinkedNodes =
                lastIndex === 0
                    ? [expandedLinkedNodes]
                    : [...linkedNodes.slice(0, lastIndex), expandedLinkedNodes]

            setLinkedNodes(newLinkedNodes)
            checkIsShowMoreDisabled(newLinkedNodes.flat(), allNodes)
        } else if (transform.k > ZOOM_ACTIONS_LIMIT) openItemById(node.id, { target: "_blank" })
    }

    const [transform, setTransform] = useState({ k: 1, x: 0, y: 0 })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSetTransform = useCallback(
        debounce((newTransform: any) => {
            setTransform(newTransform)
        }, 200),
        []
    )
    const getHoveredNodePosition = () => {
        if (!hoveredItem.item || !forceGraphRef.current) return null

        // @ts-ignore
        const { x, y } = forceGraphRef.current.graph2ScreenCoords(
            hoveredItem.node.x,
            hoveredItem.node.y
        )
        return { x, y }
    }

    if (!graphData.isLoaded) return null

    const handleZoom = (newTransform: any) => {
        debouncedSetTransform(newTransform)
    }

    const handleNodePaint = (node: any, ctx: any, globalScale: any) => {
        const isBacklinkNode =
            hoveredItem.backlinkIds && hoveredItem.backlinkIds?.some((id) => id === node.id)

        const size = item ? 4 : 4 + node.val / averageMentions
        ctx.beginPath()
        ctx.arc(node.x, node.y, size, 0, 2 * Math.PI, false)

        ctx.fillStyle =
            (isBacklinkNode && globalScale > ZOOM_ACTIONS_LIMIT) || lastZoomed === node.id
                ? "#F2B807"
                : theme.palette.primary.main
        ctx.fill()

        if (node.isExpandable) {
            ctx.lineWidth = 0.4
            ctx.moveTo(node.x - size / 3, node.y)
            ctx.lineTo(node.x + size / 3, node.y)
            ctx.stroke()

            ctx.moveTo(node.x, node.y - size / 3)
            ctx.lineTo(node.x, node.y + size / 3)
            ctx.stroke()
        }

        if (node.id === cardToZoom) {
            const shouldZoomOut = globalScale > zoom

            if (lastZoomed) {
                shouldZoomOut && forceGraphRef.current.zoom(zoom, 400)
                setTimeout(
                    () => {
                        if (forceGraphRef?.current)
                            forceGraphRef.current.centerAt(node.x, node.y, 600)
                    },
                    shouldZoomOut ? 400 : 0
                )
            }
            setTimeout(
                () => {
                    if (forceGraphRef?.current) forceGraphRef.current.zoom(4, 1500)
                },
                lastZoomed ? (shouldZoomOut ? 1000 : 500) : 0
            )
            setLastZoomed(cardToZoom)
            setCardToZoom(null)
        }

        if (
            globalScale > (item ? 1.3 : 1.5) ||
            (isBacklinkNode && globalScale > ZOOM_ACTIONS_LIMIT)
        ) {
            const x = (node.x - transform.x) / transform.k
            const y = (node.y - transform.y) / transform.k

            const bounds = {
                x: -ctx.canvas.width / 2 / transform.k,
                y: -ctx.canvas.height / 2 / transform.k,
                width: ctx.canvas.width / transform.k,
                height: ctx.canvas.height / transform.k,
            }

            const isVisible =
                x >= bounds.x &&
                x <= bounds.x + bounds.width &&
                y >= bounds.y &&
                y <= bounds.y + bounds.height

            if (!isVisible) return

            const fontSize = 12 / (globalScale + globalScale / 4)
            const lengthLimit = globalScale > 3 ? 25 : 15

            const label =
                !isBacklinkNode && node.name.length > lengthLimit
                    ? node.name.slice(0, lengthLimit - 3) + "..."
                    : node.name

            const yOffset = globalScale > 12 ? 2 : globalScale / 12

            ctx.font = `${fontSize}px Sans-Serif`
            ctx.textAlign = "center"
            ctx.fillStyle = isDark ? "#F6F6F6" : "rgba(0, 0, 0, 0.87)"
            ctx.fillText(label, node.x, node.y + size + Math.max(2, 12 - globalScale * 2 - yOffset))
        }
    }

    const handleLinkPaint = (link, ctx, globalScale) => {
        const start = link.source
        const end = link.target

        if (end && hoveredItem.node && end.id === hoveredItem.node.id) {
            ctx.beginPath()
            ctx.moveTo(start.x, start.y)
            ctx.lineTo(end.x, end.y)
            ctx.strokeStyle = isDark ? "#F6F6F6" : "rgba(0, 0, 0, 0.87)"
            const lineWidth = globalScale > 3 ? 0.3 : 0.5
            ctx.lineWidth = lineWidth
            ctx.stroke()
        }
    }

    const hoveredNodePosition = getHoveredNodePosition()

    const handleShowMore = () => {
        getGraphData(true)
    }

    const handleShowLess = () => {
        getGraphData(false)
    }

    const knowledgeGraph = (
        <Box sx={styles.container}>
            {!isStrengthSet && (
                <Box
                    sx={{
                        zIndex: 999,
                        width: item ? "100%" : "100vw",
                        height: item ? "100%" : "100vh",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        background: (theme) => theme.palette.background.layout,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Loader />
                </Box>
            )}
            <Box
                sx={{
                    canvas: {
                        transform: "translate3d(0, 0, 0)",
                    },
                }}
            >
                <ForceGraph2D
                    onZoom={handleZoom}
                    ref={forceGraphRef}
                    enableNodeDrag={false}
                    nodeLabel={null}
                    nodeCanvasObject={handleNodePaint}
                    width={width}
                    height={height}
                    graphData={graphData}
                    linkWidth={0.4}
                    linkAutoColorBy={"source"}
                    onNodeHover={handleOnNodeHover}
                    onNodeClick={handleOnNodeClick}
                    nodePointerAreaPaint={null}
                    cooldownTicks={600}
                    linkDirectionalArrowLength={2}
                    linkDirectionalArrowRelPos={1}
                    linkCanvasObjectMode={() => "before"}
                    linkCanvasObject={handleLinkPaint}
                />
            </Box>
            {!item && <KnowledgeGraphFilter filtered={Boolean(selectedTagIds.length)} />}
            {transform.k > ZOOM_ACTIONS_LIMIT && hoveredItem.item && (
                <Box
                    sx={{
                        position: "absolute",
                        left: hoveredNodePosition.x,
                        top: hoveredNodePosition.y,
                        transform: `translate(${
                            hoveredNodePosition.x + 350 > width ? "-100%" : "0"
                        }, ${hoveredNodePosition.y + 150 > height ? "-100%" : "0"})`,
                        width: 350,
                    }}
                >
                    <ItemView
                        view="list"
                        item={hoveredItem.item}
                        style={{
                            paper: {
                                background: (theme) => theme.palette.background.paper,
                            },
                        }}
                        noHoverEffect
                        onClick={() => openItem(hoveredItem.item, { target: "_blank" })}
                        hidePin
                    />
                </Box>
            )}
            {graphData.nodes.length === 0 && isStrengthSet && (
                <Box sx={styles.empty}>
                    <Paper sx={styles.paper}>You have no cards that match your filters</Paper>
                </Box>
            )}
            {item && (
                <Box sx={{ position: "absolute", bottom: 10, left: 10 }}>
                    <Button
                        onClick={handleShowLess}
                        disabled={linkedNodes.length <= 1}
                        color="inherit"
                    >
                        Show less
                    </Button>
                    <Button onClick={handleShowMore} disabled={isShowMoreDisabled} color="inherit">
                        Show more
                    </Button>
                </Box>
            )}
        </Box>
    )

    if (item) return knowledgeGraph

    return (
        <TagsTreeProvider
            handleClickItem={(id: string) => {
                if (graphData.nodes.some((node) => node.id === id)) {
                    setCardToZoom(id)
                    setLastZoomed(id)
                } else {
                    toast.warning("Card is not visible on the graph due to filters")
                }
            }}
            selectedItemId={lastZoomed}
        >
            {knowledgeGraph}
        </TagsTreeProvider>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    container: {
        position: "relative",
        overflow: "hidden",
    },
    empty: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
    },
}

export default KnowledgeGraphPage
