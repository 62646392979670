import { Add } from "@mui/icons-material"
import { Box, SxProps, Theme, Typography } from "@mui/material"
import { useDatabase } from "@nozbe/watermelondb/react"
import { ASSET_TYPES, fileService, getTheme, ItemModel } from "@recall/common"
import { ChangeEvent, FC, useState } from "react"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import { RootState } from "storage/redux/rootReducer"
import { GroupedAssets } from "./AssetsModal"
import { FileMenu } from "./FileMenu"
import { ZoomedImage } from "./ZoomedImage"

interface Props {
    assets: GroupedAssets
    item: ItemModel
    getAssets: () => void
}

const theme = getTheme("dark")

export const Files: FC<Props> = ({ assets, item, getAssets }) => {
    const [zoomedImage, setZoomedImage] = useState(null)
    const db = useDatabase()
    const uid = useSelector((state: RootState) => state.user.uid)

    const handleFileSelect = async (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files[0]
        if (!file) return

        const fileSizeInMB = file.size / (1024 * 1024)
        if (fileSizeInMB > 32) {
            toast.error(`Max file size is 32MB`)
            return
        }

        await fileService.uploadAsset({ item, file, db, uid, type: ASSET_TYPES.IMAGE })
        getAssets()
    }

    return (
        <Box>
            {zoomedImage && (
                <ZoomedImage url={zoomedImage} handleClose={() => setZoomedImage(null)} />
            )}
            {assets.order.map((date) => (
                <Box mb={2} key={date}>
                    <Typography variant="body2" mb={1}>
                        {date}
                    </Typography>
                    <Box display="flex" gap={1} flexWrap="wrap" alignItems="flex-start">
                        {assets.groupedAssets[date].map((asset) => (
                            <Box key={asset.id} position="relative" sx={styles.wrapper}>
                                <FileMenu getAssets={getAssets} asset={asset} item={item} />
                                <Box
                                    onClick={() => setZoomedImage(asset.url)}
                                    sx={styles.image}
                                    component="img"
                                    src={asset.url}
                                />
                            </Box>
                        ))}
                    </Box>
                </Box>
            ))}
            <Box
                component="input"
                type="file"
                accept="image/*"
                onChange={handleFileSelect}
                style={{ display: "none" }}
                id="file-input"
            />
            <Box
                component="label"
                htmlFor="file-input"
                sx={{ ...styles.image, ...styles.addImage } as SxProps<Theme>}
            >
                <Add fontSize="large" />
            </Box>
        </Box>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    wrapper: {
        [theme.breakpoints.up("md")]: { ".menu": { display: "none" } },

        ":hover": {
            ".menu": { display: "block" },
        },
    },
    image: {
        width: { xs: 130, md: 180 },
        height: { xs: 130, md: 180 },
        borderRadius: 1,
        objectFit: "cover",
        cursor: "pointer",
    },
    addImage: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 1,
        border: (theme) => `1px solid ${theme.palette.action.hover}`,

        ":hover": {
            borderColor: (theme) => theme.palette.primary.main,
            background: theme.palette.action.hover,
        },
    },
}
