import { Box, SxProps, Theme } from "@mui/material"
import {
    CHUNK_ID_PREFIX,
    createMyPlateEditor,
    createPlatePlugins,
    deserializeMd,
    EditorBlockData,
    PlateEditor,
    Spinner,
    UnReadable,
} from "@recall/common"
import { useFile } from "components/ItemPage/hooks/useFile"
import { useItemContext } from "components/ItemPage/providers/ItemProvider"
import { usePlatePlugins } from "hooks/editor/usePlatePlugins"
import { useQueryParameter } from "hooks/useQueryParameter"
import { useRefObserver } from "hooks/useRefObserver"
import { map } from "lodash"
import { FC, useCallback, useState } from "react"
import { useLocation } from "react-router"
import { PdfReader } from "./PdfReader"

export const READER_ID = "id-reader"

export const Reader: FC = () => {
    const { item, chunks } = useItemContext()
    const plugins = usePlatePlugins(item, true)
    const { file, isLoading: isLoadingFile } = useFile(item)
    const { param: elementIdParam } = useQueryParameter("focusedElementId")
    const location = useLocation<{ elementId?: string; isReaderMention?: boolean }>()
    const hasFocusedElement = Boolean(elementIdParam) || Boolean(location.state?.elementId)

    const [visibleChunks, setVisibleChunks] = useState(hasFocusedElement ? null : 10)

    const showMoreChunks = useCallback(() => {
        setVisibleChunks((prev) => prev + 10)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chunks])

    const ref = useRefObserver(showMoreChunks)

    const initEditorBlocks = useCallback(async () => {
        const editor = createMyPlateEditor({
            plugins: createPlatePlugins({}),
        })

        const chunksToRender = visibleChunks ? chunks.slice(0, visibleChunks) : chunks
        const markdowns = chunksToRender.map((chunk) => {
            return `${CHUNK_ID_PREFIX}${chunk.id}\n\n${chunk.markdown}`
        })

        const markdown = markdowns.join("\n")
        const editorBlocks = deserializeMd(markdown, editor.plugins)
        const connections = await item.links.fetch()

        EditorBlockData.removeStaleChunkReferences(editorBlocks, map(connections, "id"))

        const filteredEditorBlocks = editorBlocks.filter((block) => {
            const id = block?.children?.[0]?.text?.split(CHUNK_ID_PREFIX)[1]
            return !id
        })

        return filteredEditorBlocks
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chunks, visibleChunks])

    const isLoadingReader = !chunks.length || isLoadingFile

    if (item.isReadable === false) return <UnReadable />

    if (file) {
        return (
            <Box id={READER_ID}>
                <PdfReader file={file} />
            </Box>
        )
    }

    return (
        <Box px={2} id={READER_ID}>
            <PlateEditor
                id="reader"
                plugins={plugins}
                initEditorBlocks={initEditorBlocks}
                updateEditorBlocks={async () => {}}
                uploadImage={async () => ""}
                cleanUpCopiedBlocks={async (elements) => elements}
                isLoading={isLoadingReader}
                readOnly={true}
                isExtension
                sx={styles.editor}
            />
            {visibleChunks && visibleChunks < chunks.length && (
                <Box ref={ref} py={2} display="flex" justifyContent="center">
                    <Spinner size={20} />
                </Box>
            )}
        </Box>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    editor: {
        py: 1,
        m: 0,
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: 1.5,
        bgcolor: "transparent",

        "& .paragraph-element": {
            mb: 2,
            mt: 0.5,
        },

        ".plate-editor": {
            "h1, h2, h3, h4, h5, h6": {
                lineHeight: 1.3,
                m: 0,
            },
        },

        "& .youtube-timestamp-element": {
            display: "inline-block",
        },

        "& li p": {
            margin: 0,
        },
        "& li": {
            mb: 0.5,
        },
    },
}
