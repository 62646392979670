import { Box, Button, SxProps, Theme, Typography } from "@mui/material"
import { USAGE_LIMIT } from "../../../services"
import { UpgradeButton } from "../../buttons/UpgradeButton"
import { ConnectionsIcon } from "../../icons"
import { Illustration } from "../../Illustration/Illustration"

interface Props {
    canRunAction: boolean
    handleUpgrade: () => void
    handleRegenerate: () => void
}

export const NoLinks = ({ canRunAction, handleUpgrade, handleRegenerate }: Props) => {
    return (
        <Box sx={styles.container}>
            {canRunAction ? (
                <>
                    <Typography variant="h6" fontWeight={600}>
                        No connections found on this page
                    </Typography>
                    <Illustration icon={ConnectionsIcon} />
                    <Button variant="outlined" color="primary" onClick={handleRegenerate}>
                        Regenerate
                    </Button>
                </>
            ) : (
                <>
                    <Typography variant="h6" fontWeight={600}>
                        Connections are only extracted in the first {USAGE_LIMIT} cards.
                    </Typography>
                    <Illustration icon={ConnectionsIcon} />
                    <Typography color="text.secondary" my={2} variant="body2">
                        To extract connections for this card, you need to upgrade your plan.
                    </Typography>
                    <UpgradeButton onClick={handleUpgrade} />
                </>
            )}
        </Box>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        textAlign: "center",
        gap: 2,
    },
}
