import { map } from "lodash"
import { useIsMobile } from "../../../hooks/useIsMobile"
import { TagModel } from "../../../storage/watermelon/models/TagModel"

export const useTagAncestors = () => {
    const isMobile = useIsMobile()

    const getNesting = (tag: TagModel, ancestors: TagModel[]) => {
        return getTagNesting(tag, ancestors, isMobile)
    }

    return { getTagNesting: getNesting }
}

export const getTagNesting = (tag: TagModel, ancestors: TagModel[], isMobile: boolean) => {
    if (isMobile) return tag.name.length > 15 ? [tag.name.slice(0, 15).trim() + "..."] : [tag.name]

    if (!ancestors.length) return [tag.name]

    return [...map(ancestors, "name"), tag.name]
}
