import {
    collection,
    doc,
    getDocs,
    limit,
    orderBy,
    query,
    vector,
    where,
    writeBatch,
} from "firebase/firestore"
import { v4 as uuid } from "uuid"
import { FIRESTORE_COLLECTIONS } from "../../constants"
import { firebase } from "../firebaseService"
import { markdownService } from "../markdownService"

export interface Chunk {
    id: string
    text: string
    markdown: string
}

const chunkMarkdown = (markdown: string): Chunk[] => {
    const chunks = []
    let chunkMarkdown = ""
    let chunkText = ""

    const lines = markdown.split("\n")

    for (const line of lines) {
        let text = markdownToText(line)

        if (!text) {
            chunkMarkdown += line + "\n"
            continue
        }

        if (!text.trim().endsWith(".")) {
            text = text.trim() + "."
        }

        if (!chunkText || (chunkText + text).length < 300) {
            chunkMarkdown += line + "\n"
            chunkText += text
        } else {
            chunks.push({ id: uuid(), text: chunkText, markdown: chunkMarkdown })

            chunkText = text
            chunkMarkdown = line + "\n"
        }
    }

    if (chunkText) {
        chunks.push({ id: uuid(), text: chunkText, markdown: chunkMarkdown })
    }

    return chunks
}

const markdownToText = (markdown: string) => {
    let text = markdown

    // Remove images: ![alt](url)
    text = markdownService.removeImages(text)

    // Remove Markdown links but keep link text: [text](url)
    text = markdownService.removeLinks(text)

    // Remove bold/italics symbols
    text = markdownService.removeInlineFormatting(text)

    // Remove remaining markdown heading markers
    text = text.replace(/^#+\s/gm, "")

    // Replace line breaks with spaces or new lines, depending on your needs
    text = text.replace(/\n+/g, " ")

    return text.trim()
}

const getChunks = async (uid: string, id: string): Promise<Chunk[]> => {
    const q = query(
        collection(
            firebase.firestore,
            FIRESTORE_COLLECTIONS.USERS,
            uid,
            FIRESTORE_COLLECTIONS.READER_CHUNKS
        ),
        where("itemId", "==", id),
        orderBy("index")
    )

    const querySnapshot = await getDocs(q)

    return querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
    })) as Chunk[]
}

const removeChunks = async (uid: string, id: string) => {
    const batchSize = 500

    const q = query(
        collection(
            firebase.firestore,
            FIRESTORE_COLLECTIONS.USERS,
            uid,
            FIRESTORE_COLLECTIONS.READER_CHUNKS
        ),
        where("itemId", "==", id),
        limit(batchSize)
    )

    while (true) {
        const querySnapshot = await getDocs(q)

        if (querySnapshot.empty) {
            break
        }

        const batch = writeBatch(firebase.firestore)

        querySnapshot.docs.forEach((doc) => {
            batch.delete(doc.ref)
        })

        await batch.commit()

        if (querySnapshot.size < batchSize) {
            break
        }
    }
}

const saveSharedChunks = async (uid: string, id: string, chunks: Chunk[]) => {
    const batchSize = 500

    for (let i = 0; i < chunks.length; i += batchSize) {
        const batch = writeBatch(firebase.firestore)
        const batchChunks = chunks.slice(i, i + batchSize)

        for (const chunk of batchChunks) {
            batch.set(
                doc(
                    collection(
                        firebase.firestore,
                        FIRESTORE_COLLECTIONS.USERS,
                        uid,
                        FIRESTORE_COLLECTIONS.READER_CHUNKS
                    ),
                    chunk.id
                ),
                // @ts-ignore
                { ...chunk, itemId: id, embedding: vector(chunk.embedding._values) }
            )
        }

        await batch.commit()
    }
}

const getMarkdown = (chunks: Chunk[]) => {
    return chunks.map((chunk) => chunk.markdown).join("\n")
}

export const chunksService = {
    chunkMarkdown,
    getChunks,
    removeChunks,
    markdownToText,
    getMarkdown,
    saveSharedChunks,
}
