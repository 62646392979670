import { decode } from "js-base64"
import { HttpClient } from "../utils"

const SCRAPE_HTML_PATH = "/scraper/page"
const PDF_FILE_PATH = "/scraper/pdf"

export class ScraperApi {
    private httpClient: HttpClient

    constructor(httpClient: HttpClient) {
        this.httpClient = httpClient
    }

    async scrape(url: string) {
        const response = await this.httpClient.post(SCRAPE_HTML_PATH, JSON.stringify({ url }))
        const contentType = response.headers.get("content-type")

        if (contentType && contentType.includes("application/pdf")) {
            const pdfBlob = await response.blob()
            const pdfFile = new File([pdfBlob], url, { type: "application/pdf" })
            return { pdfFile }
        }

        const { encoded_html } = await response.json()
        const html = decode(encoded_html)
        return { html }
    }

    async scrapeYoutube(url: string) {
        const response = await this.httpClient.post(SCRAPE_HTML_PATH, JSON.stringify({ url }))
        const { encoded_html } = await response.json()
        const html = decode(encoded_html)
        return html
    }

    async scrapePdf(formData: FormData) {
        const response = await this.httpClient.post(PDF_FILE_PATH, formData, {})
        const { text, title } = await response.json()
        return { text, title }
    }
}
