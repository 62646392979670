import { useTheme } from "@mui/material/styles"
import { FC } from "react"
import { LinkIcon } from "./LinkIcon"

interface Props {
    count: number
}

export const BacklinkIcon: FC<Props> = ({ count }) => {
    const theme = useTheme()

    return (
        <LinkIcon
            count={count}
            iconSVG={
                <svg
                    width="193"
                    height="193"
                    viewBox="0 0 193 193"
                    fill={theme.palette.action.selected}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_17_72)">
                        <ellipse
                            cx="69.5055"
                            cy="122.23"
                            rx="69.197"
                            ry="68.1547"
                            transform="rotate(45 69.5055 122.23)"
                        />
                        <path d="M71.2036 53.2299C70.4227 54.4996 68.5773 54.4996 67.7964 53.2299L50.1567 24.5477C49.3372 23.2152 50.296 21.5 51.8603 21.5L87.1397 21.5C88.7041 21.5 89.6628 23.2152 88.8433 24.5477L71.2036 53.2299Z" />
                        <rect x="76" y="22" width="13" height="21" transform="rotate(-180 76 22)" />
                        <path d="M119.487 75.2624C118.038 75.608 116.733 74.3031 117.078 72.8531L124.886 40.0986C125.249 38.5768 127.14 38.042 128.246 39.1482L153.192 64.0944C154.299 65.2006 153.764 67.0913 152.242 67.4541L119.487 75.2624Z" />
                        <rect
                            x="145.034"
                            y="55.9632"
                            width="13"
                            height="27.3094"
                            transform="rotate(-135 145.034 55.9632)"
                        />
                        <path d="M138.77 123.204C137.5 122.423 137.5 120.577 138.77 119.796L167.452 102.157C168.785 101.337 170.5 102.296 170.5 103.86L170.5 139.14C170.5 140.704 168.785 141.663 167.452 140.843L138.77 123.204Z" />
                        <rect
                            x="170"
                            y="128"
                            width="13"
                            height="23"
                            transform="rotate(-90 170 128)"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_17_72">
                            <rect width="193" height="193" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            }
        />
    )
}
