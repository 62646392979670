import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import { Box, SxProps, Theme } from "@mui/material"
import { PrimaryButton } from "@recall/common"
import PinBrowserExtensionImage from "assets/images/pin-browser-extension.png"
import { FC } from "react"
import { TitleContainer } from "./TitleContainer"

interface Props {
    handleContinue: () => void
}

export const PinTheExtensionStep: FC<Props> = ({ handleContinue }) => {
    return (
        <>
            <TitleContainer
                title="Pin The Browser Extension"
                subtitle="Keep the Recall Browser Extension 1 click away"
            />
            <Box component="img" src={PinBrowserExtensionImage} sx={styles.image} />
            <PrimaryButton
                sx={{ mt: 2 }}
                fullWidth
                onClick={handleContinue}
                endIcon={<NavigateNextIcon />}
            >
                I've pinned it
            </PrimaryButton>
        </>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    image: {
        width: "100%",
        maxWidth: "400px",
        height: "auto",
        my: 3,
        display: "block",
    },
}
