import { Avatar, SxProps, Theme } from "@mui/material"
import React, { useState } from "react"

interface Props {
    url: string
    sx?: SxProps<Theme>
}

export const Favicon: React.FC<Props> = ({ url, sx }) => {
    const [currentUrlIndex, setCurrentUrlIndex] = useState(0)

    const getFaviconUrls = (url: string): string[] => {
        const domain = new URL(url).origin
        return [
            `${domain}/favicon.ico`,
            `${domain}/favicon.png`,
            `${domain}/apple-touch-icon.png`,
            `${domain}/apple-touch-icon-precomposed.png`,
            `${domain}/assets/favicon.ico`,
            `${domain}/static/favicon.ico`,
            `https://www.google.com/s2/favicons?domain=${domain}`,
        ]
    }

    const urls = getFaviconUrls(url)

    return (
        <Avatar
            src={urls[currentUrlIndex]}
            alt=""
            sx={sx}
            onError={(event) => {
                if (currentUrlIndex < urls.length - 1) {
                    setCurrentUrlIndex(currentUrlIndex + 1)
                } else {
                    const imgElement = event?.target as HTMLImageElement
                    if (imgElement) imgElement.style.display = "none"
                }
            }}
        />
    )
}
