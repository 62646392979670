import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material"
import CancelIcon from "@mui/icons-material/Close"
import {
    alpha,
    Box,
    Button,
    Dialog,
    Fade,
    IconButton,
    MobileStepper,
    SxProps,
    Theme,
} from "@mui/material"
import { isEqual } from "lodash"
import { FC, memo, useEffect, useState } from "react"
import { getTheme } from "../../theme"
import { TutorialPopup } from "./TutorialPopup"
import { Step } from "./types"

export const HANDLE_NEXT_STEP_EVENT = "handle-next-step-event"

interface Props {
    steps: Step[]
    onClose: () => void
    onFinish?: () => void
    onCancel?: (activeStep: number) => void
}

const TutorialBaseComponent: FC<Props> = ({ steps, onClose, onFinish, onCancel }) => {
    const [activeStep, setActiveStep] = useState(0)
    const maxSteps = steps.length
    const step = steps[activeStep]
    const isLastStep = activeStep === maxSteps - 1

    const handleNext = () => {
        if (isLastStep) {
            handleFinish()
            return
        }
        setActiveStep((prev) => prev + 1)
    }

    useEffect(() => {
        document.addEventListener(HANDLE_NEXT_STEP_EVENT, handleNext)

        return () => {
            document.removeEventListener(HANDLE_NEXT_STEP_EVENT, handleNext)
        }
    }, [isLastStep]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleBack = async () => {
        if (step.onBack) await step.onBack()
        setTimeout(() => setActiveStep((prev) => prev - 1), 0)
    }

    const handleOnClose = (_: Event, reason: string) => {
        if (reason === "backdropClick") return
        onClose()
    }

    const handleFinish = () => {
        if (onFinish) onFinish()
        setActiveStep(0)
        onClose()
    }

    const handleCancel = () => {
        if (onCancel) onCancel(activeStep)
        onClose()
    }

    if (!step) return null

    const stepContent = (
        <>
            {!step.hideClose && (
                <IconButton sx={styles.closeButton} size="small" onClick={handleCancel}>
                    <CancelIcon fontSize="small" />
                </IconButton>
            )}
            {step.component({ goBack: handleBack, goNext: handleNext })}
            {!step.hideStepperButtons && (
                <MobileStepper
                    sx={{
                        ...styles.stepper,
                        "&.MuiMobileStepper-positionStatic:not(button)": {
                            fontSize: "12px",
                            color: maxSteps === 1 || step.hideCounter ? "transparent" : "undefined",
                        },
                    }}
                    position="static"
                    variant="text"
                    steps={maxSteps}
                    activeStep={activeStep}
                    nextButton={
                        !step.hideNext && (
                            <Button
                                color="primary"
                                variant="contained"
                                size="small"
                                disabled={step.isNextDisabled}
                                onClick={handleNext}
                                endIcon={<KeyboardArrowRight />}
                            >
                                {isLastStep ? "Got it!" : step.nextText || "Next"}
                            </Button>
                        )
                    }
                    backButton={
                        !step.hideBack && (
                            <Button
                                sx={{ visibility: activeStep === 0 ? "hidden" : "visible" }}
                                color="primary"
                                variant="contained"
                                size="small"
                                onClick={handleBack}
                                startIcon={<KeyboardArrowLeft />}
                            >
                                Back
                            </Button>
                        )
                    }
                />
            )}
        </>
    )

    if (step.anchorId)
        return (
            <Fade in timeout={500} key={activeStep.toString()}>
                <Box>
                    <TutorialPopup
                        key={step.id}
                        step={{ ...step, onBack: step.onBack || handleBack }}
                    >
                        {stepContent}
                    </TutorialPopup>
                </Box>
            </Fade>
        )

    return (
        <Dialog open onClose={handleOnClose} maxWidth="sm" PaperProps={{ sx: { p: 1 } }}>
            {stepContent}
        </Dialog>
    )
}

export const TutorialBase = memo(TutorialBaseComponent, (prev, next) => {
    return isEqual(prev.steps, next.steps)
})

const styles: Record<string, SxProps<Theme>> = {
    stepper: {
        backgroundColor: "background.paper",
        color: (theme) => alpha(theme.palette.text.primary, 0.8),
    },
    closeButton: {
        position: "absolute",
        top: "5px",
        right: "5px",
        zIndex: 100,
    },
}

const theme = getTheme("dark")

export const commonTutorialStyles: Record<string, SxProps<Theme>> = {
    dialogContent: {
        p: 2,
    },
    popoverContent: {
        px: 1.5,
        py: 1,
        borderTopLeftRadius: "16px",
        borderTopRightRadius: "16px",
    },
    heading: {
        fontSize: { xs: 20, sm: 24 },
        mb: 1,
        pr: 4,
        fontWeight: 500,
    },
    body: {
        fontSize: {
            xs: 12,
            sm: 14,
            [theme.breakpoints.up("md")]: {
                minWidth: "300px",
            },
        },
    },
}
