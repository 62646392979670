import { ImageOutlined } from "@mui/icons-material"
import { Box, Button, SxProps, Theme, alpha } from "@mui/material"
import { PlateElement, PlateElementProps, focusEditor } from "@udecode/plate-common"
import { Image, useMediaState } from "@udecode/plate-media"
import { Resizable, useResizableState } from "@udecode/plate-resizable"
import { FC, useEffect, useState } from "react"
import { assets } from "../../../assets"
import { useIsMobile } from "../../../hooks/useIsMobile"
import { MyImageElement, useMyEditorState } from "../../types"
import { ResizeHandle } from "./Resizable"

interface ImageElementProps extends PlateElementProps<MyImageElement[]> {
    readOnly: boolean
    isExtension?: boolean
    isMainImage: (url: string) => boolean
    updateImage: (image: string) => Promise<void>
}

export const ImageElement: FC<ImageElementProps> = ({
    children,
    nodeProps,
    readOnly = false,
    isExtension = false,
    isMainImage,
    updateImage,
    ...props
}) => {
    const element = props.element
    const url = element?.options?.url || element?.urlOriginal || element?.url
    const isMobile = useIsMobile()

    const [failedToLoad, setFailedToLoad] = useState(false)
    const { width, setWidth } = useResizableState()
    const isMain = [url, element?.urlThumbnail, element?.url_320].some(isMainImage)
    const { focused, selected, align = "center" } = useMediaState()
    const editor = useMyEditorState()

    useEffect(() => {
        if (!isMobile) setWidth(element?.options?.width || element?.width || 350)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleMakePrimary = async () => {
        await updateImage(url)
        focusEditor(editor)
    }

    const minWidth = isMobile ? 200 : 250

    return (
        <PlateElement {...props}>
            <Box
                sx={
                    isExtension
                        ? { minHeight: 175, ...styles.resizableWrapper }
                        : styles.resizableWrapper
                }
                component="figure"
                contentEditable={false}
            >
                <Resizable
                    style={{ margin: "auto" }}
                    options={{
                        minWidth,
                        align,
                        readOnly,
                    }}
                    className="resizable"
                >
                    <Box sx={styles.imageWrapper}>
                        {!readOnly && (
                            <ResizeHandle
                                options={{ direction: "left" }}
                                style={{ zIndex: 10000 }}
                            />
                        )}
                        <Box
                            sx={{
                                ...styles.image,
                                border: (theme) =>
                                    `2px solid ${
                                        selected && focused
                                            ? theme.palette.text.primary
                                            : "transparent"
                                    }`,
                            }}
                        >
                            {!readOnly && !isExtension && (
                                <Box className="image-button" sx={styles.imageButton}>
                                    <Button
                                        color="inherit"
                                        sx={{
                                            backgroundColor: (theme) =>
                                                alpha(
                                                    theme.palette.background.default,
                                                    isMain ? 0.8 : 0.4
                                                ),

                                            ":hover": {
                                                backgroundColor: (theme) =>
                                                    alpha(theme.palette.background.default, 0.6),
                                            },
                                        }}
                                        disabled={isMain}
                                        startIcon={<ImageOutlined />}
                                        onClick={handleMakePrimary}
                                    >
                                        {isMain ? "Main image" : "Use as main image"}
                                    </Button>
                                </Box>
                            )}
                            <Image
                                style={{
                                    maxWidth: "100%",
                                    borderRadius: 10,
                                    objectFit: "contain",
                                    backgroundColor: "rgba(255,255,255,0.9)",
                                    width,
                                }}
                                alt=""
                                onError={() => {
                                    setFailedToLoad(true)
                                }}
                                {...nodeProps}
                                src={!url || failedToLoad ? assets.PLACEHOLDER_IMAGE_ASSET : url}
                            />
                        </Box>
                        {!readOnly && (
                            <ResizeHandle
                                options={{ direction: "right" }}
                                style={{ zIndex: 10000 }}
                            />
                        )}
                    </Box>
                </Resizable>
            </Box>
            {children}
        </PlateElement>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    resizableWrapper: {
        display: "flex",
        mx: 0.5,
        justifyContent: "center",
        userSelect: "none",
        "& .resizable": {
            mx: "auto",
        },
    },
    imageWrapper: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        gap: 0.4,
        mx: "auto",
        maxWidth: "100%",

        "& .resize-handler": {
            visibility: "hidden",
        },

        "&:hover .resize-handler": {
            visibility: "visible",
        },
    },
    image: {
        p: 0.4,
        borderRadius: "10px",
        boxSizing: "border-box",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        ".image-button": { display: "none" },

        ":hover": {
            ".image-button": {
                display: "flex",
            },
        },
    },
    imageButton: {
        position: "absolute",
        top: 5,
        left: 5,
        display: "flex",
    },
}
