import { Box } from "@mui/material"
import { useDatabase } from "@nozbe/watermelondb/react"
import {
    getSlugAndLanguage,
    itemRepository,
    itemService,
    Result,
    SearchResults,
    sentry,
    WIKIPEDIA_PAGE,
} from "@recall/common"
import { ReactComponent as GoogleIcon } from "assets/social/google_sm.svg"
import { ReactComponent as WikidataIcon } from "assets/social/wikidata.svg"
import { ReactComponent as WikipediaIcon } from "assets/social/wikipedia_sm.svg"
import { useOpenOnboarding } from "components/layouts/components/Onboarding/hooks/useOpenOnboarding"
import SearchInputComp from "components/shared/inputs/SearchInput"
import { ITEM_PATH } from "constants/routes"
import useSearchItems from "hooks/useSearchItems"
import { FC, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"
import { summariesApi } from "services/api"
import { SET_LOADING, SET_SEARCH_TERM } from "storage/redux/app/actionTypes"
import { RootState } from "storage/redux/rootReducer"
import { finishCreateCardInSearchOnboardingAction } from "storage/redux/user/actions"
import { AddButtonFooter } from "./AddButtonFooter"
import { SupportList } from "./SupportList"

const DATASOURCES = [
    { icon: <WikipediaIcon />, label: "Wikipedia" },
    { icon: <GoogleIcon />, label: "Google Knowledge Graph" },
    { icon: <WikidataIcon width={22} />, label: "Wikidata" },
]

interface Props {
    onClose: () => void
}

export const EntitySearchTab: FC<Props> = ({ onClose }) => {
    const dispatch = useDispatch()
    const ref = useRef<HTMLDivElement>(null)
    const searchTerm = useSelector((state: RootState) => state.app.searchTerm)
    const db = useDatabase()
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [error, setError] = useState<string | null>(null)
    const { closeOnboarding } = useOpenOnboarding()
    const { loading, results } = useSearchItems(searchTerm)
    const history = useHistory()

    useEffect(() => {
        setSearchTerm("")
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setSearchTerm = (term: string) => {
        dispatch({ type: SET_SEARCH_TERM, payload: term })
    }

    const handleSubmit = async () => {
        if (searchTerm.length === 0) {
            setError("Please enter a search term.")
            return
        }
        if (selectedIndex >= results.length) {
            setError("Please select a valid result.")
            return
        }

        const result = results[selectedIndex]
        await handleSelectResult(result)
    }

    const handleSelectResult = async (result: Result) => {
        if (result === null) return null

        onClose()
        closeOnboarding()

        await fetchItem(result)
    }

    const fetchItem = async (result: Result): Promise<void> => {
        dispatch({ type: SET_LOADING, payload: true })

        try {
            if (result?.isCreateOption) {
                await itemRepository.createFull(db, result)
                return
            } else if (result.isSaved) {
                history.push(`${ITEM_PATH.replace(":id", result.id)}`)
            } else {
                const item = await itemRepository.getBySources(db, result.sources, true)
                if (item) {
                    history.push(`${ITEM_PATH.replace(":id", item.id)}`)
                    return
                }

                const source = result.sources.find((source) => source.name === WIKIPEDIA_PAGE)

                if (source) {
                    const { lang, slug } = getSlugAndLanguage(source.identifier)
                    if (!slug) return
                    const itemApi = await summariesApi.summarizeWikipediaPage(slug, lang)

                    const item = await itemService.create({
                        db,
                        itemApi,
                        isSaved: false,
                    })

                    history.push(`${ITEM_PATH.replace(":id", item.id)}`)
                } else {
                    throw new Error(`No source found for ${searchTerm}`)
                }
            }
        } catch (e) {
            sentry.captureException(e)
        } finally {
            dispatch({ type: SET_LOADING, payload: false })
            dispatch(finishCreateCardInSearchOnboardingAction())
        }
    }

    const isOpen = searchTerm.length > 0

    return (
        <>
            <SearchInputComp
                value={searchTerm}
                onChange={setSearchTerm}
                onPressEnter={handleSubmit}
                placeholder="Search anything"
                error={error}
            />
            <Box ref={ref}>
                {ref.current && (
                    <SearchResults
                        open={isOpen}
                        value={searchTerm}
                        anchorEl={ref.current}
                        onClose={onClose}
                        loading={loading}
                        results={results}
                        selectedIndex={selectedIndex}
                        setSelectedIndex={setSelectedIndex}
                        onSelectResult={handleSelectResult}
                    />
                )}
            </Box>
            <SupportList title="From predefined data sources" supportedSources={DATASOURCES} />
            <AddButtonFooter onClick={handleSubmit} onClose={onClose} />
        </>
    )
}
