import {
    MARK_BOLD,
    MARK_CODE,
    MARK_ITALIC,
    MARK_STRIKETHROUGH,
    MARK_SUBSCRIPT,
    MARK_SUPERSCRIPT,
    MARK_UNDERLINE,
} from "@udecode/plate-basic-marks"
import { ELEMENT_BLOCKQUOTE } from "@udecode/plate-block-quote"
import {
    ELEMENT_CODE_BLOCK,
    ELEMENT_CODE_LINE,
    ELEMENT_CODE_SYNTAX,
} from "@udecode/plate-code-block"
import { PlateElement, PlateLeaf, withProps } from "@udecode/plate-common"
import { ELEMENT_PARAGRAPH } from "@udecode/plate-paragraph"

import {
    ELEMENT_H1,
    ELEMENT_H2,
    ELEMENT_H3,
    ELEMENT_H4,
    ELEMENT_H5,
    ELEMENT_H6,
} from "@udecode/plate-heading"
import { MARK_HIGHLIGHT } from "@udecode/plate-highlight"
import { ELEMENT_HR } from "@udecode/plate-horizontal-rule"
import { ELEMENT_LINK } from "@udecode/plate-link"
import { ELEMENT_LI, ELEMENT_OL, ELEMENT_UL } from "@udecode/plate-list"
import { ELEMENT_IMAGE } from "@udecode/plate-media"
import { ELEMENT_MENTION_INPUT } from "@udecode/plate-mention"
import { SearchPreview } from "../../api"
import { Options } from "../../components"
import { ItemPartial } from "../../types"
import {
    BlockquoteElement,
    CodeBlockElement,
    CodeLeaf,
    CodeLineElement,
    CodeSyntaxLeaf,
    HeadingElement,
    HighlightLeaf,
    HrElement,
    ImageElement,
    LinkElement,
    ListElement,
    ParagraphElement,
    SearchReferenceElement,
    YouTubeTimestampElement,
} from "../components"
import { AIActionElement } from "../components/AIActionElement"
import { EditorBlockElement } from "../components/EditorBlockElement"
import { ReferenceElement, ReferenceItem } from "../components/ReferenceElement"
import { Result } from "../hooks/useSearchItems"
import { ELEMENT_AI_ACTION } from "./ai-action"
import { ELEMENT_CUSTOM_EDITOR_BLOCK } from "./editor-block"
import { ELEMENT_CUSTOM_IMAGE } from "./image"
import { ELEMENT_CUSTOM_PARAGRAPH } from "./paragraph"
import { ELEMENT_REFERENCE } from "./reference"
import { ELEMENT_SKIP } from "./skip"
import { YOUTUBE_TIMESTAMP } from "./youtube-timestamp"

interface Props {
    readOnly?: boolean
    isExtension?: boolean
    isMainImage?: (url: string) => boolean
    updateImage?: (image: string) => Promise<void>
    openItemById?: (id: string, options?: Options) => void
    getItemByConnectionId?: (id: string) => Promise<ReferenceItem>
    deleteConnectionById?: (id: string) => Promise<void>
    createConnection?: (
        itemApi: Result
    ) => Promise<{ name: string; connectionId: string; itemId: string }>
    searchWikipedia?: (query: string) => Promise<ItemPartial[]>
    getSummaryPreview?: (url: string) => Promise<SearchPreview>
    handleToggleAIAction?: (id: string) => Promise<void>
    handleDeleteAIAction?: (id: string) => Promise<void>
    handleRegenerateAIAction?: (id: string) => Promise<void>
    handleCopyAIAction?: (id: string) => Promise<void>
}

export const createUIComponents = ({
    readOnly = false,
    isExtension = false,
    isMainImage,
    updateImage,
    openItemById,
    getItemByConnectionId,
    deleteConnectionById,
    createConnection,
    searchWikipedia,
    getSummaryPreview,
    handleToggleAIAction,
    handleDeleteAIAction,
    handleRegenerateAIAction,
    handleCopyAIAction,
}: Props) => {
    return {
        [ELEMENT_BLOCKQUOTE]: BlockquoteElement,
        [ELEMENT_CODE_BLOCK]: CodeBlockElement,
        [ELEMENT_CODE_LINE]: CodeLineElement,
        [ELEMENT_CODE_SYNTAX]: CodeSyntaxLeaf,
        [ELEMENT_HR]: HrElement,
        [ELEMENT_H1]: withProps(HeadingElement, { variant: "h1" }),
        [ELEMENT_H2]: withProps(HeadingElement, { variant: "h2" }),
        [ELEMENT_H3]: withProps(HeadingElement, { variant: "h3" }),
        [ELEMENT_H4]: withProps(HeadingElement, { variant: "h4" }),
        [ELEMENT_H5]: withProps(HeadingElement, { variant: "h5" }),
        [ELEMENT_H6]: withProps(HeadingElement, { variant: "h6" }),
        [ELEMENT_PARAGRAPH]: ParagraphElement,
        [ELEMENT_CUSTOM_PARAGRAPH]: ParagraphElement,
        [MARK_BOLD]: withProps(PlateLeaf, { as: "strong" }),
        [MARK_ITALIC]: withProps(PlateLeaf, { as: "em" }),
        [MARK_STRIKETHROUGH]: withProps(PlateLeaf, { as: "s" }),
        [MARK_SUBSCRIPT]: withProps(PlateLeaf, { as: "sub" }),
        [MARK_SUPERSCRIPT]: withProps(PlateLeaf, { as: "sup" }),
        [MARK_UNDERLINE]: withProps(PlateLeaf, { as: "u" }),
        [MARK_CODE]: CodeLeaf,
        [MARK_HIGHLIGHT]: HighlightLeaf,
        [ELEMENT_LI]: withProps(PlateElement, { as: "li" }),
        [ELEMENT_UL]: withProps(ListElement, { variant: "ul" }),
        [ELEMENT_OL]: withProps(ListElement, { variant: "ol" }),
        [ELEMENT_REFERENCE]: withProps(ReferenceElement, {
            readOnly,
            openItemById,
            getItemByConnectionId,
            deleteConnectionById,
        }),
        [ELEMENT_IMAGE]: withProps(ImageElement, {
            readOnly,
            isExtension,
            isMainImage,
            updateImage,
        }),
        [ELEMENT_CUSTOM_IMAGE]: withProps(ImageElement, {
            readOnly,
            isExtension,
            isMainImage,
            updateImage,
        }),
        [ELEMENT_MENTION_INPUT]: withProps(SearchReferenceElement, {
            searchWikipedia: searchWikipedia,
            getSummaryPreview: getSummaryPreview,
            createConnection,
        }),
        [ELEMENT_CUSTOM_EDITOR_BLOCK]: EditorBlockElement,
        [YOUTUBE_TIMESTAMP]: withProps(YouTubeTimestampElement, { isExtension }),
        [ELEMENT_LINK]: LinkElement,
        [ELEMENT_SKIP]: withProps(PlateLeaf, { as: "span" }),
        [ELEMENT_AI_ACTION]: withProps(AIActionElement, {
            handleToggle: handleToggleAIAction,
            handleDelete: handleDeleteAIAction,
            handleRegenerate: handleRegenerateAIAction,
            handleCopy: handleCopyAIAction,
            isExtension,
        }),
    }
}
