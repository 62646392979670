import { Box, Fade, SxProps, Theme, Typography } from "@mui/material"
import { USAGE_LIMIT } from "@recall/common"
import { AppUpgradeButton } from "components/shared/buttons/AppUpgradeButton"
import { FC } from "react"

interface Props {
    freeLimitReached: boolean
    importLimitReached: boolean
    batchImportLimit: number
}

export const BookmarksLimitReached: FC<Props> = ({
    freeLimitReached,
    importLimitReached,
    batchImportLimit,
}) => {
    if (!freeLimitReached && !importLimitReached) return null

    return (
        <Fade in>
            <Box>
                {freeLimitReached && (
                    <Box sx={styles.limitReached}>
                        <Box>
                            <Typography variant="h6">Free Limit reached</Typography>
                            <Typography variant="body2">
                                On the free plan you can import up to {USAGE_LIMIT} bookmarks.
                            </Typography>
                        </Box>
                        <AppUpgradeButton />
                    </Box>
                )}
                {importLimitReached && (
                    <Box sx={styles.limitReached} pt={0}>
                        <Typography>
                            You can only import <b>{batchImportLimit}</b> bookmarks at the time.
                        </Typography>
                    </Box>
                )}
            </Box>
        </Fade>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    limitReached: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "background.default",
        borderRadius: 1,
        p: 2,
    },
}
