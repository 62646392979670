import { Box, Checkbox, Paper } from "@mui/material"
import { alpha, SxProps, Theme } from "@mui/material/styles"
import { ItemModel, Spinner, useIsMobile } from "@recall/common"
import React, { MouseEvent } from "react"
import { ViewType } from "storage/redux/items/types"
import { GridLayout } from "./components/GridLayout"
import { ListLayout } from "./components/ListLayout"
import { useLongPressSelection } from "./hooks/useLongPressSelection"

interface Props {
    item: ItemModel
    onClick?: Function
    style?: {
        image?: SxProps<Theme>
        paper?: SxProps<Theme>
    }
    isSelected?: boolean
    isSelectionActive?: boolean
    view?: ViewType
    toggleSelected?: (id: string, event?: React.MouseEvent) => void
    noHoverEffect?: boolean
    hidePin?: boolean
}

const ItemViewComponent: React.FC<Props> = ({
    onClick,
    item,
    style,
    isSelected,
    isSelectionActive,
    view = "grid",
    toggleSelected,
    noHoverEffect,
    hidePin,
}) => {
    const isMobile = useIsMobile()
    const { handleTouchEnd, handleTouchStart } = useLongPressSelection(() => {
        if (toggleSelected) toggleSelected(item.id)
    })

    const handleOnClick = async (e: MouseEvent) => {
        if (isSelectionActive && toggleSelected) {
            e.stopPropagation()
            e.preventDefault()
            return toggleSelected(item.id, e)
        }

        if (onClick) {
            e.stopPropagation()
            await onClick(item)
        }
    }

    return (
        <Paper
            elevation={0}
            sx={{
                ...(style?.paper
                    ? ({
                          ...styles.paper,
                          ...style.paper,
                      } as SxProps<Theme>)
                    : styles.paper),
                "&:hover": {
                    opacity: noHoverEffect ? 1 : 0.9,

                    ".checkbox": {
                        display: isMobile ? undefined : "block",
                    },
                },
            }}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
            onTouchMove={handleTouchEnd}
            onMouseDown={(e) => e.preventDefault()}
            onClick={!item.isLoading ? handleOnClick : undefined}
        >
            {item.isLoading && (
                <Box sx={styles.loading}>
                    <Spinner size={20} />
                </Box>
            )}
            {view === "grid" ? (
                <GridLayout isSelected={isSelected} item={item} hidePin={hidePin} />
            ) : (
                <ListLayout isSelected={isSelected} item={item} hidePin={hidePin} />
            )}

            {toggleSelected && (
                <Box
                    className="checkbox"
                    sx={{
                        display: isSelectionActive || isSelected ? "block" : "none",
                    }}
                >
                    <Checkbox
                        onClick={(e) => {
                            e.stopPropagation()
                            toggleSelected(item.id, e)
                        }}
                        checked={isSelected}
                        sx={{
                            position: "absolute",
                            top: 4,
                            right: 4,
                            color: "white",
                            background: alpha("#000", 0.1),
                            "&:hover": {
                                background: alpha("#000", 0.2),
                            },
                            "&.Mui-checked": {
                                color: "white",
                            },
                        }}
                    />
                </Box>
            )}
        </Paper>
    )
}

export const ItemView = React.memo(ItemViewComponent)

const styles: Record<string, SxProps<Theme>> = {
    paper: {
        height: "100%",
        overflow: "hidden",
        position: "relative",
        cursor: "pointer",
        border: (theme) => `1px solid ${alpha(theme.palette.text.primary, 0.1)}`,
        userSelect: "none",
        "& img": {
            pointerEvents: "none",
        },
        background: "transparent",
    },
    loading: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0,0,0,0.5)",
        zIndex: 1000,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
}
