import { YouTubeMetaData } from "../../api"
import { sentry } from "../../utils"

const get = (html: string, videoId: string): YouTubeMetaData => {
    try {
        const metaData = parseMetadata(html, videoId)
        if (metaData) return metaData
    } catch (e) {
        sentry.captureException(e as Error)
    }

    return getFallback(html, videoId)
}

const parseMetadata = (html: string, videoId: string): YouTubeMetaData | null => {
    const jsonMatch = html.match(/var ytInitialData = ({.*?});/)

    if (!jsonMatch) return null

    const jsonStr = jsonMatch[1]
    const data = JSON.parse(jsonStr)

    const videoData =
        data?.contents?.twoColumnWatchNextResults?.results?.results?.contents?.[0]
            ?.videoPrimaryInfoRenderer

    const ownerData =
        data?.contents?.twoColumnWatchNextResults?.results?.results?.contents?.[1]
            ?.videoSecondaryInfoRenderer

    if (!videoData || !ownerData) return null

    const author = ownerData.owner.videoOwnerRenderer.title.runs[0].text
    const description =
        ownerData?.description?.runs[0]?.text ||
        ownerData?.attributedDescription?.content ||
        "No transcript available"

    const name = videoData.title.runs[0].text
    const parser = new DOMParser()
    const doc = parser.parseFromString(html, "text/html")
    const imageSrcLink = doc.querySelector<HTMLLinkElement>('link[rel="image_src"]')

    return {
        name,
        author,
        description,
        imageUrl: imageSrcLink?.href || `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`,
    }
}

const getFallback = (html: string, videoId: string): YouTubeMetaData => {
    const dom = new DOMParser().parseFromString(html, "text/html")
    const name =
        dom.querySelector("title")?.textContent ||
        dom.querySelector("meta[name='title']")?.getAttribute("content") ||
        "No title available"
    const description =
        dom.querySelector("meta[name='description']")?.getAttribute("content") ||
        "No transcript available"
    const author = dom.querySelector("ytd-channel-name")?.textContent || "Unknown author"
    const imageSrcLink = dom.querySelector<HTMLLinkElement>('link[rel="image_src"]')

    return {
        name,
        author,
        description,
        imageUrl: imageSrcLink?.href || `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`,
    }
}

export const youtubeMetaService = { get }
