import { AutoFixHigh } from "@mui/icons-material"
import { Box, IconButton, SxProps, Theme, Tooltip } from "@mui/material"
import { isYoutubeVideo, useMediaBreakpoint, WEBSITE } from "@recall/common"
import { useSources } from "components/ItemPage/hooks/useSources"
import { useItemContext } from "components/ItemPage/providers/ItemProvider"
import { useTabActions, useTabState } from "components/ItemPage/providers/TabProvider"
import { Maximize2, Minimize2 } from "lucide-react"
import { FC, PropsWithChildren, useEffect } from "react"

interface Props extends PropsWithChildren {
    isReaderVisible: boolean
    toggleReader: () => void
}

export const ReaderWrapper: FC<Props> = ({ children, isReaderVisible, toggleReader }) => {
    const { tab } = useTabState()
    const { setTab } = useTabActions()
    const { reformatReader, item, chunks } = useItemContext()
    const { sources, isLoading } = useSources(item)
    const isTablet = useMediaBreakpoint("md")

    const websiteSource = sources.find((s) => s.name === WEBSITE)

    useEffect(() => {
        if (tab === "reader" && isReaderVisible && !isTablet) {
            setTab("notebook")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isReaderVisible])

    return (
        <Box sx={styles.container}>
            {children}
            {!isLoading && (
                <Box
                    sx={{
                        ...styles.actionsContainer,
                        position: isReaderVisible ? "sticky" : "fixed",
                    }}
                >
                    <Box sx={styles.actions}>
                        {chunks.length > 0 &&
                            websiteSource?.identifier &&
                            !isYoutubeVideo(websiteSource?.identifier) && (
                                <Tooltip title="Reformat Reader to make it more readable">
                                    <IconButton onClick={reformatReader}>
                                        <AutoFixHigh />
                                    </IconButton>
                                </Tooltip>
                            )}
                        {!isTablet && (
                            <Tooltip title={isReaderVisible ? "Collapse Reader" : "Expand Reader"}>
                                <IconButton onClick={toggleReader}>
                                    {isReaderVisible ? (
                                        <Minimize2 size={20} />
                                    ) : (
                                        <Maximize2 size={20} />
                                    )}
                                </IconButton>
                            </Tooltip>
                        )}
                    </Box>
                </Box>
            )}
        </Box>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    container: {
        flex: 1,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        background: (theme) => theme.palette.background.paper,
        borderRadius: 1,
    },
    menu: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        position: "absolute",
        top: 0,
        right: 0,
        zIndex: 1000,
    },
    menuItem: {
        display: "flex",
        alignItems: "center",
        gap: 1,
    },
    actionsContainer: {
        position: "sticky",
        bottom: 12,
        right: 4,
        display: "flex",
        justifyContent: "flex-end",
        zIndex: 1000,
    },
    actions: {
        display: "flex",
        gap: 1,
        opacity: 0.7,
        background: (theme) => theme.palette.background.paper,
        borderRadius: 1,

        "&:hover": {
            opacity: 1,
        },
    },
}
