import { SvgIcon, SvgIconProps } from "@mui/material"

export const QuizIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props} sx={{ color: "action.active", ...props.sx }}>
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M23.6025 8.338L12.3525 2.338C12.244 2.28022 12.1229 2.25 12 2.25C11.8771 2.25 11.756 2.28022 11.6475 2.338L0.39751 8.338C0.277514 8.40195 0.17716 8.49732 0.107185 8.6139C0.0372091 8.73049 0.000244141 8.8639 0.000244141 8.99988C0.000244141 9.13585 0.0372091 9.26926 0.107185 9.38585C0.17716 9.50243 0.277514 9.5978 0.39751 9.66175L3.00001 11.0502V15.5896C2.99923 15.958 3.13481 16.3136 3.38064 16.588C4.60876 17.9558 7.36032 20.2499 12 20.2499C13.5384 20.2626 15.0653 19.9839 16.5 19.4286V22.4999C16.5 22.6988 16.579 22.8896 16.7197 23.0302C16.8603 23.1709 17.0511 23.2499 17.25 23.2499C17.4489 23.2499 17.6397 23.1709 17.7803 23.0302C17.921 22.8896 18 22.6988 18 22.4999V18.7039C18.978 18.1394 19.8618 17.4254 20.6194 16.588C20.8652 16.3136 21.0008 15.958 21 15.5896V11.0502L23.6025 9.66175C23.7225 9.5978 23.8229 9.50243 23.8928 9.38585C23.9628 9.26926 23.9998 9.13585 23.9998 8.99988C23.9998 8.8639 23.9628 8.73049 23.8928 8.6139C23.8229 8.49732 23.7225 8.40195 23.6025 8.338ZM12 18.7499C7.94345 18.7499 5.55751 16.768 4.50001 15.5896V11.8499L11.6475 15.6618C11.756 15.7195 11.8771 15.7498 12 15.7498C12.1229 15.7498 12.244 15.7195 12.3525 15.6618L16.5 13.4502V17.7946C15.3188 18.3458 13.83 18.7499 12 18.7499ZM19.5 15.5858C19.0504 16.0847 18.5474 16.5327 18 16.9218V12.6496L19.5 11.8499V15.5858ZM17.625 11.1505L17.6044 11.1383L12.3544 8.338C12.1792 8.24853 11.9759 8.23145 11.7883 8.29044C11.6007 8.34943 11.4437 8.47976 11.3513 8.65337C11.2588 8.82697 11.2382 9.02994 11.294 9.21855C11.3498 9.40717 11.4774 9.56633 11.6494 9.66175L16.0313 11.9999L12 14.1496L2.34376 8.99988L12 3.85019L21.6563 8.99988L17.625 11.1505Z" />
            </svg>
        </SvgIcon>
    )
}
