import { Database, Q } from "@nozbe/watermelondb"
import {
    ALIAS,
    EDITOR_BLOCKS,
    EditorBlockData,
    EditorBlockModel,
    editorBlockRepository,
    ELEMENT_CUSTOM_IMAGE,
    ELEMENT_CUSTOM_PARAGRAPH,
    ImageBlockData,
    ItemModel,
    ITEMS,
    QuestionModel,
    questionRepository,
    QUESTIONS,
    ROOT_TAG_ID,
    SourceModel,
    sourceRepository,
    SOURCES,
    TagModel,
    tagRepository,
    TAGS,
    WEBSITE,
    WIKIDATA,
    wikidataService,
    WIKIPEDIA,
    WIKIPEDIA_PAGE,
} from "@recall/common"
import { ELEMENT_IMAGE } from "@udecode/plate-media"
import { ELEMENT_PARAGRAPH } from "@udecode/plate-paragraph"
import { keyBy } from "lodash"
import { v4 as uuid } from "uuid"
import { fixBase64Images } from "./assets"
import { DataMigrationImplementation } from "./types"

const setDefaultIsExpandedValue: DataMigrationImplementation = {
    name: "Set default is_expanded value",
    run: async (database: Database) => {
        await database.write(async (writer) => {
            const items = await database.collections.get<ItemModel>(ITEMS).query().fetch()

            for (const item of items) {
                await item.update((record) => {
                    record.isExpanded = !record.isReference
                })
            }
        })
    },
}

const setEditorBlocksText: DataMigrationImplementation = {
    name: "Set editor blocks text 1",
    run: async (database: Database) => {
        await database.write(async (writer) => {
            const editorBlocks = await database.collections
                .get<EditorBlockModel>(EDITOR_BLOCKS)
                .query()
                .fetch()

            for (const editorBlock of editorBlocks) {
                await editorBlock.update((record) => {
                    record.text = EditorBlockData.getFormattedText([editorBlock])
                })
            }
        })
    },
}

const removeStaleQuestions = {
    name: "Remove questions without items",
    run: async (database: Database) => {
        const questions = await questionRepository.getAll(database)

        for (const question of questions) {
            try {
                const item = await question.item.fetch()
                if (item._raw._status === "deleted") {
                    await question.delete()
                }
            } catch {
                await question.delete()
            }
        }
    },
}

const setItemsDescriptionAndImage: DataMigrationImplementation = {
    name: "Set items description and image",
    run: async (database: Database) => {
        await database.write(async (writer) => {
            const items = await database.collections
                .get<ItemModel>(ITEMS)
                .query(Q.where("is_saved", true))
                .fetch()

            for (const item of items) {
                const editorOrders = await item.editorOrders.fetch()
                if (!editorOrders.length) continue

                const editorBlocks = await Promise.all(
                    editorOrders[0].order.map((id) => editorBlockRepository.get(database, id))
                )
                let text = ""
                if (editorBlocks.length > 1) text = EditorBlockData.getText(editorBlocks.slice(1))
                else text = EditorBlockData.getText(editorBlocks)

                const description = text.length > 60 ? text.slice(0, 60).trim() + "..." : text
                const imageBlock = EditorBlockData.getFirstImageBlock(editorBlocks)

                let image = ""
                if (imageBlock) {
                    image =
                        imageBlock?.url ||
                        imageBlock?.options?.url ||
                        ImageBlockData.getUrl320(imageBlock) ||
                        ""
                }

                const updatedAt = item.updatedAt

                await item.update((record) => {
                    record.description = description
                    record.image = image
                    record.updatedAt = updatedAt
                })
            }
        })
    },
}

const removeQuestionsWithoutAnswers = {
    name: "Remove questions without answer 2",
    run: async (database: Database) => {
        const questions = await database.collections
            .get<QuestionModel>(QUESTIONS)
            .query(Q.where("is_saved", true))
            .fetch()

        for (const question of questions) {
            if (question.options.includes(question.correctAnswer)) continue
            await question.delete()
        }
    },
}

const assignParentTags = {
    name: "Assign parent tags 2",
    run: async (database: Database) => {
        await database.write(async (writer) => {
            const rootTag = await tagRepository.get(database, ROOT_TAG_ID)
            if (!rootTag) return

            let allTags = await database.collections.get<TagModel>(TAGS).query().fetch()

            const tagsById = keyBy(allTags, "id")
            const tagsWithParentIds = new Set()
            for (const tag of allTags) {
                if (tag?.parent?.id) tagsWithParentIds.add(tag.id)
            }

            for (const tag of allTags) {
                if (!tag.children || tagsWithParentIds.has(tag.id)) continue

                for (const child of tag.children) {
                    if (child in tagsById) {
                        const tagToUpdate = tagsById[child]
                        await tagToUpdate.update((record) => {
                            record.parent.set(tag)
                        })
                    }
                }
            }

            allTags = await database.collections.get<TagModel>(TAGS).query().fetch()
            for (const tag of allTags) {
                if (!tag.parent.id && tag.id !== ROOT_TAG_ID) {
                    await tag.update((record) => {
                        record.parent.set(rootTag)
                    })
                }
            }
        })
    },
}

const setEntityAliases = {
    name: "Set entity aliases",
    run: async (database: Database) => {
        await database.write(async (writer) => {
            const sources = await database.collections
                .get<SourceModel>(SOURCES)
                .query(Q.where("is_saved", true), Q.where("name", WIKIDATA))
                .fetch()

            const itemsWithMentions = await Promise.all(
                sources.map(async (source) => {
                    const item = await source.item.fetch()

                    return {
                        identifier: source.identifier,
                        item,
                    }
                })
            )

            const itemsByIdentifier = Object.fromEntries(
                itemsWithMentions
                    .filter(({ item }) => !item.aliases.length)
                    .map(({ identifier, item }) => [identifier, item])
            )

            const aliasByIdentifier = await wikidataService.getAliasesBatch(
                Object.keys(itemsByIdentifier)
            )

            for (const [identifier, aliases] of Object.entries(aliasByIdentifier)) {
                const item = itemsByIdentifier[identifier]
                if (!item) continue

                const updatedAt = item.updatedAt
                const allAliases = [...new Set([item.name, ...(aliases as string[])])]

                await item.update((record) => {
                    record.aliases = allAliases
                    record.updatedAt = updatedAt
                })
            }
        })
    },
}

const cleanupSources = {
    name: "Migrate entity sources to new wikipedia page format",
    run: async (database: Database) => {
        await database.write(async (writer) => {
            const items = await database.collections.get<ItemModel>(ITEMS).query().fetch()
            for (const item of items) {
                const sources = await item.sources.fetch()
                const wikipediaSource = sources.find((source) => source.name === WIKIPEDIA)

                if (wikipediaSource) {
                    const identifier = `https://${item.language || "en"}.wikipedia.org/wiki/${wikipediaSource.identifier}`
                    await writer.callWriter(() =>
                        sourceRepository.create(database, item, {
                            id: uuid(),
                            name: WIKIPEDIA_PAGE,
                            identifier,
                        })
                    )

                    await writer.callWriter(() => wikipediaSource.delete())
                    const websiteSource = sources.find((source) => source.name === WEBSITE)
                    if (websiteSource) {
                        await writer.callWriter(() => websiteSource.delete())
                    }
                    continue
                }

                const aliasSource = sources.find((source) => source.name === ALIAS)

                const updatedAt = item.updatedAt
                if (aliasSource) {
                    await item.update((record) => {
                        record.aliases = [item.name]
                        record.updatedAt = updatedAt
                    })
                }
            }
        })
    },
}

const updateChildrenEditorBlocks = (imageUrl: string, editorBlock: EditorBlockModel) => {
    if (editorBlock?.options?.url && editorBlock.options.url === imageUrl) {
        return editorBlock.prepareMarkAsDeleted()
    }

    const childrenToUpdate = editorBlock.children.filter((child) => {
        if (child.type === ELEMENT_CUSTOM_IMAGE || child.type === ELEMENT_IMAGE) {
            if (!imageUrl) return true

            const imageUrls = [
                child?.options?.url,
                child?.urlOriginal,
                child?.url,
                child?.urlThumbnail,
                child?.url_320,
                child?.url_1024,
            ].filter(Boolean)

            if (imageUrls.length === 0) return true

            return imageUrls.every((url) => url !== imageUrl)
        }

        return child?.children?.[0]?.text?.length !== 0
    })

    if (childrenToUpdate.length === 0) {
        return editorBlock.prepareMarkAsDeleted()
    } else if (childrenToUpdate.length !== editorBlock.children.length) {
        return editorBlock.prepareUpdate((record) => {
            record.children = childrenToUpdate
        })
    }
}

const removeNotebookNameAndImage = {
    name: "Remove notebook name and image editor blocks",
    run: async (database: Database) => {
        await database.write(async (writer) => {
            const items = await database.collections.get<ItemModel>(ITEMS).query().fetch()
            const tasks = []

            for (const item of items) {
                const editorBlocks = await item.getOrderedEditorBlocks()

                if (editorBlocks.length === 0) continue

                const editorBlockName = editorBlocks[0]
                const name = EditorBlockData.getText([editorBlockName])

                if (name && name === item.name) {
                    tasks.push(editorBlockName.prepareMarkAsDeleted())
                } else {
                    const task = updateChildrenEditorBlocks(item.image, editorBlockName)
                    if (task) tasks.push(task)
                }

                const editorBlockImage = editorBlocks?.[1]

                if (!editorBlockImage) continue

                const task = updateChildrenEditorBlocks(item.image, editorBlockImage)
                if (task) tasks.push(task)

                const editorBlockParagraph = editorBlocks?.[2]
                if (!editorBlockParagraph) continue

                const paragraphText = EditorBlockData.getText([editorBlockParagraph])

                if (
                    paragraphText.trim() === "" &&
                    (editorBlockParagraph.type === ELEMENT_CUSTOM_PARAGRAPH ||
                        editorBlockParagraph.type === ELEMENT_PARAGRAPH)
                ) {
                    tasks.push(editorBlockParagraph.prepareMarkAsDeleted())
                }
            }

            if (tasks.length > 0) {
                await writer.batch(...tasks)
            }
        })
    },
}

const allDataMigrations = [
    removeNotebookNameAndImage,
    setDefaultIsExpandedValue,
    setEditorBlocksText,
    removeStaleQuestions,
    setItemsDescriptionAndImage,
    removeQuestionsWithoutAnswers,
    assignParentTags,
    fixBase64Images,
    setEntityAliases,
    cleanupSources,
]

export default allDataMigrations
