export class GoogleDocsService {
    private static regex = new RegExp(
        "^https://docs\\.google\\.com/(document|spreadsheets|presentation)/d/"
    )

    public static isGoogleDoc(url: string): boolean {
        return this.regex.test(url)
    }

    public static getDocId(url: string): string | null {
        const match = url.match(/\/d\/([a-zA-Z0-9-_]+)/)
        return match ? match[1] : null
    }

    public static getExportUrl(url: string): string | null {
        const docId = this.getDocId(url)
        if (!docId) {
            return null
        }

        if (url.startsWith("https://docs.google.com/spreadsheets/d/")) {
            return `https://docs.google.com/spreadsheets/d/${docId}/export?format=pdf`
        } else if (url.startsWith("https://docs.google.com/document/d/")) {
            return `https://docs.google.com/document/d/${docId}/export?format=pdf`
        } else if (url.startsWith("https://docs.google.com/presentation/d/")) {
            return `https://docs.google.com/presentation/d/${docId}/export?format=pdf`
        }

        return null
    }

    public static async getExportedDoc(url: string): Promise<Blob | null> {
        const exportUrl = this.getExportUrl(url)

        if (!exportUrl) return null

        try {
            const response = await fetch(exportUrl)
            return await response.blob()
        } catch (e) {
            return null
        }
    }
}
