import { SvgIcon, SvgIconProps } from "@mui/material"

export const OpenUrlIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M17.5 8.125C17.5 8.29076 17.4342 8.44973 17.3169 8.56694C17.1997 8.68415 17.0408 8.75 16.875 8.75C16.7092 8.75 16.5503 8.68415 16.4331 8.56694C16.3158 8.44973 16.25 8.29076 16.25 8.125V4.63438L11.068 9.81719C10.9507 9.93446 10.7916 10.0003 10.6258 10.0003C10.4599 10.0003 10.3009 9.93446 10.1836 9.81719C10.0663 9.69991 10.0004 9.54085 10.0004 9.375C10.0004 9.20915 10.0663 9.05009 10.1836 8.93281L15.3656 3.75H11.875C11.7092 3.75 11.5503 3.68415 11.4331 3.56694C11.3158 3.44973 11.25 3.29076 11.25 3.125C11.25 2.95924 11.3158 2.80027 11.4331 2.68306C11.5503 2.56585 11.7092 2.5 11.875 2.5H16.875C17.0408 2.5 17.1997 2.56585 17.3169 2.68306C17.4342 2.80027 17.5 2.95924 17.5 3.125V8.125ZM14.375 10C14.2092 10 14.0503 10.0658 13.9331 10.1831C13.8158 10.3003 13.75 10.4592 13.75 10.625V16.25H3.75V6.25H9.375C9.54076 6.25 9.69973 6.18415 9.81694 6.06694C9.93415 5.94973 10 5.79076 10 5.625C10 5.45924 9.93415 5.30027 9.81694 5.18306C9.69973 5.06585 9.54076 5 9.375 5H3.75C3.41848 5 3.10054 5.1317 2.86612 5.36612C2.6317 5.60054 2.5 5.91848 2.5 6.25V16.25C2.5 16.5815 2.6317 16.8995 2.86612 17.1339C3.10054 17.3683 3.41848 17.5 3.75 17.5H13.75C14.0815 17.5 14.3995 17.3683 14.6339 17.1339C14.8683 16.8995 15 16.5815 15 16.25V10.625C15 10.4592 14.9342 10.3003 14.8169 10.1831C14.6997 10.0658 14.5408 10 14.375 10Z"
                    fill="white"
                />
            </svg>
        </SvgIcon>
    )
}
