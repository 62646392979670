import { initializeApp } from "firebase/app"
import { GoogleAuthProvider, OAuthProvider, getAuth, signInWithPopup } from "firebase/auth"
import { getDatabase } from "firebase/database"
import { initializeFirestore } from "firebase/firestore"
import { connectFunctionsEmulator, getFunctions } from "firebase/functions"
import { getStorage } from "firebase/storage"
import { firebaseConfig } from "./firebaseConfig"

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const database = getDatabase(app)
const functions = getFunctions(app, "europe-west1")
const storage = getStorage(app)

const firestore = initializeFirestore(app, {
    experimentalForceLongPolling: true,
})

const signinWithPopup = () => {
    return signInWithPopup(firebase.auth, new GoogleAuthProvider())
}

const signinWithApple = () => {
    return signInWithPopup(firebase.auth, new OAuthProvider("apple.com"))
}

const getAuthToken = async () => {
    return await auth.currentUser?.getIdToken()
}

export const firebase = {
    app,
    auth,
    database,
    functions,
    firestore,
    storage,
    signinWithPopup,
    signinWithApple,
    getAuthToken,
}
