import { Box } from "@mui/material"
import React, { FC, useEffect } from "react"
import { ItemsList } from "./components/ItemsList"
import { TagsBar } from "./components/TagsBar"

const ItemsPage: FC = () => {
    useEffect(() => {
        document.title = "Recall Cards"
    }, [])

    return (
        <Box px={2} height="100%">
            <TagsBar />
            <ItemsList />
        </Box>
    )
}

export default React.memo(ItemsPage)
