import { ItemModel, PlateEditor, useCleanUpCopiedBlocks, usePlateHelpers } from "@recall/common"
import { usePlatePlugins } from "hooks/editor/usePlatePlugins"
import { FC } from "react"
import { useSelector } from "react-redux"
import { RootState } from "storage/redux/rootReducer"

interface Props {
    item: ItemModel
    readOnly?: boolean
    focus?: boolean
}

export const Editor: FC<Props> = ({ item, readOnly = false, focus = false }) => {
    const uid = useSelector((state: RootState) => state.user.uid)

    const plugins = usePlatePlugins(item, readOnly)

    const { initEditorBlocks, updateEditorBlocks, uploadImage } = usePlateHelpers(item, uid)
    const { cleanUpCopiedBlocks } = useCleanUpCopiedBlocks(item)

    return (
        <PlateEditor
            id={item.id}
            readOnly={readOnly}
            plugins={plugins}
            initEditorBlocks={initEditorBlocks}
            updateEditorBlocks={updateEditorBlocks}
            cleanUpCopiedBlocks={cleanUpCopiedBlocks}
            uploadImage={uploadImage}
            sx={{ p: 1, px: 2, bgcolor: "transparent" }}
            focus={focus}
        />
    )
}
