import { Database } from "@nozbe/watermelondb"
import { ELEMENT_PARAGRAPH } from "@udecode/plate-paragraph"
import { v4 as uuid } from "uuid"
import { ChatMessage } from "."
import { ELEMENT_AI_ACTION } from "../editor/plugins/ai-action"
import { AIAction } from "../repositories/userRepository"
import { ItemModel } from "../storage/watermelon/models/ItemModel"
import { editorBlockRepository } from "../storage/watermelon/repository/editorBlockRepository"
import { editorOrderRepository } from "../storage/watermelon/repository/editorOrderRepository"
import { itemService } from "../storage/watermelon/services/itemService"

const addChatMessageToNotebook = async (
    db: Database,
    item: ItemModel,
    question: ChatMessage,
    answer: ChatMessage
) => {
    return await db.write(async (writer) => {
        const editorBlocksWithEntities = await itemService.enrichMarkdown(db, answer.content, item)

        const editorBlocks = await writer.callWriter(() =>
            editorBlockRepository.createBatch(
                db,
                item.id,
                [
                    {
                        type: ELEMENT_AI_ACTION,
                        id: uuid(),
                        children: editorBlocksWithEntities,
                        action: question?.action || {
                            id: uuid(),
                            name: question.content,
                            isChatMessage: true,
                        },
                        isLoading: false,
                        isExpanded: true,
                        messageId: answer.id,
                    },
                    {
                        type: ELEMENT_PARAGRAPH,
                        id: uuid(),
                        children: [{ text: "" }],
                    },
                ],
                item.isSaved
            )
        )

        if (!editorBlocks?.length) return
        return editorBlocks[0]
    })
}

const handleToggleAIAction = async (db: Database, id: string) => {
    await db.write(async () => {
        const editorBlock = await editorBlockRepository.get(db, id)
        if (!editorBlock) return
        await editorBlock.update((record) => {
            record.options = { ...record.options, isExpanded: !record.options.isExpanded }
        })
    })
}

const handleDeleteAIAction = async (db: Database, id: string): Promise<string | null> => {
    return await db.write(async (writer) => {
        const editorBlock = await editorBlockRepository.get(db, id)
        if (!editorBlock) return null
        await writer.callWriter(() => editorBlock.delete())

        const editorOrder = await editorOrderRepository.get(db, editorBlock.item.id)
        if (!editorOrder) return null
        await editorOrder.update((record) => {
            record.order = record.order.filter((blockId) => blockId !== id)
        })
        return editorBlock?.options?.messageId || null
    })
}

const enrichAIActionElement = async (
    db: Database,
    item: ItemModel,
    markdown: string,
    action: AIAction
) => {
    const editorBlocks = await item.editorBlocks.fetch()
    const actionBlock = editorBlocks.find(
        (block) => block.type === ELEMENT_AI_ACTION && block.options?.action?.id === action.id
    )

    if (!actionBlock) return

    const editorBlocksWithEntities = await itemService.enrichMarkdown(db, markdown, item)

    await db.write(async () => {
        await actionBlock.update((record) => {
            record.children = editorBlocksWithEntities
        })
    })
}

export const notebookService = {
    addChatMessageToNotebook,
    enrichAIActionElement,
    handleToggleAIAction,
    handleDeleteAIAction,
}
