import posthog, { PostHogConfig } from "posthog-js"
import { v4 as uuid } from "uuid"
import { HttpClient, isDevelopment } from "../utils"

const POSTHOG_API_HOST = "https://d2squso3hsjcqf.cloudfront.net"
const API_KEY = "phc_XER41Cya8lZH6DXgQpNpHZb8NRHljrXBBJ8VSOeqBqG"

const posthogHttpClient = new HttpClient(POSTHOG_API_HOST)

class PosthogService {
    userId: string

    constructor() {
        this.userId = uuid()
    }

    init = (config: Partial<PostHogConfig> = {}) => {
        if (isDevelopment()) {
            return
        }

        posthog.init(API_KEY, {
            api_host: POSTHOG_API_HOST,
            ...config,
        })
    }

    setUser = (email: string) => {
        if (isDevelopment()) {
            return
        }
        posthog.identify(email, { email })
    }

    setUserId = (email: string) => {
        if (isDevelopment()) {
            return
        }

        this.userId = email
    }

    captureEvent = (name: string, properties?: Object) => {
        if (isDevelopment()) {
            return
        }
        posthog.capture(name, properties)
    }

    capturePublicApiEvent = async (event: string, properties = {}) => {
        if (isDevelopment()) {
            return
        }

        await posthogHttpClient.post(
            "/capture/",
            JSON.stringify({
                api_key: API_KEY,
                event,
                properties: { ...properties, distinct_id: this.userId },
            })
        )
    }

    isFeatureEnabled = (name: string) => {
        if (isDevelopment()) {
            return true
        }
        return posthog.isFeatureEnabled(name)
    }

    getClient() {
        return posthog
    }

    getFeatureFlag = (name: string) => {
        if (isDevelopment()) return null
        return posthog.getFeatureFlag(name)
    }
}

const posthogService = new PosthogService()

export { posthogService }
