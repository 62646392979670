import { Database } from "@nozbe/watermelondb"
import {
    chunksService,
    conciseSummaryAction,
    Content,
    editorBlockRepository,
    ELEMENT_AI_ACTION,
    fileService,
    handleCreate,
    itemService,
    LinkApi,
    markdownService,
    parseUrl,
    saveChunks,
    sentry,
} from "@recall/common"
import { ELEMENT_PARAGRAPH } from "@udecode/plate-paragraph"
import { Interest } from "components/ExtensionPages/InstallExtensionPage/components/types"
import { itemCreatorService } from "components/ItemPage/services/itemCreatorService"
import { RERENDER_ITEMS_LIST } from "components/ItemsPage/hooks/useGroupedItems"
import { v4 as uuid } from "uuid"
import { entitiesApi } from "./api"

const create = async (db: Database, url: string, uid: string, isImporting?: boolean) => {
    const content = await itemCreatorService.parseContent(url)
    if (!content) return null

    const parsedUrl = parseUrl(url)
    const item = await itemService.createBaseItem({
        db,
        id: uuid(),
        url: parsedUrl,
        name: content.name,
        description: content.description,
        image: content.image,
        isSaved: false,
        isReadable: content.isReadable,
    })
    if (content.pdfFile) await fileService.uploadAsset({ item, file: content.pdfFile, db, uid })
    let chunks = chunksService.chunkMarkdown(content.markdown)

    try {
        await itemCreatorService.createTag({
            db,
            itemId: item.id,
            text: chunksService.markdownToText(content.markdown),
            name: item.name,
            url: parsedUrl,
            isImporting,
        })
    } catch (error) {
        sentry.captureException(error)
    }

    const cleanedMarkdown = markdownService.stripMarkdownFormatting(content.markdown)

    try {
        const entities =
            content.links ||
            (await entitiesApi.extractEntities(cleanedMarkdown, item.id, parsedUrl, isImporting))

        chunks = await itemService.saveReaderConnections(db, item, entities, [...chunks])
    } catch (error) {
        sentry.captureException(error)
    }

    await saveChunks({
        itemId: item.id,
        chunks,
    })

    await item.saveDeep()
    return item
}
const getInterestData = async (interest: Interest) => {
    const interestModule = await import(`../storage/data/interests/${interest.id.toLowerCase()}.ts`)
    return interestModule.default
}

const createItemByInterest = async (db: Database, interest: Interest) => {
    const interestData = await getInterestData(interest)
    await createItemWithPredefinedData(db, interestData, interest.url)
}

interface Data {
    content: Content
    tag: string
    summary: string
    entities: LinkApi[]
}

const createItemWithPredefinedData = async (db: Database, data: Data, url: string) => {
    const item = await itemService.createBaseItem({
        db,
        id: uuid(),
        url: url,
        name: data.content.name,
        description: data.content.description,
        image: data.content.image,
        isSaved: false,
        isReadable: true,
        isLoading: true,
    })
    await handleCreate(db, item.id, data.tag)
    let chunks = chunksService.chunkMarkdown(data.content.markdown)
    chunks = await itemService.saveReaderConnections(db, item, data.entities, [...chunks])

    const editorBlocksWithEntities = await itemService.enrichMarkdown(db, data.summary, item)
    await editorBlockRepository.createBatch(
        db,
        item.id,
        [
            {
                type: ELEMENT_AI_ACTION,
                id: uuid(),
                children: editorBlocksWithEntities,
                action: conciseSummaryAction,
                isLoading: false,
                isExpanded: true,
            },
            {
                type: ELEMENT_PARAGRAPH,
                id: uuid(),
                children: [{ text: "" }],
            },
        ],
        item.isSaved
    )

    await item.saveDeep()

    await saveChunks({
        itemId: item.id,
        chunks,
    })

    await item.updateIsLoading(false)
    document.dispatchEvent(new CustomEvent(RERENDER_ITEMS_LIST))
}

export const createItemService = {
    create,
    createItemByInterest,
    createItemWithPredefinedData,
}
