import { ContentType, TAG_INPUT_ID, useMediaBreakpoint } from "@recall/common"
import { useTabState } from "components/ItemPage/providers/TabProvider"
import { useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { RootState } from "storage/redux/rootReducer"

export const ITEM_CONTENT_ID = "item-content"

export const useTabHeight = (
    contentRef: React.RefObject<HTMLDivElement>,
    sentinelRef: React.RefObject<HTMLDivElement>,
    contentType: ContentType
) => {
    const [height, setHeight] = useState(undefined)
    const isReaderVisible = useSelector((state: RootState) => state.user.settings.isReaderVisible)
    const { tab } = useTabState()
    const isTablet = useMediaBreakpoint("md")
    const isFullHeightTab =
        tab === "reader" || tab === "notebook" || tab === "connections" || tab === "quiz"

    useEffect(() => {
        if (!tab) return

        const isSingleTab = !isReaderVisible || isTablet || contentType === "entity"

        if (isSingleTab && isFullHeightTab) {
            setHeight(null)
            return
        }

        const tabContent = document.getElementById(ITEM_CONTENT_ID)
        if (tabContent) {
            const rect = tabContent.getBoundingClientRect()
            const visibleTop = Math.max(rect.top, 0)
            setHeight(`calc(100vh - ${visibleTop + 20}px)`)
        }

        const updateHeight = () => {
            setTimeout(() => {
                if (!contentRef.current || (isSingleTab && isFullHeightTab)) return
                const rect = contentRef.current.getBoundingClientRect()
                const visibleTop = Math.max(rect.top, 0)
                setHeight(`calc(100vh - ${visibleTop + 20}px)`)
            }, 500)
        }

        const observer = new IntersectionObserver(updateHeight, {
            root: null,
            threshold: 0,
        })

        if (sentinelRef.current) observer.observe(sentinelRef.current)

        const tagInputElement = document.getElementById(TAG_INPUT_ID)
        if (tagInputElement) observer.observe(tagInputElement)

        return () => observer.disconnect()
    }, [contentRef, sentinelRef, isTablet, isFullHeightTab, tab, isReaderVisible, contentType])

    const minHeight = useMemo(() => {
        if (!contentRef.current || isTablet) return "85vh"
        const rect = contentRef.current.getBoundingClientRect()
        const visibleTop = Math.max(rect.top, 0)
        return `calc(100vh - ${visibleTop + 40}px)`

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentRef?.current, isTablet])

    const tabHeight = useMemo(() => {
        if (!isFullHeightTab) return height || minHeight
        return height
    }, [height, minHeight, isFullHeightTab])

    return {
        height: tabHeight,
        minHeight,
    }
}
