import { SvgIcon, SvgIconProps } from "@mui/material"

export const NewspaperIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <svg
                width="19"
                height="13"
                viewBox="0 0 19 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M6.875 5.25C6.875 5.08424 6.94085 4.92527 7.05806 4.80806C7.17527 4.69085 7.33424 4.625 7.5 4.625H13.75C13.9158 4.625 14.0747 4.69085 14.1919 4.80806C14.3092 4.92527 14.375 5.08424 14.375 5.25C14.375 5.41576 14.3092 5.57473 14.1919 5.69194C14.0747 5.80915 13.9158 5.875 13.75 5.875H7.5C7.33424 5.875 7.17527 5.80915 7.05806 5.69194C6.94085 5.57473 6.875 5.41576 6.875 5.25ZM7.5 8.375H13.75C13.9158 8.375 14.0747 8.30915 14.1919 8.19194C14.3092 8.07473 14.375 7.91576 14.375 7.75C14.375 7.58424 14.3092 7.42527 14.1919 7.30806C14.0747 7.19085 13.9158 7.125 13.75 7.125H7.5C7.33424 7.125 7.17527 7.19085 7.05806 7.30806C6.94085 7.42527 6.875 7.58424 6.875 7.75C6.875 7.91576 6.94085 8.07473 7.05806 8.19194C7.17527 8.30915 7.33424 8.375 7.5 8.375ZM18.125 1.5V10.875C18.125 11.3723 17.9275 11.8492 17.5758 12.2008C17.2242 12.5525 16.7473 12.75 16.25 12.75H2.5C2.0042 12.75 1.52859 12.5536 1.1772 12.2039C0.825819 11.8541 0.627272 11.3794 0.625 10.8836V3.375C0.625 3.20924 0.690848 3.05027 0.808058 2.93306C0.925268 2.81585 1.08424 2.75 1.25 2.75C1.41576 2.75 1.57473 2.81585 1.69194 2.93306C1.80915 3.05027 1.875 3.20924 1.875 3.375V10.875C1.875 11.0408 1.94085 11.1997 2.05806 11.3169C2.17527 11.4342 2.33424 11.5 2.5 11.5C2.66576 11.5 2.82473 11.4342 2.94194 11.3169C3.05915 11.1997 3.125 11.0408 3.125 10.875V1.5C3.125 1.16848 3.2567 0.850537 3.49112 0.616116C3.72554 0.381696 4.04348 0.25 4.375 0.25H16.875C17.2065 0.25 17.5245 0.381696 17.7589 0.616116C17.9933 0.850537 18.125 1.16848 18.125 1.5ZM16.875 1.5H4.375V10.875C4.37531 11.0879 4.33911 11.2993 4.26797 11.5H16.25C16.4158 11.5 16.5747 11.4342 16.6919 11.3169C16.8092 11.1997 16.875 11.0408 16.875 10.875V1.5Z"
                    fill="#A8C5DF"
                />
            </svg>
        </SvgIcon>
    )
}
