import { FC, memo } from "react"

import { Box, Tooltip, Typography } from "@mui/material"
import { SxProps, Theme } from "@mui/material/styles"
import { useDatabase } from "@nozbe/watermelondb/react"
import { ItemModel, PinIcon, tagRepository } from "@recall/common"
import { useState } from "react"
import { useSelector } from "react-redux"
import { RootState } from "storage/redux/rootReducer"
import { isCardShared } from "storage/redux/user/selector"
import { MentionCountBadge } from "../../badges/MentionCountBadge"
import { SharedBadge } from "../../badges/SharedBadge"
import { RERENDER_TAGS_EVENT } from "../../tags/hooks/useGroupedTags"
import { DescriptionComp } from "./DescriptionComp"
import { ListImage } from "./ListImage"
import { Tags } from "./Tags"

interface Props {
    item: ItemModel
    isSelected: boolean
    hidePin?: boolean
}

const ListLayoutComponent: FC<Props> = ({ isSelected, item, hidePin }) => {
    const db = useDatabase()

    const [isPinned, setIsPinned] = useState(false)
    const isShared = useSelector((state: RootState) => isCardShared(state, item.id))

    const handlePin = async (e, item: ItemModel) => {
        e.preventDefault()
        e.stopPropagation()
        setIsPinned(true)
        await item.setIsReference(!item.isReference)
        const tags = await item.tags.fetch()
        await tagRepository.updateTagsReferenceState(db, tags, item)
        document.dispatchEvent(new CustomEvent(RERENDER_TAGS_EVENT))
    }

    return (
        <Box sx={{ opacity: isSelected ? 0.6 : 1, height: 145 }}>
            <Box p={1} display="flex" justifyContent="space-between" height="100%">
                <Box display="flex" flexDirection="column" justifyContent="space-between" flex={1}>
                    <Box>
                        <Typography sx={styles.name} variant="h6">
                            {item.name}
                        </Typography>
                        <DescriptionComp description={item.description} />
                    </Box>
                    <Tags tags={item.tags} />
                </Box>
                <Box display="flex" flexDirection="column" justifyContent="space-between">
                    {item.image && <ListImage image={item.image} />}
                    <Box display="flex" flexDirection="column" alignItems="flex-end" rowGap={0.8}>
                        <Box display="flex" gap={0.8} alignItems="flex-end">
                            {!isPinned && item.isReference && !hidePin && (
                                <Tooltip
                                    title={
                                        <Typography variant="body2">
                                            <b>Pin</b> this card
                                        </Typography>
                                    }
                                    onClick={(e) => handlePin(e, item)}
                                >
                                    <PinIcon isFilled={false} sx={styles.pinIcon} />
                                </Tooltip>
                            )}
                            <MentionCountBadge item={item} />
                        </Box>
                        {isShared && <SharedBadge />}
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    content: {
        py: 1,
        px: 1,
    },
    flexGrow: {
        flexGrow: 1,
    },
    name: {
        overflowWrap: "anywhere",
        lineHeight: 1.2,
        fontSize: "1rem",
        fontWeight: 500,
        mb: 0.5,
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: 2,
        WebkitBoxOrient: "vertical",
    },
    marginRight: {
        mr: (theme) => theme.spacing(3),
    },
    mentions: {
        position: "absolute",
        right: 10,
        bottom: 10,
    },

    pinIcon: {
        p: 0.3,
        border: (theme) => `2px solid ${theme.palette.action.disabledBackground}`,
        borderRadius: "50%",
        background: (theme) => theme.palette.action.disabledBackground,
        color: (theme) => theme.palette.text.secondary,
    },
}

export const ListLayout = memo(ListLayoutComponent)
