import { Avatar, Box, Button, Card, SxProps, Theme, Typography } from "@mui/material"
import { FC, MouseEvent, useState } from "react"
import { ReferenceItem } from "../../../../editor"
import { MentionItemType } from "../../../../services/links/mentionsService"
import { Options } from "../../ItemPreviewPopper"
import { MentionText } from "./MentionText"
import { WebsiteSource } from "./WebsiteSource"

interface Props {
    mentions: ReferenceItem["mentions"]
    openItemById: (id: string, options?: Options) => void
}

export const Mentions: FC<Props> = ({ mentions, openItemById }) => {
    return (
        <Box pb={0.5}>
            {mentions.map((mention) => (
                <Box key={mention.id} px={1} py={0.5}>
                    <MentionItem mention={mention} openItemById={openItemById} />
                </Box>
            ))}
        </Box>
    )
}

interface MentionProps {
    mention: MentionItemType
    openItemById: (id: string, options?: Options) => void
}

export const MentionItem: FC<MentionProps> = ({ mention, openItemById }) => {
    const [isExpanded, setIsExpanded] = useState(false)

    const blocks = isExpanded ? mention.blocks : mention.blocks.slice(0, 2)
    const isShowMoreVisible = mention.blocks.length > 2 && !isExpanded

    return (
        <Card
            sx={styles.card}
            elevation={1}
            onClick={(e: MouseEvent) => {
                e.stopPropagation()
                openItemById(mention.id)
            }}
        >
            <Box display="flex" justifyContent="space-between" p={1}>
                <Box display="flex" flexDirection="column" gap={0.5}>
                    <Typography variant="h6" sx={styles.name}>
                        {mention.name}
                    </Typography>
                    <Typography variant="caption" component="p" sx={styles.description}>
                        {mention.description}
                    </Typography>
                    {mention.sourceUrl && <WebsiteSource url={mention.sourceUrl} />}
                </Box>
                {mention.image && <Avatar variant="square" src={mention.image} sx={styles.image} />}
            </Box>
            {Boolean(mention.blocks.length) && (
                <>
                    <Box mt={1}>
                        {blocks.map(({ textBlocks, elementId, isReaderMention }) => (
                            <MentionText
                                key={elementId}
                                textBlocks={textBlocks}
                                handleClick={(e: MouseEvent) => {
                                    e.stopPropagation()
                                    openItemById(mention.id, {
                                        state: { elementId, isReaderMention },
                                    })
                                }}
                            />
                        ))}
                    </Box>
                    {isShowMoreVisible && (
                        <Box display="flex" justifyContent="center">
                            <Button
                                color="inherit"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setIsExpanded(true)
                                }}
                                size="small"
                            >
                                Show more
                            </Button>
                        </Box>
                    )}
                </>
            )}
        </Card>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    card: {
        borderRadius: 0.5,
        cursor: "pointer",
        transition: "background 0.3s ease",
        ":hover": {
            background: (theme) => theme.palette.action.disabledBackground,
        },
    },
    description: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: 1,
        WebkitBoxOrient: "vertical",
    },
    name: {
        wordBreak: "break-word",
        fontSize: "1em",
        fontWeight: 500,
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: 2,
        WebkitBoxOrient: "vertical",
    },
    image: {
        ml: 0.5,
        height: 60,
        width: 60,
        borderRadius: 0.2,
        border: "1px solid transparent",
        bgcolor: (theme) => theme.palette.action.selected,
    },
}
