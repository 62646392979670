import { FC } from "react"
import { useConnectionDeleted } from "./hooks/useConnectionDeleted"
import { useFocusEditor } from "./hooks/useFocusEditor"

interface Props {
    focus: boolean
}

export const PlateConfig: FC<Props> = ({ focus }) => {
    useConnectionDeleted()
    useFocusEditor(focus)

    return null
}
