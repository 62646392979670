import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    SxProps,
    Theme,
} from "@mui/material"
import {
    FIREFOX_RECALL_EXTENSION_STORE_URL,
    isFirefox,
    LinkButton,
    RECALL_EXTENSION_STORE_URL,
} from "@recall/common"
import InstallBrowserExtensionImage from "assets/images/install-browser-extension.png"
import { InstallExtensionButton } from "components/shared/buttons/InstallExtensionButton"
import { FC, useState } from "react"
import { TitleContainer } from "./TitleContainer"

interface Props {
    handleSkip: () => void
}

export const InstallExtensionStep: FC<Props> = ({ handleSkip }) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false)

    const handleOpenDialog = () => {
        setIsDialogOpen(true)
    }

    const handleCloseDialog = () => {
        setIsDialogOpen(false)
    }

    const handleOpenExtensionPage = () => {
        handleCloseDialog()
        window.open(
            !isFirefox() ? RECALL_EXTENSION_STORE_URL : FIREFOX_RECALL_EXTENSION_STORE_URL,
            "_blank"
        )
    }

    return (
        <>
            <TitleContainer
                title={
                    <>
                        Elevate your Workflow: <br /> Install the Browser Extension
                    </>
                }
                subtitle="Summarize, chat, and unlock Augmented Browsing — all your knowledge, connected in real time."
            />
            <Box component="img" src={InstallBrowserExtensionImage} sx={styles.image} />
            <InstallExtensionButton onClick={handleOpenExtensionPage} fullWidth />
            <LinkButton sx={{ my: 2 }} size="small" variant="text" onClick={handleOpenDialog}>
                Skip
            </LinkButton>
            <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
                <DialogTitle>Are you sure you want to skip this step?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        To get the best experience with Recall, we recommend installing the browser
                        extension.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="error" onClick={handleSkip}>
                        Skip anyway
                    </Button>
                    <InstallExtensionButton onClick={handleOpenExtensionPage} />
                </DialogActions>
            </Dialog>
        </>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    image: {
        width: "100%",
        maxWidth: "400px",
        height: "auto",
        my: 3,
        display: "block",
    },
}
