import { ContentType, ItemModel, itemService } from "@recall/common"
import { useEffect, useState } from "react"

export const useContentType = (item: ItemModel) => {
    const [contentType, setContentType] = useState<ContentType | null>(null)

    useEffect(() => {
        if (!item) {
            setContentType(null)
            return
        }
        getContentType(item)
    }, [item])

    const getContentType = async (item: ItemModel) => {
        const contentType = await itemService.getContentType(item)
        setContentType(contentType)
    }

    return contentType
}
