import { Apple } from "@mui/icons-material"
import GoogleIcon from "@mui/icons-material/Google"
import {
    APPLE_AUTH_CLICKED,
    GOOGLE_AUTH_CLICKED,
    PrimaryButton,
    messagesService,
} from "@recall/common"
import { useAuth } from "hooks/auth/useAuth"
import { useIsNative } from "hooks/useIsNative"
import { usePlatform } from "hooks/usePlatform"
import { useQueryParameter } from "hooks/useQueryParameter"
import { FC, useState } from "react"
import { Box, Theme, SxProps } from "@mui/material"

interface Props {
    reauthenticateWithGoogle?: () => void
    reauthenticateWithApple?: () => void
}

const SignUpProvider: FC<Props> = ({ reauthenticateWithGoogle, reauthenticateWithApple }) => {
    const auth = useAuth()
    const { isPresent: isAndroid } = useQueryParameter("android")
    const [loading, setLoading] = useState<"apple" | "google" | null>(null)

    const isNative = useIsNative()
    const platform = usePlatform()

    const handleClick = async () => {
        setLoading("google")
        if (isNative) return messagesService.post(GOOGLE_AUTH_CLICKED, "", true)

        const user = await auth.signInWithGooglePopup()
        setLoading(null)

        if (!user) return
    }

    const handleSignInWithApple = async () => {
        setLoading("apple")
        auth.signInWithApple()
    }

    const handleClickApple = async () => {
        setLoading("apple")
        return messagesService.post(APPLE_AUTH_CLICKED, "", true)
    }

    return (
        <Box sx={styles.buttons}>
            <PrimaryButton
                fullWidth
                onClick={reauthenticateWithGoogle || handleClick}
                loading={loading === "google"}
                disabled={!!loading}
                startIcon={<GoogleIcon />}
                isSecondary
            >
                {reauthenticateWithGoogle ? "Reauthenticate" : "Continue"} with Google
            </PrimaryButton>
            {!isAndroid && ((isNative && platform === "ios") || !isNative) && (
                <PrimaryButton
                    fullWidth
                    onClick={
                        reauthenticateWithApple ||
                        (isNative ? handleClickApple : handleSignInWithApple)
                    }
                    loading={loading === "apple"}
                    disabled={!!loading}
                    startIcon={<Apple />}
                    isSecondary
                >
                    {reauthenticateWithApple ? "Reauthenticate" : "Continue"} with Apple
                </PrimaryButton>
            )}
        </Box>
    )
}

export default SignUpProvider

const styles: Record<string, SxProps<Theme>> = {
    buttons: {
        display: "flex",
        flexDirection: "column",
        gap: 1.5,
        width: "100%",
    },
}
