import { Box, Typography } from "@mui/material"
import {
    DefaultActionInput,
    defaultOptions,
    LanguageSelect,
    Settings,
    TranslateLanguageForm,
} from "@recall/common"
import { FC, useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { settingsService } from "services/settingsService"
import { RootState } from "storage/redux/rootReducer"
import { UPDATE_USER } from "storage/redux/user/actionTypes"

interface Props {
    defaultValues: Settings
}

export const SettingsForm: FC<Props> = ({ defaultValues }) => {
    const methods = useForm<Settings>({
        mode: "onChange",
        defaultValues,
    })

    const dispatch = useDispatch()
    const uid = useSelector((state: RootState) => state.user.uid)
    const { watch } = methods

    const language = watch("language")
    const searchLanguage = watch("searchLanguage")
    const defaultAction = watch("defaultAction")

    const updateSettings = async () => {
        if (!language || !searchLanguage || !defaultAction) return

        const settings = await settingsService.get(uid)

        if (
            settings.language !== language ||
            settings.searchLanguage !== searchLanguage ||
            settings.defaultAction !== defaultAction
        ) {
            dispatch({
                type: UPDATE_USER,
                payload: { settings: { language, searchLanguage, defaultAction } },
            })

            await settingsService.update(uid, {
                ...settings,
                language,
                searchLanguage,
                defaultAction,
            })
        }
    }

    useEffect(() => {
        updateSettings()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language, searchLanguage, defaultAction])

    return (
        <FormProvider {...methods}>
            <Box>
                <DefaultActionInput />
            </Box>
            <Box display="flex" flexDirection="column" mt={4}>
                <TranslateLanguageForm />
            </Box>
            <Box display="flex" flexDirection="column" mt={4}>
                <Typography fontWeight={500} variant="h5">
                    Search Language
                </Typography>
                <Typography variant="caption" color="text.secondary">
                    This language will be used when you search for new knowledge cards within
                    Recall.
                </Typography>
                <LanguageSelect name="searchLanguage" options={defaultOptions} />
            </Box>
        </FormProvider>
    )
}
