import { alpha, Box, SxProps, Theme } from "@mui/material"
import { ChatMessage } from "@recall/common"
import { FC, useEffect, useRef, useState } from "react"
import { useTimestampClickListener } from "../hooks/useTimestampClickListener"
import { Message } from "./Message"

interface Props {
    messages: ChatMessage[]
    isAnswering: boolean
    handleAddToNotebook: (question: ChatMessage, answer: ChatMessage) => void
    handleOpenNotebook: (elementId?: string) => void
    handleRegenerate: (message: ChatMessage) => void
}

export const MessagesList: FC<Props> = ({
    messages,
    isAnswering,
    handleAddToNotebook,
    handleOpenNotebook,
    handleRegenerate,
}) => {
    const messagesContainer = useRef<HTMLDivElement>(null)
    const [shouldAutoScroll, setShouldAutoScroll] = useState(true)
    const lastScrollTop = useRef(0)
    useTimestampClickListener(messagesContainer, messages)

    const handleScroll = () => {
        if (!messagesContainer.current) return

        const container = messagesContainer.current
        const isNearBottom =
            container.scrollHeight - container.scrollTop - container.clientHeight < 20

        const isAutoScrollDisabled = container.scrollTop < lastScrollTop.current

        lastScrollTop.current = container.scrollTop

        if (isAutoScrollDisabled && shouldAutoScroll) {
            setShouldAutoScroll(false)
        } else if (isNearBottom) {
            setShouldAutoScroll(true)
        }
    }

    useEffect(() => {
        if (messagesContainer.current) {
            const container = messagesContainer.current
            if (shouldAutoScroll) {
                container.scrollTo({
                    top: container.scrollHeight,
                    behavior: "smooth",
                })
            }
        }
    }, [messages, shouldAutoScroll])

    useEffect(() => {
        if (messagesContainer.current) {
            messagesContainer.current.scrollTo({
                top: messagesContainer.current.scrollHeight,
            })
        }

        const container = messagesContainer.current
        if (container) {
            container.addEventListener("scroll", handleScroll)
            return () => container.removeEventListener("scroll", handleScroll)
        }
    }, [])

    return (
        <Box ref={messagesContainer} sx={styles.container}>
            {messages.map((message, i) => (
                <Message
                    key={i}
                    message={message}
                    isAnswering={
                        i === messages.length - 1 && isAnswering && message.role === "assistant"
                    }
                    handleOpenNotebook={handleOpenNotebook}
                    handleAddToNotebook={() => handleAddToNotebook(messages[i - 1], message)}
                    handleRegenerate={handleRegenerate}
                />
            ))}
        </Box>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    container: {
        py: 1,
        pr: 1,
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflowY: "auto",
        rowGap: 1,
        "&::-webkit-scrollbar": {
            height: 0,
            width: "6px",
        },

        "&::-webkit-scrollbar-track": {
            backgroundColor: "transparent",
        },

        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "transparent",
        },

        "&:hover::-webkit-scrollbar-thumb": {
            background: (theme) => alpha(theme.palette.grey[500], 0.2),

            "&:hover": {
                background: (theme) => alpha(theme.palette.grey[500], 0.6),
            },
        },
    },
}
