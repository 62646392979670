export const LOGIN_EXTENSION_WITH_CUSTOM_TOKEN = "LOGIN_EXTENSION_WITH_CUSTOM_TOKEN"
export const LOGIN_EXTENSION_WITH_CREDENTIAL = "LOGIN_EXTENSION_WITH_CREDENTIAL"
export const LOGIN_EXTENSION_WITH_EMAIL_PASSWORD = "LOGIN_EXTENSION_WITH_EMAIL_PASSWORD"
export const LOGOUT_EXTENSION = "LOGOUT_EXTENSION"
export const IS_EXTENSION_LOGGED_IN = "IS_EXTENSION_LOGGED_IN"
export const GET_BOOKMARKS_TREE = "GET_BOOKMARKS_TREE"

export const GOOGLE_AUTH_CLICKED = "GOOGLE_AUTH_CLICKED"
export const APPLE_AUTH_CLICKED = "APPLE_AUTH_CLICKED"
export const AUTHENTICATE_WITH_CREDENTIALS = "AUTHENTICATE_WITH_CREDENTIALS"
export const AUTHENTICATE_WITH_APPLE_CREDENTIALS = "AUTHENTICATE_WITH_APPLE_CREDENTIALS"
export const REDIRECT_TO_HOME = "REDIRECT_TO_HOME"
export const REDIRECT = "REDIRECT"
export const COPY_TO_CLIPBOARD = "COPY_TO_CLIPBOARD"
export const SET_APP_TO_BE_NATIVE = "SET_APP_TO_BE_NATIVE"
export const OPEN_URL = "OPEN_URL"

export const RESET_USER_PREMIUM_STATE = "RESET_USER_PREMIUM_STATE"
export const CHECK_USER_PREMIUM_STATE = "CHECK_USER_PREMIUM_STATE"
export const OPEN_EXTENSION_SETTINGS = "OPEN_EXTENSION_SETTINGS"
