import { useDatabase } from "@nozbe/watermelondb/react"
import { ContentType, ItemModel, itemRepository, itemService, tutorials } from "@recall/common"
import { HOME_PATH } from "constants/routes"
import { useOpenItem } from "hooks/items/useOpenItem"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router"
import { TAB_URL_SEARCH } from "storage/redux/app/constants"
import { RootState } from "storage/redux/rootReducer"
import { SET_ACTIVE_TUTORIAL } from "storage/redux/user/actionTypes"
import { useOpenOnboarding } from "../../Onboarding/hooks/useOpenOnboarding"

const manuallyActivatedTutorials: (tutorials | "none")[] = [tutorials.CREATE_LINK]
const dedicatedPagesTutorials: (tutorials | "none")[] = [tutorials.EXTENSION]

export const useActivateTutorial = () => {
    const db = useDatabase()
    const history = useHistory()
    const dispatch = useDispatch()
    const { getItemPath } = useOpenItem()
    const { id } = useParams<{ id: string }>()
    const [openedItem, setOpenedItem] = useState<{
        item: ItemModel | null
        links: number
        contentType: ContentType | null
    }>({
        item: null,
        links: 0,
        contentType: null,
    })

    const isAddModalOpen = useSelector((state: RootState) => state.app.addModalState.isOpen)
    const addModelTab = useSelector((state: RootState) => state.app.addModalState.tab)

    const completedTutorials = useSelector((state: RootState) => state.user.tutorial.completed)

    const activeTutorial = useSelector((state: RootState) => state.user.tutorial.active)
    const { isOnboardingOpen } = useOpenOnboarding()

    const getOpenedItem = async () => {
        if (!id) {
            setOpenedItem({ item: null, links: 0, contentType: null })
            return
        }

        const item = await itemRepository.get(db, id)
        if (!item) {
            setOpenedItem({ item: null, links: 0, contentType: null })
            return
        }

        const contentType = await itemService.getContentType(item)
        const links = await item.links.fetchCount()
        setOpenedItem({ item, links, contentType })
    }

    useEffect(() => {
        getOpenedItem()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const getTutorialActivationState = () => {
        const items =
            !completedTutorials[tutorials.ITEMS_INTRO] &&
            history.location.pathname === HOME_PATH &&
            !isOnboardingOpen

        const itemPath = getItemPath(id)

        const expand =
            !completedTutorials[tutorials.EXPAND_INTRO] &&
            history.location.pathname === itemPath &&
            openedItem?.item &&
            openedItem?.item?.isSaved &&
            !openedItem?.item?.isExpanded

        const item =
            !completedTutorials[tutorials.ITEM_INTRO] &&
            history.location.pathname === itemPath &&
            openedItem.links > 0 &&
            openedItem.contentType === "page"

        const add =
            !completedTutorials[tutorials.ADD_CARD] &&
            history.location.pathname === itemPath &&
            openedItem?.item &&
            !openedItem?.item?.isSaved &&
            !openedItem?.item?.isReference

        const summaryLength =
            !completedTutorials[tutorials.SUMMARY_LENGTH] &&
            addModelTab === TAB_URL_SEARCH &&
            isAddModalOpen

        return { items, item, expand, add, summaryLength }
    }

    const activateTutorial = async () => {
        const shouldActivate = getTutorialActivationState()

        if (
            !shouldActivate.item &&
            !shouldActivate.items &&
            !shouldActivate.expand &&
            !shouldActivate.add &&
            !shouldActivate.summaryLength &&
            !manuallyActivatedTutorials.includes(activeTutorial)
        ) {
            return dispatch({ type: SET_ACTIVE_TUTORIAL, payload: "none" })
        }

        if (activeTutorial !== "none" && !dedicatedPagesTutorials.includes(activeTutorial)) return

        if (shouldActivate.item) {
            return dispatch({ type: SET_ACTIVE_TUTORIAL, payload: tutorials.ITEM_INTRO })
        }
        if (shouldActivate.summaryLength) {
            return dispatch({ type: SET_ACTIVE_TUTORIAL, payload: tutorials.SUMMARY_LENGTH })
        }
        if (shouldActivate.items) {
            return dispatch({ type: SET_ACTIVE_TUTORIAL, payload: tutorials.ITEMS_INTRO })
        }
        if (shouldActivate.add) {
            return dispatch({ type: SET_ACTIVE_TUTORIAL, payload: tutorials.ADD_CARD })
        }
        if (shouldActivate.expand) {
            return dispatch({ type: SET_ACTIVE_TUTORIAL, payload: tutorials.EXPAND_INTRO })
        }

        if (dedicatedPagesTutorials.includes(activeTutorial)) {
            return dispatch({ type: SET_ACTIVE_TUTORIAL, payload: "none" })
        }
    }

    useEffect(() => {
        activateTutorial()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        dispatch,
        completedTutorials,
        history.location.pathname,
        openedItem,
        isOnboardingOpen,
        isAddModalOpen,
        addModelTab,
    ])
}
