import { Delete } from "@mui/icons-material"
import UploadFileIcon from "@mui/icons-material/UploadFile"
import { Box, Button, FormHelperText, Input, SxProps, Theme, Typography } from "@mui/material"
import { posthogService, useIsMobile } from "@recall/common"
import UploadCloudDarkIcon from "assets/images/upload_cloud_dark.png"
import UploadCloudLightIcon from "assets/images/upload_cloud_light.png"
import { ReactComponent as PdfIcon } from "assets/svg/pdf_full_outlined.svg"
import { AddButtonFooter } from "components/layouts/components/Header/AddButton/AddButtonFooter"
import { IN_APP_PDF_TOO_BIG } from "constants/events"
import { ITEM_PATH } from "constants/routes"
import { useIsDarkMode } from "hooks/useThemeMode"
import { ChangeEvent, DragEvent, FC, useRef, useState } from "react"
import { useHistory } from "react-router"
import { toast } from "react-toastify"
import { v4 as uuid } from "uuid"
interface Props {
    onClose: () => void
}

export const PdfUploadTab: FC<Props> = ({ onClose }) => {
    const history = useHistory()
    const [dragActive, setDragActive] = useState(false)
    const inputRef = useRef<HTMLInputElement | null>(null)
    const [file, setFile] = useState<File | null>(null)
    const [message, setMessage] = useState("")
    const isMobile = useIsMobile()
    const isDarkMode = useIsDarkMode()

    const handleDrag = (e: DragEvent) => {
        e.preventDefault()
        e.stopPropagation()
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true)
        } else if (e.type === "dragleave") {
            setDragActive(false)
        }
    }
    const calcFileSize = (file: File) => {
        const fileSizeInMB = file.size / (1024 * 1024)
        return fileSizeInMB
    }

    const handleFile = (file: File) => {
        const fileSizeInMB = calcFileSize(file)
        if (fileSizeInMB > 32) {
            toast.error(`Max file size is 32MB`)
            posthogService.captureEvent(IN_APP_PDF_TOO_BIG)
            return
        }
        if (file && file.type === "application/pdf") {
            setFile(file)
            setMessage("")
        } else {
            setMessage("Please upload pdf file")
        }
    }

    const handleDrop = (e: DragEvent) => {
        e.preventDefault()
        e.stopPropagation()
        setDragActive(false)
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            handleFile(e.dataTransfer.files[0])
        }
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        if (e.target.files && e.target.files[0]) {
            handleFile(e.target.files[0])
            e.target.value = ""
        }
    }

    const onButtonClick = () => {
        inputRef.current?.click()
    }

    const resetFile = () => {
        setFile(null)
    }

    const summarizeFiles = (e) => {
        e.preventDefault()

        if (file) {
            onClose()
            history.push({
                pathname: ITEM_PATH.replace(":id", uuid()),
                state: { sourcePdf: file },
            })
        }
    }

    return (
        <>
            <Input
                inputRef={inputRef}
                type="file"
                sx={styles.input}
                onChange={handleChange}
                inputProps={{ accept: "application/pdf" }}
            />
            <Box
                sx={{
                    ...styles.uploadBox,
                    ...(dragActive && {
                        backgroundColor: (theme) => theme.palette.background.paper,
                        border: (theme) => `1px solid ${theme.palette.divider}`,
                    }),
                }}
                onDragOver={handleDrag}
                onDragLeave={handleDrag}
                onDrop={handleDrop}
            >
                {file ? (
                    <>
                        <Typography sx={styles.uploadHeading} mb={2} mt={0}>
                            Uploaded File
                        </Typography>
                        <Box sx={styles.uploadedFileDetails}>
                            <Box>
                                <PdfIcon />
                            </Box>
                            <Box sx={styles.uploadedFileInfo}>
                                <Typography
                                    variant="body2"
                                    fontWeight={500}
                                    lineHeight={"normal"}
                                    sx={styles.fileName}
                                >
                                    {file.name}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    lineHeight={"normal"}
                                    color={(theme) => theme.palette.text.secondary}
                                    pb={1}
                                >{`${calcFileSize(file).toFixed(2)} MB`}</Typography>
                            </Box>
                        </Box>

                        <Button
                            startIcon={<Delete />}
                            color="inherit"
                            variant="outlined"
                            sx={{ opacity: "0.7" }}
                            onClick={resetFile}
                        >
                            Remove
                        </Button>
                    </>
                ) : (
                    <>
                        <Box
                            component="img"
                            height={56}
                            width={56}
                            src={isDarkMode ? UploadCloudDarkIcon : UploadCloudLightIcon}
                        />
                        {isMobile ? (
                            <Typography sx={styles.uploadHeading}>Upload a PDF</Typography>
                        ) : (
                            <>
                                <Typography sx={styles.uploadHeading}>PDF file upload</Typography>
                                <Typography
                                    variant="body2"
                                    py={1}
                                    fontWeight={400}
                                    lineHeight="normal"
                                    color="text.secondary"
                                >
                                    Drag and drop a PDF file here
                                </Typography>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    mt={1}
                                >
                                    <Box sx={styles.divider} />
                                    <Box sx={styles.placeholderText} px={1} color="text.secondary">
                                        or
                                    </Box>
                                    <Box sx={styles.divider} />
                                </Box>
                            </>
                        )}
                        <Button
                            startIcon={<UploadFileIcon />}
                            variant="contained"
                            sx={styles.uploadButton}
                            onClick={onButtonClick}
                        >
                            Choose file
                        </Button>
                    </>
                )}

                {message && <FormHelperText error>{message}</FormHelperText>}
            </Box>
            <AddButtonFooter
                showActionPicker
                onClick={summarizeFiles}
                onClose={onClose}
                disabled={!file}
            />
        </>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    input: {
        display: "none",
    },
    divider: {
        width: 90,
        height: "1px",
        backgroundColor: (theme) => theme.palette.divider,
    },
    uploadBox: {
        height: { xs: "calc(100vh - 330px)", sm: "336px" },
        border: (theme) => `1px solid ${theme.palette.divider}`,
        borderRadius: 1.5,
        pt: 4.5,
        pb: 5.5,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    uploadHeading: {
        mt: 2.5,
        fontSize: { xs: 17, sm: 22 },
        fontWeight: "bold",
        textAlign: "center",
        color: (theme) => theme.palette.text.primary,
    },
    uploadButton: {
        py: 1,
        px: 2,
        mt: 2.5,
        fontSize: "1rem",
        textTransform: "none",
        borderRadius: 1,
    },
    dragOverlay: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        borderRadius: 2,
        backgroundColor: (theme) => theme.palette.action.selected,
    },
    uploadedFileDetails: {
        px: 2,
        maxWidth: { lg: "60%" },
        display: "flex",
        alignItems: "flex-start",
        mb: 3,
    },
    uploadedFileInfo: {
        pl: 2,
        textAlign: "left",
        flex: 1,
    },
    fileName: {
        mb: 0.5,
        color: (theme) => theme.palette.text.primary,
    },
}
