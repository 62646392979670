import { Box, SxProps, Theme } from "@mui/material"
import { PrimaryButton } from "@recall/common"
import { FC } from "react"
import { CardStep } from "./CardStep"
import { ExampleCard } from "./ExampleCard"
import { TitleContainer } from "./TitleContainer"
import { Interest } from "./types"

interface Props {
    handleSkip: () => void
    selectedInterest: Interest
}

export const ExperienceBrowserStep: FC<Props> = ({ handleSkip, selectedInterest }) => {
    return (
        <>
            <TitleContainer
                title={
                    <>
                        You are All Set! <br />
                        Try Recall in your Browser Now.
                    </>
                }
                subtitle="Transform the way you engage with your content forever"
            />
            <Box sx={styles.cardContainer}>
                <CardStep
                    title="Summarize, Save & Connect"
                    subtitle="Open the page below and click the extension. The page below is specifically chosen to connect with the content we pre-loaded to your knowledge base. "
                    component={<ExampleCard {...selectedInterest.details} />}
                />
            </Box>

            <PrimaryButton sx={styles.button} fullWidth onClick={handleSkip}>
                Take me to my Recall Knowledge Base
            </PrimaryButton>
        </>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    loadingContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        gap: 2,
    },
    cardContainer: {
        display: "flex",
        alignItems: "stretch",
        gap: 2,
        my: 4,
    },

    image: {
        width: "auto",
        height: "auto",
        objectFit: "contain",
        maxHeight: "200px",
        maxWidth: "100%",
        marginLeft: "auto",
        marginRight: 0,
        display: "block",
        float: "right",
    },
    centeredImage: {
        width: "auto",
        height: "auto",
        objectFit: "contain",
        maxHeight: "200px",
        maxWidth: "100%",
        marginLeft: "auto",
        marginRight: "auto",
        display: "block",
    },
}
