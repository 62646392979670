import { collection, deleteDoc, doc, getDoc, setDoc, updateDoc } from "firebase/firestore"
import { FIRESTORE_COLLECTIONS } from "../../constants/collections"
import { AIAction } from "../../repositories/userRepository"
import { firebase } from "../firebaseService/firebaseServices"

export interface ChatMessage {
    id: string
    role: "user" | "assistant"
    content: string
    action?: AIAction
    error?: boolean
    notebookElementId?: string
}

const update = async (uid: string, messages: ChatMessage[], chatId: string) => {
    const chatRef = doc(
        collection(
            firebase.firestore,
            FIRESTORE_COLLECTIONS.USERS,
            uid,
            FIRESTORE_COLLECTIONS.CHATS
        ),
        chatId
    )
    const chatDoc = await getDoc(chatRef)

    if (!chatDoc.exists()) {
        const newChat = {
            messages,
            createdAt: Date.now(),
        }
        await setDoc(chatRef, newChat)
        return
    }

    await updateDoc(chatRef, { messages })
}

const get = async (uid: string, itemId: string): Promise<ChatMessage[]> => {
    const chatRef = doc(
        collection(
            firebase.firestore,
            FIRESTORE_COLLECTIONS.USERS,
            uid,
            FIRESTORE_COLLECTIONS.CHATS
        ),
        itemId
    )

    const chatDoc = await getDoc(chatRef)

    return chatDoc.data()?.messages || []
}

const remove = async (uid: string, itemId: string) => {
    const chatRef = doc(
        collection(
            firebase.firestore,
            FIRESTORE_COLLECTIONS.USERS,
            uid,
            FIRESTORE_COLLECTIONS.CHATS
        ),
        itemId
    )
    await deleteDoc(chatRef)
}

export const chatService = {
    remove,
    update,
    get,
}
