import { SvgIcon, SvgIconProps } from "@mui/material"

export const DownloadIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon sx={{ ...props.sx, color: "action.active" }} {...props}>
            <svg
                width="16"
                height="20"
                viewBox="0 0 16 20"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M8.90894 1.55107L8.90797 11.3069L11.7068 8.52714C11.9137 8.32156 12.1945 8.20607 12.4872 8.20607C12.7799 8.20607 13.0606 8.32156 13.2676 8.52714C13.4746 8.73271 13.5909 9.01153 13.5909 9.30225C13.5909 9.59298 13.4746 9.8718 13.2676 10.0774L8.58507 14.7281C8.37809 14.9337 8.09736 15.0491 7.80465 15.0491C7.51193 15.0491 7.23121 14.9337 7.02423 14.7281L2.3417 10.0774C2.13472 9.8718 2.01844 9.59298 2.01844 9.30225C2.01844 9.01153 2.13472 8.73271 2.3417 8.52714C2.54868 8.32156 2.8294 8.20607 3.12212 8.20607C3.41483 8.20607 3.69556 8.32156 3.90254 8.52714L6.70133 11.3069L6.70035 1.55107C6.70035 1.26019 6.8167 0.981213 7.02379 0.775524C7.23089 0.569836 7.51177 0.454282 7.80465 0.454282C8.09752 0.454282 8.37841 0.569835 8.5855 0.775524C8.7926 0.981212 8.90894 1.26019 8.90894 1.55107Z" />
                <path d="M8.90894 1.55107L8.90797 11.3069L11.7068 8.52714C11.9137 8.32156 12.1945 8.20607 12.4872 8.20607C12.7799 8.20607 13.0606 8.32156 13.2676 8.52714C13.4746 8.73271 13.5909 9.01153 13.5909 9.30225C13.5909 9.59298 13.4746 9.8718 13.2676 10.0774L8.58507 14.7281C8.37809 14.9337 8.09736 15.0491 7.80465 15.0491C7.51193 15.0491 7.23121 14.9337 7.02423 14.7281L2.3417 10.0774C2.13472 9.8718 2.01844 9.59298 2.01844 9.30225C2.01844 9.01153 2.13472 8.73271 2.3417 8.52714C2.54868 8.32156 2.8294 8.20607 3.12212 8.20607C3.41483 8.20607 3.69556 8.32156 3.90254 8.52714L6.70133 11.3069L6.70035 1.55107C6.70035 1.26019 6.8167 0.981213 7.02379 0.775524C7.23089 0.569836 7.51177 0.454282 7.80465 0.454282C8.09752 0.454282 8.37841 0.569835 8.5855 0.775524C8.7926 0.981212 8.90894 1.26019 8.90894 1.55107Z" />
                <path d="M14.3515 19.7612C13.7558 19.7612 1.84098 19.7613 1.24517 19.7612C0.649364 19.7612 0.649428 18.3565 1.24517 18.3569C1.84092 18.3573 13.7558 18.3563 14.3515 18.3566C14.9473 18.3569 14.9473 19.7612 14.3515 19.7612Z" />
            </svg>
        </SvgIcon>
    )
}
