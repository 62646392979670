import { createContext, FC, PropsWithChildren, useContext, useMemo, useState } from "react"

export type TabType = "chat" | "notebook" | "connections" | "graph" | "quiz" | "reader"

interface TabState {
    tab: TabType
}

interface TabActions {
    setTab: (tab: TabType) => void
}

const TabStateContext = createContext<TabState | undefined>(undefined)
const TabActionsContext = createContext<TabActions | undefined>(undefined)

export const TabProvider: FC<PropsWithChildren> = ({ children }) => {
    const [tab, setTab] = useState<TabType>(null)

    const actions = useMemo(
        () => ({
            setTab,
        }),
        [setTab]
    )

    const state = useMemo(
        () => ({
            tab,
        }),
        [tab]
    )

    return (
        <TabStateContext.Provider value={state}>
            <TabActionsContext.Provider value={actions}>{children}</TabActionsContext.Provider>
        </TabStateContext.Provider>
    )
}

export const useTabState = () => {
    const context = useContext(TabStateContext)
    if (!context) {
        throw new Error("useTabState must be used within a TabProvider")
    }
    return context
}

export const useTabActions = () => {
    const context = useContext(TabActionsContext)
    if (!context) {
        throw new Error("useTabActions must be used within a TabProvider")
    }
    return context
}
