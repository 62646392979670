import { Box, Typography } from "@mui/material"
import { AIAction } from "@recall/common"
import { FC } from "react"
import { Action } from "./Action"

export interface ActionWithNotebookElementId extends AIAction {
    notebookElementId?: string
}

interface Props {
    actions: ActionWithNotebookElementId[]
    isAnswering: boolean
    processAction: (action: AIAction) => void
    handleOpenNotebook: (notebookElementId: string) => void
}

export const Actions: FC<Props> = ({ actions, isAnswering, processAction, handleOpenNotebook }) => {
    const handleActionClick = (action: ActionWithNotebookElementId) => {
        if (action.notebookElementId) {
            handleOpenNotebook(action.notebookElementId)
        } else {
            processAction(action)
        }
    }

    return (
        <Box display="flex" gap={0.5} my={1.5}>
            {actions.map((action) => (
                <Action
                    key={action.id}
                    onClick={() => handleActionClick(action)}
                    label={action.name}
                    id={action.id}
                    disabled={isAnswering}
                />
            ))}
        </Box>
    )
}
