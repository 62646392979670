import { Box, Typography } from "@mui/material"
import { Controller, useFormContext } from "react-hook-form"
import {
    conciseSummaryAction,
    detailedSummaryAction,
    openReaderAction,
} from "../../../repositories/userRepository"
import { Action } from "../../item/chat/Action"

export const DefaultActionInput = () => {
    const { control } = useFormContext()

    return (
        <Box>
            <Typography fontWeight={500} variant="h5">
                Default Action
            </Typography>
            <Typography variant="caption" color="text.secondary">
                Choose the default action for the content you add to Recall.
            </Typography>
            <Controller
                control={control}
                name="defaultAction"
                rules={{ required: "Language is required" }}
                render={({ field: { onChange, value } }) => {
                    return (
                        <Box display="flex" gap={1} mt={2}>
                            <Action
                                id={openReaderAction.id}
                                label={openReaderAction.name}
                                sx={{
                                    backgroundColor:
                                        value.id === openReaderAction.id
                                            ? "primary.main"
                                            : "background.default",
                                }}
                                onClick={() => onChange(openReaderAction)}
                            />
                            <Action
                                id={conciseSummaryAction.id}
                                label={conciseSummaryAction.name}
                                sx={{
                                    backgroundColor:
                                        value.id === conciseSummaryAction.id
                                            ? "primary.main"
                                            : "background.default",
                                }}
                                onClick={() => onChange(conciseSummaryAction)}
                            />
                            <Action
                                id={detailedSummaryAction.id}
                                label={detailedSummaryAction.name}
                                sx={{
                                    backgroundColor:
                                        value.id === detailedSummaryAction.id
                                            ? "primary.main"
                                            : "background.default",
                                }}
                                onClick={() => onChange(detailedSummaryAction)}
                            />
                        </Box>
                    )
                }}
            />
        </Box>
    )
}
