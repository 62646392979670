import { Database } from "@nozbe/watermelondb"
import { doc, getDoc } from "firebase/firestore"
import { map, orderBy } from "lodash"
import { v4 as uuid } from "uuid"
import { convertMarkdownToEditorBlocks } from "../../../api/types/convertDTO"
import { ItemApi, SourceApi } from "../../../api/types/ItemApi"
import { LinkApi } from "../../../api/types/LinkApi"
import { TypeApi } from "../../../api/types/TypeApi"
import { FIRESTORE_COLLECTIONS, ROOT_TYPE_NAME, WEBSITE } from "../../../constants"
import { deserializeMd } from "../../../editor/parsers/markdownToSlate"
import { ELEMENT_AI_ACTION } from "../../../editor/plugins/ai-action"
import { createPlatePlugins } from "../../../editor/plugins/createPlatePlugins"
import { ELEMENT_CUSTOM_EDITOR_BLOCK } from "../../../editor/plugins/editor-block"
import { reviewsScheduleRepository } from "../../../repositories/reviewsScheduleRepository"
import { sharingRepository } from "../../../repositories/sharingRepository"
import { chatService, Chunk, chunksService, fileService, firebase } from "../../../services"
import { ImageBlockData } from "../../../services/editorData"
import { mentionsService } from "../../../services/links/mentionsService"
import { getMatchedReferences, getTextReferences } from "../../../utils"
import { handleCreate } from "../helpers"
import { ASSET_TYPES } from "../models"
import { ConnectionModel } from "../models/ConnectionModel"
import { ItemModel } from "../models/ItemModel"
import { TagModel } from "../models/TagModel"
import { assetRepository } from "../repository/assetRepository"
import { connectionRepository } from "../repository/connectionRepository"
import { editorBlockRepository } from "../repository/editorBlockRepository"
import { editorOrderRepository } from "../repository/editorOrderRepository"
import { itemRepository } from "../repository/itemRepository"
import { mentionsRepository } from "../repository/mentionsRepository"
import { ROOT_TAG_ID, tagRepository } from "../repository/tagRepository"

const addItemTagsByType = async (
    db: Database,
    type: TypeApi | null | undefined,
    item: ItemModel
) => {
    return await db.write(async (action) => {
        if (!type || type.name === ROOT_TYPE_NAME) return

        const genealogy = type?.genealogy || []
        let parentId = ROOT_TAG_ID
        for (const { display } of genealogy.reverse()) {
            if (display === ROOT_TYPE_NAME) continue

            const existingTag = await tagRepository.getTagByNameAndParentId(db, display, parentId)

            if (existingTag) {
                parentId = existingTag.id
            } else {
                // eslint-disable-next-line no-loop-func
                const tag = await action.callWriter(() =>
                    tagRepository.create({ db, name: display, parentId, isSaved: item.isSaved })
                )
                if (!tag) continue

                parentId = tag.id
            }
        }

        const tag: TagModel | null = await action.callWriter(() =>
            tagRepository.create({ db, name: type.display, item, parentId })
        )

        return tag
    })
}

const getEditorBlocks = (markdown: string, linksConnections: LinkedItemWithConnection[]) => {
    const markdownWithLinks = insertMarkdownLinks(markdown, linksConnections)
    const editorBlocks = convertMarkdownToEditorBlocks(markdownWithLinks)
    return editorBlocks
}

const replaceSpecialCharacters = (markdown: string) => {
    return markdown.replaceAll(`\\`, "\\\\")
}

const insertMarkdownLinks = (markdown: string, links: LinkedItemWithConnection[]) => {
    const placeholders: Record<string, string> = {}
    const orderedLinks = orderBy(links, ({ link }) => link.name.length, "desc")
    let markdownLines = markdown.split("\n")

    for (const { link, connection } of orderedLinks) {
        const mention_texts = link.aliases || []
        const textReferences = getTextReferences(mention_texts, link.name)
        const matchedReferences = getMatchedReferences(markdownLines, textReferences, {
            linesBetweenCount: 0,
            isReferenceAdded: false,
        })

        for (const matchedReference of matchedReferences) {
            placeholders[`__${matchedReference}__`] = `[${matchedReference}](${connection.id})`
        }
    }

    let updatedMarkdown = markdownLines.join("\n")

    for (const [placeholder, replacement] of Object.entries(placeholders)) {
        updatedMarkdown = updatedMarkdown.split(placeholder).join(replacement)
    }

    return replaceSpecialCharacters(updatedMarkdown)
}

export interface LinkedItemWithConnection {
    link: ItemModel
    connection: ConnectionModel
}
const findLinkedItem = async (db: Database, item: ItemApi) => {
    if (item.sources.length) {
        const linkedItem = await itemRepository.getBySources(db, item.sources)
        if (linkedItem) return linkedItem
    } else {
        const linkedItem = await itemRepository.getItemByAlias(db, item.name, false)
        if (linkedItem) return linkedItem
    }

    return null
}

interface SaveItemOptions {
    id?: string
    isExpanded: boolean
    isSaved: boolean
}

const generateEditorBlocks = (chunk: Chunk) => {
    const plugins = createPlatePlugins({})

    const processedTree = deserializeMd(chunk.markdown, plugins)

    return { type: ELEMENT_CUSTOM_EDITOR_BLOCK, children: processedTree, id: chunk.id }
}

const insertMarkdownLinksIntoChunks = (links: LinkedItemWithConnection[], chunks: any[]) => {
    const placeholders: Record<string, string> = {}
    const orderedLinks = orderBy(links, ({ link }) => link.name.length, "desc")

    const markdownLinesByChunkId = chunks.reduce((acc, chunk) => {
        acc[chunk.id] = chunk.markdown.split("\n")
        return acc
    }, {})

    for (const { link, connection } of orderedLinks) {
        const linkFrequency = {
            linesBetweenCount: 0,
            isReferenceAdded: false,
        }

        for (const chunk of chunks) {
            let markdownLines = markdownLinesByChunkId[chunk.id]

            const mention_texts = link.aliases || []
            const textReferences = getTextReferences(mention_texts, link.name)
            const matchedReferences = getMatchedReferences(
                markdownLines,
                textReferences,
                linkFrequency
            )

            for (const matchedReference of matchedReferences) {
                placeholders[`__${matchedReference}__`] = `[${matchedReference}](${connection.id})`
            }

            markdownLinesByChunkId[chunk.id] = markdownLines
        }
    }

    for (const chunk of chunks) {
        const markdownLines = markdownLinesByChunkId[chunk.id]
        let updatedMarkdown = markdownLines.join("\n")

        for (const [placeholder, replacement] of Object.entries(placeholders)) {
            updatedMarkdown = updatedMarkdown.split(placeholder).join(replacement)
        }

        const markdown = replaceSpecialCharacters(updatedMarkdown)
        chunk.markdown = markdown
    }

    return chunks
}

const createChunksMentions = async (
    db: Database,
    chunks: Chunk[],
    linksConnections: LinkedItemWithConnection[],
    itemModel: ItemModel
) => {
    return await db.write<Chunk[]>(async (writer) => {
        const updatedChunks = insertMarkdownLinksIntoChunks(linksConnections, chunks)

        for (const chunk of updatedChunks) {
            const editorBlock = generateEditorBlocks(chunk)

            if (!editorBlock) continue

            for (const connection of linksConnections) {
                const mentionedBlocks = mentionsService.getMentionedEditorBlocks(
                    [editorBlock],
                    connection.connection.id
                )
                if (mentionedBlocks.length === 0) continue

                await writer.callWriter(() =>
                    mentionsRepository.create({
                        db,
                        mention: mentionedBlocks[0],
                        itemModel,
                        connection: connection.connection,
                        editorBlockId: editorBlock.id,
                    })
                )
            }
        }

        return updatedChunks
    })
}

const saveReaderConnections = async (
    db: Database,
    itemModel: ItemModel,
    links: LinkApi[],
    chunks: Chunk[]
) => {
    return await db.write(async (writer) => {
        if (!links.length) return chunks

        const linksConnections = await writer.callWriter(() => createLinks(db, itemModel, links))

        return await writer.callWriter(() =>
            createChunksMentions(db, chunks, linksConnections, itemModel)
        )
    })
}

interface CreateBaseItemProps {
    id?: string
    db: Database
    url: string
    name: string
    description: string
    image: string
    isReadable: boolean
    isSaved?: boolean
    isLoading?: boolean
}

const createBaseItem = async ({
    db,
    id = uuid(),
    url,
    name,
    description,
    image,
    isReadable,
    isSaved = false,
    isLoading = false,
}: CreateBaseItemProps) => {
    return await db.write<ItemModel>(async (writer) => {
        const item = await writer.callWriter(() =>
            itemRepository.create(
                db,
                {
                    id,
                    name,
                    isExpanded: true,
                    isReference: false,
                    description,
                    image,
                    aliases: [],
                    isReadable,
                    isLoading,
                },
                isSaved
            )
        )

        if (image) {
            await writer.callWriter(() =>
                assetRepository.createAsset(db, {
                    item,
                    url: image,
                    type: ASSET_TYPES.IMAGE_URL,
                    size: 0,
                })
            )
        }

        if (url)
            await writer.callWriter(() =>
                item.addSources([{ id: uuid(), identifier: url, name: WEBSITE }])
            )

        return item
    })
}

export interface SharedItem {
    id?: string
    name: string
    tags: string[]
    chunks: Chunk[]
    markdown: string
    aliases: string[]
    image: string
    language: string
    sources: SourceApi[]
    isExpanded: string
    links: Record<string, SharedItem>
    assets: { url: string; type: string; size: number }[]
    isReadable: boolean
}

const createSharedItem = async (db: Database, data: SharedItem, id: string, uid: string) => {
    const item = await itemRepository.create(
        db,
        {
            id,
            name: data.name,
            isExpanded: true,
            isReference: false,
            description: data.markdown,
            image: "",
            aliases: data.aliases,
            isReadable: data.isReadable,
        },
        false
    )

    if (data.image) {
        try {
            const { url, size } = await fileService.copyFile(
                data.image,
                `user/${uid}/files/${uuid()}.png`
            )
            await item.updateImage(url)
            await assetRepository.createAsset(db, {
                item,
                url,
                type: ASSET_TYPES.IMAGE,
                size,
            })
        } catch (e) {
            await item.updateImage(data.image)
        }
    }

    if (data.sources.length)
        await item.addSources(data.sources.map((source) => ({ ...source, id: uuid() })))

    if (data.tags.length) {
        for (const tag of data.tags) {
            await handleCreate(db, item.id, tag)
        }
    }

    const pdfAsset = data.assets.find(({ type }) => type === "pdf")

    if (pdfAsset) {
        const { url } = await fileService.copyFile(pdfAsset.url, `user/${uid}/files/${uuid()}.pdf`)
        await assetRepository.createAsset(db, {
            item,
            url,
            type: ASSET_TYPES.PDF,
            size: pdfAsset.size,
        })
    }

    const connectionsMap = await saveSharedLinks({
        db,
        itemModel: item,
        isSaved: item.isSaved,
        links: data.links,
    })

    const sharedConnectionIds = Object.keys(connectionsMap)
    let markdown = data.markdown
    let chunks = data.chunks

    for (const connection of sharedConnectionIds) {
        const matchingConnection = connectionsMap[connection]

        if (matchingConnection) {
            markdown = markdown.replace(connection, matchingConnection.id)
            chunks = chunks.map((chunk) => ({
                ...chunk,
                markdown: chunk.markdown.replace(connection, matchingConnection.id),
            }))
        }
    }

    markdown = await replaceImageUrls(db, data.markdown, uid, item)

    for (const chunk of chunks) {
        chunk.markdown = await replaceImageUrls(db, chunk.markdown, uid, item)
    }

    const connections = Object.values(connectionsMap)
    for (const chunk of chunks) {
        const editorBlock = generateEditorBlocks(chunk)

        if (!editorBlock) continue

        for (const connection of connections) {
            const mentionedBlocks = mentionsService.getMentionedEditorBlocks(
                [editorBlock],
                connection.id
            )
            if (mentionedBlocks.length === 0) continue

            await mentionsRepository.create({
                db,
                mention: mentionedBlocks[0],
                itemModel: item,
                connection: connection,
                editorBlockId: editorBlock.id,
            })
        }
    }

    const editorBlocks = convertMarkdownToEditorBlocks(markdown)
    for (let editorBlock of editorBlocks) {
        await editorBlockRepository.upsert(db, item.id, editorBlock, false)
    }

    const editorOrder = map(editorBlocks, "id") as string[]
    await editorOrderRepository.upsert(db, item.id, editorOrder, false)
    await item.updateUpdatedAt()

    if (uid && chunks.length) await chunksService.saveSharedChunks(uid, item.id, chunks)

    return { item, chunks, markdown }
}

const replaceImageUrls = async (db: Database, markdown: string, uid: string, item: ItemModel) => {
    const imageRegex = /!\[.*?\]\((.*?)\)/g
    let markdownImageMatches = [...markdown.matchAll(imageRegex)]
    for (const match of markdownImageMatches) {
        const oldUrl = match[1]
        try {
            const { url, size } = await fileService.copyFile(
                oldUrl,
                `user/${uid}/files/${uuid()}.png`
            )
            markdown = markdown.replace(oldUrl, url)
            await assetRepository.createAsset(db, {
                item,
                url,
                type: ASSET_TYPES.IMAGE,
                size,
            })
        } catch (e) {
            console.error(e)
        }
    }
    return markdown
}

const saveSharedLinks = async ({
    db,
    links,
    isSaved,
    itemModel,
}: {
    db: Database
    links: Record<string, SharedItem>
    isSaved: boolean
    itemModel: ItemModel
}) => {
    const connectionsMap: Record<string, ConnectionModel> = {}

    const connectionIds = Object.keys(links)
    for (const connectionId of connectionIds) {
        const link = links[connectionId]

        let linkedItem = await itemRepository.getBySources(db, link.sources)

        if (!linkedItem) {
            linkedItem = await itemRepository.create(
                db,
                {
                    id: uuid(),
                    name: link.name,
                    isExpanded: true,
                    isReference: true,
                    description: link.markdown,
                    image: link.image,
                    aliases: link.aliases,
                    isReadable: link.isReadable,
                },
                isSaved
            )
            if (link.sources.length)
                await linkedItem.addSources(
                    link.sources.map((source) => ({ ...source, id: uuid() }))
                )

            if (link.tags.length) {
                for (const tag of link.tags) {
                    await handleCreate(db, linkedItem.id, tag)
                }
            }
        }

        const connection: ConnectionModel = await connectionRepository.create(db, {
            fromItem: itemModel,
            toItem: linkedItem,
        })

        connectionsMap[connectionId] = connection
    }
    return connectionsMap
}

const getSharedCardDetails = async (id: string) => {
    const sharedCardRef = doc(firebase.firestore, FIRESTORE_COLLECTIONS.SHARED_CARDS, id)
    const sharedCard = await getDoc(sharedCardRef)

    if (!sharedCard.exists) return null

    return sharedCard.data()
}

const deleteItem = async (db: Database, item: ItemModel, uid: string) => {
    await assetRepository.deleteByItemId(db, item.id)
    removeReviewSchedule(item)
    sharingRepository.unshareCard(item.id)
    chunksService.removeChunks(uid, item.id)
    chatService.remove(uid, item.id)
    await item.delete()
}

const removeReviewSchedule = async (item: ItemModel) => {
    const questions = await item.questions.fetch()
    await Promise.all(
        questions.map(async (question) => reviewsScheduleRepository.remove(question.id))
    )
}

export type ContentType = "page" | "note" | "entity"

const getContentType = async (item: ItemModel): Promise<ContentType> => {
    const sources = await item.sources.fetch()
    const websiteSource = sources.find((source) => source.name === WEBSITE)
    if (websiteSource) return "page"

    const assets = await item.assets.fetch()
    const pdfAsset = assets.find((asset) => asset.type === ASSET_TYPES.PDF)
    if (pdfAsset) return "page"

    if (!sources.length && !item.aliases.length) return "note"
    return "entity"
}

const enrichMarkdown = async (db: Database, markdown: string, item: ItemModel) => {
    const links = await item.getLinkedItems()

    const linksConnections = (
        await Promise.all(
            links.map(async (link) => {
                const connection = await connectionRepository.getConnection(db, item.id, link.id)
                if (!connection) return null

                return {
                    link,
                    connection,
                }
            })
        )
    ).filter(Boolean) as LinkedItemWithConnection[]

    const editorBlocksWithEntities = await getEditorBlocks(markdown, linksConnections)

    return editorBlocksWithEntities
}

interface CreateItemProps {
    db: Database
    itemApi: ItemApi
    isLink?: boolean
    isSaved?: boolean
}

const create = async ({ db, itemApi, isLink = false, isSaved = true }: CreateItemProps) => {
    return await db.write<ItemModel>(async (writer) => {
        const itemModel = await writer.callWriter(() =>
            createItem({ db, itemApi, isLink, isSaved })
        )
        await writer.callWriter(() => addItemTagsByType(db, itemApi.type, itemModel))

        const linkedItemsWithConnections = await writer.callWriter(() =>
            createLinks(db, itemModel, itemApi.links)
        )

        const editorBlocks = await getEditorBlocks(itemApi.markdown, linkedItemsWithConnections)
        await writer.callWriter(() => itemModel.addEditorBlocks(editorBlocks))
        await writer.callWriter(() =>
            itemModel.addSources(itemApi.sources.map((source) => ({ ...source, id: uuid() })))
        )

        return itemModel
    })
}

const createItem = async ({ db, itemApi, isLink = false, isSaved = true }: CreateItemProps) => {
    return await db.write(async (writer) => {
        let imageUrl = null

        if (itemApi?.images?.[0]) {
            imageUrl = ImageBlockData.getUrl320(itemApi.images[0])
        }

        const item = {
            id: uuid(),
            name: itemApi.name,
            isExpanded: !isLink,
            isReference: isLink,
            image: imageUrl,
            description: "",
            aliases: itemApi.aliases,
            isReadable: false,
        }

        const itemModel = await writer.callWriter(() => itemRepository.create(db, item, isSaved))

        return itemModel
    })
}

const createLinks = async (db: Database, itemModel: ItemModel, links: ItemApi["links"]) => {
    return await db.write(async (writer) => {
        if (!links?.length) return []

        const linkedItemsWithConnections: LinkedItemWithConnection[] = []

        for (let link of links) {
            let linkedItem = await findLinkedItem(db, link.item)

            if (!linkedItem) {
                linkedItem = await writer.callWriter(() =>
                    create({ db, itemApi: link.item, isLink: true, isSaved: itemModel.isSaved })
                )
            } else if (!linkedItem.isSaved && itemModel.isSaved) {
                await writer.callWriter(() => linkedItem!.setIsReference(true))
                await writer.callWriter(() => linkedItem!.saveDeep())
            }

            const connection: ConnectionModel = await writer.callWriter(() =>
                connectionRepository.create(db, {
                    fromItem: itemModel,
                    toItem: linkedItem,
                    property: link.property,
                })
            )

            linkedItemsWithConnections.push({ link: linkedItem, connection })
        }
        return linkedItemsWithConnections
    })
}

const deleteStale = async (db: Database, uid: string) => {
    const items = await itemRepository.getStaleItems(db)
    for (const item of items) await deleteItem(db, item, uid)

    const itemsForMentionsCheck = await itemRepository.getItemsForMentionsCheck(db)

    for (const item of itemsForMentionsCheck) {
        const contentType = await getContentType(item)
        if (contentType !== "entity") continue

        const mentionsCount = await item.mentions.fetchCount()
        if (mentionsCount === 0) await deleteItem(db, item, uid)
    }
}

const updateStaleLoadingItems = async (db: Database) => {
    const items = await itemRepository.getStaleLoadingItems(db)

    for (const item of items) {
        await item.updateIsLoading(false)
        const editorBlocks = await item.editorBlocks.fetch()
        const aiActionsElements = editorBlocks.filter(
            (block) => block.type === ELEMENT_AI_ACTION && block?.options?.isLoading
        )

        for (const aiAction of aiActionsElements) {
            await aiAction.delete()
        }
    }

    return items.length
}

export const itemService = {
    createBaseItem,
    saveReaderConnections,
    createSharedItem,
    getSharedCardDetails,
    deleteItem,
    getContentType,
    getEditorBlocks,
    enrichMarkdown,
    create,
    createChunksMentions,
    deleteStale,
    updateStaleLoadingItems,
}
