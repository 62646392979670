import { SvgIcon, SvgIconProps } from "@mui/material"

export const ShareIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon sx={{ ...props.sx, color: "action.active" }} {...props}>
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M21.5306 10.2805L17.0306 14.7806C16.8899 14.9213 16.6991 15.0004 16.5 15.0004C16.3009 15.0004 16.1101 14.9213 15.9694 14.7806C15.8286 14.6398 15.7496 14.449 15.7496 14.2499C15.7496 14.051 15.8286 13.86 15.9694 13.7193L19.1897 10.4999H15.4687C13.6393 10.4994 11.8617 11.107 10.4153 12.2271C8.96888 13.347 7.93578 14.9162 7.47844 16.6875C7.42871 16.8801 7.30447 17.0452 7.13305 17.1462C6.96163 17.2474 6.75707 17.2762 6.56437 17.2265C6.37168 17.1767 6.20663 17.0525 6.10554 16.881C6.00444 16.7097 5.97558 16.5051 6.02531 16.3125C6.56495 14.2186 7.78571 12.3636 9.49537 11.0398C11.205 9.7159 13.3064 8.99829 15.4687 8.99992H19.1916L15.9694 5.78055C15.8996 5.71086 15.8444 5.62814 15.8068 5.53709C15.769 5.44605 15.7496 5.34848 15.7496 5.24992C15.7496 5.15138 15.769 5.05379 15.8068 4.96275C15.8444 4.8717 15.8996 4.78898 15.9694 4.7193C16.1101 4.57857 16.3009 4.49951 16.5 4.49951C16.5985 4.49951 16.6961 4.51892 16.7872 4.55663C16.8782 4.59434 16.9609 4.64962 17.0306 4.7193L21.5306 9.2193C21.6004 9.28895 21.6557 9.37167 21.6935 9.46272C21.7312 9.55377 21.7506 9.65136 21.7506 9.74992C21.7506 9.84849 21.7312 9.94608 21.6935 10.0371C21.6557 10.1282 21.6004 10.2109 21.5306 10.2805ZM18 19.4999H3.75V8.24992C3.75 8.05101 3.67098 7.86024 3.53033 7.71959C3.38968 7.57894 3.19891 7.49992 3 7.49992C2.80109 7.49992 2.61032 7.57894 2.46967 7.71959C2.32902 7.86024 2.25 8.05101 2.25 8.24992V20.2499C2.25 20.4489 2.32902 20.6396 2.46967 20.7802C2.61032 20.921 2.80109 20.9999 3 20.9999H18C18.199 20.9999 18.3896 20.921 18.5303 20.7802C18.671 20.6396 18.75 20.4489 18.75 20.2499C18.75 20.051 18.671 19.8603 18.5303 19.7196C18.3896 19.579 18.199 19.4999 18 19.4999Z" />
            </svg>
        </SvgIcon>
    )
}
