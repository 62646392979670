import { v4 as uuidv4 } from "uuid"
import { SummaryLengthEnum } from "../repositories/userRepository"
import { Image } from "./Image"
import { Link } from "./Link"
import { Message } from "./Message"
import { Source } from "./Source"
import { Type } from "./Type"

export interface ItemPartial {
    id: string
    name: string
    description: string
    editorBlocks: any[]
    isSaved: boolean
    isReference: boolean
    isExpanded: boolean
    sources: Source[]
    images: Image[]
    createdAt?: string
    updatedAt?: string
    type?: Type
    language: string
    length: SummaryLengthEnum
    aliases: string[]
    isReadable: boolean
}

export interface Item extends ItemPartial {
    links: Link[]
    mentions: Link[]
    messages?: Message[]
    image?: string
}

export const createEmptyItem = (): ItemPartial => {
    return {
        id: uuidv4(),
        name: "",
        description: "",
        editorBlocks: [],
        isSaved: true,
        isExpanded: true,
        isReference: false,
        sources: [],
        images: [],
        language: "en",
        length: SummaryLengthEnum.concise,
        aliases: [],
        isReadable: false,
    }
}
