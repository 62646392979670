import { Avatar, Box, SxProps, Theme, Typography, alpha } from "@mui/material"
import { SurfaceBox } from "components/shared/surfaces/SurfaceBox"

interface CardProps {
    number?: number
    title: string
    subtitle: string
    component: React.ReactNode
}

export const CardStep = ({ number, title, subtitle, component }: CardProps) => {
    return (
        <SurfaceBox sx={styles.card}>
            <Box sx={styles.cardContent}>
                {number && <Avatar sx={styles.avatar}>{number}</Avatar>}
                <Typography variant="body1" fontWeight={400}>
                    {title}
                </Typography>
                {subtitle && (
                    <Typography variant="caption" color="primary.light">
                        {subtitle}
                    </Typography>
                )}
            </Box>
            {component}
        </SurfaceBox>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    card: {
        p: 0,
        flex: 1,
        maxWidth: "400px",
    },
    cardContent: {
        p: 3,
        pb: 1,
        display: "flex",
        flexDirection: "column",
        gap: 1,
    },
    avatar: {
        width: 25,
        height: 25,
        fontSize: "0.9rem",
        fontWeight: 500,
        border: (theme) => `1.5px solid ${theme.palette.primary.main}`,
        backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.2),
        color: (theme) => theme.palette.primary.main,
    },
}
