import { RootState } from "../rootReducer"
import { importEnum } from "./types"

export const isImportModalOpen = (state: RootState) => {
    return [importEnum.IMPORTING, importEnum.IMPORTING_COMPLETE].includes(state.app.importState)
}

export const isBookmarksImportComplete = (state: RootState) => {
    return state.app.importState === importEnum.IMPORTING_COMPLETE
}

export const isBookmarksImporting = (state: RootState) => {
    return state.app.importState === importEnum.IMPORTING
}

export const isUpgradePlanModalOpen = (state: RootState) => {
    return state.app.isUpgradePlanModalOpen
}

export const isScrapingModalOpen = (state: RootState) => {
    return state.app.isScrapingModalOpen
}

export const isLoadingQuestionsSelector = (state: RootState, itemId: string) => {
    return state.app.questionsLoadingItemIds.includes(itemId)
}

export const isAuthModalOpen = (state: RootState) => {
    return state.app.isAuthModalOpen
}

export const isAppReadySelector = (state: RootState) => {
    const userLoading = state.app.userLoading
    const isInitialSyncFinished = state.app.isInitialSyncFinished

    return !userLoading && isInitialSyncFinished
}
