import { Typography, TypographyProps } from "@mui/material"
import { FC } from "react"

export const Subtitle: FC<TypographyProps> = ({ children, ...props }) => {
    return (
        <Typography variant="body1" textAlign="center" color="primary.light" {...props}>
            {children}
        </Typography>
    )
}
