import { Edit, Link, LinkOff, Notes, OpenInNew } from "@mui/icons-material"
import {
    Box,
    Divider,
    IconButton,
    InputBase,
    InputProps,
    SxProps,
    Theme,
    Tooltip,
} from "@mui/material"
import {
    FloatingLinkUrlInput,
    LinkOpenButton,
    useFloatingLinkEdit,
    useFloatingLinkEditState,
    useFloatingLinkInsert,
    useFloatingLinkSelectors,
    useFloatingLinkUrlInput,
    useFloatingLinkUrlInputState,
} from "@udecode/plate-link"
import { useFloatingLinkInsertState } from "../../hooks/useFloatingLinkInsertState"

const FullWidthInput = (props: InputProps) => {
    return <InputBase sx={styles.input} fullWidth {...props} />
}

const FullWidthInputWithLink = (props: InputProps) => {
    return <FullWidthInput startAdornment={<Link sx={{ mr: 1 }} />} {...props} />
}

export function LinkFloatingToolbar(isExtension: boolean) {
    const isEditing = useFloatingLinkSelectors().isEditing()

    const state = useFloatingLinkInsertState({ isExtension })
    const { props: insertProps, ref: insertRef, textInputProps } = useFloatingLinkInsert(state)

    const editState = useFloatingLinkEditState()
    const {
        props: editProps,
        ref: editRef,
        editButtonProps,
        unlinkButtonProps,
    } = useFloatingLinkEdit(editState)

    const urlState = useFloatingLinkUrlInputState()
    const { props: { onChange: onUrlInputChange } } = useFloatingLinkUrlInput(urlState)

    const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onUrlInputChange(e);
        const inputUrl = e.target.value
        if (inputUrl.startsWith("www.")) {
            e.target.value = "https://" + inputUrl
        }
    }

    if (!state.isOpen) return null

    const input = (
        <Box sx={styles.wrapper}>
            <Box sx={styles.inputGroup}>
                <FloatingLinkUrlInput as={FullWidthInputWithLink} placeholder="Paste link" onChange={handleUrlChange} />
            </Box>

            <Box sx={styles.inputGroup} key={textInputProps.defaultValue}>
                <FullWidthInput
                    startAdornment={<Notes sx={{ mr: 1 }} />}
                    placeholder="Text to display"
                    {...textInputProps}
                />
            </Box>
        </Box>
    )

    const editContent = isEditing ? (
        input
    ) : (
        <Box sx={styles.linkOptions}>
            <Tooltip title="Edit Link">
                <IconButton {...editButtonProps} size="small">
                    <Edit />
                </IconButton>
            </Tooltip>

            <Divider orientation="vertical" />

            <Tooltip title="Open Link">
                <LinkOpenButton>
                    <IconButton size="small">
                        <OpenInNew />
                    </IconButton>
                </LinkOpenButton>
            </Tooltip>

            <Divider orientation="vertical" />
            <Tooltip title="Remove Link">
                <IconButton {...unlinkButtonProps} size="small">
                    <LinkOff />
                </IconButton>
            </Tooltip>
        </Box>
    )

    return (
        <>
            <Box ref={insertRef} {...insertProps} style={{ ...insertProps.style, zIndex: 1201 }}>
                {input}
            </Box>

            <Box ref={editRef} {...editProps} style={{ ...editProps.style, zIndex: 1201 }}>
                {editContent}
            </Box>
        </>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    wrapper: {
        minWidth: 350,
        borderRadius: 1,
        p: 1,
        backgroundColor: (theme) => theme.palette.background.paper,
        boxShadow: 4,
        gap: 1,
        display: "flex",
        flexDirection: "column",
    },
    inputGroup: {
        display: "flex",
        alignItems: "center",
        gap: 1,
    },
    linkOptions: {
        display: "flex",
        borderRadius: 1.5,
        padding: 1,
        backgroundColor: (theme) => theme.palette.background.paper,
        boxShadow: 4,
        gap: 0.5,
        alignItems: "center",
    },
    input: {
        backgroundColor: (theme) => theme.palette.action.selected,
        borderRadius: 0.5,
        px: 1,
    },
}
