import { v4 as uuid, v4 as uuidv4 } from "uuid"
import { EditorBlockData, ImageBlockData } from "../.."
import { deserializeMd } from "../../editor/parsers/markdownToSlate"
import { createPlatePlugins } from "../../editor/plugins"
import { Image, ItemPartial, Source, Type } from "../../types"
import { ItemApi } from "./ItemApi"
import { TypeApi } from "./TypeApi"

const addId = (entity: object) => {
    return {
        ...entity,
        id: uuidv4(),
    }
}

export const toItemPartial = (item: ItemApi): ItemPartial => {
    const sources = item.sources.map(addId) as Source[]
    const images = item.images.map(addId) as Image[]
    let imageUrl = null
    if (images.length > 0) {
        imageUrl = ImageBlockData.getUrl320(images[0])
    }

    const editorBlocks = convertMarkdownToEditorBlocks(item.markdown)

    const description = EditorBlockData.getTextWithoutHeadings(editorBlocks)

    return {
        id: uuidv4(),
        ...item,
        isExpanded: false,
        isReference: true,
        type: item.type && toType(item.type),
        sources,
        description,
        editorBlocks,
        images,
        length: item.summaryLength,
        isSaved: false,
        isReadable: false,
    }
}

export const toType = (type: TypeApi): Type => {
    return {
        id: uuidv4(),
        ...type,
    }
}

export const convertMarkdownToEditorBlocks = (markdown: string) => {
    const editorBlocks = deserializeMd(markdown, createPlatePlugins({}))

    return editorBlocks.map((editorBlock: any) =>
        editorBlock.id ? editorBlock : { ...editorBlock, id: uuid() }
    )
}
