import { Send } from "@mui/icons-material"
import { IconButton, InputAdornment, TextField } from "@mui/material"
import { ChatIcon, Spinner, useIsMobile } from "@recall/common"
import { FC, KeyboardEventHandler, useState } from "react"

interface Props {
    handleQuestion: (question: string) => void
    disabled: boolean
    isLoading: boolean
}

export const ChatInput: FC<Props> = ({ handleQuestion, disabled, isLoading }) => {
    const [question, setQuestion] = useState("")
    const isMobile = useIsMobile()

    const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = async (e) => {
        e.stopPropagation()
        if (e.key === "Enter") {
            e.preventDefault()
            setQuestion("")
            await handleQuestion(question)
        }
    }

    const handleSend = () => {
        if (question.length === 0) return
        setQuestion("")
        handleQuestion(question)
    }

    const getEndAdornment = () => {
        if (isLoading) {
            return (
                <InputAdornment position="end">
                    <Spinner size={20} />
                </InputAdornment>
            )
        }

        if (question && isMobile) {
            return (
                <InputAdornment position="end">
                    <IconButton onClick={handleSend}>
                        <Send />
                    </IconButton>
                </InputAdornment>
            )
        }

        return null
    }

    return (
        <TextField
            autoFocus
            value={question}
            disabled={disabled || isLoading}
            placeholder="Chat with source content"
            size="small"
            variant="outlined"
            autoComplete="off"
            onKeyUp={handleKeyDown}
            onKeyPress={handleKeyDown}
            onKeyDown={handleKeyDown}
            onChange={(e) => {
                setQuestion(e.target.value)
            }}
            InputProps={{
                sx: {
                    borderRadius: 25,
                },
                startAdornment: (
                    <InputAdornment position="start">
                        <ChatIcon fontSize="small" />
                    </InputAdornment>
                ),
                endAdornment: getEndAdornment(),
            }}
            multiline
            fullWidth
            maxRows={4}
        />
    )
}
