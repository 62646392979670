import { UsageExceededModal } from "@recall/common"
import { FC } from "react"
import { useSelector } from "react-redux"
import { RootState } from "storage/redux/rootReducer"
import { AppUpgradeButton } from "../buttons/AppUpgradeButton"

interface Props {
    closeModal(): void
}

export const UsageExceededAppModal: FC<Props> = ({ closeModal }) => {
    const isPremiumUser = useSelector((state: RootState) => state.user.isPremiumUser)

    return (
        <UsageExceededModal
            closeModal={closeModal}
            isPremiumUser={isPremiumUser}
            upgradeButton={<AppUpgradeButton onClick={() => closeModal()} />}
        />
    )
}
