import { buildUrl, HttpClient, sentry } from "../utils"
import { BaseApi } from "./BaseApi"

const AUTO_FORMAT_PATH = "/actions/auto-format"

export class ActionsApi extends BaseApi {
    constructor(httpClient: HttpClient) {
        super(httpClient)
    }

    async autoFormat(markdown: string, itemId: string, url: string) {
        const response = await this.httpClient.post(
            buildUrl(AUTO_FORMAT_PATH, { itemId, url }),
            JSON.stringify({ markdown }),
            this.getHeaders({})
        )
        return await response.json()
    }
}
