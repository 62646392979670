import {
    alpha,
    Box,
    CircularProgress,
    circularProgressClasses,
    CircularProgressProps,
    SxProps,
    Theme,
} from "@mui/material"

export const Spinner = (props: CircularProgressProps) => {
    return (
        <Box sx={styles.container}>
            <CircularProgress
                variant="determinate"
                sx={styles.backCircle}
                size={50}
                thickness={6}
                {...props}
                value={100}
            />
            <CircularProgress
                variant="indeterminate"
                disableShrink
                sx={{
                    ...styles.frontCircle,
                    color: (theme) => alpha(theme.palette.primary.main, 0.8),
                }}
                size={50}
                thickness={6}
                {...props}
            />
        </Box>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    container: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    backCircle: {
        color: (theme) => alpha(theme.palette.text.primary, 0.2),
    },
    frontCircle: {
        animationDuration: "800ms",
        position: "absolute",
        left: 0,
        [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
        },
    },
}
