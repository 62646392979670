import {
    ASSET_TYPES,
    getItemSourceUrl,
    isYoutubeVideo,
    ItemModel,
    SummaryLengthEnum,
} from "@recall/common"
import { summariesApi } from "services/api"

const getPdfUrl = async (item: ItemModel) => {
    const assets = await item.assets.fetch()
    const pdfAsset = assets.find((asset) => asset.type === ASSET_TYPES.PDF)
    return pdfAsset?.url
}

const summarize = async (
    item: ItemModel,
    markdown: string,
    length: SummaryLengthEnum,
    language = "auto"
) => {
    let url = await getItemSourceUrl(item)

    if (!url) {
        url = await getPdfUrl(item)
    }

    if (isYoutubeVideo(url)) {
        return await summarizeYoutubeMarkdown(url, length, markdown, item, language)
    } else {
        return await summarizeMarkdown(url, length, markdown, item, language)
    }
}

const summarizeMarkdown = async (
    url: string,
    length: SummaryLengthEnum,
    markdown: string,
    item: ItemModel,
    language: string
) => {
    const summary = await summariesApi.summarizeMarkdown(
        url,
        item.id,
        length,
        markdown,
        item.name,
        {
            language,
        }
    )
    return summary.markdown
}

const summarizeYoutubeMarkdown = async (
    url: string,
    length: SummaryLengthEnum,
    markdown: string,
    item: ItemModel,
    language: string
) => {
    const summary = await summariesApi.summarizeYoutubeMarkdown(
        url,
        item.id,
        length,
        markdown,
        item.name,
        {
            language,
        }
    )
    return summary.markdown
}

export const summaryService = {
    summarize,
}
