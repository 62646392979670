import { SxProps, Theme, Typography } from "@mui/material"
import { useBreadcrumbActions } from "hooks/useBreadcrumbActions"
import { FC } from "react"
import { useHistory } from "react-router"

interface Props {
    label: string
    path: string
    active?: boolean
}

export const Breadcrumb: FC<Props> = ({ label, path, active }) => {
    const { removeBreadcrumbsAfter } = useBreadcrumbActions()
    const history = useHistory()

    if (active) return <Typography sx={styles.currentPage}>{label}</Typography>

    return (
        <Typography
            sx={styles.link}
            onClick={() => {
                removeBreadcrumbsAfter(path)
                history.push(path)
            }}
        >
            {label}
        </Typography>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    link: {
        opacity: 0.7,
        fontSize: { xs: "1rem", sm: "0.875rem" },
        cursor: "pointer",
        ":hover": {
            opacity: 1,
        },
    },
    currentPage: {
        fontSize: { xs: "1rem", sm: "0.875rem" },
        opacity: 1,
        fontWeight: 500,
    },
}
