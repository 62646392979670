import { Model, Relation } from "@nozbe/watermelondb"
import { BelongsToAssociation } from "@nozbe/watermelondb/Model"
import { field, json, relation, writer } from "@nozbe/watermelondb/decorators"
import { getFormattedText } from "../helpers/editorBlocks"
import { EDITOR_BLOCKS } from "../schema"
import { ItemModel } from "./ItemModel"

const sanitizeEditorBlock = (data: any) => {
    return data
}

const sanitizeOptions = (data: any) => {
    return data
}

export class EditorBlockModel extends Model {
    static table = EDITOR_BLOCKS

    static associations = {
        items: { type: "belongs_to", key: "item_id" } as BelongsToAssociation,
    }

    @field("is_saved") isSaved: boolean
    @field("type") type: string
    @relation("items", "item_id") item: Relation<ItemModel>
    @json("children", sanitizeEditorBlock) children: any[]
    @json("options", sanitizeOptions) options: any
    @field("text") text: string

    prepareSave = () => {
        if (this.isSaved === false) {
            const text = getFormattedText(this.children)

            return this.prepareUpdate((record: EditorBlockModel) => {
                record.isSaved = true
                record.text = text
            })
        }

        return null
    }

    prepareDelete = () => {
        if (this._preparedState === null) {
            if (this.isSaved) {
                return this.prepareMarkAsDeleted()
            } else {
                return this.prepareDestroyPermanently()
            }
        }
    }

    @writer async delete() {
        const task = this.prepareDelete()
        await this.batch(task)
    }

    @writer async updateUrl(url: string) {
        await this.update((record) => {
            record.options = record.options ? { ...record.options, url } : { url }
        })
    }

    @writer async updateChildren(children: any[]) {
        await this.update((record) => {
            record.children = children
        })
    }
}
