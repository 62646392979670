import { useEffect } from "react"
import { useSelector } from "react-redux"
import { RootState } from "storage/redux/rootReducer"
import { GTMService } from "services/GTMService"

export const useUserMilestones = () => {
    const usage = useSelector((state: RootState) => state.user.usage)

    useEffect(() => {
        if (usage === 3) {
            GTMService.trackThreeCardsMilestone()
        }
    }, [usage])
}
