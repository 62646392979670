import { Box, IconButton, SxProps, Theme } from "@mui/material"
import { ItemModel } from "@recall/common"
import { Pencil } from "lucide-react"
import { FC, useEffect, useState } from "react"
import { AssetsModal } from "./Assets/AssetsModal"

interface Props {
    item: ItemModel
}

export const ItemImage: FC<Props> = ({ item }) => {
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false)
    const [isPortrait, setIsPortrait] = useState(false)

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setIsUpdateModalOpen(true)
    }
    const handleClose = () => {
        setIsUpdateModalOpen(false)
    }

    const checkImageOrientation = () => {
        if (item.image) {
            const img = new Image()
            img.src = item.image
            img.onload = () => {
                setIsPortrait(img.height > img.width)
            }
        }
    }

    useEffect(() => {
        checkImageOrientation()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item.image])

    return (
        <Box sx={styles.container}>
            <Box
                sx={{
                    ...styles.imageWrapper,
                    backgroundImage: `url("${item.image}")`,
                    backgroundColor: "background.paper",
                    backgroundSize: { xs: "contain", md: "cover" },
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    width: { xs: "100%", md: isPortrait ? 120 : 200 },
                    height: { xs: 180, md: "100%" },
                    borderRadius: 1,
                }}
            >
                <IconButton className="update-button" sx={styles.updateButton} onClick={handleOpen}>
                    <Pencil />
                </IconButton>
            </Box>

            {isUpdateModalOpen && <AssetsModal item={item} closeModal={handleClose} />}
        </Box>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    container: {
        display: "flex",
        alignItems: "flex-start",
        height: "100%",
    },
    imageWrapper: {
        position: "relative",
        display: "flex",
        "&:hover": {
            ".update-button": {
                opacity: 1,
            },
        },
    },

    updateButton: {
        opacity: 0,
        position: "absolute",
        top: 0,
        right: 0,
        m: 1,
        zIndex: 1,
        "&:hover": {
            backgroundColor: "background.paper",
        },
    },
}
