import ImageIcon from "@mui/icons-material/Image"
import { alpha, Box, SvgIcon, SxProps, Theme } from "@mui/material"
import { ReactComponent as PdfIcon } from "assets/svg/pdf_outlined.svg"
import { FC } from "react"

interface Props {
    sx?: SxProps<Theme>
    isPdf?: boolean
}

export const PlaceHolderImage: FC<Props> = ({ sx, isPdf = false }) => {
    return (
        <Box sx={[styles.image, ...(Array.isArray(sx) ? sx : [sx])]}>
            {isPdf ? (
                <SvgIcon sx={styles.icon} fontSize="large">
                    <PdfIcon />
                </SvgIcon>
            ) : (
                <ImageIcon sx={styles.icon} fontSize="large" />
            )}
        </Box>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    image: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: (theme) => alpha(theme.palette.text.primary, 0.05),
        width: 90,
        minHeight: 130,
        height: "100%",
    },
    icon: {
        color: "action.selected",
    },
}
