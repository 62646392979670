import { SxProps, Theme, Tooltip, Typography } from "@mui/material"
import { FC } from "react"
import CountBadge from "./CountBadge"

interface Props {
    name: string
    count: number
    onlyOtherMentionCount?: boolean
    sx?: SxProps<Theme>
}

export const TotalCountBadge: FC<Props> = ({ count, onlyOtherMentionCount, name, sx }) => {
    const tooltipTitle = (
        <Typography variant="body2">
            <b>{name}</b> is mentioned by <b>{count}</b>
            {onlyOtherMentionCount ? " other" : ""} card
            {count > 1 ? "s" : ""}
        </Typography>
    )

    if (count < 1) return null

    return (
        <Tooltip title={tooltipTitle}>
            <span>
                <CountBadge count={count} sx={sx} />
            </span>
        </Tooltip>
    )
}
