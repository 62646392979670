import { ELEMENT_AI_ACTION, ELEMENT_CUSTOM_IMAGE, useMediaBreakpoint } from "@recall/common"
import {
    focusEditor,
    getEndPoint,
    getPluginType,
    insertNodes,
    usePlateEditorRef,
} from "@udecode/plate-common"
import { ELEMENT_IMAGE } from "@udecode/plate-media"
import { ELEMENT_PARAGRAPH } from "@udecode/plate-paragraph"
import { useEffect } from "react"
import { Path } from "slate"

export const useFocusEditor = (focus: boolean) => {
    const editor = usePlateEditorRef()
    const isTouchableDevice = useMediaBreakpoint("md")

    useEffect(() => {
        if (!focus || !editor || isTouchableDevice) return
        let lastNodeIndex = editor.children.length > 0 ? editor.children.length - 1 : 0
        const lastNode = editor.children[lastNodeIndex]

        if (
            lastNode.type === ELEMENT_AI_ACTION ||
            lastNode.type === ELEMENT_IMAGE ||
            lastNode.type === ELEMENT_CUSTOM_IMAGE
        ) {
            const nodeContent = {
                type: getPluginType(editor, ELEMENT_PARAGRAPH),
                children: [{ text: "" }],
            }
            insertNodes(editor, nodeContent, { at: Path.next([lastNodeIndex]) })
            lastNodeIndex = editor.children.length - 1
        }

        if (lastNodeIndex !== 0) return

        const endPoint = getEndPoint(editor, [lastNodeIndex])
        focusEditor(editor, endPoint)
    }, [editor, focus, isTouchableDevice])
}
