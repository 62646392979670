import { WEBSITE, WIKIPEDIA_PAGE } from "../constants"
import { ItemModel } from "../storage/watermelon/models/ItemModel"
import { WikipediaUrlRegex } from "./url"

export const getSlugAndLanguage = (url: string) => {
    const parts = WikipediaUrlRegex.exec(url)
    if (!parts) return { lang: null, slug: null }

    const lang = parts[1]
    const slug = parts[2]

    return { lang, slug }
}

export const getItemSourceUrl = async (item: ItemModel) => {
    const sources = await item.sources.fetch()

    const websiteSource = sources.find((source) => source.name === WEBSITE)
    if (websiteSource) return websiteSource.identifier

    const wikipediaSource = sources.find((source) => source.name === WIKIPEDIA_PAGE)
    if (wikipediaSource) return wikipediaSource.identifier

    return null
}
